import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  DRAWER_TYPES,
  setOpenDrawer,
  setProductsBrands,
} from '../../../script/redux/actions.app'
import Button from '../../form/Button'
import Checkbox from '../../form/Checkbox'

const ProductsFilter = (props) => {
  const { dispatch, brands, closeDrawer } = props
  const [localBrands, setLocalBrands] = useState(brands)

  useEffect(() => {
    setLocalBrands(brands)
  }, [brands])

  const onBrandChange = (event, brand, index) => {
    const newBrands = JSON.parse(JSON.stringify(localBrands))
    newBrands[index].active = event.target.checked
    setLocalBrands(newBrands)
  }

  const onClear = () => {
    dispatch(
      setProductsBrands(localBrands.map((el) => ({ ...el, active: false })))
    )
    dispatch(setOpenDrawer('FILTER_PRODUCTS', false))
  }

  const onApply = () => {
    dispatch(setProductsBrands(localBrands))
    dispatch(setOpenDrawer('FILTER_PRODUCTS', false))
  }

  return (
    <div className="bw-store-selector bw-orders-drawer">
      <div className="bw-store-selector--header bw-mb-8">
        <button
          className="bw-store-selector--close"
          onClick={() => closeDrawer(DRAWER_TYPES.filterProducts)}
        ></button>
        <h2 className="bw-store-selector--title">Filter</h2>
      </div>
      <div className="bw-orders-drawer-brands">
        {localBrands?.map((brand, index) => (
          <Checkbox
            key={index}
            value={brand.label}
            checked={brand.active}
            onChange={(e) => onBrandChange(e, brand, index)}
          >
            {brand.label + ` (${brand.count})`}
          </Checkbox>
        ))}
      </div>
      <div className="bw-orders-drawer--btns">
        <Button
          label="Clear"
          className="bw-button--secondary"
          onClick={onClear}
        />
        <Button label="Apply" onClick={onApply} />
      </div>
    </div>
  )
}

ProductsFilter.propTypes = {}

const stateMapping = (state) => {
  return {
    brands: state.app.productsBrands || [],
  }
}

export default connect(stateMapping)(ProductsFilter)
