import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "Components/Preloader";
import { openPopup } from "Redux/actions.app";
import StockCardProduct from "Components/blocks/StockCardProduct";
import DropDown from "Components/blocks/DropDown";
import { getStockCardREQUEST } from "@Requests/products";
import { StockCardsortTypes } from "Functions/data";
import productHelper from "Functions/product.helper";
import { addOrder, updateSavedCart } from "Redux/actions.prod";
import ProductBar from "Components/blocks/ProductBar";
import {
  GAEcommerce,
  gaStockCardAddToCard,
  getGASocialProofingLabels,
  getIsSocialProofed,
} from "../../script/functions/ga";
import { addCartProduct, getCart } from "../../axios/private/cart";
import { generalError } from "../../utils/constants";
import { normalizeOldCartData } from "../../utils/functions/old";
import { connect } from "react-redux";
import {
  completeTour,
  getTourStatus,
  saveTourProgress,
} from "../../script/requests/products";
import InfoButton from "../../components/StockCard/InfoButton";
import TourPopOver from "../../components/StockCard/TourPopOver";
import useScrollBackToTop from "../../utils/hooks/useScrollBackToTop";
import messageBanner from "../../images/stockcard-banner.png";

const tourSteps = {
  0: "Welcome to My Stock Card! Our new intelligent functionality has created your next order based on your last five orders. Take our tour to find out how to use Stock Card and make future ordering quick and easy.",
  1: <><Link style={{color: "white"}} to="/videos">Click here</Link> to watch our helpful video that explains how to use 'My Stock Card'.</>,
  2: "You can view your past five orders here.",
  3: "Check your total order cost here. This will update automatically as you add or remove products from the list above.",
  4: "When you're happy with your order, click 'Add to Cart'. This will add all your produces to your cart. You will then be promoted to ‘Proceed to Checkout’, hit the button again to checkout securely.",
};


function StockCard({ history, savedCart, dispatch, beCart, accountId, areStockcardProductsInCart }) {
  const [sortType, setSortType] = useState(0);
  const [stockCardProductList, setStockCardProductList] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const [error, setError] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [showProceedToCart, setShowProceedToCart] = useState(areStockcardProductsInCart);
  const [isTourComplete, setIsTourComplete] = useState(false);
  const [tourStep, setTourStep] = useState("");
  useScrollBackToTop();

  let catBrand = "0";

  const totalQuantityToPurchase = stockCardProductList.reduce(
    (sum, prod) => sum + prod.QuantityToPurchase,
    0,
  );

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return [day, month, year].join("/");
  };

  const filterStockCardProducts = (data) => {
    let stockCardProductList = [];
    let list = [];

    const purchaseHistory = data.PreviousOrders.map((product) => ({
      OrderDate: product.OrderDate,
      OrderNumber: product.OrderNumber,
      OrderTotal: product.OrderTotal,
    })).reverse();

    data.PreviousOrders.forEach((product) => {
      stockCardProductList = [
        ...stockCardProductList,
        ...product.Purchases.map((group) => ({
          ...group,
          OrderDate: product.OrderDate,
        })),
      ];
    });

    stockCardProductList = stockCardProductList.map((product) => ({
      ...product.Product,
      QuantityPurchased: product.Quantity,
      OrderDate: product.OrderDate,
    }));

    data.DerivedValues.forEach((derivedValue) => {
      let historyList = [];
      stockCardProductList.forEach((prod) => {
        if (prod.SKU === derivedValue.SKU) {
          historyList.push({
            QuantityPurchased: prod.QuantityPurchased,
            OrderDate: prod.OrderDate,
          });
        }
      });

      list.push({
        ...derivedValue,
        ...stockCardProductList.find((p) => p.SKU === derivedValue.SKU),
        History: historyList,
      });
    });

    stockCardProductList = list.map((pro) => {
      delete pro.OrderDate;
      delete pro.QuantityPurchased;
      return {
        ...pro,
        QuantityToPurchase: pro.OutOfStock ? 0 : pro.SuggestedPurchase,
      };
    });

    stockCardProductList = stockCardProductList.filter(
      (pro) => !pro.IsArchived,
    );
    stockCardProductList = productHelper.sorSCtProducts(
      0,
      stockCardProductList,
    );

    setPurchaseHistory(purchaseHistory);
    setStockCardProductList(stockCardProductList);
  };

  const remove = (sku) => {
    setStockCardProductList((prevList) =>
      prevList.filter((product) => product.SKU !== sku),
    );
  };

  const selectQuantity = (sku, action) => {
    setStockCardProductList((prevList) =>
      prevList.map((product) => {
        if (product.SKU === sku) {
          let newQuantityToPurchase = product.QuantityToPurchase;
          if (action === "-" && newQuantityToPurchase > 0) {
            newQuantityToPurchase -= product.MinimumOrderIncremental;
          } else if (action === "+") {
            newQuantityToPurchase += product.MinimumOrderIncremental;
          }
          return {
            ...product,
            QuantityToPurchase: newQuantityToPurchase,
          };
        }
        return product;
      }),
    );
  };

  const sort = (value) => {
    const sortType = productHelper.sortSC(value);
    setSortType(sortType);
    setStockCardProductList((prevList) =>
      productHelper.sorSCtProducts(sortType, prevList),
    );
  };

  const addAllToCart = () => {
    setLoading(true);
    setDisableAddToCart(false);
    gaStockCardAddToCard();
    saveToDatabaseBulk(stockCardProductList, () => {
      dispatch(openPopup("alert", "", ""));
    });

    stockCardProductList.forEach((item) => {
      const socialProofed = item.Product.IsSocialProofing
        ? "social_proofed"
        : "not_social_proofed";

      GAEcommerce(
        "add_to_cart",
        accountId,
        item.PriceOuter,
        item.PackBarCode,
        item.Product,
        item.Brand,
        item.Category,
        "suggested",
        getIsSocialProofed(item),
        "stock_card",
        item.QuantityToPurchase,
        "stock_card",
        getGASocialProofingLabels(item),
      );
    });
  };

  const saveToDatabaseBulk = (products) => {
    Promise.all(
      products.map((product) =>
        addCartProduct({
          beCartId: beCart?.BackEndCartId,
          SKU: product?.SKU,
          quantity: product?.QuantityToPurchase,
          cart_add: "stock_card",
        }),
      ),
    )
      .then((res) => {
        getCart()
          .then((res) => {
            dispatch(updateSavedCart(normalizeOldCartData(res)));
          })
          .catch((err) => {});
        products.forEach((product) => {
          if (product.QuantityToPurchase > 0) {
            dispatch(
              addOrder(
                product.sku,
                product.QuantityToPurchase,
                product.QuantityToPurchase,
                true,
              ),
            );
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(openPopup("alert", generalError, ""));
      });
  };

  const skipTour = () => {
    setTourStep("skip");
    completeTour("skip");
    setIsTourComplete(true);
  };

  const scrollToElem = (element) => {
    setTimeout(() => {
      document
        .getElementById(element)
        .querySelector(".tooltip")
        .scrollIntoView({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
    }, 100);
  };

  useEffect(() => {
    if (savedCart) {
      document.cookie = `stockCardId=${savedCart.id}`;
    }

    getStockCardREQUEST((result, data) => {
      if (result) {
        filterStockCardProducts(data.body);
      } else {
        if (data.statusCode === 204) {
          setNoProducts(true);
        } else {
          setError(true);
        }
      }
    });

    getTourStatus().then((res) => {
      if (res) {
        setIsTourComplete(res.Completed);
        setTourStep(res.LastStep);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 931) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setShowProceedToCart(areStockcardProductsInCart);
  }, [areStockcardProductsInCart]);

  return (
    <>
      {error ? (
        <div className="page page-pad-L stock-card">
          <div className="message-section" style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "1.3rem",
                lineHeight: "1.3",
                marginBottom: "50px",
              }}
            >
              We cannot load your order history at this time, please use the
              place order page or check back later for Stock card access.
              Apologies for any inconvenience.
            </p>
            <Link
              to="/place-order"
              className="button_style-1 fontsize19"
              onClick={() =>
                GA(gac, "Button :: Place Order", "click", accountId)
              }
            >
              Place Order
            </Link>
          </div>
        </div>
      ) : noProducts ? (
        <div className="page page-pad-L stock-card">
          <div className="message-section" style={{ textAlign: "center" }}>
            <p
              style={{
                fontSize: "1.3rem",
                lineHeight: "1.3",
                marginBottom: "50px",
              }}
            >
              You will need to place some orders for us to make our
              recommendations. Please add products to your cart from the place
              order page.
            </p>
            <Link
              to="/place-order"
              className="button_style-1 fontsize19"
              onClick={() =>
                GA(gac, "Button :: Place Order", "click", accountId)
              }
            >
              Place Order
            </Link>
          </div>
        </div>
      ) : (
        <div className={"bw-relative"}>
          <div className="stock-card bw-relative">
            <div className="message-section message-section_banner bw-relative">
              <div className="page page-pad-L bw-flex">
                <div className="bw-flex message-section_banner-left">
                  <img src={messageBanner} />
                </div>
                <div
                  className="message-section_banner-right"
                  id="message-section_banner-right"
                >
                  <h2 className="bw-h1">
                    <span>Welcome to</span>
                    <br />
                    MY STOCK CARD
                  </h2>
                  <p>
                    Introducing the intelligent stock card that provides faster
                    and personalised ordering specifically for you.
                  </p>
                  <Link to="/videos" className="button_style-4 how-to-button">
                    How do I work My Stock Card?
                  </Link>
                  {tourStep === "1" && (
                    <TourPopOver
                      title={"My Stock Card"}
                      className={"tour-1"}
                      description={tourSteps[1]}
                      onSkipTour={skipTour}
                      onPrevious={() => {
                        setTourStep("0");
                        saveTourProgress("0");
                        scrollToElem("stockCard-header");
                      }}
                      onNext={() => {
                        setTourStep("2");
                        saveTourProgress("2");
                        scrollToElem("order-history");
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="page page-pad-L">
              <div className={"bw-flex bw-items-center bw-relative"}>
                <h3 className="title"> MY STOCK CARD </h3>
              </div>

              <div className="stockCard-header" id="stockCard-header">
                <a href="/place-order" className="continue-product-page-cta">
                  Continue to product page
                </a>
                <div className="calculated-title">
                  <p>
                    {tourStep === "0" && (
                      <TourPopOver
                        title={"My Stock Card"}
                        className={"tour-0"}
                        description={tourSteps[0]}
                        onNext={() => {
                          setTourStep("1");
                          saveTourProgress("1");
                          scrollToElem("message-section_banner-right");
                        }}
                        onSkipTour={skipTour}
                      />
                    )}
                    We’ve Calculated Your Order
                    <InfoButton
                      onClick={() => {
                        setTourStep("0");
                        scrollToElem("stockCard-header");
                      }}
                    />
                  </p>
                </div>
                <p className="calculated-info">
                  Number of items based on the users previous 5 orders
                </p>
              </div>
              <div className="menu-bar">
                <div className="dropdown-and-add-group bw-relative">
                  <div className="sort-stockcard-dropdown">
                    <label>
                      <b>Sort By</b>
                    </label>
                    <DropDown
                      options={StockCardsortTypes.map((st) => st.label)}
                      select={(value) => sort(value)}
                      defaultIndex={sortType}
                    />
                  </div>
                </div>
              </div>
              {stockCardProductList.length > 0 ? (
                stockCardProductList.map((prod, i) => {
                  const hasHead = catBrand !== prod.Category + prod.Brand;
                  catBrand = prod.Category + prod.Brand;

                  return (
                    <StockCardProduct
                      key={i}
                      index={i}
                      purchaseHistory={purchaseHistory}
                      product={prod}
                      remove={remove}
                      selectQuantity={selectQuantity}
                      hasHead={hasHead}
                      accountId={accountId}
                    />
                  );
                })
              ) : (
                <p
                  className="fontsize19"
                  style={{ textAlign: "center", margin: "30px auto" }}
                >
                  There are no products to display.
                </p>
              )}

              <div className="footer-sc" id="footer-sc">
                <div className="footer-sc_number">
                  <a href="/place-order" className="continue-product-page-cta">
                    Continue to product page
                  </a>
                </div>
                <div className="footer-sc_total_add">
                  <div className="footer-sc_total-container">
                    <div>
                      <p>
                        Total amount:
                        <span className="footer-sc_total bw-relative">
                          €
                          {stockCardProductList
                            .reduce(
                              (sum, prod) =>
                                sum + prod.QuantityToPurchase * prod.PriceOuter,
                              0,
                            )
                            .toFixed(2)}
                          {tourStep === "3" && (
                            <TourPopOver
                              title={"My Stock Card"}
                              className={"tour-4"}
                              description={tourSteps[3]}
                              onSkipTour={skipTour}
                              onPrevious={() => {
                                setTourStep("2");
                                saveTourProgress("2");
                                scrollToElem("order-history");
                              }}
                              onNext={() => {
                                setTourStep("4");
                                saveTourProgress("4");
                                scrollToElem("footer-sc");
                              }}
                            />
                          )}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Items added to cart:
                        <span>
                          {stockCardProductList.reduce(
                            (sum, prod) => sum + prod.QuantityToPurchase,
                            0,
                          )}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="bw-relative bw-float-right footer-sc_buttons-right">
                    {showProceedToCart ? (
                      <Link
                        to="/cart"
                        role="button"
                        className="button_style-5 proceed-cta"
                      >
                        Proceed To Cart
                      </Link>
                    ) : (
                      <button
                        className={`button_style-4 black add-all-cta ${
                          !totalQuantityToPurchase || disableAddToCart
                            ? " disabled"
                            : ""
                        }`}
                        disabled={!totalQuantityToPurchase || disableAddToCart}
                        onClick={(e) => {
                          e.preventDefault();
                          addAllToCart();
                          setShowProceedToCart(true);
                        }}
                      >
                        Add To Cart
                      </button>
                    )}

                    {tourStep === "4" && (
                      <TourPopOver
                        title={"My Stock Card"}
                        className={"tour-5"}
                        description={tourSteps[4]}
                        onSkipTour={skipTour}
                        isLastStep
                        onPrevious={() => {
                          setTourStep("3");
                          saveTourProgress("3");
                          scrollToElem("footer-sc");
                        }}
                        onNext={() => {
                          setTourStep("5");
                          saveTourProgress("5");
                          completeTour("5");
                          setIsTourComplete(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="order-history bw-relative" id="order-history">
                {tourStep === "2" && (
                  <TourPopOver
                    title={"My Stock Card"}
                    className={"tour-2"}
                    description={tourSteps[2]}
                    onSkipTour={skipTour}
                    onPrevious={() => {
                      setTourStep("1");
                      saveTourProgress("1");
                      scrollToElem("message-section_banner-right");
                    }}
                    onNext={() => {
                      setTourStep("3");
                      saveTourProgress("3");
                      scrollToElem("footer-sc");
                    }}
                  />
                )}

                <div className="order-history_col">Your Previous Orders</div>

                {purchaseHistory.map((purchase, i) => (
                  <div key={i} className="order-history_col">
                    {formatDate(purchase.OrderDate)}
                  </div>
                ))}
              </div>
              <div className="order-numbers">
                <div className="order-numbers_col">Order Number</div>

                {purchaseHistory.map((purchase) => (
                  <div key={purchase.OrderNumber} className="order-numbers_col">
                    {
                      <a
                        href={`/review-order?ordernumber=${purchase.OrderNumber}`}
                      >
                        {purchase.OrderNumber}
                      </a>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ProductBar />
        </div>
      )}

      {loading && <Preloader fixed={true} />}
    </>
  );
}

function stateMapping(state) {
  return {
    savedCart: state.prod.savedCart,
    dispatch: state.prod.dispatch,
    beCart: state.app.cart,
    accountId: state.app.currentAccount.JtiAccountId,
    areStockcardProductsInCart: state.app.cart.HasStockcardProduct
  };
}

export default connect(stateMapping)(StockCard);
