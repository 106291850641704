import React, { useState } from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { resendEmailForm } from '../../utils/forms/public'
import { resendEmail } from '../../axios/public'
import { generalError } from '../../utils/constants'

import Button from '../../components/form/Button'
import TextInput from '../../components/form/TextInput'

const ResendVerification = (props) => {
  const [state, setState] = useState({
    submitted: false,
    error: false,
  })

  const onResend = async (values, { setSubmitting }) => {
    await resendEmail(values)
      .then(() => {
        setState((oldVal) => ({
          ...oldVal,
          error: false,
          submitted: true,
        }))
      })
      .catch((err) => {
        const message = err?.data?.Message || generalError
        setState((oldVal) => ({
          ...oldVal,
          error: message,
        }))
      })
    setSubmitting(false)
  }

  return (
    <>
      <h3 className="bw-h3 bw-mb-6">RE-SEND VERIFICATION EMAIL</h3>

      {!state.submitted ? (
        <>
          {state.error && (
            <p className="bw-text-red bw-text-15 bw-mb-2">{state.error}</p>
          )}
          <Formik {...resendEmailForm} onSubmit={onResend}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                  label="Email Address"
                  errortext={errors.Email && touched.Email && errors.Email}
                />

                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  label="Submit"
                />
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <p className="bw-font-bold bw-text-23 bw-mb-4">Thank you!</p>
          <p className="bw-font-normal bw-text-14 bw-mb-4 bw-pr-8 bw-leading-snug">
            The confirmation email has been sent to your address again. Please
            check your emails to verify your email address
          </p>
          <a href="/login" className="bw-btn-link">
            Go to Log In
          </a>
        </>
      )}
    </>
  )
}

export default connect()(ResendVerification)
