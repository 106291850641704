import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { resetAuth, setSuggestedStorage } from 'Functions/storage'
import { openPopup, closePopup, hidePopup, toggleLoader } from 'Redux/actions.app'
import { setOrderEditing } from 'Redux/actions.prod'

import PopupCheckout from 'Components/popup-actions/Checkout'
import PopupClearCart from 'Components/popup-actions/ClearCart'
import PopupReviewOrder from 'Components/popup-actions/ReviewOrder'
import PopupSuggested from 'Components/popup-actions/Suggested'
import PopupForgotPassAlert from 'Components/popup-actions/ForgotPassAlert'
import PopupStepGuide from 'Components/popup-actions/StepGuide'
import PopupJustAfterLogin from 'Components/popup-actions/JustAfterLogin'
import PopupCancelOrder from 'Components/popup-actions/CancelOrder'
import PopupEditOrder from 'Components/popup-actions/EditOrder'
import PopupSubmitTAndTWarn from 'Components/popup-actions/SubmitTAndTWarn'
import PopupVideo from 'Components/popup-actions/VideoPop'

import PromoCodeField from 'Components/popup-actions/PromoCodeField'
import NavigationPrompt from 'Components/popup-actions/NavigationPrompt'

class Popup extends React.Component {

  getButtons(section) {
    const { history, array } = this.props;

    switch (section) {
      case 'logout':
        return (
          <div className="buttons">
            <button className="button_style-1 fontsize19" onClick={(e) => this.close(e)}>Cancel</button>
            <button className="button_style-2 fontsize19" onClick={(e) => this.logout(e)}>Log out</button>
          </div>
        );

      case 'close-redirect':
        return (
          <div className="buttons">
            <button className="button_style-2 fontsize19" onClick={(e) => this.redirect(e, '/login')}>Close</button>
          </div>
        );

      case 'fail':
      case 'redirect':
        return (
          <div className="buttons">
            <button className="button_style-2 fontsize19" onClick={(e) => this.redirect(e, '/login')}>Go to Login</button>
          </div>
        );

      case 'expired':
        return (
          <div className="buttons">
            <button className="button_style-2 fontsize19" onClick={(e) => this.redirect(e, '/resend-email')}>Re-send email</button>
          </div>
        );

      case 'success':
      case 'html-message':
      case 'checkoutError':
        return (
          <div className="buttons">
            <button className="button_style-2 fontsize19" onClick={(e) => this.close(e)}>Close</button>
          </div>
        );

      case 'account':
        return (
          <div className="buttons">
            <button className="button_style-1 fontsize19" onClick={(e) => this.redirect(e, '/my-account')}>Close</button>
          </div>
        );

      case 'redirect-home':
      case 'just-after-login':
        return (
          <div className="buttons">
            <button className="button_style-1 fontsize19" onClick={(e) => this.redirect(e, '/')}>Close</button>
          </div>
        );

      case 'clear-cart':
        return (
          <PopupClearCart history={history} closeHandler={(e) => this.close(e)} />
        );

      case 'checkout':
        return (
          <PopupCheckout history={history} closeHandler={(e) => this.close(e)} orderDetail={array[0]} />
        );
        
      case 'edit-order':
        return (
          <PopupEditOrder history={history} closeHandler={(e) => this.close(e)} orderDetail={array[0]} />
        );

      case 'cancel-order':
        return (
          <PopupCancelOrder history={history} closeHandler={e => this.close(e)} orderId={array[0]} />
        );
     

      default:
        return null;
    }
  }

  render() {
    const { show, open, title, desc, section, array } = this.props;

    const animType = open ? 'fadeIn' : 'fadeOut';

    return (
      <div className={'popup-block animated duration1 ' + animType}>
        <div>
          <div>
            {
              title !== '' && (
                /^(alert|fail|checkoutError)$/.test(section) ?
                  <h3 className="section_title alert">{title}</h3>
                  :
                  <h3 className="section_title">{title}</h3>
              )
            }
            {
              desc !== '' && section !== 'html-message' && section !== 'checkout' && section !== 'checkoutError' && (
                <p>{desc}</p>
              )
            }
            {
              desc !== '' && section === 'checkoutError' && (
                <div dangerouslySetInnerHTML={{ __html: desc }}/>
              )
            }
            {
              desc !== '' && section === 'html-message' && (
                <div dangerouslySetInnerHTML={{ __html: desc }}/>
              )
            }
             {
              desc !== '' && section === 'checkout' && (
                <div dangerouslySetInnerHTML={{ __html: desc }}/>
              )
            }

            {
              section === 'review-order' && (
                <PopupReviewOrder closeHandler={(e) => this.close(e)} type={ array[0] } outers={ array[1] } unavailable={ array[2] } />
              )
            }
            {
              section === 'tm-suggested' && (
                <PopupSuggested closeHandler={(e) => this.close(e)} />
              )
            }
            {
              section === 'forgot-pass-alert' && (
                <PopupForgotPassAlert closeHandler={(e) => this.close(e)} />
              )
            }
            {
              section === 'step-guide' && (
                <PopupStepGuide closeHandler={(e) => this.close(e)} showLink={array[0]} />
              )
            }
            {
              section === 'just-after-login' && (
                <PopupJustAfterLogin banner={array[0]} redirect={(e, distination) => this.redirect(e, distination)} />
              )
            }
            {
              section === 'promo-code' && (
                <PromoCodeField redirect={(e, distination) => this.redirect(e, distination)} compName={array[0]} />
              )
            }

            {
              section === 'submit-tandt-warn' && (
                <PopupSubmitTAndTWarn closeHandler={(e)=>this.close(e)} tandtProducts={ array[0] } outStocks={ array[1] }/>
              )
            }
            
            {
              section === 'navigation-prompt' && (
                <NavigationPrompt redirect={(e, distination) => this.redirect(e, array)} closeHandler={(e) => this.close(e)} nextLocation={1} />
              )
            } 
            
            {
              section === 'video' && (
                <PopupVideo assets={ array[0] } closeHandler={(e) => this.close(e)}/>
              )
            }
            
            { this.getButtons(section) }
            
          </div>
        </div>
      </div>
    )
  }

  close(e) {
    const { dispatch } = this.props;

    dispatch(closePopup());

    window.setTimeout(() => {
      dispatch(hidePopup());
    }, 1000);
  }
  
  logout(e) {
    resetAuth();
    window.location.reload();
  }

  redirect(e, destination) {
    const { dispatch, history } = this.props;

    dispatch(hidePopup());
    dispatch(setOrderEditing(null, null, null))
    history.push(destination);
  }
}

Popup.propTypes = {
  history: PropTypes.object.isRequired
}

function stateMapping(state) {
  return {
    open: state.app.popup.open,
    title: state.app.popup.title,
    desc: state.app.popup.desc,
    section: state.app.popup.section,
    array: state.app.popup.array,
  }
}

export default connect(stateMapping)(Popup)