import PropTypes from 'prop-types'

import Video from 'Components/blocks/Video'

class VideoPop extends React.Component {
  
  render() {
    const { assets: { image, video, autoPlay, loop }, closeHandler } = this.props;
    
    return (
      <div className="popup-video-box">
        
        <Video image={ image } video={ video } autoplay={ autoPlay } loop={ loop }/>
        
        <button className="popup-fixed-close button-default" onClick={ closeHandler }><i className="icon-Cross"/></button>
      </div>
    )
  }
  
}

VideoPop.propTypes = {
  assets: PropTypes.shape({
    image: PropTypes.string,
    video: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
  }).isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default VideoPop