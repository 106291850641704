export const TOGGLE_LOADER = 'TOGGLE_LOADER'

export const SHOW_ERROR = 'SHOW_ERROR'

export const OPEN_POPUP = 'OPEN_POPUP'
export const CLOSE_POPUP = 'CLOSE_POPUP'
export const HIDE_POPUP = 'HIDE_POPUP'

export const TOGGLE_BACK_TOP = 'TOGGLE_BACK_TOP'

export const TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU'

export const SET_USER_ACCOUNTS = 'SET_USER_ACCOUNTS'
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT'
export const SET_CART = 'SET_CART'
export const SET_ORDER_RESULT = 'SET_ORDER_RESULT'

export const DRAWER_TYPES = {
  store: 'STORE',
  filter: 'FILTER',
  filterProducts: 'FILTER_PRODUCTS',
}
export const SET_CURRENT_ORDERS = 'SET_CURRENT_ORDERS'
export const SET_SUGGESTED_PRODUCTS = 'SET_SUGGESTED_PRODUCTS'
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS'
export const SET_PRODUCTS_BRANDS = 'SET_PRODUCTS_BRANDS'

export function toggleLoader(display) {
  return {
    type: TOGGLE_LOADER,
    display,
  }
}

export function showError(error) {
  return {
    type: SHOW_ERROR,
    error,
  }
}

export function openPopup(section = '', title = '', desc = '', array = []) {
  return {
    type: OPEN_POPUP,
    section,
    title,
    desc,
    array,
  }
}

export function closePopup() {
  return {
    type: CLOSE_POPUP,
  }
}

export function hidePopup() {
  return {
    type: HIDE_POPUP,
  }
}

export function toggleBackTop(show) {
  return {
    type: TOGGLE_BACK_TOP,
    show,
  }
}

export function toggleNavMenu(show) {
  return {
    type: TOGGLE_NAV_MENU,
    show,
  }
}

export const SET_TRACKANDTRACE = 'SET_TRACKANDTRACE'

export function setTrackAndTrace(show) {
  return {
    type: SET_TRACKANDTRACE,
    show,
  }
}

export function setUserAccounts(accounts) {
  return {
    type: SET_USER_ACCOUNTS,
    accounts,
  }
}

export function setCurrentAccount(account) {
  return {
    type: SET_CURRENT_ACCOUNT,
    account,
  }
}

export function setOpenDrawer(type = DRAWER_TYPES.store, isOpen = false) {
  return {
    type,
    isOpen,
  }
}

export function setCart(data) {
  return {
    type: SET_CART,
    data,
  }
}

export function setOrderResult(data) {
  return {
    type: SET_ORDER_RESULT,
    data,
  }
}

export function setCurrentOrders(orders, currentAccount) {
  return {
    type: SET_CURRENT_ORDERS,
    orders: orders.map((order) => {
      return {
        ...order,
        AccountNumber: currentAccount,
      }
    })
  }
}

export function setSuggestedProducts(products) {
  return {
    type: SET_SUGGESTED_PRODUCTS,
    products,
  }
}

export function setAllProducts(products) {
  return {
    type: SET_ALL_PRODUCTS,
    products,
  }
}

export function setProductsBrands(brands) {
  return {
    type: SET_PRODUCTS_BRANDS,
    brands,
  }
}
