import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { getCart } from "../../axios/private/cart";
import { orderSteps } from "../../utils/constants";

import Steps from "../../components/Steps";
import ProductCard from "../../components/Product/Card";
import Button from "../../components/form/Button";
import { toCurrencyString } from "../../utils/functions/currency";
import { getStockCardREQUEST } from "../../script/requests/products";

import {
  GAEcommerce,
  getGASocialProofingLabels,
  getIsSocialProofed,
} from "../../script/functions/ga";

const BeforeYouGo = (props) => {
  const {
    cart,
    products,
    productStatus,
    onAddToCart,
    onRemoveProduct,
    onProceed,
    onContinue,
    accountId,
  } = props;

  const productsRef = useRef([]);

  const [recommendedProducts, setRecommendedProducts] = useState({
    notInCart: [],
    inCart: [],
  });

  const [stockCardRequest, setStockCardRequest] = useState([]);

  useEffect(() => {
    getStockCardREQUEST((res, data) =>
      setStockCardRequest(data?.body?.DerivedValues),
    );
  }, []);

  function getRecommendedProductsNotInCart(recommendedProducts, cart) {
    const notInCart = [];
    let inCart = [];

    if (cart && Array.isArray(cart.BackEndCartDetails)) {
      for (const recommendedProduct of recommendedProducts) {
        let found = false;
        for (const cartProduct of cart.BackEndCartDetails) {
          if (recommendedProduct.Product.SKU === cartProduct.SKU) {
            found = true;
            inCart.push({
              ...recommendedProduct,
              SuggestedPurchase:
                recommendedProduct.SuggestedPurchase - cartProduct.Quantity >= 0
                  ? recommendedProduct.SuggestedPurchase - cartProduct.Quantity
                  : 0,
            });
          }
        }

        if (!found) notInCart.push(recommendedProduct);

        setRecommendedProducts((prevState) => ({ inCart, notInCart }));
      }
    }
  }

  useEffect(() => {
    getRecommendedProductsNotInCart(products, cart);
  }, [products, cart?.BackEndCartDetails]);

  const onAddAll = () => {
    // add all products and the get the cart updated
    let numberOfNotInCartRequests = 0;

    recommendedProducts.notInCart.map((product) => {

      GAEcommerce(
        "add_to_cart",
        accountId,
        product.Product.PriceOuter,
        product.Product.PackBarCode,
        product.Product.Product,
        product.Product.Brand,
        product.Product.Category,
        "suggested",
        getIsSocialProofed(product),
        "cart",
        product.SuggestedPurchase,
        "before_you_go",
        getGASocialProofingLabels(product)
      );
      onAddToCart(
        product.Product,
        product.SuggestedPurchase,
        undefined,
        "before_you_go"
      ).then(() => {
        numberOfNotInCartRequests++;
        if (
          numberOfNotInCartRequests === recommendedProducts.notInCart.length
        ) {
          getCart();
        }
      });
    });

    let numberOfinCartRequests = 0;

    recommendedProducts.inCart.map((product) => {
      onAddToCart(
        product.Product,
        product.SuggestedPurchase,
        undefined,
        "before_you_go",
      ).then(() => {
        numberOfinCartRequests++;
        if (numberOfinCartRequests === recommendedProducts.notInCart.length) {
          getCart();
        }
      });
    });
  };

  const onProceedToCheckout = () => {
    onProceed();
  };

  return (
    <div className="bw-before-go">
      <Steps
        steps={orderSteps}
        currentStep={0}
        className="bw-mb-10 md:bw-mb-12"
      />
      <h1 className="bw-h1">BEFORE YOU CHECKOUT</h1>
      <p className="bw-mb-6 bw-text-15">
        Did you miss anything? We've noticed that the below products were
        included in a recent order. Would you like to add any of these items to
        your shopping cart?
      </p>
      <div className="bw-title-wrapper bw-before-go-actions">
        <Button
          label="Add all To My Order"
          className="bw-button--black"
          onClick={onAddAll}
        />
        <div className="bw-before-go-actions--right">
          <Button
            label="Continue Shopping"
            className="bw-button--secondary bw-mr-1"
            onClick={onContinue}
          />
          <Button label="Review Your Cart" onClick={onProceedToCheckout} />
        </div>
      </div>

      <div className="bw-before-go-content">
        <div className="bw-before-go-products">
          {recommendedProducts.notInCart?.map((product, index) => (
            <ProductCard
              ref={(el) => {
                productsRef.current[index] = el;
              }}
              key={index}
              type="before-go"
              accountId={accountId}
              productData={product?.Product || {}}
              quantity={product?.SuggestedPurchase}
              loading={productStatus?.index === index && productStatus?.loading}
              error={
                productStatus?.index === index && productStatus?.error
                  ? productStatus?.error
                  : ""
              }
              className={products.length - 1 === index ? "" : "bw-mb-3"}
              onAddToCart={async (productData, quantity) =>
                onAddToCart(productData, quantity, index, "before_you_go")
              }
              onRemoveProduct={async (productData, quantity) =>
                onRemoveProduct(productData, quantity, index)
              }
            />
          ))}
          {recommendedProducts?.inCart?.length > 0 &&
          recommendedProducts?.inCart?.find((x) => x.SuggestedPurchase > 0) ? (
            <>
              <h1>
                You might want to increase the quantity on these product(s)
              </h1>
              <h2 style={{ fontSize: "20px" }}>
                (based on your average order volume)
              </h2>
              <br />
            </>
          ) : null}
          {recommendedProducts.inCart?.map((product, index) =>
            product?.SuggestedPurchase > 0 ? (
              <ProductCard
                ref={(el) => {
                  productsRef.current[index] = el;
                }}
                key={index}
                type="before-go"
                productData={product?.Product || {}}
                quantity={product?.SuggestedPurchase}
                accountId={accountId}
                loading={
                  productStatus?.index === index && productStatus?.loading
                }
                error={
                  productStatus?.index === index && productStatus?.error
                    ? productStatus?.error
                    : ""
                }
                className={products.length - 1 === index ? "" : "bw-mb-3"}
                onAddToCart={async (productData, quantity) =>
                  onAddToCart(productData, quantity, index, "before_you_go")
                }
                onRemoveProduct={async (productData, quantity) =>
                  onRemoveProduct(productData, quantity, index)
                }
              />
            ) : null,
          )}
        </div>
        <div className="bw-gray-box bw-before-go-cart-items bw-sticky-cart">
          <p
            className={`bw-before-go-item bw-text-16 bw-font-medium ${
              cart?.BackEndCartDetails?.length > 12 && "bw-mr-4 md:bw-mr-2"
            }`}
          >
            <span>ITEMS ADDED TO CART</span>
            <span>{cart?.TotalItems}</span>
          </p>
          {cart?.BackEndCartDetails?.length ? (
            <div className="bw-added-to-cart-height">
              {cart?.BackEndCartDetails?.map(({ Product, Quantity }, index) => (
                <p className="bw-before-go-item bw-text-15" key={index}>
                  <span className="bw-pr-2">
                    {Product.Product} ({Quantity})
                  </span>
                  <span className="bw-font-bold">
                    {toCurrencyString(Product.PriceOuter * Quantity)}
                  </span>
                </p>
              ))}
            </div>
          ) : (
            ""
          )}

          <Button
            className="bw-proceed-to-button"
            label="Review Your Cart"
            onClick={onProceedToCheckout}
          />
        </div>
      </div>
    </div>
  );
};

function stateMapping(state) {
  return {
    cart: state.app.cart,
    accountId: state.app.currentAccount.JtiAccountId,
  };
}

export default connect(stateMapping)(BeforeYouGo);
