import Cookies from 'universal-cookie';

const storage = window.sessionStorage;
const localstorage = window.localStorage;

/***
AUTHORIZATION
***/

export function setToken(s) {
  localstorage.setItem('token', s);
}

export function setTokenType(s) {
  localstorage.setItem('token_type', s);
}

export function getAuthorization() {
  return localstorage.getItem('token_type') + ' ' + localstorage.getItem('token');
}

export function resetAuth() {
  localstorage.setItem('token', '');
  localstorage.setItem('token_type', '');
  localstorage.setItem('username', '');
  localstorage.setItem('cart', '');
}

/***
USERNAME
***/

export function setUsername(s) {
  localstorage.setItem('username', s);
}

export function getUsername() {
  return localstorage.getItem('username');
}

/***
IS STAFF
***/

export function setIsStaff(b) {
  localstorage.setItem('is_staff', b == 'False' ? 0 : 1);
}

export function getIsStaff() {
  return !!+localstorage.getItem('is_staff');
}

export function setIsRetailer(str) {
  localstorage.setItem('is_retailer', /Retailer/i.test(str) ? 1 : 0);
}

export function getIsRetailer() {
  return !!+localstorage.getItem('is_retailer');
}

/***
CART
***/

export function resetCartStorage() {
  localstorage.setItem('cart', '');
}

export function setCartStorage(cart) {
  localstorage.setItem('cart', JSON.stringify(cart));
}

export function getCartStorage() {
  var cart = localstorage.getItem('cart');
  return cart ? JSON.parse(cart) : [];
}

/***
COOKIE CONSENT
***/
const cookies = new Cookies;

export function getConsentCookie(type) {
  return cookies.get(`cookie-consent-${type}`, { path: '/' }) || '';
}

export function getConsentCookies() {
  const allCookies = cookies.getAll({path: '/'});
  let array = [];
  Object.keys(allCookies).forEach((key) => {
    const matches = key.match(/^cookie-consent-(.+)$/i);
    if(matches) {
      array.push({
        name: matches[1],
        value: allCookies[key],
      });
    }
  });
  return array;
}

export function setConsentCookie(status, type) {
  const date = new Date();
  const expire = date.getTime() + 1000 * 60 * 60 * 24 * 30;
  date.setTime(expire);
  return cookies.set(`cookie-consent-${type}`, status, { path: '/', expires: date });
}

export function deleteConsentCookie(type) {
  return cookies.remove(`cookie-consent-${type}`, { path: '/' });
}

export function removeCookies(cks) {
  cks.forEach((ck) => {
    cookies.remove(ck, { path: '/' });  
  });
}

/***
SUGGESTED PRODUCTS
***/

export function getSuggestedStorage() {
  const sug = localstorage.getItem('suggested-skus', { path: '/' });
  return sug ? JSON.parse(sug) : [];
}

export function setSuggestedStorage(value) {
  localstorage.setItem('suggested-skus', JSON.stringify(value), {path: '/'});
}

/***
CHECK FIRST LOGIN
***/

const forPopupAfterLogin = 'had-logged-in-jan2019';

export function hadLoggedIn() {
  return localstorage.getItem(forPopupAfterLogin) || 0;
}

export function setLoggedIn() {
  return localstorage.setItem(forPopupAfterLogin, 1);
}

export function removeLoggedIn() {
  localstorage.removeItem(forPopupAfterLogin);
}