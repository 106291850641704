import Request from 'superagent'

import {
  getAuthorization,
  getAccountNumberQuery as accountNumber,
} from '../../utils/storage'

var domain =
  typeof DOMAIN == 'undefined'
    ? 'https://jtiwebapidev.azurewebsites.net'
    : DOMAIN

const _sessionError =
  'Session has expired. Please refresh your browser and log back in.'
const _generalError =
  'Sorry, our server has encountered an internal error and was unable to complete your request. Please try again later.'

const registerUrl = domain + '/api/Account/RetailerRegister'
const registerStaffUrl = domain + '/api/Account/StaffRegister'
const forgotPassUrl = domain + '/api/Account/ForgotPassword'
const resendEmailUrl = domain + '/api/Account/ResendVerification'
const verifyEmailUrl = domain + '/api/Account/ConfirmEmail'
const setPasswordUrl = domain + '/api/Account/ResetPassword'

const contactUsUrl = domain + '/api/ContactUs/Submit'
const contactUsAuthUrl = domain + '/api/ContactUs/SubmitLoggedIn'
const changePasswordUrl = domain + '/api/Account/ChangePassword'

const competitionAllUrl = domain + '/api/competition/AllCompetitions'
const competitionSubmitUrl = domain + '/api/Competition/SubmitCompetition'

const partnerContentUrl = domain + '/api/Cms/Track/PartnerContent'

/***
PARTNERHUB CONTENT TRACK
***/

export function partnerContentTrack(id, callback) {
  post(
    partnerContentUrl + accountNumber() + '&id=' + id,
    '',
    (data) => {
      callback(true)
    },
    (error) => {
      if (error.status === 401) {
        callback(false, _sessionError)
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
REGISTER REQUEST (COMPONENT: Register)
***/

export function register(values, callback) {
  postNoAuth(
    registerUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error, res) => {
      if (error.status === 400) {
        callback(
          false,
          'Please check that your JTI account ID and registered JTI email address are correct.'
        )
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
STAFF REGISTER REQUEST (COMPONENT: RegisterStaff)
***/

export function registerStaff(values, callback) {
  postNoAuth(
    registerStaffUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error, res) => {
      if (error.status === 400) {
        callback(
          false,
          'Sorry, something went wrong. Please check your details and try again.'
        )
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
FORGOT PASSWORD REQUEST (COMPONENT: ForgottenPassword)
***/

export function forgotPass(values, callback) {
  postNoAuth(
    forgotPassUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error, res) => {
      if (error.status === 400) {
        callback(
          false,
          error.status,
          'Password can be changed only once a day.'
        )
      } else if (error.status === 403) {
        callback(
          false,
          error.status,
          'The email address you have entered is misspelled or not registered. Please try again.'
        )
      } else {
        callback(false, error.status, _generalError)
      }
    }
  )
}

/***
RESEND VERIFICATION EMAIL REQUEST (COMPONENT: ResendEmail)
***/

export function resendEmail(values, callback) {
  postNoAuth(
    resendEmailUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error) => {
      if (error.status === 400) {
        callback(false, 'Invalid email format. Please try again.')
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
CONFIRM EMAIL REQUEST (COMPONENT: VerifyEmail)
***/

export function verifyEmail(values, callback) {
  postNoAuth(
    verifyEmailUrl + accountNumber(),
    values,
    (data) => {
      callback(true, encodeURIComponent(data.EmailTokenId))
    },
    (error) => {
      if (error.status === 500) {
        callback(false, '', _generalError)
      } else {
        callback(false, '', '')
      }
    }
  )
}

/***
SET PASSWORD REQUEST (COMPONENT: Password)
***/

export function setPassword(values, callback) {
  postNoAuth(
    setPasswordUrl + accountNumber(),
    values,
    () => {
      callback(true)
    },
    (error, res) => {
      if (error.status === 400) {
        callback(
          false,
          'Your password does not meet the above security criteria. Please try again.'
        )
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
CHANGE PASSWORD REQUEST (COMPONENT: ChangePassword)
***/

export function changePassword(values, callback) {
  post(
    changePasswordUrl + accountNumber(),
    values,
    () => {
      callback(true)
    },
    (error, res) => {
      if (error.status === 400) {
        callback(
          false,
          'Invalid password. Please check your current password and if your new password meets the requirements and try again.'
        )
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
CONTACT US REQUEST (COMPONENT: ContactUs)
***/

export function contactUs(values, callback) {
  postNoAuth(
    contactUsUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error) => {
      callback(false, _generalError)
    }
  )
}

/***
CONTACT US REQUEST FOR LOGGED IN USER (COMPONENT: FAQs)
***/

export function contactUsAuth(values, callback) {
  post(
    contactUsAuthUrl + accountNumber(),
    values,
    (data) => {
      callback(true)
    },
    (error) => {
      if (error.status === 401) {
        callback(false, _sessionError)
      } else {
        callback(false, _generalError)
      }
    }
  )
}

/***
GET COMPETITION ID BY NAME
***/

export function getCompetitionByName(name, callback) {
  get(competitionAllUrl + accountNumber(), null, (data) => {
    callback(
      true,
      data.filter((dat) => {
        return dat.CompetitionName === name
      })[0]
    )
  })
}

/***
COMPETITION SUBMIT
***/

export function competitionSubmit({ id, data }, callback) {
  const value = {
    CompetitionName: id,
    CompetitionEntryResultCollection:
      data instanceof Array
        ? data.map((dat) => {
            return {
              Name: dat.name ? dat.name : null,
              ResultString: typeof dat.value === 'string' ? dat.value : null,
              ResultInt: typeof dat.value === 'number' ? dat.value : null,
            }
          })
        : [
            {
              Name: data.name ? data.name : null,
              ResultString: typeof data.value === 'string' ? data.value : null,
              ResultInt: typeof data.value === 'number' ? data.value : null,
            },
          ],
  }

  post(
    competitionSubmitUrl + accountNumber(),
    value,
    () => {
      callback(true)
    },
    (error) => {
      if (error.status === 403 || error.status === 400) {
        callback(false, error.status, competitionErrors[id](id, error.status))
      } else {
        callback(false, error.status, _generalError)
      }
    }
  )
}

const competitionErrors = {
  'Welcome prize draw': (id, status) => {
    switch (status) {
      case 403:
        return 'You have already registered to the prize draw.'
      case 400:
        return 'Invalid code. Please try again.'
    }
  },
}

/***
AJAX CALLS
***/

function postLogin(url, data, success, fail) {
  Request.post(url)
    .send(normalize(data))
    .set({ 'Content-Type': 'application/x-www-form-urlencoded' })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        fail(err, res)
        return false
      }

      success(res.body)
    })
}

function get(url, data, success, fail) {
  Request.get(url)
    .query(data ? data : null)
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (typeof fail == 'function') fail(err, res)
        return false
      }

      success(res.body)
    })
}

function getNoAuth(url, data, success, fail) {
  Request.get(url)
    .query(data ? data : null)
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (typeof fail == 'function') fail(err, res)
        return false
      }

      success(res.body)
    })
}

function post(url, data, success, fail) {
  Request.post(url)
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (typeof fail == 'function') fail(err, res)
        return false
      }

      success(res.body)
    })
}

function postNoAuth(url, data, success, fail) {
  Request.post(url)
    .send(JSON.stringify(data))
    .set({ 'Content-Type': 'application/json' })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (typeof fail == 'function') fail(err, res)
        return false
      }

      success(res.body)
    })
}

function normalize(obj) {
  return Object.keys(obj).reduce((a, b) => {
    return a + (a ? '&' : '') + b + '=' + encodeURIComponent(obj[b])
  }, '')
}

export function fetchApi(url, callback) {
  fetch(url)
    .then((response) => response.json())
    .then(callback)
}
