import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { showError, hidePopup, toggleNavMenu } from 'Redux/actions.app'
import { toggleInformation } from 'Redux/actions.prod'

import HeaderMain from 'Components/HeaderMain'
import HeaderForm from 'Components/HeaderForm'
import Footer from 'Components/Footer'
import TriangledBack from 'Components/TriangledBack'
import Popup from 'Components/Popup'
import ScrollBackToTop from 'Components/ScrollBackToTop'
// import CookieConsent from 'Components/CookieConsent'
import EditNotice from 'Components/blocks/EditNotice'
import TrackTraceWarn from 'Components/blocks/TrackTraceWarn'

import { gaPageView } from 'Functions/ga'

class Container extends React.Component {
  getTheme(theme) {
    if (theme === void 0) {
      return ' theme-style-default'
    } else {
      return ` theme-style-${theme}`
    }
  }

  render() {
    const {
      history,
      type,
      hasBlur,
      popup,
      backToTop,
      navMenu,
      children,
      auth,
      theme,
      isOrderEditing,
      trackAndTrace,
    } = this.props

    let mainClass = ''
    mainClass += type === 1 ? 'wrapper-form' : 'wrapper-main'
    mainClass += hasBlur ? ' has_blur' : ''
    mainClass += navMenu ? ' nav_opened' : ''

    mainClass += this.getTheme(theme)

    return (
      <React.Fragment>
        <div
          id="Container"
          className={mainClass}
          onClick={() => this.screenClick()}
        >
          {/* 
            type === 1 && (
              <TriangledBack/>
            )
          */}

          {type === 1 && <div className="triangled no-print" />}

          {type === 2 ? <HeaderMain history={history} /> : <HeaderForm />}

          <div
            className={
              'container animated fadeIn' +
              (history.location.pathname === '/' ? ' home' : '')
            }
          >
            {children}
          </div>

          {type === 2 && <Footer history={history} />}
        </div>

        {backToTop && <ScrollBackToTop />}

        {/* <CookieConsent auth={auth}/> */}

        {popup && <Popup history={history} />}

        {isOrderEditing !== null && <EditNotice />}

        {trackAndTrace === 2 && <TrackTraceWarn history={history} />}
      </React.Fragment>
    )
  }

  screenClick() {
    const { dispatch } = this.props
    dispatch(toggleInformation(''))
  }

  componentWillMount() {
    const { dispatch } = this.props

    window.scrollTo(0, 0)
    dispatch(showError(''))
    dispatch(hidePopup())
    dispatch(toggleNavMenu(false))
  }

  componentDidMount() {
    const { history } = this.props
    gaPageView(history.location.pathname)
  }
}

Container.propTypes = {
  history: PropTypes.object.isRequired,
  type: PropTypes.oneOf([1, 2]).isRequired,
  auth: PropTypes.bool,
  theme: PropTypes.number,
}

function stateMapping(state) {
  return {
    hasBlur: state.app.popup.open,
    popup: state.app.popup.show,
    backToTop: state.app.backTopButton,
    navMenu: state.app.navMenu,
    isOrderEditing: state.prod.isOrderEditing,
    trackAndTrace: state.app.trackAndTrace,
  }
}

export default connect(stateMapping)(Container)
