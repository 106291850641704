import React from 'react'
import PropTypes from 'prop-types'
import Check from '../../images/icons/forms/check.svg'
import CheckFilled from '../../images/icons/forms/check-filled.svg'
import Uncheck from '../../images/icons/forms/uncheck.svg'

const PasswordHelper = ({ hasEight, hasUppercase, hasLowercase, hasNumber, hasSpecialCharacter, className }) => {
  const conditionsStatuses = [
    { status: hasUppercase, text: 'uppercase letter' },
    { status: hasLowercase, text: 'lowercase letter' },
    { status: hasNumber, text: 'number' },
    { status: hasSpecialCharacter, text: 'special character (e.g. !#?^&*/+-=)' }
  ]
  const cantHave = [
    'JTI Account No.',
    'more than 3 consecutive letters of your name',
  ]

  let strength = conditionsStatuses.filter((el) => el.status).length
  hasEight && strength++;

  return (
    <div className={`password-helper ${className || ''}`}>
      <p className="bw-font-semibold bw-mb-3">
        Your password must be 8 or more characters and have at least 3 of the following:
      </p>
      {conditionsStatuses.map((cond, index) => (
        <div
          className="bw-flex bw-items-center bw-flex-initial bw-pb-1"
          key={index}
        >
          <img src={cond.status ? CheckFilled : Check} alt="Check" />
          <p className="bw-ml-3 bw-font-medium bw-text-15">{cond.text}</p>
        </div>
      ))}
      <p className="bw-font-semibold bw-my-2">Strength:</p>
      <div className="strength-progress bw-mb-2">
        <div className={`progress p${strength}`}></div>
      </div>
      <p className="bw-font-normal bw-text-15 bw-mr-10 bw-mb-5">
        Avoid passwords you already use with other websites, or that might be
        easy for someone else to guess.
      </p>
      <p className="bw-font-bold bw-text-15 bw-mb-2">
        Your password cannot have
      </p>
      {cantHave.map((cond, index) => (
        <div
          className="bw-flex bw-items-center bw-flex-initial bw-pb-1"
          key={index}
        >
          <img src={Uncheck} alt="Uncheck" />
          <p className="bw-ml-3 bw-font-medium bw-text-15">{cond}</p>
        </div>
      ))}
    </div>
  )
}

PasswordHelper.propTypes = {
  hasEight: PropTypes.bool.isRequired,
  hasUppercase: PropTypes.bool.isRequired,
  hasLowercase: PropTypes.bool.isRequired,
  hasNumber: PropTypes.bool.isRequired,
  hasSpecialCharacter: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
PasswordHelper.defaultProps = {
  hasEight: false,
  hasUppercase: false,
  hasLowercase: false,
  hasNumber: false,
  hasSpecialCharacter: false,
  className: '',
}

export default PasswordHelper
