import axios from '../index'

export const getBackgrounds = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Cms/Backgrounds')
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export const getNotifications = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Cms/Notifications')
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export const getContactSubjects = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/ContactUs/GetSubjectTitles')
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response))
  })
}
