import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { openPopup, hidePopup, toggleLoader } from 'Redux/actions.app'
import { getCart } from '../../../axios/private/cart'
import { submitOrder } from '../../../axios/private/orders'
import { generalError } from '../../../utils/constants'
import { setOrderResult } from '../../redux/actions.app'
import { GAEcommerceMultiple, getGASocialProofingLabels, getIsSocialProofed } from "../../functions/ga";

class Checkout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lock: false,
    }
  }

  render() {
    const { closeHandler } = this.props

    return (
      <div className="buttons">
        <button
          className="button_style-2 fontsize19"
          onClick={(e) => this.checkout(e)}
        >
          Yes
        </button>
        <button
          className="button_style-1 fontsize19"
          onClick={(e) => closeHandler(e)}
        >
          Cancel
        </button>
      </div>
    )
  }

  async checkout(e) {
    if (this.state.lock) return false

    this.setState({ lock: true })

    const { dispatch, orderDetail, history,accountId } = this.props

    dispatch(toggleLoader(true))
    dispatch(hidePopup())
    let errMessage = null
    await submitOrder(orderDetail)
    .then((res) => {
      const data = res?.data
      dispatch(setOrderResult(data || {}))
      if (data) {
          var productsForAnalitics = []
          
          data.OrderDetails.forEach((item) => {

            if (item && item.Product) {
              var tempProduct = {}
              tempProduct = item.Product
              tempProduct.suggested = "suggested"
              tempProduct.socialProofed = getIsSocialProofed(item)
              tempProduct.productList = 'cart'
              tempProduct.quantity = item.Quantity
              tempProduct.social_proof = getGASocialProofingLabels(item)
              productsForAnalitics.push(tempProduct)
            }
          })

          GAEcommerceMultiple('add_to_cart',accountId, productsForAnalitics)
          history.push('/order-confirmation')
        } else {
          dispatch(
            openPopup('success', 'Your order has been submitted correctly.', '')
          )
        }
        return data || null
      })
      .catch((err) => {
        errMessage = err?.data?.Message || generalError
        dispatch(
          openPopup(
            'checkoutError',
            'Sorry, there has been an error and we were unable to complete your request',
            errMessage
          )
        )
      })
    dispatch(toggleLoader(false))
    getCart()
      .then((res) => res)
      .catch((err) => err)
  }
}

Checkout.propTypes = {
  history: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  orderDetail: PropTypes.object.isRequired,
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(Checkout);
