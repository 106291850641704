import React from 'react';
import closeIcon from "../../../icons/Cross.svg";
import logo from "../../../images/jti-logo.svg";

export default function QuestionsContainer({isOpen, handleClose, children}) {
  return <div onClick={e => e.stopPropagation()}
              className={`survey__question-container ${isOpen ? 'survey__question-container--open' : 'survey__question-container--closed'}`}>
    {/* Side Label */}
    <div onClick={handleClose}
         className={`survey__question-container__label`}>
      <p>Feedback survey</p>
    </div>

    {/* Continue Survey*/}
    <div className={'survey__question-container__body'}>
      <button className={'survey__question-container__body__close-btn'}
              onClick={handleClose}
      >
        <img src={closeIcon} alt={'Close'}/>
      </button>
      <img className={'survey__question-container__body__logo'} src={logo} alt='JTI Engage logo'/>
      {children}
    </div>
  </div>
}