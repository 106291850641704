import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  openPopup,
  hidePopup,
  closePopup,
  toggleLoader,
} from 'Redux/actions.app'
import { updatePendingOrder } from '../../../axios/private/orders'
import { getCart } from '../../../axios/private/cart'
import { generalError } from '../../../utils/constants'
import { setOrderResult } from '../../redux/actions.app'

class EditOrder extends React.Component {
  render() {
    const { closeHandler } = this.props

    return (
      <div className="buttons">
        <button
          className="button_style-2 fontsize19"
          onClick={(e) => this.checkout(e)}
        >
          Yes
        </button>
        <button
          className="button_style-1 fontsize19"
          onClick={(e) => closeHandler(e)}
        >
          Cancel
        </button>
      </div>
    )
  }

  // checkout(e) {
  //   const { dispatch, orderDetail, history } = this.props;

  //   dispatch(toggleLoader(true));

  //   updateOrderDetailREQUEST(orderDetail, (result, data, error) => {

  //     dispatch(toggleLoader(false));

  //     if (result) {

  //       dispatch(saveOrderResult(data));
  //       dispatch(resetOrder());
  //       dispatch(resetSavedCart());

  //       history.push('/order-confirmation');

  //     } else {

  //       dispatch(closePopup());

  //       window.setTimeout(() => {
  //         dispatch(hidePopup());

  //         window.setTimeout(() => {
  //           dispatch(openPopup(
  //             'alert',
  //             error,
  //             ''
  //           ));
  //         }, 100);

  //       }, 1000);

  //     }
  //   });
  // }
  async checkout(e) {
    const { dispatch, orderDetail, history } = this.props

    dispatch(toggleLoader(true))
    dispatch(hidePopup())
    let errMessage = null
    await updatePendingOrder(orderDetail)
      .then((data) => {
        dispatch(setOrderResult(data || {}))
        if (data) {
          history.push('/order-confirmation')
        } else {
          dispatch(
            openPopup('success', 'Your order has been submitted correctly.', '')
          )
        }
        return data || null
      })
      .catch((err) => {
        errMessage = err?.data?.Message || generalError
        dispatch(
          openPopup(
            'checkoutError',
            'Sorry, there has been an error and we were unable to complete your request',
            errMessage
          )
        )
      })
    dispatch(toggleLoader(false))
    getCart()
      .then((res) => res)
      .catch((err) => err)
  }
}

EditOrder.propTypes = {
  history: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  orderDetail: PropTypes.object.isRequired,
}

export default connect()(EditOrder)
