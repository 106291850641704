import React, { Component } from 'react';

class Logo extends Component {
  render() {
    return (
      <svg className={ !this.props.classless ? `chatbot__logo` : '' } xmlns="http://www.w3.org/2000/svg" width="35" height="22" viewBox="0 0 33.703 21.638"><g transform="translate(-923.537 -189.966)"><path d="M948.162,189.966H932.615a9.078,9.078,0,0,0,0,18.156h15.547a9.078,9.078,0,0,0,0-18.156Zm0,15.547H932.615a6.469,6.469,0,0,1,0-12.938h15.547a6.469,6.469,0,1,1,0,12.938Z" transform="translate(0)"/><path d="M2.332,0A2.332,2.332,0,1,1,0,2.332,2.332,2.332,0,0,1,2.332,0Z" transform="translate(931.719 196.712)"/><circle cx="2.332" cy="2.332" r="2.332" transform="translate(944.254 196.712)"/><path d="M978.094,271.664,981.758,268h-7.329Z" transform="translate(-37.705 -60.061)"/></g></svg>
    );
  }
}

export default Logo;