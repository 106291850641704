import React, { useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { getOrderStatus } from '../../utils/functions/order'
import { toCurrencyString } from '../../utils/functions/currency'
import { vat, orderSteps } from '../../utils/constants'
import { setOrderResult } from '../../script/redux/actions.app'
import { GA, GAEC, gaCartStep } from '../../script/functions/ga'
import { dateFormat } from '../../utils/functions/formatDate'

import Steps from '../../components/Steps'
import FieldGroup from '../../components/FieldGroup'
import Button from '../../components/form/Button'

const OrderConfirmation = (props) => {
  const { currentAccount, dispatch, orderResult } = props

  useEffect(() => {
    if (!orderResult?.OrderNumber) return false
    GAEC(currentAccount.JtiAccountId, orderResult)
    gaCartStep(currentAccount.JtiAccountId, 3)
  }, [orderResult])

  useLayoutEffect(() => {
    // on unmount, reset order
    return () => {
      dispatch(setOrderResult({}))
    }
  }, [])

  if (!orderResult.OrderNumber) {
    return <Redirect to="/cart" />
  }

  return (
    <>
      <div className="bw-container-page bw-order-confirmation">
        <div className="bw-container">
          <Steps
            steps={orderSteps}
            currentStep={2}
            className="bw-mb-10 md:bw-mb-12"
          />
          <div className="bw-order-header">
            <h1 className="bw-h1 bw-mt-0 bw-mb-4">Order Confirmation</h1>
            <p className="bw-text-15 bw-mb-6">
              Your order has been submitted and you’ll receive a confirmation
              email shortly.
            </p>
            <Link
              to={
                '/review-order?confirmation=true&ordernumber=' +
                encodeURIComponent(orderResult?.OrderNumber)
              }
              className="bw-btn-link bw-text-14"
              onClick={() => GA('Order Confirmation', 'Button :: Review order','click', currentAccount.JtiAccountId)}
            >
              Review order
            </Link>
          </div>
          <div className="bw-gray-box">
            <h3 className="store-name">{currentAccount?.AccountName}</h3>
            <p className="store-address">{currentAccount?.AddressLine1}</p>
            <hr />
            <FieldGroup
              label="Order date"
              value={orderResult?.date ? dateFormat(orderResult.date) : ''}
              type="horizontal"
            />
            <FieldGroup
              label="Order number"
              value={orderResult?.OrderNumber}
              type="horizontal"
            />
            <FieldGroup
              label="Status"
              value={getOrderStatus(orderResult?.Status)}
              type="horizontal"
            />
            <FieldGroup
              label="Number of items"
              value={orderResult?.NumberOfItems}
              type="horizontal"
              className="bw-mb-0"
            />
            <hr />
            <FieldGroup
              label="Sub-total"
              value={toCurrencyString(orderResult?.SubTotal)}
              type="horizontal"
            />
            <FieldGroup
              label={`VAT @ ${vat * 100}%`}
              value={toCurrencyString(orderResult?.VatTotal)}
              type="horizontal"
            />
            <FieldGroup
              label="Order total"
              value={toCurrencyString(orderResult?.OrderTotal)}
              type="horizontal"
              className="bw-pb-6"
            />
            <Button
              label="Print Confirmation"
              className="bw-button--black"
              onClick={() => window.print()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function stateMapping(state) {
  return {
    currentAccount: state.app.currentAccount,
    orderResult: state.app.orderResult
  }
}

export default connect(stateMapping)(OrderConfirmation)
