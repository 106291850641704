import React, { useState } from "react";
import Progress from "./Progress";
import {submitQuestion} from "../../../axios/private/survey";

const Checkbox = ({ id, label, onChange, disabled }) => {
  return (
    <div className={"checkbox"}>
      <input
        className={"input"}
        type="checkbox"
        id={id}
        onChange={onChange}
        disabled={disabled}
      />
      <label className={"label"} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default function MultipleQuestion({
  question,
  answers,
  maxSelections = 2,
  currentQuestion,
  totalQuestions,
  nextQuestion,
  questionId,
  surveyId,
  setIsLoading,
}) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  function handleSubmit() {
    if (selectedAnswers.length > 0) {
      setIsLoading(true);
      submitQuestion(surveyId, questionId, selectedAnswers)
        .then(() => {
          setIsLoading(false);
          nextQuestion();
        });
    }
  }

  const handleCheckboxChange = (e, answerText, answerId) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedAnswers([...selectedAnswers, { AnswerId: answerId }]);
    } else {
      setSelectedAnswers(
        selectedAnswers.filter(
          (existingAnswer) => existingAnswer.AnswerId !== answerId
        )
      );
    }
  };

  return (
    <div className={"multiple-question"}>
      <p className={"multiple-question__subtitle"}>
        To help us build the JTI ENGAGE of the future it would be great if you
        could answer the below question:
      </p>
      <p className={"multiple-question__question"}>{question}</p>

      <p className={"multiple-question__max-selections"}>
        Maximum {maxSelections} selections
      </p>

      <div className={"multiple-question__answers"}>
        {answers.map((answer) => (
          <Checkbox
            key={answer.AnswerId}
            id={answer.AnswerId}
            label={answer.Text}
            value={answer.Text}
            onChange={(e) =>
              handleCheckboxChange(e, answer.Text, answer.AnswerId)
            }
            disabled={
              selectedAnswers.length >= maxSelections &&
              !selectedAnswers.find(
                (selectedAnswer) => selectedAnswer.AnswerId === answer.AnswerId
              )
            }
          />
        ))}
      </div>

      <button
        onClick={handleSubmit}
        className={"multiple-question__next-btn"}
      >
        Next
      </button>
      <div className={"multiple-question__progress"}>
        <Progress currentStep={currentQuestion} totalSteps={totalQuestions} />
      </div>
    </div>
  );
}