import * as Yup from 'yup'
import { formErrors } from '../constants'

export const changePasswordForm = {
  initialValues: {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
  },
  validationSchema: Yup.object().shape({
    OldPassword: Yup.string().required(formErrors.general),
    NewPassword: Yup.string().required(formErrors.general),
    ConfirmPassword: Yup.string().required(formErrors.passNotMatch),
  }),
  validate: (val, setStatus) => {
    const passwordsMatch = val.NewPassword === val.ConfirmPassword
    const hasEight = val.NewPassword.length >= 8
    const hasLowercase = !!val.NewPassword.match(/(?=.*[a-z])/gm)
    const hasUppercase = !!val.NewPassword.match(/(?=.*[A-Z])/gm)
    const hasNumber = !!val.NewPassword.match(/(?=.*\d)/gm)
    const hasSpecialCharacter = !!val.NewPassword.match(/(?=.*[!@#$%^&*)(+=.,<>?{}\[\]:;'"|~`_\-\\\/])/gm)

    setStatus((oldValue) => ({
      ...oldValue,
      passwordStatus: {
        hasEight,
        hasUppercase,
        hasLowercase,
        hasNumber,
        hasSpecialCharacter,
        passwordsMatch,
      },
    }))
    if (!hasEight || ((hasUppercase?1:0)+(hasLowercase?1:0)+(hasNumber?1:0)+(hasSpecialCharacter?1:0) < 3))
      return {
        NewPassword:
          'Your password must be 8 or more characters and have at least 3 of the following: a uppercase letter, a lowercase letter, a number, a special character'
      }
    if (!passwordsMatch)
      return {
        ConfirmPassword: formErrors.passNotMatch,
      }
  },
}

export const setEoidFidForm = {
  initialValues: {
    EOID: '',
    FID: '',
  },
  validationSchema: Yup.object().shape({
    EOID: Yup.string()
      .required(formErrors.invalidEoid)
      .matches(/^QCALL/gm, formErrors.invalidEoid),
    // .test((val) => val.match(/^QCALL/gm)),
    FID: Yup.string()
      .required(formErrors.invalidFid)
      .matches(/^QCALL/gm, formErrors.invalidFid),
    // .test((val) => val.match(/^QCALL/gm)),
  }),
}
