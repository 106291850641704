import PropTypes from "prop-types";
import {connect} from "react-redux";
import {openPopup} from "Redux/actions.app";
import {p4pItems, path} from "Functions/p4pData";
import PartnerHub from "Components/blocks/PartnerHub";
import {GA} from "Functions/ga";
import {useState} from "react";
import useScrollBackToTop from "../../utils/hooks/useScrollBackToTop";

const P4PItem = ({item, dispatch, accountId}) => {
  const showVideo = () => {
    const {title, video, thumb} = item;
    GA("P4P", `Watch Video :: ${title}`, "CTA Click", accountId);
    dispatch(
      openPopup("video", "", "", [{video: path + video, image: path + thumb}])
    );
  };

  return (
    <li className="cf">
      <div className="p4p-thumb">
        <div style={{backgroundImage: `url(${path}${item.thumb})`}}/>
      </div>

      <div className="p4p-copy">
        <h3>{item.title}</h3>
        <p>{item.description}</p>
      </div>

      <div className="p4p-buttons">
        <a
          href={item.link}
          className="button_style-2 fontsize19"
          target="_blank"
          onClick={() => GA("P4P", `View PDF :: ${item.title}`, "CTA Click", accountId)}
        >
          View PDF
        </a>
        {item.video !== void 0 && item.video !== "" && (
          <button
            className="button_style-2 fontsize19"
            onClick={() => showVideo()}
          >
            Watch video
          </button>
        )}
        <a
          href={path + item.pdf}
          className="button_style-2 fontsize19"
          target="_blank"
          onClick={() =>
            GA("P4P", `View Download :: ${item.title}`, "CTA Click", accountId)
          }
        >
          Download PDF
        </a>
      </div>
    </li>
  );
};

P4PItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired,
    video: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const P4P = ({dispatch, accountId}) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  useScrollBackToTop()

  return (
    <>
      <div className="page page-pad-L page-p4p theme-style-2">
        <PartnerHub
          isMobileMenuVisible={isMobileMenuVisible}
          setIsMobileMenuVisible={setIsMobileMenuVisible}
        />
        <h3 className={`page_title ${isMobileMenuVisible ? 'bw-invisible lg:bw-visible' : ''}`}>Partners for
          Progress</h3>

        <ul className={`p4p-list ${isMobileMenuVisible ? 'bw-invisible lg:bw-visible' : ''}`}>
          {p4pItems.map((item) => (
            <P4PItem key={item.thumb} item={item} dispatch={dispatch} accountId={accountId}/>
          ))}
        </ul>
      </div>
    </>
  );
};

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(P4P);