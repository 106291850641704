const storage = window.sessionStorage
const localstorage = window.localStorage

/***
AUTHORIZATION
***/

export function setToken(s) {
  localstorage.setItem('token', s)
}

export function setTokenType(s) {
  localstorage.setItem('token_type', s)
}

export function getAuthorization() {
  return localstorage.getItem('token_type') + ' ' + localstorage.getItem('token')
}

export function resetAuth() {
  localstorage.setItem('token', '')
  localstorage.setItem('token_type', '')
  localstorage.setItem('username', '')
  localstorage.setItem('cart', '')
  localstorage.setItem('account_number', '')
}

/***
ACCOUNT
***/

export const setAccountNumber = (id) => {
  return localstorage.setItem('account_number', id || '')
}

export const getAccountNumber = () => {
  return localstorage.getItem('account_number')
}

// query for old requests
export const getAccountNumberQuery = () => {
  const accountNumber = localstorage.getItem('account_number')
  return accountNumber ? '?accountNumber=' + accountNumber : ''
}

/***
USERNAME
***/

export function setUsername(s) {
  localstorage.setItem('username', s)
}

export function getUsername() {
  return localstorage.getItem('username')
}

/***
IS STAFF
***/

export function setIsStaff(b) {
  localstorage.setItem('is_staff', b == 'False' ? 0 : 1)
}

export function getIsStaff() {
  return !!+localstorage.getItem('is_staff')
}

export function setIsRetailer(str) {
  localstorage.setItem('is_retailer', /Retailer/i.test(str) ? 1 : 0)
}

export function getIsRetailer() {
  return !!+localstorage.getItem('is_retailer')
}

/***
CHECK FIRST LOGIN
***/

const forPopupAfterLogin = 'had-logged-in-jan2019'

export function hadLoggedIn() {
  return localstorage.getItem(forPopupAfterLogin) || 0
}

export function setLoggedIn() {
  return localstorage.setItem(forPopupAfterLogin, 1)
}

export function removeLoggedIn() {
  localstorage.removeItem(forPopupAfterLogin)
}
