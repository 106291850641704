import axios from '../index'
import store from '../../script/redux/store'
import { setCart } from '../../script/redux/actions.app'
import { stringify as stringifyParams } from 'query-string'
import { addOrder, updateSavedCart } from '../../script/redux/actions.prod'
import { normalizeOldCartData } from '../../utils/functions/old'

export const getRecentOrder = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/OrderHistory/GetRecentOrder`)
      .then((res) => resolve(res?.data || null))
      .catch((err) => reject(err.response))
  })
}

export const submitOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Order/Submit', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response))
  })
}

export const updatePendingOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put('/api/Order/UpdatePendingOrderWithDetails', data)
      .then((res) => resolve(res?.data || {}))
      .catch((err) => reject(err.response))
  })
}

export const loadPendingOrderIntoCart = (orderNumber) => {
  const params = stringifyParams({ orderNumber })
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/Order/LoadPendingOrderIntoBackEndCart?${params}`)
      .then((res) => {
        const data = res?.data || {}
        resolve(data)
        store.dispatch(setCart(data))
        // store data in old way for old pages (place-order and stock-card)
        data?.BackEndCartDetails?.map(({ Product, Quantity }) => {
          store.dispatch(addOrder(Product.SKU, Quantity, Quantity, true))
        })
        store.dispatch(updateSavedCart(normalizeOldCartData(data)))
      })
      .catch((err) => reject(err.response))
  })
}

export const getOrderDetails = (orderNumber) => {
  const params = stringifyParams({ orderNumber })
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/OrderHistory/OrderHistoryDetails?${params}`)
      .then((res) => resolve(res?.data || null))
      .catch((err) => reject(err.response))
  })
}
