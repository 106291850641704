import CookiePolicyContent from 'Components/blocks/CookiePolicyContent'
import { connect } from 'react-redux'

class CookiesPolicyPublic extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="page page-pad-L page-default page-cookies_policy">
        <CookiePolicyContent />
      </div>
    )
  }
}

export default connect()(CookiesPolicyPublic)
