import React from 'react'
import Slider from 'react-slick'
import { GA } from '../../script/functions/ga'

const PrevArrow = (props) => {
  const { onClick, State } = props
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <svg
        width="11"
        height="17"
        viewBox="0 0 11 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.94531 10.4474L0.998047 8.50012L8.77539 0.722778L10.7227 2.67004L4.88867 8.50403L10.7227 14.3361L8.77539 16.2775L2.94531 10.4474Z"
          fill="#191919"
          fillOpacity="0.4"
        />
      </svg>
    </div>
  )
}
const NextArrow = (props) => {
  const { onClick } = props
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.7814 0.724664L15.5596 8.50284L7.7814 16.281L5.83823 14.3379L11.6719 8.50422L5.83685 2.66921L7.7814 0.724664Z"
          fill="#191919"
          fillOpacity="0.4"
        />
      </svg>
    </div>
  )
}

const MessageArea = (props) => {
  const { State } = props
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  
  const onLinkClick = (title) => {
    GA('Accordion :: Slider', `Click Text :: ${title}`, 'click', State.userInfo.JtiAccountId)
  }

  return (
    <>
      {State.notifications.length ? (
        <div className="bw-message-area">
          <div className="bw-container">
            <Slider {...settings}>
              {State.notifications.map((item) => (
                <div key={item.Id} className="bw-message-area--item">
                  <p>{item?.Title}</p>
                  {item?.LinkLabel && item?.LinkUrl ? (
                    <a
                      href={item?.LinkUrl}
                      onClick={() => onLinkClick(item?.Title)}
                    >
                      {item?.LinkLabel}
                    </a>
                  ) : null}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : null}
    </>
  )
}

MessageArea.propTypes = {}

export default MessageArea
