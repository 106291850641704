import React, { useEffect, useState } from 'react'

const Background = (props) => {
    const { backgrounds } = props
    const [pickedBg,setPickedBg] = useState({})

    useEffect(()=>{
        if(backgrounds.length) {
            let pickedPosition = backgrounds.findIndex((el) => el.IsSticky)
            if(pickedPosition === -1) {
                // generate random
                pickedPosition = Math.floor(Math.random() * (backgrounds.length))
            }
            setPickedBg(backgrounds[pickedPosition]) 
        }
    },[backgrounds])

    return (
        <>
            {pickedBg.IsImage && <img src={pickedBg.AssetUrl} alt="Background" />}
            {pickedBg.IsVideo && <video src={pickedBg.AssetUrl} autoPlay />}
        </>
    )
}

export default Background