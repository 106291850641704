import { NavLink } from 'react-router-dom'
import { Fragment } from 'react'
import { connect } from "react-redux";

import logo from '@Images/jti@2x.png'
import { GA } from 'Functions/ga'
import ChatBot from './chatbot/ChatBot'

const gac = 'Footer Navigation'

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { history, accountId } = this.props
    return (
      <Fragment>
        <footer className="footer">
          <div className="footer__container">
            <div className="footer__logo">
              <img src={logo} alt="JTI" />
            </div>
            <div className="footer__nav">
              <nav>
                <ul className="a-wh-wh source-sans-semibold">
                  <li>
                    <NavLink to="/faqs" onClick={() => GA(gac, 'Contact Us','click', accountId)}>
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/terms-conditions"
                      onClick={() => GA(gac, 'Terms & Conditions','click', accountId)}
                    >
                      Terms &amp; Conditions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/privacy-policy"
                      onClick={() => GA(gac, 'Privacy Policy','click', accountId)}
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/cookies-policy"
                      onClick={() => GA(gac, 'Cookie Policy','click', accountId)}
                    >
                      Cookies Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/declaration"
                      onClick={() => GA(gac, 'REACH Declaration','click', accountId)}
                    >
                      <span className="uppercase">Reach</span> Declaration
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer__alert">
              <p>
                This application is for tobacco retailers only and should not be
                made available to the public nor should it be displayed in any
                area where it is visible to the public. This application is
                restricted to those involved in the business of the sale or
                distribution of tobacco products who are 18 years of age
                or&nbsp;older.
              </p>
            </div>
          </div>
        </footer>
        <ChatBot history={history} />
      </Fragment>
    )
  }
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}
//here

export default connect(stateMapping)(Footer)
