import React from 'react'
import PropTypes from 'prop-types'

const Filter = (props) => {
  const { type, label, count, active, disabled, className, onSelect } = props

  return (
    <button
      className={`bw-product-filter ${
        active || type === 'brands' ? 'active' : ''
      } ${type} ${className}`}
      disabled={disabled}
      onClick={() => (!disabled ? onSelect() : null)}
    >
      <span>
        {label +
          (type !== 'brands' && count !== undefined ? ` (${count})` : '')}
      </span>
    </button>
  )
}

Filter.propTypes = {
  type: PropTypes.oneOf(['categories', 'brands', 'brands-filter']),
  label: PropTypes.string,
  count: PropTypes.number,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
}

Filter.defaultProps = {
  type: 'categories',
  label: 'Ready Made Cigarettes',
  count: undefined,
  active: false,
  disabled: false,
  className: '',
  onSelect: () => {},
}

export default Filter
