import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { getFAQs, getSubjects } from 'Functions/contents'
import { contactUsAuth } from 'Functions/requests'
import { toggleLoader, showError, openPopup } from 'Redux/actions.app'

import Accordions from 'Components/blocks/Accordions'
import Select from 'Components/form/Select'
import TextArea from 'Components/form/TextArea'
import InputText from 'Components/form/InputText'

import { GA } from 'Functions/ga'
const gac = 'FAQs'

const Names = {
  sj: 'subject',
  ms: 'message',
  on: 'order_no',
}

class FAQs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subjects: [],
      faqs: [],
      selectedSubject: '',
    }
  }

  render() {
    const { history, handleSubmit, invalid, app_error, subject } = this.props
    const { faqs } = this.state

    const faqData = faqs.map((dat) => {
      return {
        dt: dat.Question,
        dd: dat.Answer,
      }
    })

    return (
      <>
        <div className="page page-pad-L page-faqs">
          <h3 className="page_title">FAQs</h3>

          <section className="faqs">
            <Accordions contents={faqData} gac={gac} />
          </section>

          <section className="contact_us no-print" id="contact-us">
            <h3 className="section_title">
              Don't see what you're looking for?
            </h3>
            <p>Fill in the form below and we'll get back to you</p>

            <div className="cf">
              <div>
                <form onSubmit={handleSubmit(submission)}>
                  <Field
                    name={Names.sj}
                    component={Select}
                    defaultValue="Orders"
                    label="Subject of your query"
                    placeholder="Please select the subject"
                    options={this.state.subjects}
                    selectedSubject={this.UrlParam(
                      'subject',
                      this.props.location.search
                    )}
                    required
                  />

                  {subject === 'Orders' && (
                    <Field
                      name={Names.on}
                      component={InputText}
                      label="Order number (optional)"
                      placeholder="Your order number"
                    />
                  )}

                  <Field
                    name={Names.ms}
                    component={TextArea}
                    label="Message"
                    placeholder="Please tell us your query"
                    rows="8"
                  />

                  <div className="submit_box">
                    <button
                      className="button_style-2 fontsize19 nowrap"
                      type="submit"
                      disabled={invalid}
                    >
                      Submit query
                    </button>

                    <div className="submit_error">
                      <b className="error_message">{app_error}</b>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <p className="fontsize17">
                  You can contact us <span className="nowrap">by phone on</span>
                </p>
                <p className="by_phone-tel">
                  <a
                    className="noline a-bl-gr nowrap"
                    href="tel:014040240"
                    onClick={() => GA(gac, 'Text Link :: 01 404 0240','click', accountId)}
                  >
                    01 404 0240
                  </a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }

  UrlParam(name, params) {
    var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(params)
    if (results == null) {
      return null
    } else {
      return decodeURI(results[1]) || 0
    }
  }

  componentDidMount() {
    getSubjects((data) => {
      this.setState({ subjects: data })
    })

    getFAQs((data) => {
      this.setState({ faqs: data }, function () {
        if (this.props.location.pathname === '/contact') {
          setTimeout(function () {
            let el = document.getElementById('contact-us')
            document.documentElement.scrollTop = document.body.scrollTop =
              el.offsetTop
          }, 200)
        }
      })
    })

    this.UrlParam('subject', this.props.location.search) !== null &&
      setTimeout(function () {
        let el = document.getElementById('contact-us')
        document.documentElement.scrollTop = document.body.scrollTop =
          el.offsetTop
      }, 200)
  }
}

const validations = (values) => {
  var errors = {}

  if (!values[Names.ms]) {
    errors[Names.ms] = 'This field is required.'
  }

  if (!values[Names.sj]) {
    errors[Names.sj] = 'This field is required.'
  }

  return errors
}

const submission = (values, dispatch, props) => {
  dispatch(toggleLoader(true))

  GA(gac, 'Button :: Submit query','click', this.props.accountId)

  contactUsAuth(values, (result, error) => {
    dispatch(toggleLoader(false))

    if (result) {
      dispatch(showError(''))
      dispatch(
        openPopup(
          'success',
          'Thank you for your query.',
          'We will be back in touch as quick as we can.'
        )
      )
    } else {
      dispatch(showError(error))
    }

    props.reset()
  })
}

const FAQsForm = reduxForm({
  form: 'faqs',
  validate: validations,
})(FAQs)

function stateMapping(state) {
  let subject = ''
  if (state.form.faqs)
    if (state.form.faqs.values) subject = state.form.faqs.values.subject
  return {
    app_error: state.app.error,
    subject: subject,
    accountId: state.app.currentAccount.JtiAccountId
  }
}

export default connect(stateMapping)(FAQsForm)
