import React, {useState} from 'react';
import Progress from "./Progress";
import {submitQuestion} from "../../../axios/private/survey";

export default function TextEntry({question, currentQuestion, totalQuestions, nextQuestion, surveyId, questionId, setIsLoading}) {
  const [answer, setAnswer] = useState('')

  function handleSubmit() {
    if (answer) {
      setIsLoading(true);
      submitQuestion(surveyId, questionId, [{Custom: answer.replace(/(\r\n|\n|\r)/gm, " ")}])
        .then(() => {
          setIsLoading(false);
          nextQuestion();
        })
    }
  }

  return <div className={'text-entry-question'}>
    <p className={'text-entry-question__subtitle'}>To help us build the JTI ENGAGE of the future it would be great
      if you could answer the below question:</p>
    <p className={'text-entry-question__question'}>{question}</p>

    <p className={'text-entry-question__please-enter'}>Please enter your answer below</p>

    <textarea className={'text-entry-question__answer'} value={answer} onChange={(e) => setAnswer(e.target.value)}/>

    <button onClick={handleSubmit} className={'text-entry-question__next-btn'}>Next</button>
    <div className={'text-entry-question__progress'}>
      <Progress currentStep={currentQuestion} totalSteps={totalQuestions} />
    </div>
  </div>
}