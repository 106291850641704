import {
  getScreenWidth,
  getScreenHeight
} from 'Functions/helper'

import TriangledBackBetaTriangle from 'Components/TriangledBackBetaTriangle'

const frames = [0, 30, 34, 34, 34, 33, 33, 34, 34, 34, 34, 34, 34, 33, 34, 34];

class TriangledBackBeta extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.start = 2;
    
    this.state = {
      current: this.start,
      max: frames.length
    }
    
    this.timer = null;
  }
  
  render() {
    const { current } = this.state;
    
    return (
      <div className="triangled triangled-beta no-print">
      
        <div>
          <TriangledBackBetaTriangle end={(i)=>this.end(i)} index={ current } frames={ frames[current - 1] }/>
        </div>
      
      </div>
    )
  }
  
  end(index) {
    var current = ++index;
    if(current > this.state.max) current = this.start;
    this.setState({ current: current}); 
  }
}

export default TriangledBackBeta