import React, {useEffect, useState} from "react";
import QuestionsContainer from "./QuestionsContainer";
import ComeBackLater from "./ComeBackLater";
import ContinueSurvey from "./ContinueSurvey";
import SurveyTC from "./SurveyTC";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import MultipleQuestion from "./MultipleQuestion";
import SingleQuestion from "./SingleQuestion";
import RateQuestion from "./RateQuestion";
import TextEntry from "./TextEntry";
import SurveyEnd from "./SurveyEnd";
import {onSurveyClose, onSurveyStart} from "../../../axios/private/survey";

export default function Survey({
                                 showComeBackMessage,
                                 showFirstQuestion,
                                 survey,
                                 resetWasSurveyDismissed,
                                 resetWasSurveyContinued,
                               }) {
  const [isOpen, setIsOpen] = useState(false);
  const [surveyStep, setSurveyStep] = useState("continue");
  const [isComeBackOpen, setIsComeBackOpen] = useState("false");
  const [hasProvidedEmail, setHasProvidedEmail] = useState(survey.HasProvidedEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(
    survey.Survey.CurrentQuestionId ?? survey.Survey.Questions.length - 1
  );

  const {Survey: surveyData, ShowSticky: showSticky} = survey;
  const {Id: surveyId, Questions, EmailHtml: emailText} = surveyData;
  const {
    Text: questionText,
    Type: questionType = "survey-end",
    QuestionId: questionId,
    MultipleSelections: isQuestionMultiselect = false,
    MaxSelections: questionMaxSelections = 1,
  } = Questions[currentQuestion] || {};
  const isSurveyComplete = currentQuestion === Questions.length;

  useEffect(() => {
    if (showComeBackMessage) {
      setIsComeBackOpen(true);
      resetWasSurveyDismissed();
      setSurveyStep("come-back-later");
      setTimeout(() => {
        setIsComeBackOpen(false);
        setTimeout(() => {
          setSurveyStep("continue");
        }, 750);
      }, 3000);
    }
  }, [showComeBackMessage]);

  useEffect(() => {
    if (showFirstQuestion) {
      resetWasSurveyContinued();
      handleOpen();
    }
  }, [showFirstQuestion]);

  function handleOpen() {
    setIsOpen(true);
    setSurveyStep("survey-start");
    document.body.style.overflowY = "hidden";
  }

  function handleClose() {
    setIsOpen(false);
    onSurveyClose(surveyId, true);
    document.body.style.overflowY = "unset";
    setTimeout(() => setSurveyStep("continue"), 750);
  }

  function toggle() {
    if (isOpen) {
      setIsOpen(false);
      onSurveyClose(surveyId, true);
      document.body.style.overflowY = "unset";
    } else {
      setIsOpen(true);
      onSurveyStart(surveyId);
      document.body.style.overflowY = "hidden";
    }
  }

  function nextQuestion() {
    setCurrentQuestion((prev) => prev + 1);
  }

  function handleOpenTC() {
    setSurveyStep("terms-and-conditions");
  }
  function handleOpenTCLastStep() {
    setSurveyStep("terms-and-conditions-last");
  }

  function handleCloseTC() {
    setSurveyStep("continue");
  }

  function handleCloseTCLast() {
     setSurveyStep("survey-start");
  }

  return (
    <div className={"survey"}>
      <div
        className={`${isOpen ? "survey__backdrop" : ""} ${
          surveyStep === "survey-start" ? "survey__backdrop--right" : ""
        }`}
        onClick={handleClose}
      >
        {surveyStep === "continue" && showSticky && (
          <ContinueSurvey
            isOpen={isOpen}
            toggle={isSurveyComplete ? handleOpen : toggle}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleOpenTC={handleOpenTC}
            text={surveyData.StickyHtml}
          />
        )}

        {surveyStep === "terms-and-conditions" && (
          <Modal closeModal={handleCloseTC} className={"survey-popup-modal"}>
            <SurveyTC
              termsAndConditions={surveyData?.TermsHtml}
              startSurvey={handleOpen}
              goBack={handleCloseTC}
            />
          </Modal>
        )}

        {surveyStep === "terms-and-conditions-last" && (
          <Modal closeModal={handleCloseTCLast} className={"survey-popup-modal"}>
            <SurveyTC
              termsAndConditions={surveyData?.TermsHtml}
              startSurvey={handleOpen}
              goBack={handleCloseTCLast}
            />
          </Modal>
        )}

        {surveyStep === "come-back-later" && showSticky && (
          <ComeBackLater
            isComeBackOpen={isComeBackOpen}
            toggle={toggle}
            text={surveyData.OnClosePopupStickyHtml}
          />
        )}

        {surveyStep === "survey-start" && (
          <QuestionsContainer isOpen={isOpen} handleClose={handleClose}>
            {isLoading ? (
              <Loading/>
            ) : (
              <>
                {questionType === 1 &&
                  isQuestionMultiselect &&
                  questionMaxSelections > 1 && (
                    <MultipleQuestion
                      question={questionText}
                      answers={surveyData.Questions[currentQuestion].Answers}
                      maxSelections={questionMaxSelections}
                      totalQuestions={surveyData.Questions.length}
                      currentQuestion={currentQuestion + 1}
                      surveyId={surveyId}
                      questionId={questionId}
                      nextQuestion={nextQuestion}
                      setIsLoading={setIsLoading}
                    />
                  )}
                {questionType === 1 &&
                  (!isQuestionMultiselect || questionMaxSelections === 1) && (
                    <SingleQuestion
                      question={questionText}
                      answers={surveyData.Questions[currentQuestion].Answers}
                      totalQuestions={surveyData.Questions.length}
                      currentQuestion={currentQuestion + 1}
                      surveyId={surveyId}
                      questionId={questionId}
                      nextQuestion={nextQuestion}
                      setIsLoading={setIsLoading}
                    />
                  )}
                {questionType === 2 && (
                  <RateQuestion
                    question={questionText}
                    totalQuestions={surveyData.Questions.length}
                    currentQuestion={currentQuestion + 1}
                    surveyId={surveyId}
                    questionId={questionId}
                    nextQuestion={nextQuestion}
                    setIsLoading={setIsLoading}
                  />
                )}
                {questionType === 3 && (
                  <TextEntry
                    question={questionText}
                    totalQuestions={surveyData.Questions.length}
                    currentQuestion={currentQuestion + 1}
                    surveyId={surveyId}
                    questionId={questionId}
                    nextQuestion={nextQuestion}
                    setIsLoading={setIsLoading}
                  />
                )}
                {questionType === "survey-end" && (
                  <SurveyEnd
                    handleClose={handleClose}
                    surveyId={surveyId}
                    setIsLoading={setIsLoading}
                    handleOpenTC={handleOpenTCLastStep}
                    hasProvidedEmail={hasProvidedEmail}
                    setHasProvidedEmail={setHasProvidedEmail}
                    emailText={emailText}
                  />
                )}
              </>
            )}
          </QuestionsContainer>
        )}
      </div>
    </div>
  );
}