import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleBackTop } from '../../script/redux/actions.app'

export default function useScrollBackToTop() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleBackTop(true));
    return () => {
      dispatch(toggleBackTop(false));
    }
  })
}