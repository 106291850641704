import Velocity from 'velocity-animate'

import Request from 'superagent'
import { domain } from 'Functions/data'
import { getAuthorization } from 'Functions/storage'

import { getVlogList } from 'Functions/contents'
import { matchColumns } from 'Functions/helper'

import moment from 'moment'

import Video from 'Components/blocks/Video'
import { connect } from 'react-redux'

class Vlogs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videos: [],
      current: 0,
      loading: false,
      answered: false,
      answer: '',
      initHero: false,
      initHeroID: 0,
    }

    this.capacity = 4
    this.totalPage = 0
  }

  render() {
    const { history } = this.props
    const { videos, current, loading } = this.state

    const activeVideo =
      videos.filter((vd) => {
        return vd.isHero
      })[0] || {}
    const restVideos = videos.filter((vd) => {
      return !vd.isHero
    })

    return (
      <>
        <div className="page page-pad-L page-vloglist page-vloglist--top">
          <div
            className="page-vloglist__video"
            ref={(dom) => (this.mainVideo = dom)}
          >
            <Video
              video={loading ? '' : activeVideo.video}
              image={activeVideo.image}
              id={activeVideo.id}
              track={(action, id) => this.trackVideo(action, id)}
            />

            <div className="page-vloglist__video-header">
              <div className="page-vloglist__video-header-title">
                <h4>{activeVideo.title}</h4>
              </div>
              <div className="page-vloglist__video-header-stats">
                <div
                  onClick={() => this.trackVideo('Like', activeVideo.id)}
                  className={activeVideo.like ? 'liked' : 'like'}
                ></div>
                <div
                  onClick={() => this.trackVideo('Dislike', activeVideo.id)}
                  className={activeVideo.dislike ? 'disliked' : 'dislike'}
                ></div>
                <div
                  className={activeVideo.viewed ? 'viewed' : 'unviewed'}
                ></div>
                <span>
                  {activeVideo.views} VIEWS &nbsp; | &nbsp; POSTED{' '}
                  {moment(activeVideo.posted).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>

            <p>{activeVideo.description}</p>
          </div>
        </div>

        {activeVideo.question && !this.state.answered && (
          <div className="page-vloglist__question">
            <h4>{activeVideo.question}</h4>
            <div className="answer">
              <input
                id="answer1"
                type="radio"
                name="answer"
                value={1}
                onChange={(e) => this.changeAnswer(e)}
              />
              <label htmlFor="answer1">{activeVideo.answer1}</label>
            </div>
            <div className="answer">
              <input
                id="answer2"
                type="radio"
                name="answer"
                value={2}
                onChange={(e) => this.changeAnswer(e)}
              />
              <label htmlFor="answer2">{activeVideo.answer2}</label>
            </div>
            <br />
            <button
              className="submit"
              disabled={this.state.answer === ''}
              onClick={() => this.submitAnswer(activeVideo.id)}
            >
              Submit my feedback
            </button>
          </div>
        )}

        {this.state.answered && (
          <div className="page-vloglist__thank-you">
            <span className="thank-you__head">Thank you</span>
            <span className="thank-you__body">
              Your feedback is appreciated, we'll be uploading the next vlog
              very soon
            </span>
          </div>
        )}

        <div className="page page-pad-L page-vloglist page-vloglist--bottom">
          <div className="page-vloglist-list">
            {restVideos.map((video) => (
              <div
                id={video.id}
                className={`page-vloglist__thumb ${
                  video.like || video.dislike
                    ? 'page-vloglist__thumb--reacted'
                    : ''
                }`}
                onClick={() => this.changeVideo(video.id)}
                key={video.id}
              >
                <div
                  className="thumbnail"
                  style={{ backgroundImage: `url(${video.image})` }}
                ></div>

                <div className="details">
                  <i className={video.viewed ? 'viewed' : 'unviewed'}></i>
                  {video.like && <i className="liked"></i>}
                  {video.dislike && <i className="disliked"></i>}
                  <h5 data-col="thumb-header">{video.title}</h5>
                  <p className="date">
                    <strong>
                      POSTED {moment(video.posted).format('DD/MM/YYYY')}
                    </strong>
                  </p>
                  <p>{video.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  trackVideo(type, id, answer = '') {
    let url = domain + '/api/Cms/Track/VlogAsset?vlogId=' + id + '&type=' + type
    if (answer !== '') url += '&answer=' + answer
    Request.post(url)
      .set({ Authorization: getAuthorization() })
      .responseType('json')
      .end((err, res) => {
        if (err) {
          return false
        }
        console.log(res)
      })

    let { videos } = this.state,
      i = 0
    for (i; i < videos.length; i++) {
      if (videos[i].id === id) {
        if (type === 'Like') {
          videos[i].like = true
          videos[i].dislike = false
        }
        if (type === 'Dislike') {
          videos[i].like = false
          videos[i].dislike = true
        }
        if (type === 'View') {
          videos[i].viewed = true
          videos[i].views++
        }
      }
    }
    this.setState({ videos: videos })
  }

  changeAnswer(e) {
    this.setState({ answer: e.target.value })
  }

  submitAnswer(id) {
    this.setState({ answered: true })
    this.trackVideo('Question', id, this.state.answer)
  }

  changeVideo(id) {
    const { videos } = this.state

    Velocity(this.mainVideo, 'scroll', {
      duration: 500,
      easing: 'ease-out',
    })

    this.setState({
      videos: this.updateHero(id, videos),
      loading: true,
      answered: false,
    })

    window.setTimeout(() => {
      matchColumns()
      this.setState({ loading: false })
    }, 200)
  }

  updateHero(id, videos) {
    videos.forEach((vd) => {
      if (vd.id === id) {
        vd.isHero = true
      } else {
        vd.isHero = false
      }
    })

    return videos
  }

  componentDidMount() {
    getVlogList((videos) => {
      const videoAssets = [...videos]

      const heroes = videoAssets.filter((vd) => {
        return vd.isHero
      })

      if (!this.state.initHero) {
        this.setState({
          videos: this.updateHero(
            !heroes.length ? videoAssets[0].id : heroes[0].id,
            videoAssets
          ),
        })
        this.setState({
          initHero: true,
          initHeroID: !heroes.length ? videoAssets[0].id : heroes[0].id,
        })
      }

      window.addEventListener('resize', matchColumns)
      matchColumns()
    })
  }

  componentWillUnmount() {
    getVlogList((videos) => {
      const videoAssets = [...videos]

      const heroes = videoAssets.filter((vd) => {
        return vd.isHero
      })

      this.setState({
        videos: this.updateHero(
          !heroes.length ? videoAssets[0].id : this.state.initHeroID,
          videoAssets
        ),
      })
    })
  }
}

export default connect()(Vlogs)
