import React from 'react'
import closeIcon from '../icons/Cross.svg'

export default function Modal({closeModal, className, children}) {
  return <div onClick={closeModal} className='modal-backdrop'>
    <div onClick={e => e.stopPropagation()} className={`modal-content ${className ? className : ''}`}>
      <div className='close-btn-container'>
        <button className='close-btn'
                onClick={closeModal}
        >
          <img src={closeIcon} alt={'Close'}/>
        </button>
      </div>
      {children}
    </div>
  </div>
}