import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { orderInvoiceREQUEST } from '../../script/requests/history';
import Preloader from 'Components/Preloader';

function Invoice() {
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [firstRefetchDone, setFirstRefetchDone] = useState(false);

  const fetchInvoice = () => {
    setLoading(true);
    const params = qs.parse(location.search);

    orderInvoiceREQUEST(params.ordernumber, (result, data, error) => {
      setLoading(false);

      if (result) {
        setInvoiceUrl(data);
        setIsSuccess(true);
      }
    });
  };

  useEffect(() => {
    fetchInvoice();
  }, [location.search]);

  if (isSuccess && !firstRefetchDone) {
    fetchInvoice();
    setFirstRefetchDone(true);
  }

  return (
    <>
      {!loading ? (
        invoiceUrl.length > 0 ? (
          <>
            <iframe
              src={invoiceUrl}
              data-ot-ignore={true}
              style={{
                width: '100%',
                height: '100%',
              }}
            ></iframe>
            <button onClick={fetchInvoice}>Refetch Invoice</button>
          </>
        ) : (
          <div>Invoice not available.</div>
        )
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default Invoice;