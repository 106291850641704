import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Radio from '../../form/Radio'
import Button from '../../form/Button'
import {getMyOrders} from '../../../axios/private/account'
import {defaultSortInterval} from '../../../utils/constants'
import {setCurrentOrders, setOpenDrawer} from '../../../script/redux/actions.app'
import Select from "../../form/Select";
import {getAccountNumber} from "../../../utils/storage";

const initialState = {
  selectedStore: {
    value: null,
    label: 'Select Store',
  },
  selectedFilter: null,
  stores: [],
}

const OrdersFilter = (props) => {
  const {stores, filters, closeDrawer, dispatch} = props
  const [state, setState] = useState(initialState)

  const getOrders = (filter = defaultSortInterval, store = getAccountNumber()) => {
    return getMyOrders(filter, store)
  }

  const onApplyFilters = () => {
    if (!state.selectedStore.value && !state.selectedFilter) {
      getOrders().then((orders) => {
        dispatch(setCurrentOrders(orders, state.selectedStore.value))
      })
    } else if (state.selectedStore.value && !state.selectedFilter) {
      getOrders(undefined, state.selectedStore.value).then((orders) => {
        dispatch(setCurrentOrders(orders, state.selectedStore.value))
      })
    } else {
      switch (state.selectedFilter?.type) {
        case 'time':
          getOrders(state.selectedFilter.value, state.selectedStore.value || getAccountNumber()).then((orders) => {
            dispatch(setCurrentOrders(orders, state.selectedStore.value || getAccountNumber()))
          })
          break
        case 'status':
          getOrders().then((orders) => {
            const filtered = orders?.filter(
              (order) => order?.Status === state.selectedFilter.value
            )
            dispatch(setCurrentOrders(filtered, state.selectedStore.value))
          })
          break
        default:
          break
      }
    }

    dispatch(setOpenDrawer('FILTER', false))
  }

  useEffect(() => {
    const newStores = stores.map((store) => ({
      ...store,
      value: store?.JtiAccountId,
      label: `${store?.AccountName}\n${store?.AddressLine1}`,
    }))
    setState((oldVal) => ({...oldVal, stores: newStores}))
  }, [stores])

  const onFilterChange = (item, index) => {
    setState((oldVal) => ({...oldVal, selectedFilter: item}))
  }

  const onStoreChange = (item) => {
    setState((oldVal) => ({...oldVal, selectedStore: item}))
  }

  const onResetAll = () => {
    setState(initialState)
  }

  return (
    <div className="bw-store-selector bw-orders-drawer">
      <div className="bw-store-selector--header bw-mb-10">
        <button
          className="bw-store-selector--close"
          onClick={() => closeDrawer('STORE')}
        ></button>
        <h2 className="bw-store-selector--title">{stores?.length > 1 ? 'STORE SELECTOR' : 'FILTER'}</h2>
      </div>
      <div>
        {stores?.length > 1 && (
          <>
            <p className="bw-text-28 bw-font-medium">BY STORE</p>
            <Select placeholder={"Select Store"}
                    selectedItem={stores.map((store) => ({
                      label: store.AccountName,
                      value: store.JtiAccountId,
                    }))}
                    dataSelected={state.selectedStore}
                    setData={onStoreChange}
            />
          </>
        )}
      </div>
      <div className="bw-orders-drawer--filters">
        {filters.map((item, index) => (
          <div key={index}>
            <Radio
              value={item.type + '-' + item.value}
              name="store"
              checked={state.selectedFilter?.value === item.value}
              id={item.type + '-' + item.value}
              onChange={() => onFilterChange(item, index)}
            >
              <p className="bw-text-15 bw-text-gray-800">{item.label}</p>
            </Radio>
          </div>
        ))}
      </div>

      <div className="bw-orders-drawer--btns">
        <Button
          label="Clear All"
          className="bw-button--secondary"
          onClick={onResetAll}
        />
        <Button label="Apply" onClick={onApplyFilters}/>
      </div>
    </div>
  )
}

OrdersFilter.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  stores: PropTypes.array,
  filters: PropTypes.array,
}

OrdersFilter.defaultProps = {
  closeDrawer: () => {
  },
  dispatch: () => {
  },
  stores: [],
  filters: [],
}

export default OrdersFilter