import productLoadingIcon from '../../../images/Loading.svg';

export default function Placeholder() {
  return (
    <img className="placeholder" src={ productLoadingIcon } />
  );
}

export function getPlaceholderImage() {
  return productLoadingIcon;
}