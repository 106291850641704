import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { getUsername, resetAuth } from 'Functions/storage'
import productHelper from 'Functions/product.helper'
import { toggleNavMenu, openPopup } from 'Redux/actions.app'
import { GA } from 'Functions/ga'
import { showStockCardREQUEST } from '@Requests/products'
import { toCurrencyString } from '../../utils/functions/currency'

import JTILogo from '../../images/jti-logo.svg'
import CartIcon from '../../images/icons/cart.svg'
import UserIcon from '../../images/icons/user.svg'

import StockCardPopUp from '../../script/components/blocks/StockCardPopUp'
import { getTier } from '../../utils/functions/tier'
import { gaStockCardType } from "../../script/functions/ga";

const gac = 'Primary Navigation'

const Navigation = (props) => {
  const { history, location, dispatch, quantity, subTotal, currentAccount } =
    props

  const tierLevel = getTier(currentAccount?.TierAccessLevel)

  const initialState = {
    showNavigation: false,
    animType: '',
    showStockCardPopUp: false,
    weather: {},
    partnerHubCount: 0,
  }

  const [State, setState] = useState(initialState)
  const [showStockCard, setShowStockCard] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 931) {
      showStockCardREQUEST((result) => {
        if (result) {
          setShowStockCard(true)
        }})
    }
    else {
      setShowStockCard(false)
    }
  }, [showStockCard, window.innerWidth]);

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'bw-active' : ''
  }

  const onMobileMenuToogle = () => {
    setState((oldVal) => ({
      ...oldVal,
      showNavigation: !oldVal.showNavigation,
    }))
  }

  const onPlaceOrderClick = (e) => {
    // e.preventDefault()
    history.push('/place-order')
    GA(gac, 'Place order', 'click', currentAccount.JtiAccountId)

    // UNCOMMENT TO ENABLE STOCK CARD POPUP
    // if (window.innerWidth > 931) {
    //   showStockCardREQUEST((result) => {
    //     if (result) {
    //       setState((prevState) => ({
    //         ...prevState,
    //         showStockCardPopUp: true,
    //       }))
    //     } else {
    //       history.push('/place-order')
    //     }
    //   })
    // } else {
    //   history.push('/place-order')
    // }
  }

  const closeStockCardPopUp = () => {
    setState((prevState) => ({
      ...prevState,
      showStockCardPopUp: false,
    }))
  }

  const onLogoClick = () => {
    closeStockCardPopUp()
    history.push('/')
  }

  const onCartClick = () => {
    closeStockCardPopUp()
    GA(gac, 'My cart', 'click', currentAccount.JtiAccountId)
  }

  const onMyOrdersClick = () => {
    closeStockCardPopUp()
    GA(gac, 'My orders', 'click', currentAccount.JtiAccountId)
  }

  const onMyAccountClick = () => {
    closeStockCardPopUp()
    GA(gac, 'My account', 'click', currentAccount.JtiAccountId)
  }

  const onHelpClick = () => {
    closeStockCardPopUp()
    GA(gac, 'Help', 'click', currentAccount.JtiAccountId)
  }

  const onLogoutClick = (e) => {
    closeStockCardPopUp()
    logout(e)
  }

  const logout = (e) => {
    e.preventDefault()

    GA(gac, 'Log out', 'click', currentAccount.JtiAccountId)

    if (quantity) {
      dispatch(
        openPopup(
          'logout',
          'You have items in your cart',
          'Are you sure you want to log out without placing your order?'
        )
      )
    } else {
      resetAuth()
      window.location.reload()
    }
  }

  return (
    <>
      <div className={`bw-navigation ${State.showNavigation ? 'active' : ''}`}>
        <div className="bw-container bw-flex">
          <div className="bw-navigation--brand">
            <img
              src={JTILogo}
              alt="JTI Engage"
              onClick={onLogoClick}
            />
          </div>
          <div className="bw-navigation-mobile lg:bw-hidden">
            <ul className="bw-navigation--nav">
              {tierLevel !== 3 && (
                <>
                  <li className={getNavLinkClass("/place-order")}>
                    <NavLink
                      className="bw-link"
                      to="/place-order"
                      onClick={(e) => onPlaceOrderClick(e)}
                    >
                      PLACE ORDER
                    </NavLink>
                  </li>
                  <li className={getNavLinkClass("/my-orders")}>
                    <NavLink
                      className="bw-link"
                      to="/my-orders"
                      onClick={() => GA(gac, "My orders", "click", currentAccount.JtiAccountId)}
                    >
                      MY ORDERS
                    </NavLink>
                  </li>
                </>
              )}
              {showStockCard && <li className={getNavLinkClass("/stock-card")}>
                <NavLink
                  className="bw-link bw-relative"
                  to="/stock-card"
                  onClick={() => gaStockCardType("StockCard", currentAccount.JtiAccountId)}

                >
                  MY STOCK CARD
                  <svg xmlns="http://www.w3.org/2000/svg" fill="gold" viewBox="0 0 24 24" strokeWidth={1.5}
                       stroke="gold" className="bw-w-3 bw-h-3 bw-ml-1 bw-absolute"
                       style={{ top: "-5px", right: "-12px" }}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                  </svg>
                </NavLink>
              </li>}
              <li className={getNavLinkClass("/videos")}>
                <NavLink
                  className="bw-link"
                  to="/videos"
                  onClick={() => GA(gac, "Videos", "click", currentAccount.JtiAccountId)}
                >
                  VIDEOS
                </NavLink>
              </li>
              <li className={getNavLinkClass("/p4p")}>
                <NavLink
                  className="bw-link"
                  to="/p4p"
                  onClick={() => GA(gac, "Partner Hub", "click", currentAccount.JtiAccountId)}
                >
                  PARTNER HUB
                </NavLink>
              </li>
              {/* <li className="bw-special">
                <a className="bw-link" href="">
                  OFFERS
                </a>
              </li> */}
            </ul>
          </div>
          <ul className="bw-navigation--nav bw-hidden lg:bw-flex">
            {tierLevel !== 3 && (
              <>
                <li className={getNavLinkClass("/place-order")}>
                  <NavLink
                    className="bw-link"
                    to="/place-order"
                    onClick={(e) => onPlaceOrderClick(e)}
                  >
                    PLACE ORDER
                  </NavLink>
                </li>
                <li className={getNavLinkClass("/my-orders")}>
                  <NavLink
                    className="bw-link"
                    to="/my-orders"
                    onClick={() => GA(gac, "My orders", "click", currentAccount.JtiAccountId)}
                  >
                    MY ORDERS
                  </NavLink>
                </li>
              </>
            )}
            {showStockCard && <li className={getNavLinkClass("/stock-card")}>
              <NavLink
                className="bw-link bw-flex bw-items-center bw-relative bw-mr-1"
                style={{ paddingTop: "4px" }}
                to="/stock-card"
                onClick={() => gaStockCardType("StockCard", currentAccount.JtiAccountId)}

              >
                MY STOCK CARD
                <svg xmlns="http://www.w3.org/2000/svg" fill="gold" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="gold" className="bw-w-3 bw-h-3 bw-ml-1 bw-absolute" style={{top: "-5px", right: "-12px"}}>
                  <path strokeLinecap="round" strokeLinejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
              </NavLink>
            </li>}
            <li className={getNavLinkClass("/videos")}>
              <NavLink
                className="bw-link"
                to="/videos"
                onClick={() => GA(gac, "Videos", "click", currentAccount.JtiAccountId)}
              >
                VIDEOS
              </NavLink>
            </li>
            <li className={getNavLinkClass("/p4p")}>
              <NavLink
                className="bw-link"
                to="/p4p"
                onClick={() => GA(gac, "Partner Hub", "click", currentAccount.JtiAccountId)}
              >
                PARTNER HUB
              </NavLink>
            </li>
            {/* <li className="bw-special">
              <a className="bw-link" href="">
                OFFERS
              </a>
            </li> */}
          </ul>

          <div className="bw-navigation--cart">
            {tierLevel !== 3 && (
              <>
                <div className="total">{toCurrencyString(subTotal)}</div>
                <Link to="/cart" onClick={onCartClick} replace>
                  <span>{quantity}</span>
                  <img src={CartIcon} alt="your cart" />
                </Link>
              </>
            )}
            <div className="menuToggle" onClick={onMobileMenuToogle}>
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </div>
            <div className="profile">
              <span>
                <img src={UserIcon} alt="profile" />
              </span>
              <div className="menu-wrap">
                <ul className="menu">
                  {tierLevel !== 3 && (
                    <li>
                      <Link
                        className="bw-link"
                        to="/my-orders"
                        onClick={onMyOrdersClick}
                      >
                        MY ORDERS
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      className="bw-link"
                      to="/my-account"
                      onClick={onMyAccountClick}
                    >
                      MY ACCOUNT
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="bw-link"
                      to="/faqs"
                      onClick={onHelpClick}
                    >
                      HELP
                    </Link>
                  </li>
                  <li>
                    <a className="bw-link" href="#" onClick={onLogoutClick}>
                      LOG OUT
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {State.showStockCardPopUp && (
        <StockCardPopUp closePopup={closeStockCardPopUp} />
      )}
    </>
  )
}

const stateMapping = (state) => {
  return {
    quantity: state.app.cart?.TotalItems || 0,
    subTotal: state.app.cart?.OrderTotal || 0,
    currentAccount: state.app.currentAccount,
  }
}

export default connect(stateMapping)(Navigation)
