import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Velocity from "velocity-animate";
import { getVideoList } from "Functions/contents";
import Video from "Components/blocks/Video";
import useScrollBackToTop from "../../utils/hooks/useScrollBackToTop";

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const heroVideoRef = useRef(null);
  const capacity = 4;

  useEffect(() => {
    getVideoList((fetchedVideos) => {
      const videoAssets = [...fetchedVideos];

      const heroes = videoAssets.filter((vd) => {
        return vd.isHero;
      });

      setVideos(
        updateHero(
          !heroes.length ? videoAssets[0].id : heroes[0].id,
          videoAssets
        )
      );
    });
  }, []);

  useScrollBackToTop()

  const heroVideo = videos.find((vd) => vd.isHero) || {};
  const restVideos = videos.filter((vd) => !vd.isHero);
  const numberOfMissingVideos = capacity - (restVideos.length % capacity);

  if (numberOfMissingVideos !== capacity && restVideos.length > capacity) {
    restVideos.push(...restVideos.slice(0, numberOfMissingVideos));
  }

  const videoBlocks = [];

  // Creating video blocks by slicing restVideos into sub-arrays of size "capacity"
  for (let i = 0; i < Math.ceil(restVideos.length / capacity); i++) {
    videoBlocks.push(restVideos.slice(i * capacity, (i + 1) * capacity));
  }
  const pageTotal = videoBlocks.length;

  const slide = (next) => {
    if (next < 0) {
      setCurrent(pageTotal - 1);
      return;
    }

    if (next > pageTotal - 1) {
      setCurrent(0);
      return;
    }

    setCurrent(next);
  };

  const changeVideo = (id) => {
    Velocity(heroVideoRef, "scroll", {
      duration: 500,
      easing: "ease-out",
    });

    setVideos(updateHero(id, videos));
    setLoading(true);

    window.setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const updateHero = (id, videos) => {
    videos.forEach((vd) => {
      vd.isHero = vd.id === id;
    });

    return videos;
  };

  return (
    <>
      <div className="page page-pad-L page-videolist">
        <div className="page-videolist-video" ref={heroVideoRef}>
          <Video
            title={heroVideo.title}
            video={loading ? "" : heroVideo.video}
            image={heroVideo.image}
          />

          <h4>{heroVideo.title}</h4>

          <p>{heroVideo.description}</p>
        </div>

        <div  className={`page-videolist-list ${
          videoBlocks.length > 1 ? 'page-videolist-list--with-arrows' : ''
          }`}
          >
          {videoBlocks.length > 1 && (
            <button
              onClick={() => slide(current - 1, current === 0)}
              className={`button-left`}
            >
              <i className="icon-Left-Arrow" />
            </button>
          )}

          <div className="list-wrapper">
            <div
              style={{
                WebkitTransform: `translate3d(${
                  current * -100 + current * -2
                }%, 0, 0)`,
                transform: `translate3d(${
                  current * -100 + current * -2
                }%, 0, 0)`,
              }}
              className="transition duration05"
            >
              <div style={{ width: `${pageTotal * 100 + pageTotal * 2}%` }}>
                {videoBlocks.map((block, i) => (
                  <div
                    className="list-page"
                    key={i}
                    style={{ width: `${100 / pageTotal}%` }}
                  >
                    {block.map((video) => (
                      <div
                        id={video.id}
                        className="cf"
                        onClick={() => changeVideo(video.id)}
                        key={video.id}
                      >
                        <div className="thumbnail">
                          <img src={video.image} alt={"video thumbnail"} />
                          <span />
                        </div>

                        <div className="details">
                          <div>
                            <h5>{video.title}</h5>
                            <p>{video.description}</p>
                          </div>

                          <div />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {videoBlocks.length > 1 && (
            <button
              onClick={() => slide(current + 1, pageTotal - 1 <= current)}
              className={`button-right`}
            >
              <i className="icon-Right-Arrow" />
            </button>
          )}
        </div>

        {videoBlocks.length > 1 && (
          <div className="page-videolist-squares">
            <div>
              {videoBlocks.map((block, i) => (
                <span
                  key={i}
                  className={i === current ? "active" : ""}
                  onClick={() => slide(i)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect()(VideoList);
