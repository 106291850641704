import {BrowserRouter as Router, Redirect, Route, Switch, useHistory,} from 'react-router-dom'
import './scss/main.scss'
import {Provider} from 'react-redux'
import store from 'Redux/store'
import Loader from 'Components/Loader'
import Login from './pages/public/Login'
import Register from './pages/public/Register'
import ResetPassword from './pages/public/ResetPassword'
import ContactUs from './pages/public/ContactUs'
import CookiesPolicyPublic from './pages/public/CookiesPolicyPublic'
import TermsConditionsRegister from './pages/public/TermsConditionsRegister'
import PrivacyPolicyPublic from './pages/public/PrivacyPolicy'
import Home from 'Views/Home'
import FAQs from 'Views/FAQs'
import StockCard from './pages/private/StockCard'
import MyAccount from './pages/private/MyAccount'
import ChangePassword from './pages/private/ChangePassword'
import SetEoidFid from './pages/private/SetEoidFid'
import Invoice from './pages/private/Invoice';
import Cart from './pages/private/Cart'
import PlaceOrder from './pages/private/PlaceOrder'
import OrderConfirmation from './pages/private/OrderConfirmation'
import ReviewOrder from './pages/private/ReviewOrder'
import MyOrders from './pages/private/MyOrders'
import VideoList from 'Views/VideoList'
import Vlogs from 'Views/Vlogs'
import PrivacyPolicy from 'Views/PrivacyPolicy'
import CookiesPolicy from 'Views/CookiesPolicy'
import TermsConditions from 'Views/TermsConditions'
import Declaration from 'Views/Declaration'
import P4P from 'Views/P4P'
import TermsConditionsPromo from 'Views/TermsConditionsPromo'
import Page404 from './pages/Page404'
import VerifyAccount from './pages/public/VerifyAccount'
import ResendVerification from './pages/public/ResendVerification'
import ForgottenPassword from './pages/public/ForgottenPassword'
import PrivateTemplate from './components/template/Private'
import PublicTemplate from './components/template/Public'
import InvoiceTemplate from './components/template/Invoice';

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
  // TEMP
  const history = useHistory()

  if (location.hash.startsWith('#/')) {
    let tempLocation = location.hash.replace('#', '')

    let newLocation = {
      pathname: tempLocation.split("?")[0],
      search: tempLocation.split("?")[1]
        ? `?${tempLocation.split("?")[1]}`
        : false,
    };

    return <Redirect to={newLocation} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

ReactDom.render(
  <Provider store={store}>
    <Router>
      <React.Fragment>
        <Switch>
          <RouteWrapper
            path="/login"
            component={Login}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/register"
            component={Register}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/password"
            component={ResetPassword}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/contact-us"
            component={ContactUs}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/resend-verification"
            component={ResendVerification}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/verify-account"
            component={VerifyAccount}
            layout={PublicTemplate}
          />
          <RouteWrapper
            path="/forgotten-password"
            component={ForgottenPassword}
            layout={PublicTemplate}
          />
          <Route
            path="/cookies-policy-public"
            component={CookiesPolicyPublic}
          />
          <Route
            path="/terms-conditions-register"
            component={TermsConditionsRegister}
          />
          <Route
            path="/privacy-policy-public"
            component={PrivacyPolicyPublic}
          />
          <Route
            path="/terms-conditions-promo"
            component={TermsConditionsPromo}
          />
          <RouteWrapper
            exact
            path="/"
            component={Home}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/faqs"
            component={FAQs}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/contact"
            component={FAQs}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/my-account"
            component={MyAccount}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/change-password"
            component={ChangePassword}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/set-eoid-fid"
            component={SetEoidFid}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/place-order"
            component={PlaceOrder}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/stock-card"
            component={StockCard}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/cart"
            component={Cart}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/order-confirmation"
            component={OrderConfirmation}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/review-order"
            component={ReviewOrder}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/my-orders"
            component={MyOrders}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/videos"
            component={VideoList}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/vlogs"
            component={Vlogs}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/privacy-policy"
            component={PrivacyPolicy}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/cookies-policy"
            component={CookiesPolicy}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/terms-conditions"
            component={TermsConditions}
            layout={PrivateTemplate}
          />
          <RouteWrapper
            path="/declaration"
            component={Declaration}
            layout={PrivateTemplate}
          />
          <RouteWrapper path="/p4p" component={P4P} layout={PrivateTemplate} />
          <RouteWrapper path="/invoice" component={Invoice} layout={InvoiceTemplate}/>
          <Route component={Page404} />
        </Switch>

        <Loader />
      </React.Fragment>
    </Router>
  </Provider>,
  document.getElementById('AppRoot')
)