export const COOKIES_STATUS = 'COOKIES_STATUS';

export const ADD_CONSENT_TYPE = 'ADD_CONSENT_TYPE';
export const RESET_CONSENT_TYPE = 'RESET_CONSENT_TYPE';
export const REMOVE_CONSENT_TYPE = 'REMOVE_CONSENT_TYPE';

export const ADD_ACTIVE_CONSENT_TYPE = 'ADD_ACTIVE_CONSENT_TYPE';
export const RESET_ACTIVE_CONSENT_TYPE = 'RESET_ACTIVE_CONSENT_TYPE';
export const REMOVE_ACTIVE_CONSENT_TYPE = 'REMOVE_ACTIVE_CONSENT_TYPE';

export const TOGGLE_CONSENT_DIALOG = 'TOGGLE_CONSENT_DIALOG';


export function cookiesStatus(consent) {
  return {
    type: COOKIES_STATUS,
    consent,
  };
};

export function addConsentType(cookies) {
  return {
    type: ADD_CONSENT_TYPE,
    cookies,
  };
};
export function resetConsentType() {
  return {
    type: RESET_CONSENT_TYPE,
  };
};
export function removeConsentType(cookie) {
  return {
    type: REMOVE_CONSENT_TYPE,
    cookie,
  };
};

export function addActiveConsentType(cookies) {
  return {
    type: ADD_ACTIVE_CONSENT_TYPE,
    cookies,
  };
};
export function resetActiveConsentType() {
  return {
    type: RESET_ACTIVE_CONSENT_TYPE,
  };
};
export function removeActiveConsentType(cookie) {
  return {
    type: REMOVE_ACTIVE_CONSENT_TYPE,
    cookie,
  };
};

export function toggleConsentDialog(show) {
  return {
    type: TOGGLE_CONSENT_DIALOG,
    show,
  };
};