import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import JTILogoWhite from '../images/jti-logo-white.svg'
import { Link } from 'react-router-dom'
import ChatBot from '../script/components/chatbot/ChatBot'
import { getTier } from '../utils/functions/tier'
import axios from "axios";

const Footer = withRouter((props) => {
  const { loggedIn, currentAccount, history } = props
  const [showChatbot, setShowChatbot] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/Cms/HelpbotQuestion");
        if(response.status === 200) {
          setShowChatbot(true)
        }
      } catch (error) {
        if(error.response.status === 404) {
          setShowChatbot(false)
        }
      }
    };

    fetchData();
  }, []);

  const tierLevel = getTier(currentAccount?.TierAccessLevel)

  return (
    <>
      <footer className="bw-footer">
        <div className="bw-container">
          <img src={JTILogoWhite} alt="JTI Engage" className="bw-logo" />
          <nav>
            {loggedIn && (
              <>
                <div className="bw-category bw-category--1">
                  <h3>
                    <Link to="/my-account" className="bw-link">
                      My account
                    </Link>
                  </h3>
                  {tierLevel !== 3 && (
                    <ul>
                      <li>
                        <Link to="/my-orders" className="bw-link">
                          My orders
                        </Link>
                      </li>
                      <li>
                        <Link to="/place-order" className="bw-link">
                          Place order
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="bw-category bw-category--2">
                  <h3>Resources</h3>
                  <ul>
                    <li>
                      <Link to="/p4p" className="bw-link">
                        Partner hub
                      </Link>
                    </li>
                    {/* <li>
                <Link to="" className="bw-link">
                  Profit Calculator
                </Link>
              </li> */}
                    <li>
                      <Link to="/videos" className="bw-link">
                        Videos
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        href="https://jticonnex.com/"
                        target="_blank"
                        className="bw-link"
                      >
                        Connex app
                      </a>
                    </li> */}
                  </ul>
                </div>
              </>
            )}
            <div className="bw-category bw-category--3">
              <h3>Corporate</h3>
              <ul>
                <li>
                  <Link
                    to={loggedIn ? '/faqs' : '/contact-us'}
                    className="bw-link"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/faqs" className="bw-link">
                    Help
                  </Link>
                </li>
                {/* <li>
                <Link to="" className="bw-link">
                  About
                </Link>
              </li> */}
              </ul>
            </div>
            <div className="bw-category bw-category--4">
              <h3>Legal</h3>
              <ul>
                <li>
                  <Link
                    to={`/terms-conditions${loggedIn ? '' : '-register'}`}
                    className="bw-link"
                  >
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/privacy-policy${loggedIn ? '' : '-public'}`}
                    className="bw-link"
                  >
                    Privacy Statement
                  </Link>
                </li>
                {/* <li>
                <Link to="" className="bw-link">
                  Accesibility Statement
                </Link>
              </li> */}
              </ul>
            </div>
          </nav>
          <div className="bw-copyright">
            <p>Copyright © JTI ENGAGE 2021</p>
            <div className="bw-copyright--links">
              {loggedIn && (
                <Link to="/declaration" className="bw-link">
                  Reach Declaration
                </Link>
              )}
              <Link to="/cookies-policy" className="bw-link">
                Cookies Policy
              </Link>
            </div>
          </div>
          <hr />
          <p className="bw-text-13 bw-leading-tight bw-font-normal">
            This application is for tobacco retailers only and should not be
            made available to the public nor should it be displayed in any area
            where it is visible to the public. This application is restricted to
            those involved in the business of the sale or distribution of
            tobacco products who are 18 years of age or older.
          </p>
        </div>
      </footer>
      {loggedIn && showChatbot && <ChatBot history={history} />}
    </>
  )
})

Footer.propTypes = {
  loggedIn: PropTypes.bool,
}

Footer.defaultProps = {
  loggedIn: false,
}

const stateMapping = (state) => {
  return {
    currentAccount: state.app.currentAccount,
  }
}

export default connect(stateMapping)(Footer)
