import React from 'react'

import Footer from '../../components/Footer'
import JTILogo from '../../images/jti-logo.svg'
import TermsConditionsContent from '../../components/TermsConditionsContent'

const TermsConditionsRegister = (props) => {
  const { history } = props
  return (
    <>
      <div className="bw-container-page page-privacy_policy">
        <div className="bw-container">
          <img
            src={JTILogo}
            alt="JTI Engage"
            className="bw-mb-10 md:bw-mb-12 bw-cursor-pointer"
            onClick={() => history.push('/')}
          />
          <div className="bw-page-terms">
            <h1 className="bw-h1">Terms and Conditions</h1>
            <TermsConditionsContent />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

TermsConditionsRegister.propTypes = {}

export default TermsConditionsRegister
