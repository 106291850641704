import React from "react";

export default function SurveyTC({termsAndConditions, startSurvey, goBack}) {
  return <div className={'survey-popup-tc'}>
    <h2 className={'survey-tc-title'}>Terms and Conditions</h2>
    <p className={'survey-tc-text'}>{termsAndConditions}</p>
    <div className='survey-popup-btns' style={{marginBottom: '16px'}}>
      <button className='btn-continue' onClick={startSurvey}>Start survey</button>
      <button className='btn-no-thanks' onClick={goBack}>Back</button>
    </div>
  </div>
}