import React, { useEffect, useState } from 'react'
import Radio from '../form/Radio'
import Button from '../form/Button'

import { setAccountNumber } from '../../utils/storage'
import { GA } from '../../script/functions/ga'

const StoreSelector = (props) => {
  const { State, closeDrawer } = props
  const [currentStore, setCurrentStore] = useState(State?.currentAccount)

  useEffect(() => {
    setCurrentStore(State?.currentAccount)
  }, [State?.currentAccount])

  const onCloseDrawer = () => {
    closeDrawer('STORE')
  }

  const handleChange = (item) => {
    setCurrentStore(item)
  }
  
  const onUpdateStore = () => {
    GA('Switch :: Store Selector', `Store :: ${currentStore?.AccountName}`, 'click', currentStore?.JtiAccountId)
    setAccountNumber(currentStore?.JtiAccountId)
    window.location.reload()
  }

  return (
    <div className="bw-store-selector">
      <div className="bw-store-selector--header bw-mb-10">
        <button
          className="bw-store-selector--close"
          onClick={onCloseDrawer}
        ></button>
        <h2 className="bw-store-selector--title">STORE SELECTOR</h2>
      </div>

      {State?.userAccounts?.map((item, index) => (
        <div key={item.JtiAccountId}>
          <Radio
            value={item.JtiAccountId}
            name="store"
            checked={currentStore?.JtiAccountId === item.JtiAccountId}
            id={item.JtiAccountId}
            onChange={() => handleChange(item, index)}
          >
            <div className="bw-store-selector--info">
              <h3>{item.AccountName}</h3>
              <p>{item.AddressLine1}</p>
            </div>
          </Radio>
        </div>
      ))}
      <Button label="Update Store" onClick={onUpdateStore} />
    </div>
  )
}

export default StoreSelector
