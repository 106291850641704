import React, {useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'
import {GA} from '../../script/functions/ga'
import {generalError} from '../../utils/constants'
import {register} from '../../axios/public'
import {registerForm} from '../../utils/forms/public'
import TextInput from '../../components/form/TextInput'
import Checkbox from '../../components/form/Checkbox'
import Button from '../../components/form/Button'
import {RecaptchaSiteKey} from './Login/utils'
import ReCAPTCHA from "react-google-recaptcha"

const Register = (props) => {
  const recaptchaRef = useRef(null)

  const handleOnchangeRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync();
  }

  const [state, setState] = useState({
    submitted: false,
    error: false,
  })

  const onRegister = async (values, {setSubmitting}) => {
    GA('Register', 'Button :: Create account')

    handleOnchangeRecaptcha()
      .then((cToken) => {
        values.recaptchaResponse = cToken

        register({
          ...values,
          ConfirmEmail: values.Email,
          TermsAndConditionsConfirmation: values.AgeConfirmation,
        })
      })
      .then(() => {
        setState((oldVal) => ({...oldVal, submitted: true}))
      })
      .catch((err) => {
        recaptchaRef.current.reset()
        const message = err?.data?.Message || generalError
        setState((oldVal) => ({...oldVal, error: message}))
      })
    setSubmitting(false)
  }

  return (
    <>
      <h3 className={`bw-h3 bw-mb-${state.submitted ? 5 : 8}`}>
        Create your account
      </h3>
      {state.submitted && (
        <>
          <p className="bw-font-bold bw-text-23 bw-mb-4">Thank you!</p>
          <p className="bw-font-normal bw-text-14 bw-mb-4 bw-pr-8 bw-leading-snug">
            You have successfully registered for JTI Engage, the confirmation
            email has been sent to your email address. Please check your emails
            to verify your email address.
          </p>
        </>
      )}

      {!state.submitted && state.error && (
        <p className="bw-text-red bw-text-15 bw-mb-2">{state.error}</p>
      )}
      {state.submitted ? (
        <a href="/login" className="bw-btn-link">
          Go to Log In
        </a>
      ) : (
        <Formik {...registerForm} onSubmit={onRegister}>
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="FirstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.FirstName}
                label="First Name"
                errortext={
                  errors.FirstName && touched.FirstName && errors.FirstName
                }
              />
              <TextInput
                name="LastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.LastName}
                label="Last Name"
                errortext={
                  errors.LastName && touched.LastName && errors.LastName
                }
              />
              <TextInput
                name="JtiAccountId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.JtiAccountId}
                label="JTI Account Number"
                errortext={
                  errors.JtiAccountId &&
                  touched.JtiAccountId &&
                  errors.JtiAccountId
                }
              />
              <TextInput
                name="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Email}
                label="Account Email Address"
                errortext={errors.Email && touched.Email && errors.Email}
              />

              <Checkbox
                error={
                  errors.AgeConfirmation &&
                  touched.AgeConfirmation &&
                  errors.AgeConfirmation
                }
                name="AgeConfirmation"
                className="bw-mb-1"
                value={values.AgeConfirmation}
                onChange={handleChange}
              >
                I confirm I am 18 years of age. I have read and accept the{' '}
                <Link
                  to="/terms-conditions-register"
                  target="_blank"
                  onClick={() =>
                    GA('Register', 'Text Link :: Terms and Conditions')
                  }
                >
                  Terms & Conditions
                </Link>{' '}
                and <Link to="/privacy-policy-public">Privacy Policy</Link>
              </Checkbox>
              <ReCAPTCHA
                sitekey={RecaptchaSiteKey}
                ref={recaptchaRef}
                size='invisible'
              />
              <Button
                onClick={handleOnchangeRecaptcha}
                loading={isSubmitting}
                disabled={isSubmitting}
                label="Create Account"
              />
            </form>
          )}
        </Formik>
      )}
    </>
  )
}

export default connect()(Register)
