import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { GA } from '../../script/functions/ga'
import { connect } from "react-redux";

const Widget = (props) => {
  const history = useHistory()
  const { AssetPath, Icon, TopLineText, NewWindow, accountId } = props

  const onLinkClick = (title) => {
    GA(`${history.location.pathname} :: PDF Download`, title, accountId)
  }

  return (
    <a
      className="bw-private-widget"
      href={AssetPath}
      target={NewWindow ? '_blank' : '_self'}
      onClick={() => onLinkClick(TopLineText)}
    >
      <div className="widget-icon-container">
        <i className={`${Icon} widget-icon`}></i>
      </div>
      <h3 className="widget-text">{TopLineText}</h3>
    </a>
  )
}

Widget.propTypes = {
  AssetPath: PropTypes.string.isRequired,
  Icon: PropTypes.string.isRequired,
  TopLineText: PropTypes.string.isRequired,
  NewWindow: PropTypes.bool,
}

Widget.defaultProps = {
  AssetPath: 'string',
  Icon: 'string',
  TopLineText: 'string',
  NewWindow: true,
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(Widget);
