import PropTypes from 'prop-types'

class DropDown extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      open: false,
      selection: '',
      icon: 'icon-jti-arrow-down'
    }
  }
  
  render() {
    const { open, selection, icon } = this.state;
    const { options } = this.props;
    
    return (
      <div className="block-dropdown">
        <div className="relative" onClick={()=>this.openOptions()}>
          <p>{ selection }</p>
          <span className={ icon }></span>
        </div>
        
        <ul>
        {
          open && options.map((op, i) => {
 
            if(op !== selection) {
              return (
                <li key={ i } className="animated duration03 fadeIn" style={{ animationDelay: 0.2 * i + 's' }} >
                  <a href="#" onClick={(e)=>this.select(e, op)}>{ op }</a>
                </li>
              )
            }
            return null;
          })
        }
        </ul>
        
      </div>  
    )
  }
  
  select(e, value) {
    e.preventDefault();
    
    this.setState({ open: false, selection: value, icon: 'icon-jti-arrow-down'});
    this.props.select(value);
  }
  
  openOptions() {
    this.setState({ open: !this.state.open, icon: this.state.open ? 'icon-jti-arrow-down' : 'icon-jti-arrow-up' });
  }
  
  componentDidMount() {
    const { defaultIndex } = this.props;
    this.setState({ selection: this.props.options[defaultIndex ? defaultIndex : 0] });
  }
}

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number
}

export default DropDown