import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import app from 'Redux/reducers.app'
import prod from 'Redux/reducers.prod'
import cook from 'Redux/reducers.cookie'

export default combineReducers({
  app,
  prod,
  cook,
  form: formReducer,
})