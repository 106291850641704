/***
HANDLE CLASS HELPER
***/

export function addClass(ele, classname) {
  
  if(ele.classList) {
    
    ele.classList.add(classname);
  
  } else {
    if(ele.className){
      let list = ele.className.split(' ');
      if(list.indexOf(classname) === -1) {
        ele.className += ' ' + classname;
      }
    }else{
      ele.className += classname;
    }
  }
  return;
}
  
export function removeClass(ele, classname) {
  
  if(ele.classList) {
    
    ele.classList.remove(classname);
    
  } else {
    
    if(!ele.className) return;
    
    let list = ele.className.split(' ');
    let index = list.indexOf(classname);
    if(index !== -1) {
      list.splice(index, 1);
      ele.className = list.join(' ');
    }
  }
  return;
}

/***
DATASET HANDLE HELPER
***/

export function getDataset(ele, key) {

  if(ele.dataset) {
    return ele.dataset[key];
  } else {
    return ele.getAttribute('data-' + key); 
  }
}

/***
ADD SUFFIX TO DAY
***/

export function suffix(num) {
  var j = num % 10,
  k = num % 100;
  if (j === 1 && k !== 11) {
  return num + "st";
  }
  if (j === 2 && k !== 12) {
  return num + "nd";
  }
  if (j === 3 && k !== 13) {
  return num + "rd";
  }
  return num + "th";
}

/***
SCREEN SIZE
***/

export function getScreenWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function getScreenHeight() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

/***
RESPONSIVE HELPER
***/

const breakPoint = 819.5;
const _breakPointEm = breakPoint / 16;

export function isDesktop() {
  return window.matchMedia && window.matchMedia('(min-width: ' + _breakPointEm + 'em)').matches;  
}

export function isUnder(num) {
  return window.matchMedia && window.matchMedia('(max-width: ' + (num / 16) + 'em)').matches; 
}

export function isOver(num) {
  return window.matchMedia && window.matchMedia('(min-width: ' + (num / 16) + 'em)').matches; 
}

export function isOverSS() {
  return window.matchMedia && window.matchMedia('(min-width: ' + (619.5 / 16) + 'em)').matches; 
}

/***
RETINA HELPER
***/

const _isRetina = (window.devicePixelRatio && window.devicePixelRatio > 2) || (window.matchMedia && window.matchMedia('(-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2), (min-resolution:192dpi), (min-resolution: 2dppx)').matches) || false;

export function isRetina() {
  return _isRetina;
}

/***
HEX TO RGB HELPER
***/

export function hexToRgb(hex) {
  if (hex.charAt(0) === '#') {
    hex = hex.substr(1);
  }
  if ((hex.length < 2) || (hex.length > 6)) {
    return false;
  }
  var values = hex.split(''),
    r,
    g,
    b;

  if (hex.length === 2) {
    r = parseInt(values[0].toString() + values[1].toString(), 16);
    g = r;
    b = r;
  } else if (hex.length === 3) {
    r = parseInt(values[0].toString() + values[0].toString(), 16);
    g = parseInt(values[1].toString() + values[1].toString(), 16);
    b = parseInt(values[2].toString() + values[2].toString(), 16);
  } else if (hex.length === 6) {
    r = parseInt(values[0].toString() + values[1].toString(), 16);
    g = parseInt(values[2].toString() + values[3].toString(), 16);
    b = parseInt(values[4].toString() + values[5].toString(), 16);
  } else {
      return false;
  }
  return {r, g, b};
}

export function matchColumns() {
	var cols = document.querySelectorAll('[data-col]'), encountered = [];
	for (var i = 0; i < cols.length; i++) {
		var attr = cols[i].getAttribute('data-col');
		if (encountered.indexOf(attr) === -1) {
			encountered.push(attr);
		}
	}
	for (var set = 0; set < encountered.length; set++) {
		var col = document.querySelectorAll('[data-col="' + encountered[set] + '"]'), group = [];
		for (i = 0; i < col.length; i++) {
			col[i].style.height = 'auto';
			group.push(col[i].scrollHeight);
		}
		for (i = 0; i < col.length; i++) {
			col[i].style.height = Math.max.apply(Math, group) + 'px'
		}
	}
}
