import { combineReducers } from 'redux'

import productHelper from 'Functions/product.helper'

import { updateCartREQUEST } from '@Requests/saved-cart'

import {
  PRODUCT_LOADED,
  ADD_ORDER,
  REMOVE_ORDER,
  RESET_ORDER,
  //ADD_TO_CART,
  //SET_CART,
  //UPDATE_CART,
  //ADD_PRODS_CART,
  //REMOVE_FROM_CART,
  CHANGE_CART_SORT,
  CHANGE_PROD_SORT,
  SAVE_ORDER_RESULT,
  RESET_ORDER_RESULT,
  CHANGE_HISTORY_PERIOD,
  TOGGLE_INFORMATION,
  CHANGE_RECOMMEND_PREFERENCE,
  SET_SUGGESTED_PRODUCTS,
  SHOW_SUGGESTED,
  CREATE_SAVED_CART,
  UPDATE_SAVED_CART,
  DELETE_SAVED_CART,
  RESET_SAVED_CART,
  ADD_PRODUCT_SAVED_CART,
  SET_ORDER_EDITING,
  SET_SUGGESTED_PRODUCTS_CHECKOUT,
  SET_SUGGESTED_INFO,
} from 'Redux/actions.prod'

function product_loaded(state = false, action) {
  switch (action.type) {
    case PRODUCT_LOADED:
      return action.loaded
    default:
      return state
  }
}

function orders(state = {}, action) {
  switch (action.type) {
    case ADD_ORDER:
      const obj = {}
      obj['sku' + action.sku] = {
        order: action.order,
        current: action.current,
        inCart: action.inCart,
        viewIconCard: action.sku,
      }
      return Object.assign({}, state, obj)

    case REMOVE_ORDER:
      Object.keys(state).forEach((sku) => {
        if (state[sku].inCart) {
          delete state[sku]
        }
      })
      return Object.assign({}, state)

    case RESET_ORDER:
      return {}

    default:
      return state
  }
}

function cartSortType(state = 0, action) {
  switch (action.type) {
    case CHANGE_CART_SORT:
      return action.sort

    default:
      return state
  }
}

function prodSortType(state = 0, action) {
  switch (action.type) {
    case CHANGE_PROD_SORT:
      return action.sort

    default:
      return state
  }
}

function orderResult(state = {}, action) {
  switch (action.type) {
    case SAVE_ORDER_RESULT:
      return action.data

    case RESET_ORDER_RESULT:
      return {}

    default:
      return state
  }
}

function historyPeriod(state = 0, action) {
  switch (action.type) {
    case CHANGE_HISTORY_PERIOD:
      return action.index

    default:
      return state
  }
}

function informationSku(state = '', action) {
  switch (action.type) {
    case TOGGLE_INFORMATION:
      return action.sku

    default:
      return state
  }
}

function recommendPreference(state = true, action) {
  switch (action.type) {
    case CHANGE_RECOMMEND_PREFERENCE:
      return action.show

    default:
      return state
  }
}

/***
suggests = [
  {
    sku: '',
    quantity: 0,
    name: '',
  }
]
***/

function suggests(state = [], action) {
  switch (action.type) {
    case SET_SUGGESTED_PRODUCTS:
      return action.products

    default:
      return state
  }
}

function suggestsCheckout(state = [], action) {
  switch (action.type) {
    case SET_SUGGESTED_PRODUCTS_CHECKOUT:
      return action.suggestedProductsCheckout

    default:
      return state
  }
}

function suggestsInfo(state = [], action) {
  switch (action.type) {
    case SET_SUGGESTED_INFO:
      return action.suggestedInfo

    default:
      return state
  }
}

function showSuggested(state = false, action) {
  switch (action.type) {
    case SHOW_SUGGESTED:
      return action.show

    default:
      return state
  }
}

/***
savedCart = {
  id: 0,
  products: [
    {
      id: 0,
      sku: '',
      quantity: 0,
    }
  ]
}
***/

function savedCart(state = null, action) {
  switch (action.type) {
    case CREATE_SAVED_CART:
      return {
        id: action.id,
        products: action.products,
      }

    case UPDATE_SAVED_CART:
      return action.savedCart

    case DELETE_SAVED_CART:
      return null

    case RESET_SAVED_CART:
      return {
        id: state.id,
        products: [],
      }

    case ADD_PRODUCT_SAVED_CART:
      return {
        id: state.id,
        products:
          action.quantity === 0
            ? state.products.filter((prod) => {
                return prod.sku !== action.sku
              })
            : (() => {
                if (
                  state.products.every((prod) => {
                    if (prod.sku === action.sku) {
                      prod.quantity = action.quantity
                      return false
                    }
                    return true
                  })
                ) {
                  state.products.push({
                    sku: action.sku,
                    quantity: action.quantity,
                  })
                }
                return [...state.products]
              })(),
      }

    default:
      return state
  }
}

function isOrderEditing(state = null, action) {
  switch (action.type) {
    case SET_ORDER_EDITING:
      return action.id
        ? {
            id: action.id,
            date: action.date,
            po: action.po,
          }
        : null

    default:
      return state
  }
}

export default combineReducers({
  product_loaded,
  orders,
  cartSortType,
  prodSortType,
  orderResult,
  historyPeriod,
  informationSku,
  recommendPreference,
  suggests,
  showSuggested,
  suggestsCheckout,
  savedCart,
  isOrderEditing,
  suggestsInfo,
})
