import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getpartnerHubGetNewContentCount, getPartnerHubList,} from "Functions/contents";
import {partnerContentTrack} from "Functions/requests";
import {GA} from "Functions/ga";
import {createPortal} from "react-dom";

const PartnerHub = ({isMobileMenuVisible, setIsMobileMenuVisible, accountId}) => {
  const [partnerCategories, setPartnerCategories] = useState([]);
  const [partnerContent, setPartnerContent] = useState([]);
  useEffect(() => {
    partnerHubGET();
  }, []);

  useEffect(() => {
    resizeLeftMenu();
  }, [partnerCategories]);

  const selectCategory = (id) => {
    setPartnerCategories((prevCategories) =>
      prevCategories.map((cat) => ({
        ...cat,
        Selected: cat.PartnerCategoryId === id ? !cat.Selected : cat.Selected,
      }))
    );
    resizeLeftMenu();
  };

  const clearCategory = () => {
    setPartnerCategories((prevCategories) =>
      prevCategories.map((cat) => ({
        ...cat,
        Selected: false,
      }))
    );
    resizeLeftMenu();
  };

  const resizeLeftMenu = () => {
    document.querySelector(".partner-hub_leftmenu").style.minHeight = `${
      document.querySelector(".partner-hub_content").clientHeight - 21
    }px`;
  };

  const trackPartnerContentPOST = (id) => {
    partnerContentTrack(id, (result) => {
      if (result) {
        partnerHubGETContent();
      }
    });
  };

  const partnerHubGetNewContentCount = () => {
    getpartnerHubGetNewContentCount((data) => {
      const partnerHubNotification = document.querySelector(
        ".partnerhub-notification"
      );
      if (partnerHubNotification) {
        partnerHubNotification.style.display =
          data.Count === 0 ? "none" : "inline-block";
        partnerHubNotification.innerText = data.Count;
      }
    });
  };

  const partnerHubGETContent = () => {
    partnerHubGetNewContentCount();
    getPartnerHubList((data) => {
      let PartnerContent = data.PartnerContent.map((content) => ({
        ...content,
        Icon: data.PartnerCategories.find(
          (obj) => obj.PartnerCategoryId === content.PartnerCategoryId
        ).Icon,
        Colour: data.PartnerCategories.find(
          (obj) => obj.PartnerCategoryId === content.PartnerCategoryId
        ).Colour,
      }));
      setPartnerContent(PartnerContent);
      resizeLeftMenu();
    });
  };

  const partnerHubGET = () => {
    getPartnerHubList((data) => {
      let PartnerContent = data.PartnerContent.map((content) => ({
        ...content,
        Icon: data.PartnerCategories.find(
          (obj) => obj.PartnerCategoryId === content.PartnerCategoryId
        ).Icon,
        Colour: data.PartnerCategories.find(
          (obj) => obj.PartnerCategoryId === content.PartnerCategoryId
        ).Colour,
      }));
      const PartnerCategories = data.PartnerCategories.map((cat) => ({
        ...cat,
        Selected: false,
        Count: PartnerContent.filter(
          (obj) => obj.PartnerCategoryId === cat.PartnerCategoryId
        ).length,
      }));
      setPartnerCategories(PartnerCategories);
      setPartnerContent(PartnerContent);
      resizeLeftMenu();
    });
  };

  const mobileMenuShow = () => {
    setIsMobileMenuVisible((prevState) => {
      const chatbot = document.querySelector(".chatbot");
      if (prevState && chatbot) {
        document.querySelector(".chatbot").style.display = "inherit";
      } else if (chatbot) {
        document.querySelector(".chatbot").style.display = "none";
      }

      if (prevState) {
        document.body.style.overflowY = "unset";
      } else {
        document.body.style.overflowY = "hidden";
      }
      return !prevState;
    });
  };

  const isCategorySelected = (content) =>
    partnerCategories.filter((cat) => cat.Selected).length === 0 ||
    partnerCategories.find(
      (cat) => cat.PartnerCategoryId === content.PartnerCategoryId
    ).Selected;

  const MobileMenu = () => {
    return (
      <div className="mobile-filters-dropdown">
        <div className="mobile-filter-brands">
          <div className="mobile-brand-type">
            <div className="filters-title">
              <div
                className="icon-jti-return-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  mobileMenuShow();
                }}
              ></div>
              Digital Business Advisor
              <div
                className="icon-jti-cross"
                onClick={(e) => {
                  e.preventDefault();
                  mobileMenuShow();
                }}
              >
                {" "}
              </div>
            </div>
          </div>
          <div className="separator">
            <hr/>
          </div>
          <div className="mobile-menu-items-container">
            {partnerCategories.map((cat) => (
              <div
                className="mobilemenu_item"
                key={cat.PartnerCategoryId}
                onClick={() => {
                  GA('P4P', `Category selected  :: ${cat.Name}`, 'click', accountId)
                  selectCategory(cat.PartnerCategoryId);
                }}
              >
                <div className={`mobilemenu_icon ${cat.Colour}`}>
                  <div className={cat.Icon}/>
                </div>
                <div
                  className={`mobilemenu_label ${
                    cat.Selected ? cat.Colour + " selected " : ""
                  }`}
                >
                  {cat.Name} ({cat.Count})
                </div>
              </div>
            ))}
          </div>
          <div className="mobile-filter-ctas">
            <div className=" mobile-filter-ctas-content">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  clearCategory();
                }}
                className={`mobile-filter-clear ${
                  partnerCategories.filter((cat) => cat.Selected).length ===
                  0 && "mobile-filter-clear-disabled"
                } `}
              >
                Clear
              </a>
              <a
                href="#"
                className={`mobile-filter-done   `}
                onClick={(e) => {
                  e.preventDefault();
                  mobileMenuShow();
                }}
              >
                Done
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TitleBar = () => {
    return (
      <div className=" partner-hub_titlebar">
        <h3 className="partner-hub_title">Your Digital Partner</h3>
        <a
          href="#"
          className="partner-hub_clearcta"
          onClick={(e) => {
            e.preventDefault();
            clearCategory();
          }}
        >
          Clear filters (
          {partnerCategories.filter((cat) => cat.Selected).length}){" "}
        </a>

        <button className="mobile-menu-toggle bw-border-0 bw-bg-transparent" onClick={mobileMenuShow}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black"
               className="bw-w-6 bw-h-6">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"/>
          </svg>
        </button>
      </div>
    );
  };

  const Category = ({cat}) => {
    return (
      <div
        className="leftmenu_item"
        onClick={() => {
          GA("P4P", `Category selected  :: ${cat.Name}`, 'click', accountId)
          selectCategory(cat.PartnerCategoryId);
        }}
      >
        <div className={`leftmenu_icon ${cat.Colour}`}>
          <div className={cat.Icon}/>
        </div>

        <div
          className={`leftmenu_label ${
            cat.Selected ? cat.Colour + " selected " : ""
          }`}
        >
          {cat.Name} ({cat.Count})
        </div>
      </div>
    );
  };

  const PartnerCard = ({content}) => {
    return (
      <div className="partner-hub_blocks">
        <div className="partner-hub_blocks-row">
          <div className="partner-hub_blocks-col1">
            <div className={`${content.Icon} ${content.Colour} icon-content`}/>
          </div>
          <div className="partner-hub_blocks-col2">
            <div className="partner-hub_blocks-col2-titlebar">
              <label className="partner-hub_blocks-col2-title">
                {content.Title}
              </label>
              {!content.Seen && (
                <div className="product-new-pill">
                  <b>New</b>
                </div>
              )}
            </div>
            <div className="partner-hub_blocks-col2-copy">
              {content.Description}
            </div>
          </div>
        </div>
        <div className="partner-hub_blocks-row">
          <div className="partner-hub_blocks-ctas">
            <a
              href={content.AssetPath}
              onClick={() => {
                GA("P4P", `View PDF :: ${content.Title}`, 'click', accountId)
                trackPartnerContentPOST(content.PartnerContentId);
              }}
              className={`partner-hub-view  `}
              target="_blank"
            >
              View PDF{" "}
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="partner-hub">
      {isMobileMenuVisible && createPortal(<MobileMenu/>, document.querySelector('#Container'))}

      <div className={`${isMobileMenuVisible ? 'bw-invisible lg:bw-visible' : ''}`}>
        <TitleBar/>
      </div>

      <div className={`partner-hub_container ${isMobileMenuVisible ? 'bw-invisible lg:bw-visible' : ''}`}>
        <div className="partner-hub_leftmenu">
          {partnerCategories.map((cat) => (
            <Category key={cat.PartnerCategoryId} cat={cat}/>
          ))}
        </div>

        <div className="partner-hub_content">
          {partnerContent.filter(isCategorySelected).map((content) => (
            <PartnerCard key={content.PartnerContentId} content={content}/>
          ))}
        </div>
      </div>
    </div>
  );
};

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(PartnerHub)