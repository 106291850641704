import PropTypes from "prop-types";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";

import { getIsRetailer } from "Functions/storage";
// import { cigCategories } from 'Functions/data'
import { alternativeProductREQUEST } from "@Requests/products";
import { updateCartREQUEST } from "@Requests/saved-cart";
import { toCurrencyString, toFileName } from "Functions/functions";

// import TickFilled from '../../../images/react_svg/AddedTick_Filled_REACTSVG.svg'

import { openPopup } from "Redux/actions.app";
import {
  addOrder,
  toggleInformation,
  updateSavedCart,
  addProductSavedCart,
} from "Redux/actions.prod";
// import store from 'Redux/store'

import Preloader from "Components/Preloader";

import PlaceholderImage, {
  getPlaceholderImage,
} from "Components/blocks/PlaceholderImage";
// import Tick from '../../../images/react_svg/AddedTick_REACTSVG.svg'
// import Pending from '../../../images/react_svg/Pending_REACTSVG.svg'
import Replaced from "../../../images/react_svg/product_replaced_icon.svg";
import FlagNew from "@Images/flags/flag-new.png";

import {
  GA,
  getProdCat,
  GAEcommerce,
  getGASocialProofingLabels,
  getIsSocialProofed,
} from "../../functions/ga";
import { addCartProduct, removeCartProduct } from "../../../axios/private/cart";
import { normalizeOldCartData } from "../../../utils/functions/old";
import { generalError } from "../../../utils/constants";

class RecommendProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      minimumDisabled: false,
      loading: false,
    };
  }

  // getButton(type, order, button, current, quantity, trackTraceFlag) {
  //   if (!trackTraceFlag && getIsRetailer()) {
  //     if (quantity) {
  //       return (
  //         <a
  //           href="#"
  //           className="button_style-2"
  //           onClick={(e) => this.addSuggest(e, quantity)}
  //         >
  //           Add
  //         </a>
  //       )
  //     } else {
  //       return (
  //         <a
  //           href="#"
  //           className={'button_style-2 ' + (order !== current ? '' : 'no_use')}
  //           onClick={(e) => this.cart(e, order, button)}
  //         >
  //           {button}
  //         </a>
  //       )
  //     }
  //   }

  //   return null
  // }

  getButton(
    type,
    order,
    button,
    current,
    quantity,
    trackTraceFlag,
    indexProd = 0,
  ) {
    const isAdded = button === "Update" && order === current ? true : false;

    if (!trackTraceFlag && getIsRetailer()) {
      if (quantity) {
        return (
          <a
            href="#"
            className={isAdded ? "button_style-2" : "button_style-5"}
            onClick={(e) => this.addSuggest(e, quantity)}
          >
            Add
          </a>
        );
      } else {
        return (
          <a
            href="#"
            className={
              isAdded
                ? "button_style-2"
                : "button_style-5" + (order !== current ? "" : " no_use")
            }
            onClick={(e) =>
              order !== current
                ? this.cart(e, order, button, indexProd, quantity)
                : false
            }
          >
            {isAdded ? "Added" : button}
          </a>
        );
      }
    }

    return null;
  }

  getImage(prod) {
    if (prod.OuterImage) {
      return <img className="animated fadeIn" src={prod.OuterImage} />;
    }

    return <PlaceholderImage />;
  }

  getFlag(type) {
    const { prod } = this.props;

    switch (type) {
      case "text":
        const text = [];
        if (prod.SubCopy !== undefined && prod.SubCopy) {
          text.push(
            <span
              key={Math.random() * 100}
              dangerouslySetInnerHTML={{ __html: prod.SubCopy }}
            />,
          );
        }
        // if(prod.IsNew && prod.NewText !== void 0) {
        //   text.push(<span dangerouslySetInnerHTML={{ __html: prod.NewText }}/>);
        // }
        if (text.length) return text;

        break;

      case "flag":
        if (prod.IsNew) {
          return <img src={FlagNew} alt="New" className="product-flag" />;
        }
        break;
    }

    return null;
  }

  render() {
    const {
      prod,
      hasHead,
      orders,
      type,
      quantity,
      informationSku,
      trackAndTrace,
    } = this.props;
    const { minimumDisabled, loading } = this.state;

    var order = 0;
    var button = "Add";
    var current = 0;

    const trackTraceFlag = !!(
      prod.IsTrackAndTraceRequiredToBuy && trackAndTrace
    );

    const prodOrder = orders["sku" + prod.SKU];

    if (typeof prodOrder != "undefined") {
      order = prodOrder.order;
      button = prodOrder.inCart ? "Update" : "Add";
      current = prodOrder.current;
    }

    if (type === "profitbot") {
      return (
        <React.Fragment>
          <div className={`product_block product_type-${type}`}>
            <div
              className={`product-row ${
                trackTraceFlag ? "product-row-disabled" : ""
              } cf`}
            >
              <div>
                <div className="product-row-buttons">
                  <div className="product-row-order">
                    {type !== "review" ? (
                      [
                        !trackTraceFlag ? (
                          <React.Fragment key="product-row-order">
                            <a
                              href="#"
                              className={`icon-Minus no-print ${
                                minimumDisabled ? "disabled" : ""
                              } `}
                              onClick={(e) =>
                                prod.OutOfStock
                                  ? e.preventDefault()
                                  : this.count(
                                      e,
                                      order - +prod.MinimumOrderIncremental,
                                      current,
                                      "-",
                                    )
                              }
                            ></a>

                            {type === "cart" ? (
                              <span>{quantity || order}</span>
                            ) : (
                              <input
                                type="text"
                                className={type !== "cart" ? "no-print" : ""}
                                value={order}
                                onChange={(e) =>
                                  prod.OutOfStock
                                    ? e.preventDefault()
                                    : this.inputChange(e, current)
                                }
                                onClick={(e) =>
                                  e.target.setSelectionRange(
                                    0,
                                    e.target.value.length,
                                  )
                                }
                                maxLength="3"
                                onBlur={(e) => this.blurHandler(e, current)}
                              />
                            )}

                            <a
                              href="#"
                              className="icon-Plus no-print"
                              onClick={(e) =>
                                prod.OutOfStock
                                  ? e.preventDefault()
                                  : this.count(
                                      e,
                                      order + +prod.MinimumOrderIncremental,
                                      current,
                                      "+",
                                    )
                              }
                            ></a>
                          </React.Fragment>
                        ) : (
                          <div
                            style={{ visibility: "hidden" }}
                            key="product-row-order"
                          >
                            <a />
                            <span />
                            <a />
                          </div>
                        ),
                      ]
                    ) : (
                      <span>{quantity || order}</span>
                    )}
                  </div>

                  <div className="product-row-button no-print">
                    {prod.OutOfStock ? (
                      <span className="button_style-5 no_use">
                        Out of stock
                      </span>
                    ) : (
                      this.getButton(
                        type,
                        order,
                        button,
                        current,
                        quantity,
                        trackTraceFlag,
                        prod.SKU,
                      )
                    )}
                    {button === "Update" &&
                      type !== "cart" &&
                      type !== "review" &&
                      order === current && (
                        <svg
                          className=" checkmark"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          <circle
                            className="checkmark__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                          />
                          <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </svg>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading && <Preloader />}
        </React.Fragment>
      );
    }

    return (
      <div
        key={prod.SKU}
        className={`product_block product_type-${type} ${
          prod.IsNew ? "product_block-highlight" : ""
        } cf`}
      >
        <div
          className={`product-row ${
            trackTraceFlag ? "product-row-disabled" : ""
          } cf`}
        >
          <div>
            <div className="product-row-top">
              <div className="product-row-image">
                <div>
                  <LazyLoad
                    throttle={100}
                    offset={100}
                    once
                    placeholder={<PlaceholderImage />}
                  >
                    {this.getImage(prod)}
                  </LazyLoad>
                </div>
              </div>
              <div className="product-row-details">
                <div className="product-row-price-wrapper">
                  <div className="product-row-price nowrap">
                    {toCurrencyString(prod.PriceOuter)}
                  </div>
                </div>
                <div className="product-row-order">
                  {!trackTraceFlag ? (
                    <React.Fragment key="product-row-order">
                      <a
                        href="#"
                        className={`icon-Minus no-print ${
                          minimumDisabled ? "disabled" : ""
                        }`}
                        onClick={(e) =>
                          this.count(
                            e,
                            order - +prod.MinimumOrderIncremental,
                            current,
                            "-",
                          )
                        }
                      ></a>
                      <input
                        type="text"
                        className={type !== "cart" ? "no-print" : ""}
                        value={order}
                        onChange={(e) => this.inputChange(e, current)}
                        onClick={(e) =>
                          e.target.setSelectionRange(0, e.target.value.length)
                        }
                        maxLength="3"
                        onBlur={(e) => this.blurHandler(e, current)}
                      />
                      <a
                        href="#"
                        className="icon-Plus no-print"
                        onClick={(e) =>
                          this.count(
                            e,
                            order + +prod.MinimumOrderIncremental,
                            current,
                            "+",
                          )
                        }
                      ></a>
                    </React.Fragment>
                  ) : (
                    <div
                      style={{ visibility: "hidden" }}
                      key="product-row-order"
                    >
                      <a />
                      <span />
                      <a />
                    </div>
                  )}
                </div>

                <div className="product-row-button no-print">
                  {prod.OutOfStock ? (
                    <span className="button_style-5 no_use">Out of stock</span>
                  ) : (
                    this.getButton(
                      type,
                      order,
                      button,
                      current,
                      quantity,
                      trackTraceFlag,
                      prod.SKU,
                    )
                  )}
                  {button === "Update" &&
                    type !== "cart" &&
                    type !== "review" &&
                    order === current && (
                      <svg
                        className=" checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          className="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          className="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    )}
                </div>
              </div>
            </div>

            <div className="product-row-bottom">
              <div className="pillcontainer">
                {type !== "review" && prod.IsNew && !prod.OutOfStock && (
                  <span className="product-new-pill">New</span>
                )}
                {type !== "review" && prod.Replaces.length > 0 && (
                  <span className="product-new-pill">
                    <img src={Replaced} alt="" />
                  </span>
                )}
              </div>
              <div className="product-row-name">
                <p className="recommanded-product-title">
                  {prod.Product} {prod.OutOfStock ? <i>OUT OF STOCK</i> : ""}
                </p>
                {prod.Replaces.map((product, index) => (
                  <span className="replaced" key={index}>
                    This product replaces {product}
                  </span>
                ))}
                {prod.OutOfStock
                  ? this.getFlagOutOfStock("text")
                  : this.getFlag("text")}
                {!prod.OutOfStock && (
                  <React.Fragment>
                    <span className={prod.MinimumOrder > 1 ? "" : "hidden"}>
                      {prod.MinimumOrder > 1
                        ? "Minimum order number is " +
                          prod.MinimumOrder +
                          " outers"
                        : ""}
                    </span>
                    <span
                      className={
                        prod.MinimumOrderIncremental > 1 ? "" : "hidden"
                      }
                    >
                      {prod.MinimumOrderIncremental > 1
                        ? "Incremental order number is " +
                          prod.MinimumOrderIncremental +
                          " outers"
                        : ""}
                    </span>
                    <span className={prod.MaximumOrder > 1 ? "" : "hidden"}>
                      {prod.MaximumOrder
                        ? "Maximum order number is " +
                          prod.MaximumOrder +
                          " outers"
                        : ""}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="product-row-info">
                <React.Fragment>
                  <a
                    href="#"
                    className="product-info-link no-print"
                    onClick={(e) => this.showInformation(e)}
                  >
                    Product details
                  </a>

                  <div
                    className={
                      "information_box transition duration03 " +
                      (informationSku === type + prod.SKU ? "active" : "")
                    }
                  >
                    <a
                      href="#"
                      className="icon-Information no-print"
                      onClick={(e) => this.closeInformation(e)}
                    ></a>

                    <dl>
                      <dt>Pack Quantity</dt>
                      <dd>{prod.PackSize}</dd>
                      <dt>RRP</dt>
                      <dd>&euro;{prod.PricePackRrp.toFixed(2)}</dd>
                      <dt>Barcode</dt>
                      <dd>{prod.PackBarCode}</dd>
                    </dl>
                  </div>
                </React.Fragment>
              </div>
            </div>

            {loading && <Preloader />}
          </div>
        </div>
      </div>
    );
  }

  blurHandler(e, current) {
    const { prod } = this.props;
    var order = +e.target.value;

    order =
      prod.MaximumOrder && prod.MaximumOrder < order
        ? +prod.MaximumOrder
        : order;
    order =
      +Math.ceil(+order / prod.MinimumOrderIncremental) *
      prod.MinimumOrderIncremental;
    order = order > 0 && prod.MinimumOrder > order ? prod.MinimumOrder : order;

    this.updateOrder(e, order, current);
  }

  inputChange(e, current) {
    const { prod } = this.props;
    var order = +e.target.value;

    if (!/^[0-9]*$/.test(order)) return false;

    this.updateOrder(e, order, current);
  }

  count(e, order, current, operator) {
    e.preventDefault();
    const { prod, type } = this.props;

    if (order >= 0 && order < 1000) {
      if (prod.MaximumOrder && order > +prod.MaximumOrder) {
        order = prod.MaximumOrder;
      } else if (
        order <= prod.MinimumOrder &&
        type !== "recommended" &&
        type !== "profitbot"
      ) {
        order = prod.MinimumOrder;
      } else {
        if (operator === "-") {
          order =
            order >= prod.MinimumOrder
              ? +Math.ceil(+order / prod.MinimumOrderIncremental) *
                prod.MinimumOrderIncremental
              : 0;
        }
        if (operator === "+") {
          order =
            order >= prod.MinimumOrder
              ? ~~(+order / prod.MinimumOrderIncremental) *
                prod.MinimumOrderIncremental
              : prod.MinimumOrder;
        }
      }
      this.updateOrder(e, order, current);
    }

    this.gaHandler("count", operator);
  }

  updateOrder(e, order, current) {
    e.preventDefault();

    const { dispatch, prod, type, orders } = this.props;
    const orderInfo = orders["sku" + prod.SKU];

    if (prod.MaximumOrder && order > prod.MaximumOrder) {
      order = prod.MaximumOrder;
    }

    prod.Order = order;

    dispatch(
      addOrder(prod.SKU, order, current, orderInfo ? orderInfo.inCart : false),
    );
  }

  showInformation(e) {
    e.preventDefault();
    e.stopPropagation();

    const { dispatch, prod, type } = this.props;
    dispatch(toggleInformation(type + prod.SKU));

    this.gaHandler("showInformation");
  }

  closeInformation(e) {
    e.preventDefault();

    const { dispatch } = this.props;
    dispatch(toggleInformation(""));
  }

  cart(e, order, button, indexProd, quantity) {
    e.preventDefault();

    const { dispatch, prod, orders, accountId } = this.props;
    const currentProduct = orders["sku" + prod.SKU];

    if (prod.OutOfStock) {
      dispatch(addOrder(prod.SKU, 0, 0, false));
      this.alternativeProduct(prod.SKU);
      return false;
    }

    if (order === orders["sku" + prod.SKU].current) return false;

    order =
      prod.MaximumOrder && prod.MaximumOrder < order
        ? +prod.MaximumOrder
        : order;
    order =
      +Math.ceil(+order / prod.MinimumOrderIncremental) *
      prod.MinimumOrderIncremental;
    order = order > 0 && prod.MinimumOrder > order ? prod.MinimumOrder : order;

    prod.Order = order;

    if (order >= currentProduct.current) {
      this.saveToDatabase(prod, order - currentProduct.current, "add");
    } else {
      this.saveToDatabase(prod, currentProduct.current - order, "remove");
    }

    this.gaHandler("cart", button, prod);

    var eventName = "";
    var addedQuantity = "";
    var bannerType = "";
    if (e.target.closest(".product-row-buttons")) {
      addedQuantity = e.target
        .closest(".product-row-buttons")
        .querySelector("input.no-print").value;
      bannerType = "individual_banner";
    } else if (e.target.closest(".product-row-details") !== null) {
      addedQuantity = e.target
        .closest(".product-row-details")
        .querySelector("input.no-print").value;
      bannerType = "multiple_banner";
    }

    if (addedQuantity === "0") {
      eventName = "remove_from_cart";
    } else {
      eventName = "add_to_cart";
    }

    GAEcommerce(
      eventName,
      accountId,
      prod.PriceOuter,
      prod.PackBarCode,
      prod.Product,
      prod.Brand,
      prod.Category,
      "suggested",
      getIsSocialProofed(prod),
      bannerType,
      addedQuantity,
      "have_you_considered",
      getGASocialProofingLabels(prod),
    );
  }

  remove(e) {
    e.preventDefault();

    const { dispatch, prod } = this.props;

    this.saveToDatabase(prod, prod.Order, "remove");
    prod.Order = 0;

    this.gaHandler("remove");
  }

  addSuggest(e, quantity) {
    e.preventDefault();

    const { dispatch, prod } = this.props;

    prod.Order = quantity;

    this.saveToDatabase(prod, quantity, "add");

    this.gaHandler("addSuggest", prod);
  }

  alternativeProduct(sku) {
    const { dispatch } = this.props;

    alternativeProductREQUEST(sku, (result, data) => {
      let desc = "";
      if (result) {
        desc = `Have a look at similar products: ${data.Product}`;
      }

      dispatch(
        openPopup(
          "success",
          "Sorry, it looks this product is out of stock.",
          desc,
        ),
      );
    });
  }

  imageError(e) {
    e.target.src = getPlaceholderImage();
  }

  async saveToDatabase(product, quantity = 0, type = "add") {
    const { dispatch, savedCart, isOrderEditing, beCart } = this.props;

    const sku = product.SKU;
    const order = product.Order;

    if (!isOrderEditing) {
      this.setState({ loading: true });
      let cartMethod = type === "add" ? addCartProduct : removeCartProduct;
      await cartMethod({
        beCartId: beCart?.BackEndCartId,
        SKU: sku,
        quantity,
        cart_add: type === "add" ? "have_you_considered" : undefined,
      })
        .then((res) => {
          dispatch(addOrder(sku, order, order, order ? true : false));
          dispatch(updateSavedCart(normalizeOldCartData(res)));
        })
        .catch((err) => {
          dispatch(openPopup("alert", generalError, ""));
        });
      this.setState({ loading: false });
    } else {
      dispatch(addOrder(sku, order, order, order ? true : false));
      dispatch(addProductSavedCart(sku, order));
    }
  }

  gaHandler(method, ...params) {
    const { type, prod, accountId } = this.props;
    // console.log(params)
    if (type) {
      switch (method) {
        case "count":
          GA(
            getProdCat(type),
            `Icon :: ${
              params[0] === "+" ? "Increase" : "Decrease"
            } Quantity :: ${prod.Product}`,
            "click",
            accountId,
          );
          break;
        case "cart":
          GA(
            getProdCat(type),
            `Button :: ${params[0]} :: ${params[1].Order} :: ${prod.Product}`,
            "click",
            accountId,
          );
          break;
        case "showInformation":
          GA(getProdCat(type), "Icon :: Information", "click", accountId);
          break;
        case "addSuggest":
          GA(getProdCat(type), "Button :: Add", "click", accountId);
          break;
        case "remove":
          GA(getProdCat(type), "Text Link :: Remove", "click", accountId);
          break;
      }
    }
  }
}

RecommendProduct.propTypes = {
  type: PropTypes.string,
  hasHead: PropTypes.bool.isRequired,
  prod: PropTypes.shape({
    SKU: PropTypes.string.isRequired,
    Brand: PropTypes.string.isRequired,
    Category: PropTypes.string.isRequired,
    Product: PropTypes.string.isRequired,
    PriceOuter: PropTypes.number.isRequired,
    IsTrackAndTraceRequiredToBuy: PropTypes.boolean,
  }),
  quantity: PropTypes.number,
};

function stateMapping(state) {
  return {
    beCart: state.app.cart,
    orders: state.prod.orders,
    savedCart: state.prod.savedCart,
    informationSku: state.prod.informationSku,
    isOrderEditing: state.prod.isOrderEditing,
    trackAndTrace: state.app.trackAndTrace,
    accountId: state.app.currentAccount.JtiAccountId,
  };
}

export default connect(stateMapping)(RecommendProduct);
