class Timer {
  
  constructor() {
    window.requestAnimFrame = (function() {
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callback) {
          window.setTimeout( callback, 1000 / 60 );
        };
    })();
    
    this.updates = {};
    this.keys = [];
    
    this.update();
  }
  
  update() {
    window.requestAnimFrame(() => {
      this.keys.forEach((k) => {
        this.updates[k]();  
      });
      
      this.update();
    });
  }
  
  register(id, f) {
    this.updates[id] = f;
    this.keys.push(id);
  }
  
  remove(id) {
    if(!Object.keys(this.updates).every((key)=>{ return key !== id; })) {
      delete this.updates[id];
      this.keys.splice(this.keys.indexOf(id), 1);
    }
  }
  
  check() {
    console.log(this.updates);
  }
}

const timer = new Timer();

export default timer