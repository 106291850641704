import React from 'react'

import PropTypes from 'prop-types'
import formatDate from '../../utils/functions/formatDate'
import { connect } from 'react-redux'
import { setOpenDrawer } from '../../script/redux/actions.app'
import { useHistory } from 'react-router'
import { GA } from '../../script/functions/ga'

const InfoBar = (props) => {
  const history = useHistory()
  const { State, userAccounts, currentAccount, dispatch, accountId } = props

  const switchDrawer = (e) => {
    e.preventDefault()
    dispatch(setOpenDrawer('STORE', true))
  }

  const onPhoneClick = (text) => {
    GA(`${history.location.pathname} :: Call Click`, text, accountId)
  }

  return (
    <>
      <div className="bw-infobar">
        <div className="bw-container bw-flex bw-justify-between">
          <div className="bw-infobar--switch">
            {currentAccount?.AccountName}{' '}
            {userAccounts?.length > 1 ? (
              <a className="bw-link" href="#" onClick={switchDrawer}>
                (<span>switch</span>)
              </a>
            ) : (
              ''
            )}
          </div>
          {State?.recentOrder?.OrderNumber ? (
            <div className="bw-infobar--last-order">
              Last order: {formatDate(State?.recentOrder?.OrderDate)}{' '}
              <a
                className="bw-link"
                href={`/review-order?ordernumber=${State?.recentOrder?.OrderNumber}`}
              >
                (<span>{State?.recentOrder?.OrderNumber || ''}</span>)
              </a>
            </div>
          ) : (
            ''
          )}
          <div className="bw-infobar--connex-app">
            <a
              className="bw-link"
              href={`tel:${
                State?.userInfo?.TmeAccount?.EmployeeMobilePhone || ''
              }`}
              onClick={() =>
                onPhoneClick(
                  `BA ${State?.userInfo?.TmeAccount?.EmployeeMobilePhone || ''}`
                )
              }
            >
              BA {State?.userInfo?.TmeAccount?.EmployeeMobilePhone || ''}
            </a>
            {/* <a
              href="https://jticonnex.com/"
              target="_blank"
              className="bw-link"
            >
              CONNEX APP
            </a> */}
          </div>
        </div>
      </div>
    </>
  )
}

InfoBar.defaultProps = {
  openDrawer: () => {},
}

InfoBar.propTypes = {
  openDrawer: PropTypes.func,
}

const stateMapping = (state) => {
  return {
    userAccounts: state.app.userAccounts,
    currentAccount: state.app.currentAccount,
    accountId: state.app.currentAccount.JtiAccountId
  }
}

export default connect(stateMapping)(InfoBar)
