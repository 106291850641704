import { months } from '../constants'
export const formatDate = (
  date,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }
) => {
  if (!date) return ''
  // replace space for Safari support
  const formatted = date.replace(/(.*?)\s(.*)/g, '$1T$2')
  const dateNew = new Date(formatted)
  return Intl.DateTimeFormat('en-GB', options).format(dateNew)
}

export const addDaySuffix = (num) => {
  const j = num % 10,
    k = num % 100
  if (j === 1 && k !== 11) return num + 'st'
  if (j === 2 && k !== 12) return num + 'nd'
  if (j === 3 && k !== 13) return num + 'rd'
  return num + 'th'
}

export const dateFormat = (dateString) => {
  const date = new Date(dateString)

  return (
    addDaySuffix(date.getDate()) +
    ' ' +
    months[date.getMonth()] +
    ' ' +
    date.getFullYear()
  )
}

export default formatDate
