import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import {  getpartnerHubGetNewContentCount } from 'Functions/contents'
import { toggleNavMenu, openPopup } from 'Redux/actions.app'
import { getUsername, resetAuth } from 'Functions/storage'
import { showStockCardREQUEST } from '@Requests/products'
import { weatherREQUEST } from '@Requests/account'
import {  messagePromptREQUEST } from '@Requests/contents'
import logo from '@Images/engage@2x.png'
import logoBlue from '@Images/engage-theme2@2x.png'
import icon_cart from '@Images/icon_cart.svg'
import icon_account from '@Images/icon_account.svg'
import icon_logout from '@Images/icon_logout.svg'
import icon_cartBlue from '@Images/icon_cart--blue.svg'
import icon_accountBlue from '@Images/icon_account--blue.svg'
import icon_logoutBlue from '@Images/icon_logout--blue.svg'
import StockCardPopUp from 'Components/blocks/StockCardPopUp'

import { GA } from 'Functions/ga'

const gac = 'Primary Navigation';

class HeaderMain extends React.Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      showNavigation: false,
      animType: '',
      showStockCardPopUp:false,
      weather: {},
      partnerHubCount:0,
      recentOrder:null
    }

      this.showStockCardPopUp = this.showStockCardPopUp.bind(this)
      this.resize = this.resize.bind(this)
      this.getWeather = this.getWeather.bind(this)
  }
  
  render() {
    const { total, accountId } = this.props;
    const { showNavigation, animType, showStockCardPopUp, weather, propmtMsg, recentOrder, partnerHubCount } = this.state;
 
    let recentOrderDate = '', recentOrderTime = '' , countFromRecentOrder = 49 ;
 
    if(recentOrder !== null) {
      recentOrder.OrderDate = recentOrder.OrderDate.replace(' ', 'T');
      recentOrderDate = new Date(recentOrder.OrderDate).toLocaleDateString();
      recentOrderTime = new Date(recentOrder.OrderDate).toLocaleTimeString('en-US');
      var date1 = new Date(recentOrder.OrderDate); 
      var date2 = new Date(); 
        
      // To calculate the time difference of two dates 
      var Difference_In_Time = date2.getTime() - date1.getTime(); 
      
      // To calculate the no. of days between two dates 
       countFromRecentOrder = Difference_In_Time / (1000 * 3600 ); 
    }
   
    return (
      <header className={'header' + (showNavigation ? ' header--active' : '')}>
        <div className="header__container">
          <div className="triangle-background" />
          <div className="triangle-background--extra" />
          <div className="header__inner">
            <div className="header__logo">
              <Link to="/" onClick={()=>GA('Primary Navigation','Home Logo','click', accountId)}>
                <img src={(window.location.hash.indexOf('p4p') !== -1) ? logoBlue : logo} alt="JTI Engage" />
              </Link>
            </div>
            <div className="header__content">
              <button className={'hamburger hamburger--spring shown-m' + (showNavigation ? ' is-active' : '')} type="button" aria-label="Menu" aria-controls="navigation" aria-expanded={showNavigation ? 'true' : 'false'} onClick={(e)=>this.toggleNavigation(e)}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <div className={'header__nav animated duration05 ' + animType}>
                <div className="header__info">
                  <div className="header__name">
                    <h3>{ this.getWeather() }Welcome back { getUsername() }</h3>
                  </div>
                  <div className="header__actions">
                    <Link to="/cart" onClick={ ()=>GA(gac,'My cart','click', accountId) } replace>
                      <img src={(window.location.hash.indexOf('p4p') !== -1) ? icon_cartBlue : icon_cart} alt="My cart" title="My cart" />
                      <span>{total}</span>
                    </Link>
                    <Link to="/my-account" onClick={ ()=>GA(gac,'click','My account', accountId) }>
                      <img src={(window.location.hash.indexOf('p4p') !== -1) ? icon_accountBlue : icon_account} alt="My account" title="My account" />
                    </Link>
                    <a href="#" onClick={(e)=>this.logout(e)}>
                      <img src={(window.location.hash.indexOf('p4p') !== -1) ? icon_logoutBlue : icon_logout} alt="Logout" title="Logout" />
                    </a>
                  </div>
                </div>
                <div className="header__menu">
                  <nav>
                    <ul className="source-sans-semibold">
                      <li>
                        <NavLink to="/place-order" onClick={ (e)=>this.onPlaceOrderClick(e) }>Place order</NavLink>
                      </li>
                      <li>
                        <NavLink to="/my-orders" onClick={ ()=>GA(gac,'My orders','click', accountId) }>My orders</NavLink>
                      </li>
                      <li>
                        <NavLink to="/faqs" onClick={ ()=>GA(gac,'Help','click', accountId) }>Help</NavLink>
                      </li>
                      <li>
                        <NavLink to="/videos" onClick={ ()=>GA(gac,'Videos','click', accountId) }>Videos</NavLink>
                      </li>
                      {/*<li>
                        <NavLink to="/vlogs" onClick={ ()=>GA(gac,'Vlogs') }>Vlogs</NavLink>
                      </li>*/}
                      <li className="partnerhub-notification-container">
                        <NavLink to="/p4p" onClick={ ()=>GA(gac,'Partner Hub','click', accountId) }>Partner Hub 
                            {
                              <span className={`partnerhub-notification ${partnerHubCount===0?' hide':''}`}   >{partnerHubCount}</span> 
                            }
                           

                        </NavLink>
                      </li>
                    </ul> 
                  </nav>
                </div>
              </div>
              {
                recentOrder !== null && 
                <span className={`prompt48h  ${countFromRecentOrder<=48?' red':''}`}  >
                  <b> Last order: {recentOrderDate} {recentOrderTime} <a href={`/review-order?ordernumber=${recentOrder.OrderNumber}`}>({recentOrder.OrderNumber}) </a></b>
                </span>
              }
              
            </div>
          </div>
        </div>
        {
            showStockCardPopUp && (<StockCardPopUp closePopup={this.showStockCardPopUp} />)
        }
      </header>
    )
  }

  componentDidMount() {

    this.partnerHubGetNewContentCount()
    window.addEventListener('resize', this.resize)

    weatherREQUEST((result, data) => {
      this.setState({
        weather: data
      });
    });

    messagePromptREQUEST((result, data) => {
      if(result) {
        this.setState({
          recentOrder:data,
        })
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    if (window.innerWidth >= '1023.5' && this.state.showNavigation) {
      this.toggleNavigation();
    }
  }

  showStockCardPopUp(){
    this.setState({showStockCardPopUp:false})
  }

  onPlaceOrderClick(e){
    e.preventDefault();
    GA(gac,'Place order','click', this.props.accountId);

    if (window.innerWidth > 931) {
      showStockCardREQUEST(result => {
        if (result) {
          this.setState({showStockCardPopUp:true});
        } else {
          this.props.history.push('/place-order');
        }
      })
    } else {
      this.props.history.push('/place-order');
    }
  }

  partnerHubGetNewContentCount(){
    getpartnerHubGetNewContentCount((data) => {
      this.setState({
        partnerHubCount:data.Count
      })
    
    });
  }

  toggleNavigation(e) {

    if (e) {
      e.preventDefault();
    }
    
    const { dispatch } = this.props;
    
    if(!this.state.showNavigation) {
    
      this.setState({showNavigation: true, animType: 'fadeInLeft'});
      dispatch(toggleNavMenu(true));
      
    } else {
      
      this.setState({animType: 'fadeOutLeft'});
      
      window.setTimeout(()=>{
        this.setState({showNavigation: false});
        dispatch(toggleNavMenu(false));
      }, 100);
      
    }
  }

  logout(e) {
    e.preventDefault();
    const { dispatch, savedCart, accountId } = this.props;
    
    GA(gac, 'Log out', 'click',accountId);

    if(savedCart && savedCart.products && savedCart.products.length) {
      dispatch(openPopup(
        'logout',
        'You have items in your cart',
        'Are you sure you want to log out without placing your order?'
      ));
    } else {
      resetAuth();
      window.location.reload();
    }
  }

  getWeather() {
    const { weather } = this.state;

    if (Object.keys(weather).length > 0) {
      return (
        <img className="header__weather" src={`/assets/weather/${weather.weather_code}_${weather.is_day === 'yes' ? 'day' : 'night'}.png`} title={`${weather.temperature}\u2103, ${weather.weather_descriptions[0]}`} />
      );
    }

    return false;
  }
}

function stateMapping(state) {
  let suggestCount = 0;

  if (state.prod.suggestsCheckout.length) {
    state.prod.suggestsCheckout.map((item) => {
      suggestCount += item.quantity
    })
  }

  return {
    total: state.prod.savedCart ? state.prod.savedCart.products.reduce((a, prod) => {
      return a + prod.quantity;
    }, 0 + suggestCount) : 0 + suggestCount,
    savedCart: state.prod.savedCart,
    /*_total: state.prod.cart.reduce((a, prod) => {
      return a + prod.Order;
    }, 0)*/
    accountId: state.app.currentAccount.JtiAccountId
  }
}

export default connect(stateMapping)(HeaderMain)