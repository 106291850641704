import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GA } from "../functions/ga";

function ScrollBackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleWithTimeout, setIsVisibleWithTimeout] = useState(false);
  const navMenu = useSelector((state) => state.app.navMenu);
  const accountId = useSelector(
    (state) => state.app.currentAccount.JtiAccountId,
  );

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPos =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;
      setIsVisible(scrollPos > 100);
    }, 50)

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GA("Place An Order", "Icon :: Back to Top", "click", accountId);
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisibleWithTimeout(true);
      }, 100);
    } else {
      setTimeout(() => {
        setIsVisibleWithTimeout(false);
      }, 500);
    }
  }, [isVisible])

  return (
    <>
      {isVisibleWithTimeout ? (
        <button
          className={`back_to_top_button button_style-2 fontsize19 transition duration02 no-print animated ${
            isVisible ? "fadeIn" : "fadeOut tw-hidden"
          } ${navMenu ? "nav_opened" : ""}`}
          onClick={scrollBackToTop}
          aria-label="Scroll back to top"
        >
          <span className="icon-Up-Arrow"></span>
        </button>
      ) : null}
    </>
  );
}

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default ScrollBackToTop;