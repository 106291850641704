import PropTypes from 'prop-types'

import { GA } from 'Functions/ga'
import { connect } from "react-redux";
class Accordion extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      height: 0,
      icon: 'icon-Down-Arrow'
    }
    
    this.height = 0;
  }

  render() {
    const { dt, dd, index, selectedIndex, select } = this.props;
    
    return (
      <dl>
        <dt className="noline">
          <a className="a-bl-gr" href="#" onClick={(e)=>this.clickHandler(e)}>
            { dt }
            <span className={ (index === selectedIndex ? 'icon-Up-Arrow' : 'icon-Down-Arrow') + ' no-print' }/>
          </a>
        </dt>
        <dd ref={(dom)=>this.dd=dom} className="transition duration03" style={{ height: (index === selectedIndex ? this.height : 0) + 'px' }}>
          <div dangerouslySetInnerHTML={{ __html: dd }}/>
        </dd>
      </dl>
    )
  }
  
  getHeight() {
    this.dd.style.height = 'auto';
    const height = this.dd.offsetHeight;
    this.dd.style.height = 0;
    return height;
  }
  
  clickHandler(e) {
    const { select, index, selectedIndex } = this.props;
    e.preventDefault();
    
    if(index === selectedIndex) {
      select(null); 
      
      this.gaHandler(e, 'Close');
      
    } else {
      this.height = this.getHeight();
      if(!this.height) this.height = 'auto';
    
      window.setTimeout(()=>{
        select(index);
      }, 50);
      
      this.gaHandler(e, 'Open');
    }
  }
  
  gaHandler(e, act) {
    const { gac, accountId } = this.props;
    const label = e.target.textContent;
    
    if(gac) {
      GA(gac, `Dropdown :: ${act} :: ${label}`,'click', accountId);
    }
  }
}

Accordion.propTypes = {
  dt: PropTypes.string.isRequired,
  dd: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number,
  select: PropTypes.func.isRequired,
  gac: PropTypes.string
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(Accordion)