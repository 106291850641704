import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { setTrackAndTrace } from 'Redux/actions.app'

class TrackTraceWarn extends React.Component {
  
  render() {
    const { history: { location } } = this.props;
    
    if(!/^(\/|\/cart)$/.test(location.pathname)) {
      return null;  
    }
    
    return (
      <div className="tracktrace-warn">
        <h5><b>IMPORTANT NOTICE:</b><br />Track & Trace came into effect on May 20th.</h5>
        <p>You will not be able to place an order until we receive your EOID and FID codes.</p>
        <p><b>Please click on My Account to submit your codes to allow you to place your order.</b></p>
        
        <div className="buttons">
          <button className="button_style-2 fontsize19" onClick={()=>this.goAccount()}>My account</button>
          <button className="button_style-1 fontsize19" onClick={()=>this.close()}>Close</button>
        </div>
      </div>
    )
  }
        
  goAccount() {
    const { history } = this.props;
    
    //this.close();
    history.push('/my-account');
  }
  
  close() {
    const { dispatch } = this.props;
    dispatch(setTrackAndTrace(1));
  }
}

TrackTraceWarn.propTypes = {
  history: PropTypes.object.isRequired,
}

export default connect()(TrackTraceWarn)