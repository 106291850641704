import React from "react";
import closeIcon from "../../../icons/Cross.svg";
import logo from "../../../images/jti-logo.svg";

export default function ContinueSurvey({
  isOpen,
  toggle,
  handleClose,
  handleOpen,
  handleOpenTC,
  text,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`survey__continue ${
        isOpen ? "survey__continue--open" : "survey__continue--closed"
      }`}
    >
      {/* Side Label */}
      <div onClick={toggle} className={"survey__continue__label"}>
        <p>Feedback survey</p>
      </div>

      {/* Continue Survey*/}
      <div className={"survey__continue__body"}>
        <div className={"survey__continue__body__close-btn-container"}>
          <button
            className={"survey__continue__body__close-btn-container__close-btn"}
            onClick={handleClose}
          >
            <img src={closeIcon} alt={"Close"} />
          </button>
        </div>
        <img
          className={"survey__continue__body__logo"}
          src={logo}
          alt="JTI Engage logo"
        />
        <p>{text || "Click below to continue with your survey"}</p>
        <button
          className={"survey__continue__body__continue-btn"}
          onClick={handleOpen}
        >
          Continue
        </button>
        <button
          className={"survey__continue__body__tc-btn"}
          onClick={handleOpenTC}
        >
          Terms and Conditions
        </button>
      </div>
    </div>
  );
}
