import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { gaStockCardType } from "../../functions/ga";
import testimonialImage1 from "../../../images/stockcard-popup-testimonial.png";

const TESTIMONIALS = [
  {
    quote: "Using this has really made a real difference to my ordering process.",
    name: "John Smith",
    company: null,
    image: testimonialImage1
  }
  // {
  //   quote: "This is a really great tool, I can't believe I didn't know about it before",
  //   name: "Bob Smith",
  //   company: "Business Name Here",
  //   image: testimonialImage1
  // },
  // {
  //   quote: "I love this tool, it's so easy to use and saves me so much time",
  //   name: "Jane Smith",
  //   company: "Business Name Here",
  //   image: testimonialImage1
  // }
];

function StockCardPopUp({ closePopup }) {
  const accountId = useSelector(state => state.app.currentAccount.JtiAccountId);
  const history = useHistory();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const nextTestimonial = () => {
    if (currentTestimonial < TESTIMONIALS.length - 1) {
      setCurrentTestimonial(currentTestimonial + 1);
    }
  };

  const prevTestimonial = () => {
    if (currentTestimonial > 0) {
      setCurrentTestimonial(currentTestimonial - 1);
    }
  };

  const handleCreateOrder = () => {
    gaStockCardType("StockCard", accountId);
    closePopup();
    history.push("/stock-card");
  };

  const handleViewProductList = () => {
    gaStockCardType("ProductList", accountId);
    closePopup();
    history.push("/place-order");
  };

  return (
    <div className="sc-popup animated fadeIn">
      <div className="sc-popup__container">
        <button className="close-btn" onClick={closePopup}>
          <CloseButtonIcon />
        </button>
        <div className="content-blue">
          <div className="main">
            <StockCardIcon />
            <h3>
              Create new order with <br /> <em> My Stock Card </em>
            </h3>
            <button onClick={handleCreateOrder} className="create-order-btn">Create Order</button>
            <Link to="/videos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whats-this-btn">
              What is this?
            </Link>
          </div>
          <div className="testimonials">
            <div className="testimonials__card">
              <p className="testimonials__card__quote">"{TESTIMONIALS[currentTestimonial].quote}"</p>
              <div className="testimonials__card__user">
                <img src={TESTIMONIALS[currentTestimonial].image} alt="user" />
                <div>
                  <p className="testimonials__card__user__name">{TESTIMONIALS[currentTestimonial].name}</p>
                  {TESTIMONIALS[currentTestimonial].company && (
                    <p className="testimonials__card__user__company">{TESTIMONIALS[currentTestimonial].company}</p>
                  )}
                </div>
              </div>
            </div>
            {
              TESTIMONIALS.length > 1 && (
                <div className="testimonials__controls">
                  <button disabled={currentTestimonial === 0} onClick={prevTestimonial}
                          className="testimonials__controls--left">
                    <LeftArrowIcon />
                  </button>
                  <span>
                    {currentTestimonial + 1} / {TESTIMONIALS.length}
                  </span>
                  <button disabled={currentTestimonial === TESTIMONIALS.length - 1} onClick={nextTestimonial}
                          className="testimonials__controls--right">
                    <RightArrowIcon />
                  </button>
                </div>
              )
            }
          </div>
        </div>
        <div className="content-white">
          <p>Or, manually create your next order</p>
          <div className="content-white__btns">
            <button onClick={handleViewProductList} className="product-list-btn">View Product List</button>
          </div>
        </div>
      </div>
    </div>
  );
}

const CloseButtonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
      <g id="_ICON" data-name="// ICON" transform="translate(-1079 -304)" opacity="0.5">
        <line id="Line_366" data-name="Line 366" x1="9.2" y2="9.2" transform="translate(1091 316)" fill="none"
              stroke="#fff" strokeLinecap="round" strokeWidth="3" />
        <line id="Line_365" data-name="Line 365" x2="9.2" y2="9.2" transform="translate(1091 316)" fill="none"
              stroke="#fff" strokeLinecap="round" strokeWidth="3" />
        <path id="Path_2074" data-name="Path 2074" d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
              transform="translate(1077.5 302.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="3" />
      </g>
    </svg>
  );
};

const StockCardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="93" viewBox="0 0 93 93">
      <g id="_ICON" data-name="// ICON" transform="translate(-372 -348)">
        <circle id="Ellipse_439" data-name="Ellipse 439" cx="46.5" cy="46.5" r="46.5" transform="translate(372 348)"
                fill="#fff" />
        <g id="Group_5903" data-name="Group 5903" transform="translate(-691.323 308.434)">
          <g id="shopping-cart" transform="translate(1088 75.087)">
            <circle id="Ellipse_436" data-name="Ellipse 436" cx="1.516" cy="1.516" r="1.516"
                    transform="translate(10.613 28.807)" fill="none" stroke="#000" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="4" />
            <circle id="Ellipse_437" data-name="Ellipse 437" cx="1.516" cy="1.516" r="1.516"
                    transform="translate(27.291 28.807)" fill="none" stroke="#000" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1980" data-name="Path 1980"
                  d="M1,1H7.065l4.063,20.3a3.032,3.032,0,0,0,3.032,2.441H28.9A3.032,3.032,0,0,0,31.929,21.3l2.426-12.72H8.581"
                  transform="translate(-1 -1)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="4" />
          </g>
          <g id="Group_2774" data-name="Group 2774" transform="translate(1109.855 67.46)">
            <g id="Path_2047" data-name="Path 2047" transform="translate(-1.532 -2.893)">
              <path
                d="M 13 25 C 9.794679641723633 25 6.781219959259033 23.75177955627441 4.51471996307373 21.48527908325195 C 2.248219966888428 19.21878051757812 1 16.20532035827637 1 13 C 1 9.794679641723633 2.248219966888428 6.781219959259033 4.51471996307373 4.51471996307373 C 6.781219959259033 2.248219966888428 9.794679641723633 1 13 1 C 16.20532035827637 1 19.21878051757812 2.248219966888428 21.48527908325195 4.51471996307373 C 23.75177955627441 6.781219959259033 25 9.794679641723633 25 13 C 25 16.20532035827637 23.75177955627441 19.21878051757812 21.48527908325195 21.48527908325195 C 19.21878051757812 23.75177955627441 16.20532035827637 25 13 25 Z"
                stroke="none" />
              <path
                d="M 13 2 C 10.06179046630859 2 7.299449920654297 3.144199371337891 5.221830368041992 5.221830368041992 C 3.144199371337891 7.299449920654297 2 10.06179046630859 2 13 C 2 15.93820953369141 3.144199371337891 18.7005500793457 5.221830368041992 20.77816963195801 C 7.299449920654297 22.85580062866211 10.06179046630859 24 13 24 C 15.93820953369141 24 18.7005500793457 22.85580062866211 20.77816963195801 20.77816963195801 C 22.85580062866211 18.7005500793457 24 15.93820953369141 24 13 C 24 10.06179046630859 22.85580062866211 7.299449920654297 20.77816963195801 5.221830368041992 C 18.7005500793457 3.144199371337891 15.93820953369141 2 13 2 M 13 0 C 20.1796989440918 0 26 5.82029914855957 26 13 C 26 20.1796989440918 20.1796989440918 26 13 26 C 5.82029914855957 26 0 20.1796989440918 0 13 C 0 5.82029914855957 5.82029914855957 0 13 0 Z"
                stroke="none" fill="#fff" />
            </g>
            <text id="_" data-name="+" transform="translate(11.5 -9)" fill="#fff" fontSize="22"
                  fontFamily="SourceSansPro" fontWeight="600" letterSpacing="0.04em">
              <tspan x="-6.168" y="25">+</tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

const LeftArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.193" height="14.142" viewBox="0 0 9.193 14.142">
      <g id="_ICON" data-name="// ICON" transform="translate(7.071 14.142) rotate(-135)">
        <rect id="Rectangle_191" data-name="Rectangle 191" width="10" height="3" transform="translate(0 0)"
              fill="#fff" />
        <rect id="Rectangle_190" data-name="Rectangle 190" width="10" height="3" transform="translate(10 0) rotate(90)"
              fill="#fff" />
      </g>
    </svg>
  );
};

const RightArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.193" height="14.142" viewBox="0 0 9.193 14.142">
      <g id="_ICON" data-name="// ICON" transform="translate(-1353.696 560.297) rotate(-45)">
        <rect id="Rectangle_191" data-name="Rectangle 191" width="10" height="3" transform="translate(1344.897 569.518)"
              fill="#fff" />
        <rect id="Rectangle_190" data-name="Rectangle 190" width="10" height="3"
              transform="translate(1351.897 572.518) rotate(-90)" fill="#fff" />
      </g>
    </svg>
  );
};

export default StockCardPopUp;