import Request from 'superagent'

import {
  getAuthorization,
  getIsRetailer,
  getAccountNumberQuery as accountNumber,
} from '../../utils/storage'
import { domain, generalError } from 'Functions/data'
import productHelper from 'Functions/product.helper'

const createCartURL = domain + '/api/Order/AddSavedOrder'
const deleteCartURL = domain + '/api/Order/DeleteSavedOrder'
const updateCartURL = domain + '/api/Order/UpdateSavedOrder'
const getCartURL = domain + '/api/Order/GetSavedOrder'

/***
CREATE SAVED CART REQUEST (initialDataLoad)
***/

export function createCartREQUEST(callback) {
  Request.post(createCartURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) callback(-1, generalError)
        return false
      }

      callback(res.body.SavedOrderId)
    })
}

/***
DELETE SAVED CART REQUEST
***/

export function deleteCartREQUEST(_data, callback) {
  const data = {
    savedOrderId: _data.id,
  }

  Request.delete(deleteCartURL + accountNumber())
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .end((err, res) => {
      if (err) {
        callback(false, generalError)
        return false
      }

      callback(true)
    })
}

/***
UPDATE SAVED CART REQUEST (COMPONENT: Product, OrderConfirmation, ReviewOrder)
***/

export function updateCartREQUEST(_existings, _updates, callback) {
  if (!_existings) return false

  const data = {
    SavedOrderId: _existings.id,
    SavedOrderDetails: (() => {
      if (!_updates.length) {
        return []
      }

      const products = _existings.products.map((prod) => {
        return {
          SavedOrderDetailsId: prod.id,
          SKU: prod.sku,
          Quantity: prod.quantity,
        }
      })

      _updates.forEach((prod) => {
        if (
          products.every((existing) => {
            if (existing.SKU === prod.sku) {
              existing.Quantity = prod.quantity
              return false
            }
            return true
          })
        ) {
          products.push({
            SKU: prod.sku,
            Quantity: prod.quantity,
            SavedOrderDetailsId: 0,
          })
        }
      })

      return products.filter((prod) => {
        return prod.Quantity
      })
    })(),
  }

  //console.log('updateCartREQUEST', data.SavedOrderDetails);

  Request.put(updateCartURL + accountNumber())
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .end((err, res) => {
      if (err) {
        callback(false, {}, generalError)
        return false
      }

      callback(true, normalize(res.body))
    })
}

/***
GET CART REQUEST (COMPONENT: Product, OrderConfirmation)
***/

export function getCartREQUEST(callback) {
  Request.get(getCartURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status === 401 && !getIsRetailer()) {
          callback(-2)
        } else {
          callback(-1)
        }
        return false
      }

      if (res.status === 200) {
        callback(1, normalize(res.body))
      } else if (res.status === 204) {
        callback(0)
      }
    })
}

function normalize(_data) {
  const data = {
    id: _data.SavedOrderId,
    products: _data.SavedOrderDetails.map((prod) => {
      if (!productHelper.getProduct(prod.SKU)) return null

      if (prod.OutOfStock) return null

      return {
        id: prod.SavedOrderDetailsId,
        sku: prod.SKU,
        quantity: prod.Quantity,
        outOfStock: prod.OutOfStock,
        archived: prod.IsArchived,
        SavedOrderDetailsId: 0,
      }
    }),
  }

  data.products = data.products.filter((prod) => {
    return prod
  })

  return data
}
