import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Formik } from 'formik'

import { generalError } from '../../utils/constants'
import { getAccountInfo, setEoidFid } from '../../axios/private/account'

import { setEoidFidForm } from '../../utils/forms/private'
import Button from '../../components/form/Button'
import TextInput from '../../components/form/TextInput'
import EoidFidInfo from '../../components/EoidFidInfo'

const SetEoidFid = (props) => {
  const [state, setState] = useState({
    redirect: false,
    submitted: false,
    error: false,
    confirm: false,
  })

  useEffect(() => {
    checkAccountInfo()
  }, [])

  const checkAccountInfo = async () => {
    await getAccountInfo()
      .then((data) => {
        if (data?.TmeAccount?.EOID && data?.TmeAccount?.FID) {
          setState((oldVal) => ({ ...oldVal, redirect: true }))
        }
      })
      .catch((err) => {
        console.error(err)
        setState((oldVal) => ({ ...oldVal, redirect: true }))
      })
  }

  const onSetEoidFid = async (values, { setSubmitting }) => {
    if (!state.confirm) {
      setState((oldVal) => ({ ...oldVal, confirm: true, error: false }))
      return false
    }
    // console.log('submit', values)
    await setEoidFid(values)
      .then((res) => {
        setState((oldVal) => ({ ...oldVal, submitted: true }))
      })
      .catch((err) => {
        const message = err?.data?.Message || generalError
        setState((oldVal) => ({ ...oldVal, error: message, confirm: false }))
      })
    setSubmitting(false)
  }

  const onGoBack = () => {
    setState((oldVal) => ({
      ...oldVal,
      confirm: false,
      error: false,
      submitted: false,
    }))
  }

  if (state.redirect) return <Redirect to="/my-account" />

  return (
    <>
      <div className="bw-container-page">
        <div className="bw-container">
          {state.submitted ? (
            <>
              <h1 className="bw-h1 bw-mt-0 bw-mb-16">
                Eoid and Fid have been successfully set
              </h1>
              <Button label="Back" onClick={onGoBack} />
            </>
          ) : (
            <>
              <h1 className="bw-h1 bw-mt-0 bw-mb-5">Set Eoid and Fid</h1>
              {state.confirm && (
                <p className="bw-text-red bw-text-16 bw-mb-8">
                  Please confirm that the EIOD and FID codes are correct. Once
                  submitted, codes will need to be updated through Customer
                  Services.
                </p>
              )}
              {state.error && (
                <p className="bw-text-red bw-text-15 bw-mb-2">{state.error}</p>
              )}
              <Formik {...setEoidFidForm} onSubmit={onSetEoidFid}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextInput
                      name="EOID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.EOID}
                      label="EOID"
                      errortext={errors.EOID && touched.EOID && errors.EOID}
                    />
                    <TextInput
                      name="FID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.FID}
                      label="FID"
                      className="bw-mb-3"
                      errortext={errors.FID && touched.FID && errors.FID}
                    />
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      label={state.confirm ? 'Confirm' : 'Set EOID and FID'}
                    />
                  </form>
                )}
              </Formik>
              <EoidFidInfo />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default connect()(SetEoidFid)
