import { connect } from 'react-redux'

import { deleteConsentCookie } from 'Functions/storage'
import {
  removeConsentType,
  addActiveConsentType,
  toggleConsentDialog,
} from 'Redux/actions.cookie'

import { remove } from 'Functions/ga'

class CookiesPolicyContent extends React.Component {
  constructor(props) {
    super(props)
  }

  revokeCookieConsent(type) {
    const { dispatch } = this.props

    deleteConsentCookie(type)

    dispatch(addActiveConsentType([type]))
    dispatch(toggleConsentDialog(true))
  }

  toggleDisplay() {
    // toggle cookie settings popup
    if (window.OneTrust) window.OneTrust.ToggleInfoDisplay()
    else if (OneTrust) OneTrust.ToggleInfoDisplay()
  }

  render() {
    const {
      history,
      consentTypes,
      activeConsentTypes,
      consentDialog,
    } = this.props

    return (
      <React.Fragment>
        <h3 className="page_title">Cookies Policy</h3>

        <p>
          We use cookies to analyse our traffic. We also share information about
          your use of our site with our analytics partners who may combine it
          with other information that you've provided to them or that they've
          collected from your use of their services. You consent to our cookies
          if you accepted our Cookie Declaration.
        </p>

        <p>
          Cookies are small text files that can be used by websites to make a
          user's experience more efficient. The law states that we can store
          cookies on your device if they are strictly necessary for the
          operation of this site. For all other types of cookies we need your
          permission. This site uses different types of cookies. Some cookies
          are placed by third party services that appear on our pages. You can
          at any time change or withdraw your consent from the Cookie
          Declaration on our website.
        </p>

        <p>
          Learn more about who we are, how you can contact us and how we process
          personal data in our Privacy Policy.
        </p>

        <h4 className="section_title">Necessary Cookies</h4>

        <p>
          Necessary cookies help make a website usable by enabling basic
          functions like page navigation and access to secure areas of the
          website. The website cannot function properly without these cookies.
        </p>

        <p>Below are the list of necessary cookies that we use:</p>

        {/* OneTrust Cookies List start  */}
        <div id="ot-sdk-cookie-policy"></div>
        {/* OneTrust Cookies List end  */}

        {/* OneTrust Cookies Settings button start  */}
        <button
          id="ot-sdk-btn"
          className="ot-sdk-show-settings"
          onClick={this.toggleDisplay}
        >
          Cookie Settings
        </button>
        {/* OneTrust Cookies Settings button end  */}

        <br />
        <br />
        <br />
      </React.Fragment>
    )
  }
  componentDidMount() {
    // init cookie policy list
    if (window.OneTrust) window.OneTrust.initializeCookiePolicyHtml()
  }
}

function stateMapping(state) {
  return {
    consentTypes: state.cook.consentTypes,
    activeConsentTypes: state.cook.activeConsentTypes,
    consentDialog: state.cook.consentDialog,
  }
}

export default connect(stateMapping)(CookiesPolicyContent)
