import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Moment from 'moment'

import { showSuggested } from 'Redux/actions.prod'

class Suggested extends React.Component {
  render() {
    const { closeHandler, suggested } = this.props

    return (
      <React.Fragment>
        <h3 className="section_title">
          At your BA store visit on{' '}
          {Moment(suggested.AgreedDate).format('DD/MM/YYYY')},{' '}
          {suggested.AdditionalInfo !== ''
            ? suggested.AdditionalInfo
            : ' you or one of your team agreed with your BA '}{' '}
          agreed with {suggested.BAName} to order the following product(s):
        </h3>

        {suggested?.SuggestedProducts.map((prod, i) => (
          <p className="fontsize19" key={i}>
            {prod.StockReplenishedQuantity} outer
            {prod.StockReplenishedQuantity > 1 ? 's' : ''}{' '}
            {prod.ProductDetails?.Product}
          </p>
        ))}
        <br />
        <p>
          These have automatically been added to your cart. You can still add to
          your order.
        </p>

        <div className="buttons">
          <button
            className="button_style-1 fontsize19"
            onClick={(e) => closeHandler(e)}
          >
            Close
          </button>
        </div>
      </React.Fragment>
    )
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(showSuggested(false))
  }

  // componentWillUnmount() {
  //   const { dispatch } = this.props
  //   dispatch(setSuggestedProducts())
  // }
}

Suggested.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

function stateMapping(state) {
  return {
    suggested: state.app.suggestedProducts,
  }
}

export default connect(stateMapping)(Suggested)
