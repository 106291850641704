import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import Request from 'superagent'
import Slider from 'react-slick'
import Velocity from 'velocity-animate'

import { domain } from 'Functions/data'
import { getAuthorization } from 'Functions/storage'

import {
  getHomePods,
  getUsefulDownloads,
  getLatestVlogs,
} from 'Functions/contents'
import { openPopup } from 'Redux/actions.app'

import Preloader from 'Components/Preloader'
import Video from 'Components/blocks/Video'

import { GA } from 'Functions/ga'
import { toggleBackTop } from "../redux/actions.app";

const urlRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingHero: false,
      loadingHomePods: false,
      loadingUsefulDownloads: false,
      loadingLatestVlogs: false,
      hero: [],
      homePods: [],
      usefulDownloads: [],
      latestVlogs: {},
      homePodsSlider: {
        oldSlide: 0,
        activeSlide: 0,
      },
    }
  }

  renderHero(hero) {
    return (
      <div className="slide__contents">
        <div>
          <h2 style={{color: hero.ColorText}} dangerouslySetInnerHTML={{ __html: hero.Title }} />
          <p style={{color: hero.ColorText}} dangerouslySetInnerHTML={{ __html: hero.Desc }} />
          {urlRegex.test(hero.Link) ? (
            <a
              href={hero.OpenSurvey ? hero.Link + '?survey=true' : hero.Link}
              target="_blank"
              className={`button_style-4 fontsize19 ${
                hero.CTAColour ? hero.CTAColour : ''
              }`}
              onClick={() => GA('Home :: Hero', `Hero :: ${hero.Title}`,'click', this.props.accountId)}
            >
              {hero.CTA}
            </a>
          ) : (
            <a
              href={hero.OpenSurvey ? hero.Link + '?survey=true' : hero.Link}
              className={`button_style-4 fontsize19 ${
                hero.CTAColour ? hero.CTAColour : ''
              }`}
              onClick={() => GA('Home :: Hero', `Hero :: ${hero.Title}`,'click', this.props.accountId)}
            >
              {hero.CTA}
            </a>
          )}
        </div>
        <div>
          {hero.VideoPath ? (
            <video
              autoPlay
              playsInline
              loop
              muted
              poster={hero.ImagePath}
              preload="auto"
            >
              <source src={hero.VideoPath} type="video/mp4" />
            </video>
          ) : (
            <img src={hero.ImagePath} alt={hero.Title} />
          )}
        </div>
      </div>
    )
  }

  scrollToContent() {
    Velocity(document.getElementById('scrollToContent'), 'scroll', {
      duration: 500,
      easing: 'ease-out',
      offset: 100,
    })

    GA('Home :: CTA', 'Icon :: Scroll', 'scroll', this.props.accountId)
  }

  renderHomePods(data) {
    return data.map((data) => (
      <div key={data.HomePodId}>
        <div className="slide__contents">
          {data.VideoPath ? (
            <video
              autoPlay
              playsInline
              loop
              muted
              poster={data.ImagePath}
              preload="auto"
            >
              <source src={data.VideoPath} type="video/mp4" />
            </video>
          ) : (
            <img src={data.ImagePath} alt={data.Title} />
          )}
          <h3 style={{color: data.ColorText}} dangerouslySetInnerHTML={{ __html: data.Title }} />
          <p style={{color: data.ColorText}} dangerouslySetInnerHTML={{ __html: data.Desc }} />
          {urlRegex.test(data.Link) ? (
            <a
              className={`${
                data.CTAColour ? data.CTAColour : ''
              }`}
              href={data.OpenSurvey ? data.Link + '?survey=true' : data.Link}
              target="_blank"
              onClick={() =>
                GA('Home :: Home Pods', `Home Pod :: ${data.Title}`,'click', this.props.accountId)
              }
            >
              {data.CTA}
            </a>
          ) : (
            <Link
              className={`${
                data.CTAColour ? data.CTAColour : ''
              }`}
              to={data.OpenSurvey ? data.Link + '?survey=true' : data.Link}
              onClick={() =>
                GA('Home :: Home Pods', `Home Pod :: ${data.Title}`,'click', this.props.accountId)
              }
            >
              {data.CTA}
            </Link>
          )}
        </div>
      </div>
    ))
  }

  renderUsefulDownload(data) {
    return data.map((data, index) => (
      <li key={index}>
        <a
          href={data.AssetPath}
          {...(data.NewWindow
            ? { target: '_blank', rel: 'noopener noreferrer' }
            : {})}
          onClick={() =>
            GA('Home :: Useful Downloads', `Icon Button :: ${data.TopLineText}`,'click', this.props.accountId)
          }
        >
          <span className={`icon ${data.Icon}`}></span>
          <p>
            <b>{data.TopLineText}</b>
          </p>
        </a>
      </li>
    ))
  }

  trackVideo(type, id, title) {
    let url = domain + '/api/Cms/Track/VlogAsset?vlogId=' + id + '&type=' + type

    Request.post(url)
      .set({ Authorization: getAuthorization() })
      .responseType('json')
      .end((err, res) => {
        if (err) {
          return false
        }
        GA('Home :: Latest Vlogs', title, 'play', this.props.accountId)
      })
  }

  render() {
    const { history, app_error } = this.props
    const {
      loadingHero,
      loadingHomePods,
      loadingUsefulDownloads,
      loadingLatestVlogs,
      hero,
      homePods,
      homePodsSlider,
      slideCount,
      usefulDownloads,
      latestVlogs,
    } = this.state

    const settingsHomePod = {
      dots: false,
      infinite: homePods.length > 2 || (window.innerWidth >= 510 && window.innerWidth < 1024) ? true : false,
      draggable: homePods.length > 2 || (window.innerWidth >= 510 && window.innerWidth < 1024) ? true : false,
      speed: 500,
      variableWidth: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            variableWidth: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            variableWidth: false,
          },
        },
      ],
      beforeChange: (current, next) => {
        const { homePodsSlider } = { ...this.state }
        const currentState = homePodsSlider
        currentState.oldSlide = current
        currentState.activeSlide = next

        this.setState({ homePodsSlider: currentState })
      },
    }

    if (
      loadingHero &&
      loadingHomePods &&
      loadingUsefulDownloads &&
      loadingLatestVlogs
    ) {
      return <Preloader />
    }

    return (
      <>
        <div className="page-home bw-bg-white">
          {/* <div className="triangle-background" /> */}

          {Object.values(hero).length >= 1 && (
            <React.Fragment>
              <div className="section section__hero">
                <div className="section__container">
                  {this.renderHero(hero)}
                </div>
              </div>
              {!!homePods.length && (
                <div
                  className="section section__home-pods"
                  style={{ paddingTop: '0' }}
                >
                  <div className="section__container">
                    <Slider
                      ref={(slider) => (this.sliderHomePod = slider)}
                      {...settingsHomePod}
                    >
                      {this.renderHomePods(homePods)}
                    </Slider>
                    <div className="slider-navigation" style={{
                      display: (window.innerWidth >= 1024 && homePods.length <= 2) ||
                      (window.innerWidth < 1024 && homePods.length <= 1)
                        ? 'none' : 'unset'
                    }}>
                      <div
                        className={`slider-bar slider-bar-${
                          homePodsSlider.activeSlide + 1
                        }`}
                      >
                        <div
                          className="active"
                          style={{
                            width: 100 / homePods.length + '%',
                            left:
                              (100 / homePods.length) *
                                homePodsSlider.activeSlide +
                              '%',
                          }}
                        />
                      </div>

                      <div className="slider-counter">
                        <button
                          onClick={(e) => this.sliderHomePod.slickPrev()}
                          title="Previous"
                        />
                        {homePodsSlider.activeSlide + 1}/{homePods.length}
                        <button
                          onClick={(e) => this.sliderHomePod.slickNext()}
                          title="Next"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div
                id="scrollToContent"
                className="section section__scroll"
                onClick={() => this.scrollToContent()}
              >
                <div className="scroll__container">
                  <p>Scroll</p>
                </div>
              </div> */}
            </React.Fragment>
          )}

          {/* <div className="section--bg-alt">
            {!!usefulDownloads.length && (
              <div
                className="section section__useful-downloads"
                id="useful-downloads"
              >
                <div className="section__container">
                  <h3 className="section__title">Useful Downloads</h3>
                  <ul className="cf">
                    {this.renderUsefulDownload(usefulDownloads)}
                  </ul>
                </div>
              </div>
            )}
          </div> */}

          {Object.values(latestVlogs).length >= 1 && (
            <div className="section section__latest-vlog">
              <div className="section__container">
                <h3 className="section__title">Latest Vlog</h3>
                <Video
                  video={latestVlogs.VideoPath}
                  image={latestVlogs.ImagePath}
                  id={latestVlogs.VlogAssetId}
                  track={(action, id) =>
                    this.trackVideo(action, id, latestVlogs.Title)
                  }
                />
                <div className="section__details">
                  <div className="">
                    <h4>{latestVlogs.Title}</h4>
                  </div>
                  <div className="">
                    <span>
                      {latestVlogs.Views}
                      {latestVlogs.Views === 1 ? ' View' : ' Views'} |{' '}
                    </span>
                    <span>
                      Posted {Moment(latestVlogs.Posted).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  UrlParam(name, params) {
    var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(params)
    if (results == null) {
      return null
    } else {
      return decodeURI(results[1]) || 0
    }
  }

  componentDidMount() {
    getHomePods((data) => {
      let hero = data.HomePods[0] || {}
      data.HomePods.shift()

      this.setState({
        hero: hero,
        homePods: data.HomePods,
      })
    })

    getUsefulDownloads((data) => {
      this.setState({
        usefulDownloads: data.HomeAssets,
      })
    })

    getLatestVlogs((data) => {
      this.setState({
        latestVlogs: data,
      })
    })
    this.UrlParam('section', this.props.location.search) == 'downloads' &&
      setTimeout(function () {
        document.documentElement.scrollTop =
          document.getElementById('bw-private-widgets').offsetTop
      }, 500)

    this.props.dispatch(toggleBackTop(true))
  }

  componentWillUnmount() {
    this.props.dispatch(toggleBackTop(false))
  }

  componentDidUpdate() {
    const { dispatch, showSuggested } = this.props
    this.props.dispatch(toggleBackTop(true))

    if (showSuggested) {
      dispatch(openPopup('tm-suggested', '', ''))
    }
  }
}

function stateMapping(state) {
  return {
    showSuggested: state.prod.showSuggested,
    accountId: state.app.currentAccount.JtiAccountId
  }
}

export default connect(stateMapping)(Home)
