import React from 'react';
import {ReactComponent as Vehicle} from '../../icons/Vehicle.svg';
import {ReactComponent as Computer} from '../../icons/Computer.svg';
import {ReactComponent as Phone} from '../../icons/Phone.svg';
import {ReactComponent as Person} from '../../icons/Person.svg';

export default function OrderMethod({ type, active = false}) {
 return (
    <span className={active ? 'bw-my-orders__order-method--active' : ''}>
      {type === 'Web Sales Order' && <Computer />}
      {type === 'Telesales Order' && <Phone />}
      {type === 'Sales Order' && <Person />}
      {type === 'Ex-Car Order' && <Vehicle />}
    </span>
 )   
}
