import Request from 'superagent'

import {
  getAuthorization,
  getAccountNumberQuery as accountNumber,
} from '../../utils/storage'
import { domain } from 'Functions/data'

const homeTitleCopyURL = domain + '/api/Cms/HomeTitleCopy'
const usefulDownloadURL = domain + '/api/Cms/HomeAssets'
const homePodsURL = domain + '/api/Cms/HomePods'
const latestVlogsURL = domain + '/api/Cms/LatestVlogAsset'
const videoListURL = domain + '/api/Cms/VideoAssets'
const vlogListURL = domain + '/api/Cms/VlogAssets'
const faqsURL = domain + '/api/Cms/Faqs'
const contactSubjectsURL = domain + '/api/ContactUs/GetSubjectTitles'
const popupBannersURL = domain + '/api/Cms/PopUpBanners'
const SAPClosureURL = domain + '/api/Status/GetSapClosureStatus'
const messagePromptURL = domain + '/api/OrderHistory/GetRecentOrder'
const partnerHubListURL = domain + '/api/Cms/PartnerContent'
const newPartnerContentURL = domain + '/api/Cms/NewPartnerContent'

/***
message Prompt REQUEST  
***/

export function messagePromptREQUEST(callback) {
  Request.get(messagePromptURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
      //  callback(true, {
      //   "OrderDate": "2020-03-01T16:28:19.067Z",
      //   "OrderNumber": "PW-9837FF"
      // });
    })
}

/***
PARTNER HUB LIST REQUEST (FILE: functions/contents.es6)
***/

export function partnerHubGetNewContentCountREQUEST(callback) {
  Request.get(newPartnerContentURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
PARTNER HUB LIST REQUEST (FILE: functions/contents.es6)
***/

export function partnerHubListREQUEST(callback) {
  Request.get(partnerHubListURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
HOME PAGE TITLE/COPY REQUEST (FILE: functions/contents.es6)
***/

export function homeTitleCopyREQUEST(callback) {
  Request.get(homeTitleCopyURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
HOME PODS REQUEST (FILE: functions/contents.es6)
***/

export function homePodsREQUEST(callback) {
  Request.get(homePodsURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
USEFUL DOWNLOADS REQUEST (FILE: functions/contents.es6)
***/

export function usefulDownloadREQUEST(callback) {
  Request.get(usefulDownloadURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
LATEST VLOGS REQUEST (FILE: functions/contents.es6)
***/

export function latestVlogsREQUEST(callback) {
  Request.get(latestVlogsURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body)
    })
}

/***
FAQS PAGE FAQS REQUEST (FILE: functions/contents.es6)
***/

export function faqsREQUEST(callback) {
  Request.get(faqsURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body.Faqs)
    })
}

/***
CONTACT US SUBJECTS (FILE: functions/contents.es6)
***/

export function contactSubjectsREQUEST(callback) {
  Request.get(contactSubjectsURL + accountNumber())
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }
      callback(true, res.body.Subjects)
    })
}

/***
GET VIDEO LIST REQUEST (FILE: functions/contents.es6)
***/

export function videoListREQUEST(callback) {
  Request.get(videoListURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      if (res.body.VideoAssets.length) {
        const response = res.body.VideoAssets.map((video) => {
          return {
            id: video.VideoAssetId,
            video: video.VideoPath,
            image: video.ImagePath,
            title: video.Title,
            description: video.Desc,
            isHero: video.IsHero,
          }
        })
        callback(true, response)
      } else {
        callback(false)
      }
    })
}

/***
GET VLOG LIST REQUEST (FILE: functions/contents.es6)
***/

export function vlogListREQUEST(callback) {
  Request.get(vlogListURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      if (res.body.VlogAssets.length) {
        const response = res.body.VlogAssets.map((video) => {
          return {
            id: video.VlogAssetId,
            video: video.VideoPath,
            image: video.ImagePath,
            title: video.Title,
            description: video.Desc,
            isHero: video.IsHero,
            question: video.Question,
            answer1: video.Answer1,
            answer2: video.Answer2,
            views: video.Views,
            like: video.Like,
            dislike: video.Dislike,
            posted: video.Posted,
            viewed: video.Viewed,
          }
        })
        callback(true, response)
      } else {
        callback(false)
      }
    })
}

/***
GET POPUPBANNERS REQUEST (COMPONENT: Login)
***/

export function popupBannersREQUEST(callback) {
  Request.get(popupBannersURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      const response = {
        id: res.body.PopUpBanners.PopUpBannerId,
        image: res.body.PopUpBanners.ImagePath,
        video: res.body.PopUpBanners.VideoPath,
        title: res.body.PopUpBanners.Title,
        autoPlay: res.body.PopUpBanners.AutoPlay,
        loop: res.body.PopUpBanners.RepeatVideoPlay,
        activeFrom:
          res.body.PopUpBanners.ActiveFrom ||
          res.body.PopUpBanners.LastModifiedDateTime,
        isDisplayMultiple: res.body.PopUpBanners.IsDisplayMultiple,
        AssetPath: res.body.PopUpBanners.AssetPath,
        CTA: res.body.PopUpBanners.CTA,
      }
      callback(true, response)
    })
}

/***
GET SAPCLOSURE REQUEST (COMPONENT: Login)
***/

export function SAPClosureREQUEST(callback) {
  Request.get(SAPClosureURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      if (res.statusCode === 200) {
        callback(true, res.body)
      } else {
        callback(false)
      }
    })
}
