import PropTypes from 'prop-types'

import Accordion from 'Components/blocks/Accordion'

class Accordions extends React.Component {
  
  constructor(props) {
    super(props)
  
    this.state = {
      selectedIndex: null 
    }
  }
  
  render() {
    const { contents, type, gac } = this.props;
    
    return (
      <div className="block-accordion">
      {
        contents.map((dat, i) => 
          <Accordion key={ i } dt={ dat.dt } dd={ dat.dd } index={ i } selectedIndex={ this.state.selectedIndex } select={(index)=>this.select(index)} gac={ gac }/>
        )
      }
      </div>  
    )
  }
  
  select(index) {
    this.setState({ selectedIndex: index });  
  }
}

Accordions.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({
    dt: PropTypes.string.isRequired, 
    dd: PropTypes.string.isRequired
  })),
  gac: PropTypes.string
}

export default Accordions