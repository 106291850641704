import React, { useEffect } from 'react'

const Drawer = (props) => {
  const { open, children } = props

  useEffect(() => {
    if (open) document.body.classList.add('open-drawer')

    return () => {
      document.body.classList.remove('open-drawer')
    }
  }, [open])

  return (
    <div className={`bw-drawer ${open ? 'is-open' : ''}`}>
      <div className="bw-drawer__sidebar">{children}</div>
    </div>
  )
}

export default Drawer
