import productHelper from '../../script/functions/product.helper'

export function normalizeOldCartData(_data) {
  const data = {
    id: _data.BackEndCartId,
    products: _data.BackEndCartDetails.map(
      ({ Product, Quantity, BackEndCartDetailsId }) => {
        if (!productHelper.getProduct(Product.SKU)) return null

        if (Product.OutOfStock) return null

        return {
          id: BackEndCartDetailsId,
          sku: Product.SKU,
          quantity: Quantity,
          outOfStock: Product.OutOfStock,
          archived: Product.IsArchived,
          SavedOrderDetailsId: 0,
        }
      }
    ),
  }

  return data
}
