import React from 'react'
import PropTypes from 'prop-types'

import Filter from './Filter'

const Filters = (props) => {
  const { filters, disabledFilters, type, className, onSelect, children } = props
  return (
    <>
      {filters?.length ? (
        <div
          className={`bw-product-filters bw-product-filters-${type} ${className}`}
        >
          {filters.map((filter, index) =>
            type === 'categories' || filter?.active ? (
              <Filter
                key={index}
                type={type}
                disabled={
                  disabledFilters.length
                    ? disabledFilters[index] && !filter?.active
                    : false
                }
                {...filter}
                onSelect={() => onSelect(filter, index)}
              />
            ) : (
              ''
            )
          )}
          {children}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

Filters.propTypes = {
  filters: PropTypes.array,
  disabledFilters: PropTypes.array,
  type: PropTypes.oneOf(['categories', 'brands']),
  className: PropTypes.string,
  onSelect: PropTypes.func,
}

Filters.defaultProps = {
  filters: [],
  disabledFilters: [],
  type: 'categories',
  className: '',
  onSelect: () => {},
}

export default Filters
