export const getOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'Submitted'
    case 1:
      return 'Pending'
    case 2:
      return 'Processing'
    case 3:
      return 'Cancelled'
    default:
      return 'Submitted'
  }
}
