import { suffix } from "Functions/helper";
import { vat } from "Functions/data";

/***
FORMAT DATE
***/

export function dateFormat(dateString) {
  const date = new Date(dateString);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    suffix(date.getDate()) +
    " " +
    months[date.getMonth()] +
    " " +
    date.getFullYear()
  );
}

export function orderDateFormat(dateObj) {
  if (!dateObj) return "";
  return `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
}

/***
TO CURRENCY
***/

export function toCurrency(n) {
  return Math.round(n * 100) / 100;
}

export function toCurrencyString(n) {
  var ns = n.toFixed(2).split(".");
  return "€" + (+ns[0]).toLocaleString() + "." + ns[1];
}

export function getVatTotal(n, customVAT = vat) {
  return toCurrency(n * customVAT);
}

/***
TO FILE NAME BY REMOVING SPECIAL CHACTERS
***/

export function toFileName(s) {
  return s.replace(/\W/g, "").toLowerCase();
}

/***
OBSERVER FOR STICKY BUTTONS
***/

var observer = null;
var runObserver = false;

export function setObserver(callback) {
  if (typeof window.IntersectionObserver !== "function") return false;

  observer = new IntersectionObserver((entries) => {
    if (runObserver) {
      callback(
        entries.reduce((a, ent) => {
          a[ent.target.id] = ent.isIntersecting;
          return a;
        }, {}),
      );
    }
  });
  runObserver = true;
}

export function addObserveTarget(targetID) {
  if (!observer) return false;

  var target = document.getElementById(targetID);
  if (target) observer.observe(target);
}

export function deleteObserver() {
  observer = null;
  runObserver = false;
}

/***
GET WINDOW OR ELEMENT SIZE
***/

export function getSize(el) {
  if (el) {
    return {
      w: el.offsetWidth,
      h: el.offsetHeight,
    };
  }
  return {
    w: window.innerWidth,
    h: window.innerHeight,
  };
}

/***
GET ELEMENT POSITION
***/

export function getPosition(el) {
  const box = el.getBoundingClientRect();
  return {
    left: box.left,
    top: box.top,
  };
}

/***
SET PROMISE
***/

export function setPromise(task, callback) {
  const promise = new Promise((resolve, reject) => {
    callback(resolve, reject);
  });
  promise.then(
    () => {
      task();
    },
    () => {},
  );
}
