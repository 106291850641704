import React from 'react'

const TermsConditionsContent = (props) => {
  return (
    <ol>
      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Introduction</b>
        </h5>
        <p>
          The JTI Application (the “Application”) is provided to you (“you” or
          the “user”) by JTI Ireland Limited (“JTI” or “us”) of 1st Floor Block
          4-6, River Walk, Citywest Business Campus, Dublin 24. By using the
          Application you agree to be bound by these T&Cs. If you do not agree
          to these T&Cs please do not use the Application.
        </p>

        <p>
          This Application is provided to people who are (i) 18 years of age,
          (ii) currently employed either by or as a proprietor of a licensed
          establishment which is registered to sell retail tobacco products and
          (iii) engaged in the retailing of tobacco and reduced-risk products.
          If you use the Application you are warranting to JTI that you meet the
          criteria specified in these T&Cs. Your access to the Application may
          be terminated by us if you act in breach of these T&Cs or if we have
          reasonable grounds for suspecting a breach.
        </p>

        <p>
          The JTI Engage Privacy Statement (“Privacy Statement”) also applies to
          your use of this Application. The JTI Terms and Conditions of Sale (as
          same may be amended by JTI in its absolute discretion form time to
          time) (“Sale T&Cs”) apply to the supply by JTI of JTI tobacco and
          reduced-risk products.
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Conditions of use</b>
        </h5>
        <p>
          The Application is an ordering tool to allow JTI customers to place
          their order by logging into the Application, selecting products and
          completing the process to place an order with JTI.
        </p>

        <p>
          The Application is currently made available to you free of charge for
          your commercial use for the purpose of ordering JTI tobacco and
          reduced-risk products. JTI reserves the right to amend or withdraw the
          Application provided to you in accordance with these T&Cs, at any time
          and for any reason.
        </p>

        <p>
          You will not, nor allow third parties on your behalf to (i) make and
          distribute copies of the Application (ii) attempt to copy, reproduce,
          alter, modify, reverse engineer, disassemble, decompile, transfer,
          exchange or translate the Application; (iii) create derivative works
          of any kind whatsoever of the Application; (iv) access all or any part
          of the Application in order to build a product or service which
          competes with JTI; (d) use the Application to provide services to
          third party retailers; (vi) license, sell, rent, lease, transfer,
          assign, distribute, display, disclose, or otherwise commercially
          exploit, or otherwise make the Application available to any third
          party, or (vi) attempt to obtain, or assist third parties in
          obtaining, access to Application other than as provided under this
          these T&Cs; and you shall use all reasonable endeavours to prevent any
          unauthorised access to, or use of, the Application and, in the event
          of any such unauthorised access or use, promptly notify JTI.
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Intellectual property</b>
        </h5>
        <p>
          All content and other materials incorporated in or accessible through
          the Application, including, without limitation, text, photographs,
          images, graphics, illustrations, logos, button icons, audio clips,
          software, and other content, (including the look and feel of the
          Application), (the “Content”) are protected by Irish and international
          copyright laws, and are owned, controlled or licensed by JTI or by the
          original creators of such materials or their permitted licensors. Such
          materials may be used only for using the Application in the ordinary
          course and subject to the restrictions and conditions set out in these
          T&Cs. Any other use of such materials, including any copying,
          reproduction, modification, sale, distribution, extraction,
          re-utilisation, transmission, republication, downloading, display,
          posting, performance, or other exploitation thereof by any means or
          medium without the prior written permission of the copyright owner is
          strictly prohibited.
        </p>

        <p>
          If you use the Application JTI grants to you a non-exclusive,
          non-transferable right to view the Content subject to the restrictions
          and conditions set out in these T&Cs. The foregoing license is subject
          to your payment of all applicable charges, is solely for use in
          connection with the retail of the Products.
        </p>

        <p>
          The JTI logo is a trade mark of JTI. This trade mark or any other
          trade mark (registered or unregistered) of JTI may not be used in any
          manner that is likely to cause confusion among customers, or in any
          manner that disparages or discredits JTI or any other party.
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Privacy and cookies</b>
        </h5>
        <p>
          Your privacy is important to us. Please review our Privacy Statement,
          which also governs to understand our practices. Please do this before
          using this Application, as your use of the Application will also apply
          to your use of this Application.{' '}
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Availability</b>
        </h5>
        <p>
          The Application is provided on an “as is” basis. JTI will use
          commercially reasonable efforts to make the Application available at
          all times. To the fullest extent permitted by law JTI does not warrant
          that the Application or any function contained in the Application will
          be uninterrupted or error-free, that defects will be corrected, or
          that the Application or the servers that make the Application
          available are free of viruses or other harmful components.
        </p>

        <p>
          JTI and sub-contractors do not accept any responsibility whatsoever
          for unavailability of the Application, or any difficulty or inability
          to download or access content or any other communication system
          failure which may result in the Application being unavailable. JTI
          will not be responsible for any support or maintenance for the
          Application.
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Disclaimer</b>
        </h5>
        <p>
          JTI disclaims all warranties, express or implied, including, without
          limitation, implied warranties of merchantability, fitness for a
          particular purpose, compatibility, security, accuracy or infringement.
          You acknowledge that the Application is provided over the internet and
          mobile networks and so the quality and availability of the Application
          may be affected by factors outside JTI’s control.{' '}
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Termination</b>
        </h5>
        <p>
          JTI Ireland Limited may terminate use of the Application at any time
          by giving notice of termination to you. Upon any termination, (a) the
          rights and licenses granted to you herein shall terminate; (b) you
          must cease all use of the Software;
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>Limitation of liability</b>
        </h5>
        <p>
          In no event will JTI be liable for any direct, indirect, special,
          punitive, exemplary or consequential losses or damages of whatsoever
          kind arising out of your use or access to the Application, including
          loss of profit or the like whether or not in the contemplation of the
          parties, whether based on breach of contract, tort (including
          negligence), product liability or otherwise.
        </p>

        <p>
          You expressly understand and agree that (to the fullest extent
          permitted by law) JTI or any of its affiliates, or any of our or their
          respective licensors, licensees, service providers or suppliers shall
          not be liable for any indirect, incidental, special, consequential,
          exemplary or punitive damages, or any other damages whatsoever,
          including but not limited to, damages for loss of profits, goodwill,
          use, data or other intangible losses (even if we have been advised of
          the possibility of such damages), arising out of, or resulting from
          the use or the inability to use the Application.
        </p>

        <p>
          Nothing in these T&Cs shall exclude or limit JTI’s liability for death
          or personal injury caused by negligence or for fraud or fraudulent
          misrepresentation or any other liability which cannot be excluded or
          limited under applicable law.
        </p>
      </li>

      <li>
        <h5 className="bw-mb-1 bw-text-18">
          <b>General</b>
        </h5>
        <p>
          These T&Cs, the Privacy Statement and the Sale T&Cs constitute the
          entire agreement between JTI and you with respect to your access to
          and use of the Application. If any provision of these T&Cs shall be
          unlawful, void, or for any reason unenforceable, then that provision
          shall be deemed severable from these T&Cs and shall not affect the
          validity and enforceability of any remaining provisions.
        </p>
      </li>
    </ol>
  )
}

export default TermsConditionsContent
