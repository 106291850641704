import * as Yup from 'yup'
import { formErrors } from '../constants'

export const registerForm = {
  initialValues: {
    FirstName: '',
    LastName: '',
    JtiAccountId: '',
    Email: '',
    AgeConfirmation: false,
  },
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required('Invalid first name. Please try again.'),
    LastName: Yup.string().required('Invalid last name. Please try again.'),
    JtiAccountId: Yup.string().required(formErrors.general),
    Email: Yup.string().email().required(formErrors.email),
    AgeConfirmation: Yup.boolean().oneOf([true], true),
  }),
}

export const contactUsForm = {
  initialValues: {
    Email: '',
    JtiAccountNumber: '',
    Subject: {
      label: 'Please Select Subject',
      value: false,
    },
    Message: '',
  },
  validationSchema: Yup.object().shape({
    Email: Yup.string().email().required(formErrors.email),
    JtiAccountNumber: Yup.string(),
    Subject: Yup.object()
      .test(
        'validate-custom-select',
        (selected, event) =>
          selected.value ||
          event.createError({
            path: event.path,
            message: formErrors.general,
          })
      )
      .required(),
    Message: Yup.string().required(formErrors.general),
  }),
}

export const resetPasswordForm = {
  initialValues: {
    Email: '',
    Password: '',
    ConfirmPassword: '',
  },
  validationSchema: Yup.object().shape({
    Email: Yup.string().email().required(formErrors.email),
    Password: Yup.string().required(formErrors.general),
    ConfirmPassword: Yup.string().required(formErrors.passNotMatch),
  }),
  validate: (val, setStatus) => {
    const passwordsMatch = val.Password === val.ConfirmPassword
    const hasEight = val.Password.length >= 8
    const hasLowercase = !!val.Password.match(/(?=.*[a-z])/gm)
    const hasUppercase = !!val.Password.match(/(?=.*[A-Z])/gm)
    const hasNumber = !!val.Password.match(/(?=.*\d)/gm)
    const hasSpecialCharacter = !!val.Password.match(/(?=.*[!@#$%^&*)(+=.,<>?{}\[\]:;'"|~`_\-\\\/])/gm)

    setStatus((oldValue) => ({
      ...oldValue,
      passwordStatus: {
        hasEight,
        hasUppercase,
        hasLowercase,
        hasNumber,
        hasSpecialCharacter,
        passwordsMatch,
      },
    }))
    if (!hasEight || ((hasUppercase?1:0)+(hasLowercase?1:0)+(hasNumber?1:0)+(hasSpecialCharacter?1:0) < 3))
      return {
        Password:
          'Your password must be 8 or more characters and have at least 3 of the following: a uppercase letter, a lowercase letter, a number, a special character',
      }
    if (!passwordsMatch)
      return {
        ConfirmPassword: formErrors.passNotMatch,
      }
  },
}

export const resendEmailForm = {
  initialValues: {
    Email: '',
  },
  validationSchema: Yup.object().shape({
    Email: Yup.string()
      .email()
      .required('Invalid email format. Please try again.'),
  }),
}
