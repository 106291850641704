import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class StepGuide extends React.Component {
  
  render() {
    const { closeHandler, showLink } = this.props;
    
    return (
      <React.Fragment>
        <h3 className="section_title">First time login</h3>
        
        <p>Please follow these easy steps :</p>
        {
          showLink ? (
            <p>1. <Link to="/reset-password">Click here</Link> to reset your password</p>
          ) : (
            <p>1. Reset your password</p>
          )
        }
        <p>2. Enter your registered email address – please note that you must also have access to the inbox to verify the password reset</p>
        <p>3. Enter your NEW password which must be 8 characters long and contain 1 x number, 1 x upper and 1 x lower case letter – please ensure that the password criteria is met, it should come up as strong. Your old password will not work</p>
        <p>4. Click submit</p>
        <p>5. Go to your email account inbox, open the email from JTI Engage and click on the link. Your password is now reset and you are re-registered.</p>
        <p>6. You can now log in!</p>
        <p>7. Whilst the web address is the same www.jtiengage.com your favourites link may not work – so please re-enter the web address and re-save to your favourites</p>
        
        <div className="buttons">
          <button className="button_style-1 fontsize19" onClick={(e)=>closeHandler(e)}>Close</button>
        </div>
      </React.Fragment>
    )
  }
}

StepGuide.propTypes = {
  showLink: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default StepGuide