import Container from 'Components/Container'

class TermsConditionsPromo extends React.Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const { history } = this.props;
    
    return (
      <Container history={ history } type={ 1 }>
        <div className="page page-terms page-default">
          
          <h3 className="page_title">Terms and Conditions</h3>
          
          <ol>
            <li>
              <p>This is a free competition with no direct or indirect cost of entry. </p>
            </li>
            
            <li>
              <p>This competition is open only to tobacco retailers located in Ireland. Participants must be aged 18 years or over. This competition is not open to members of the general public, employees of the Company and their families, the Company’s agencies or their employees and families or anyone else professionally associated with the competition. </p>
            </li>
            
            <li>
              <p>Participants should ensure that they have the permission of their business proprietors to participate in this competition. </p>
            </li>
            
            <li>
              <p>Prizes are described in the competition material. Prizes will be awarded by the Company to the retailer who is successful in the prize draw. No purchase necessary. </p>
            </li>
            
            <li>
              <p>By entering this competition all participants will be deemed to have accepted and be bound by these terms and conditions and any other competition requirements set out in the competition materials. </p>
            </li>
            
            <li>
              <p>Prize winners may be required to enter into publicity as a result of this competition. </p>
            </li>
            
            <li>
              <p>The prize of three Amazon Echo Spot are non-refundable and not exchangeable and must be redeemed by Friday 21 December 2018. There is no cash alternative. </p>
            </li>
            
            <li>
              <p>Under no circumstances will the Company (or its parent, subsidiaries and affiliated companies) be liable directly or indirectly (unless such liability cannot be lawfully excluded) for any losses, damages, costs or expenses arising from or in any way connected with any errors, defects, interruptions, malfunctions or delays in the competition or any prize connected with the competition. </p>
            </li>
            
            <li>
              <p>By entering into this competition, participants agree that the Company, and any third parties acting on its behalf, may process any personal data submitted by the participants (such as name, address, email address, telephone numbers and photographs) solely for the purpose of running this competition. Except as described, any personal data provided will not be disclosed to any third parties outside the EU unless such third parties are domiciled in countries considered under EU law to provide an adequate level of protection for personal data transferred from the EU. Your personal data shall be retained for 1 week after the closing date. You have certain rights under data protection legislation in respect of your data, such as the right to access a copy of your personal data, a right to rectify any inaccuracies, a right to erasure of your personal data, right of data portability and to restrict its processing. These rights only apply in specified circumstances and are subject to exemptions set out in data protection legislation. Please contact us for further information should you wish to exercise any of these rights. You may complain to the Data Protection Commissioner in the event you have a complaint but we would ask that you refer any complaints to us in the first instance for investigation.</p>
            </li>
            
            <li>
              <p>The decision of the Company on all matters pertaining to the competition and the awarding of prizes is final and no correspondence will be entered into. </p>
            </li>
            
            <li>
              <p>Any tax implications from the winning of the prize will be the responsibility of the winner. </p>
            </li>
            
            <li>
              <p>These Terms and Conditions shall be governed by and construed in accordance with Irish Law and subject to the exclusive jurisdiction of the Irish Courts. </p>
            </li>
          </ol>
          
          <p>Company: JTI Ireland Limited, 1st Floor, 4-6 Riverwalk, Citywest Business Campus, Dublin 24.</p>
          
        </div>
      </Container>
    )
  }
  
  componentDidMount() {}
}

export default TermsConditionsPromo