import axios from '../index'

export const register = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/RetailerRegister', data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response))
  })
}

export const contactUs = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/ContactUs/Submit', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response))
  })
}

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/ResetPassword', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response))
  })
}

export const verifyEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/ConfirmEmail', data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response))
  })
}

export const resendEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/ResendVerification', data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response))
  })
}

export const forgottenPassword = (data) => {
 
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/ForgotPassword', data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response))
  })
}

