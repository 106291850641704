import React, { useState } from 'react'
import PropTypes from 'prop-types'

const TextInput = ({
  label,
  errortext,
  type,
  className,
  children,
  ...props
}) => {
  const [show, setText] = useState(false)

  const toggle = (e) => {
    e.preventDefault()
    setText(!show)
  }

  const inputType = () => {
    switch (type) {
      case 'password':
        return show ? 'text' : 'password'
      default:
        return type
    }
  }

  return (
    <div
      className={`bw-text-input ${className || ''} ${
        errortext ? 'bw-text-input--error' : ''
      }`}
    >
      <label>
        <span>{label}</span>{' '}
        <span className="bw-inline-block bw-text-right">{children}</span>
      </label>
      <div className="relative">
        <input {...props} type={inputType()} />
        {type === 'password' ? (
          <a className="toggle" href="#" onClick={toggle}>
            {show ? 'Hide' : 'Show'}
          </a>
        ) : null}
      </div>
      {errortext ? <div className="bw-text-12 bw-pt-2">{errortext}</div> : null}
    </div>
  )
}

// Set default props
TextInput.defaultProps = {
  type: 'text',
  label: 'Label',
  errortext: null,
}

//PropTypes
TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  errortext: PropTypes.string,
}

export default TextInput
