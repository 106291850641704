import PropTypes from 'prop-types'
import qs from 'query-string'

import timer from 'Functions/timer'

class TriangledBackBlock extends React.Component {
  
  constructor(props) {
    super(props);
  
    this.totalFrames = 5;
    this.counter = 0;
    this.interval = 30;
    
    this.state = {
      frame: 1
    }
  }
  
  render() {
    const { index } = this.props;
    const { frame } = this.state;
    
    return (
      <div className={ `triangled-block triangled-block-${index + 1} triangled-frame-${index * this.totalFrames + frame}` }></div>
    )
  }
  
  update() {
    const { index, endHandler } = this.props;
    
    if(++this.counter % this.interval === 0) {
      this.counter = 0;
      if(this.state.frame >= this.totalFrames) {
        timer.remove(`triangled_block_${index}`);
        endHandler();
      } else {
        this.setState({ frame: ++this.state.frame });
      }
    }
  }
  
  componentWillMount() {
    //TEST script for adjusting animation speed
    /*const params = qs.parse(window.location.href.split('?')[1]);
    if(params.interval) {
      this.interval = +params.interval;
    }*/
  }
  
  componentDidMount() {
    const { index } = this.props;
    timer.register(`triangled_block_${index}`, () => { this.update() });
  }
  
  componentWillUnmount() {
    const { index } = this.props;
    timer.remove(`triangled_block_${index}`);
  }
  
}

TriangledBackBlock.propTypes = {
  index: PropTypes.number.isRequired,
  endHandler: PropTypes.func.isRequired,
}

export default TriangledBackBlock