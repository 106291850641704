class TextArea extends React.Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const {
      input, label, placeholder, rows,
      meta: { touched, error }
    } = this.props;
    
    return (
      <div className="form-field form-field-textarea">
        <label>{ label }</label>
        <textarea { ...input } placeholder={ placeholder } type="text" rows={ rows }/>
        { 
          touched && error && <span className="error_message">{ error }</span> 
        }
      </div>  
    )
  }
}

export default TextArea