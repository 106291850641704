import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Select = (props) => {
  const {
    selectedItem,
    dataSelected,
    disabled,
    name,
    label,
    placeholder,
    errortext,
    className,
  } = props

  const [state, setState] = useState({
    toggleOpen: false,
    selectedItem: selectedItem,
    dataModel: dataSelected,
    initialState: placeholder,
  })

  const elementRef = useRef()

  const handleClickOutside = () => {
    setState((prevState) => ({
      ...prevState,
      toggleOpen: false,
    }))
  }

  const handleClick = (e) => {
    if (elementRef.current && elementRef.current.contains(e.target)) {
      // inside click
      return
    }
    handleClickOutside()
  }

  const toggleList = () => {
    if (disabled) return
    setState((prevState) => ({
      ...prevState,
      toggleOpen: !prevState.toggleOpen,
    }))
  }

  const handleItemSelected = (selectedVal) => {
    props.setData(selectedVal)
    toggleList()
  }

  useEffect(() => {
    // componentDidMount
    document.addEventListener('click', handleClick, false)

    return () => {
      // componentWillUnmount
      document.removeEventListener('click', handleClick, false)
    }
  })

  const ArrowIcon = () => (
    <svg
      viewBox="0 0 11 6"
      className={`bw-dropdown-arrow bw-pointer-events-none ${
        state.toggleOpen ? 'bw-invert-arrow' : ''
      } `}
      onClick={() => toggleList()}
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="chcDropdownArrow"
          transform="translate(-719.000000, -953.000000)"
          fillRule="nonzero"
        >
          <g transform="translate(520.000000, 795.000000)">
            <g transform="translate(0.000000, 142.000000)">
              <g transform="translate(204.909091, 18.681818) rotate(90.000000) translate(-204.909091, -18.681818) translate(201.409091, 12.681818)">
                <path
                  d="M1.18145032,2.29532917 C0.898481345,1.98666289 0.870184448,1.49279683 1.15315342,1.18413054 C1.4078255,0.875464249 1.88887276,0.84459762 2.17184173,1.15326391 L6.33148566,5.44372531 C6.64275153,5.7523916 6.64275153,6.24625766 6.33148566,6.58579058 L2.17184173,10.876252 C1.88887276,11.1540516 1.4078255,11.1540516 1.15315342,10.8145187 C0.870184448,10.5058524 0.898481345,10.0119864 1.18145032,9.73418672 L4.8034532,5.99932463 L1.18145032,2.29532917 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return (
    <div
      className={`bw-select-dropdown ${
        state.toggleOpen ? 'bw-select-dropdown--open' : ''
      } ${errortext ? 'bw-select-dropdown--error' : ''} ${className}`}
    >
      {label && <label>{label}</label>}
      <div className="relative">
        <div
          ref={elementRef}
          className={`bw-dropdown-selected relative`}
          onClick={() => toggleList()}
        >
          {dataSelected ? dataSelected.label : state.initialState}
          <ArrowIcon />
        </div>

        <select
          className="bw-hidden"
          name={name}
          onChange={() => handleItemSelected(item)}
          value={!dataSelected ? state.initialState : ''}
        >
          {props.selectedItem.map((item, index) => (
            <option key={`select-${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <ul
          className={`bw-dropdown-items  ${
            state.toggleOpen ? '' : 'bw-hidden'
          }`}
        >
          {props.selectedItem.map((item, index) => (
            <li
              className={
                dataSelected && dataSelected.value === item.value
                  ? 'selected'
                  : null
              }
              key={index}
              onClick={() => handleItemSelected(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
      {errortext ? <div className="bw-text-12 bw-pt-2">{errortext}</div> : null}
    </div>
  )
}

// Set default props
Select.defaultProps = {
  name: 'select',
  label: '',
  placeholder: 'Please Select',
  errortext: null,
  className: '',
}

//PropTypes
Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errortext: PropTypes.string,
  className: PropTypes.string,
}

export default Select
