import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { cancelOrderREQUEST } from '@Requests/history'
import { toggleLoader, openPopup, hidePopup } from 'Redux/actions.app'

class CancelOrder extends React.Component {
  
  render() {
    const { closeHandler } = this.props;
    
    return (
      <div className="buttons">
        <button className="button_style-1 fontsize19" onClick={(e)=>closeHandler(e)}>No</button>
        <button className="button_style-2 fontsize19" onClick={(e)=>this.cancel(e)}>Yes</button>
      </div>
    )
  }
  
  cancel(e) {
    const { dispatch, history, orderId } = this.props;
    
    dispatch(toggleLoader(true));
    
    cancelOrderREQUEST(orderId, (result, error) => {
      
      dispatch(toggleLoader(false));
      
      if(result) {
        
        history.push('/my-orders');
      
      } else {
        
        dispatch(openPopup(
          'alert',
          error,
          ''
        ));
      }
    });
    
    dispatch(hidePopup());
  }
}

CancelOrder.propTypes = {
  history: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
}

export default connect()(CancelOrder)