import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Formik} from 'formik'
import {GA} from '../../script/functions/ga'
import {generalError} from '../../utils/constants'
import {contactUs} from '../../axios/public'
import {getContactSubjects} from '../../axios/public/content'
import {RecaptchaSiteKey} from './Login/utils'
import {contactUsForm} from '../../utils/forms/public'
import TextInput from '../../components/form/TextInput'
import Select from '../../components/form/Select'
import Button from '../../components/form/Button'
import Textarea from '../../components/form/Textarea'
import ReCAPTCHA from "react-google-recaptcha"

const ContactUs = (props) => {
  const [subjects, setSubjects] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const recaptchaRef = useRef(null)

  const handleOnchangeRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync();
  }

  useEffect(() => {
    getContactSubjects().then((res) => {
      if (res.Subjects) {
        setSubjects(() => res.Subjects.map((el) => ({label: el, value: el})))
      }
    })
  }, [])

  const onContactUs = async (values, {setSubmitting}) => {
    GA('Contact Us', 'Button :: Submit query')

    handleOnchangeRecaptcha()
      .then((cToken) => {
        values.recaptchaResponse = cToken
        contactUs({...values, Subject: values.Subject.value})
      })
      .then((result) => {
        setError(false)
        setSubmitted(true)
      })
      .catch((err) => {
        const message = err?.data?.Message || generalError
        setError(message)
        console.log(err)
        recaptchaRef.current.reset()
      })
    setSubmitting(false)
  }

  return (
    <>
      <h3 className={`bw-h3 bw-mb-${submitted ? 5 : 8}`}>
        {submitted ? (
          <>
            Thank you for <br/>
            your enquiry
          </>
        ) : (
          'Contact us'
        )}
      </h3>
      {submitted ? (
        <p className="bw-font-normal bw-text-14 bw-mb-4 bw-pr-8 bw-leading-snug">
          One of our team members will be in touch shortly.
        </p>
      ) : (
        <>
          <p className="bw-font-normal bw-text-15 bw-mb-5 bw-pr-1 bw-leading-snug">
            If you are already a JTI Engage online customer, welcome to the new
            look website. To enter the site, please enter the email you
            registered with.
          </p>
          <p className="bw-font-normal bw-text-13 bw-mb-2 bw-leading-snug bw-text-right">
            * Mandatory fields
          </p>
        </>
      )}
      {!submitted && error && (
        <p className="bw-text-red bw-text-15 bw-mb-2">{error}</p>
      )}
      {submitted ? (
        <a href="/login" className="bw-btn-link">
          Go to Log In
        </a>
      ) : (
        <>
          <Formik {...contactUsForm} onSubmit={onContactUs}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                  label="Account Email Address *"
                  errortext={errors.Email && touched.Email && errors.Email}
                />
                <TextInput
                  name="JtiAccountNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.JtiAccountNumber}
                  label="JTI Account Number"
                  errortext={
                    errors.JtiAccountNumber &&
                    touched.JtiAccountNumber &&
                    errors.JtiAccountNumber
                  }
                />
                <Select
                  label="Subject of your enquiry *"
                  dataSelected={values.Subject}
                  selectedItem={subjects}
                  setData={(newVal) => setFieldValue('Subject', newVal)}
                  errortext={
                    errors.Subject && touched.Subject && errors.Subject
                  }
                />
                <Textarea
                  label="Message *"
                  name="Message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Message}
                  errortext={
                    errors.Message && touched.Message && errors.Message
                  }
                  placeholder="Please enter your enquiry"
                />
                <ReCAPTCHA
                  sitekey={RecaptchaSiteKey}
                  ref={recaptchaRef}
                  size='invisible'
                />
                <Button
                  onClick={handleOnchangeRecaptcha}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  label="Submit Enquiry"
                  className="bw-mb-4"
                />
              </form>
            )}
          </Formik>
          <p className="bw-text-14 bw-leading-snug">
            You can contact us by phone on
          </p>
          <a href="tel:01 404 0240" className="bw-text-20 bw-leading-snug">
            01 404 0240
          </a>
        </>
      )}
    </>
  )
}

export default connect()(ContactUs)
