import axios from '../index'

export const getHomeAssets = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Cms/HomeAssets')
      .then((res) => {
        resolve(res?.data?.HomeAssets || {})
      })
      .catch((err) => reject(err))
  })
}
