import { connect } from 'react-redux'

class Loader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { display } = this.props

    return (
      <div className={'loading animated ' + (display ? 'fadeIn' : 'hidden')}>
        <div className="triangle_loader bw-text-center">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#00B2A9"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
          <span className="align-center nowrap">
            <b className="bw-text-aqua">Loading ...</b>
          </span>
        </div>
      </div>
    )
  }
}

function stateMapping(state) {
  return {
    display: state.app.display,
  }
}

export default connect(stateMapping)(Loader)
