import { combineReducers } from 'redux'

import {
  TOGGLE_LOADER,
  SHOW_ERROR,
  OPEN_POPUP,
  CLOSE_POPUP,
  HIDE_POPUP,
  TOGGLE_BACK_TOP,
  TOGGLE_NAV_MENU,
  SET_TRACKANDTRACE,
  SET_USER_ACCOUNTS,
  SET_CURRENT_ACCOUNT,
  DRAWER_TYPES,
  SET_CART,
  SET_ORDER_RESULT,
  SET_CURRENT_ORDERS,
  SET_SUGGESTED_PRODUCTS,
  SET_ALL_PRODUCTS,
  SET_PRODUCTS_BRANDS,
} from './actions.app'

function display(state = false, action) {
  switch (action.type) {
    case TOGGLE_LOADER:
      return action.display

    default:
      return state
  }
}

function error(state = '', action) {
  switch (action.type) {
    case SHOW_ERROR:
      return action.error

    default:
      return state
  }
}

function popup(state = { show: false }, action) {
  switch (action.type) {
    case OPEN_POPUP:
      return Object.assign({}, state, {
        show: true,
        open: true,
        title: action.title,
        desc: action.desc,
        section: action.section,
        array: action.array,
      })

    case CLOSE_POPUP:
      return Object.assign({}, state, {
        open: false,
      })

    case HIDE_POPUP:
      return Object.assign({}, state, {
        show: false,
        open: false,
      })

    default:
      return state
  }
}

function backTopButton(state = false, action) {
  switch (action.type) {
    case TOGGLE_BACK_TOP:
      return action.show

    default:
      return state
  }
}

function navMenu(state = false, action) {
  switch (action.type) {
    case TOGGLE_NAV_MENU:
      return action.show

    default:
      return state
  }
}

function trackAndTrace(state = 0, action) {
  switch (action.type) {
    case SET_TRACKANDTRACE:
      return action.show

    default:
      return state
  }
}

function userAccounts(state = [], action) {
  switch (action.type) {
    case SET_USER_ACCOUNTS:
      return action.accounts

    default:
      return state
  }
}

function currentAccount(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_ACCOUNT:
      return action.account

    default:
      return state
  }
}

function drawer(state = { type: DRAWER_TYPES.store, isOpen: false }, action) {
  switch (action.type) {
    case DRAWER_TYPES.store:
    case DRAWER_TYPES.filter:
    case DRAWER_TYPES.filterProducts:
      return action

    default:
      return state
  }
}

function cart(state = {}, action) {
  switch (action.type) {
    case SET_CART:
      return action.data

    default:
      return state
  }
}

function orderResult(state = {}, action) {
  switch (action.type) {
    case SET_ORDER_RESULT:
      return action.data
    default:
      return state
  }
}

function currentOrders(state = [], action) {
  switch (action.type) {
    case SET_CURRENT_ORDERS:
      return action.orders

    default:
      return state
  }
}

function suggestedProducts(state = {}, action) {
  switch (action.type) {
    case SET_SUGGESTED_PRODUCTS:
      return action.products

    default:
      return state
  }
}

function allProducts(state = [], action) {
  switch (action.type) {
    case SET_ALL_PRODUCTS:
      return action.products
    default:
      return state
  }
}

function productsBrands(state = [], action) {
  switch (action.type) {
    case SET_PRODUCTS_BRANDS:
      return action.brands
    default:
      return state
  }
}

export default combineReducers({
  display,
  error,
  popup,
  backTopButton,
  navMenu,
  trackAndTrace,
  userAccounts,
  currentAccount,
  drawer,
  cart,
  orderResult,
  currentOrders,
  suggestedProducts,
  allProducts,
  productsBrands,
})
