import React from 'react'

const Next = ({onClick}) => {
    return (
        <button type="button" className="slick-arrow slick-next" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.725" height="15.555" viewBox="0 0 9.725 15.555">
            <g id="ARROW_L" data-name="ARROW L" transform="translate(9.725 15.555) rotate(180)">
                <path id="Union_3" data-name="Union 3" d="M23294.447,20463.4l-1.947-1.947,7.777-7.777,1.947,1.947-5.834,5.834,5.834,5.832-1.947,1.941Z" transform="translate(-23292.5 -20453.674)" fill="#fff"/>
            </g>
            </svg>
        </button>
    )
}


export default Next
