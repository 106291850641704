import React from "react";
import PropTypes from "prop-types";
import Widget from "./Widget";

function Widgets({ widgets }) {
  return (
    <div className="bw-private-widgets" id="bw-private-widgets">
      {widgets.length > 0 && (
        <div
          className={`
        bw-container bw-private-widgets--container 
        ${widgets.length === 1 ? "widgets-1-item" : ""}
        ${widgets.length === 3 ? "widgets-3-items" : ""}
        ${widgets.length === 4 ? "widgets-4-items" : ""}
        `}
        >
          {widgets.map((widget, index) => (
            <Widget key={index} {...widget} />
          ))}
        </div>
      )}
    </div>
  );
}

Widgets.propTypes = {
  widgets: PropTypes.array.isRequired,
};
Widgets.defaultProps = {
  widgets: [],
};
export default Widgets;
