import { connect } from 'react-redux'

class EditNotice extends React.Component {
  
  render() {
    const { isOrderEditing: { id } } = this.props;
    
    return (
      <div className="edit_notice_board animated duration08 fadeIn">
        <div>Currently editing order ({ id }).</div>
      </div>   
    )
  }
}

function stateMapping(state) {
  return {
    isOrderEditing: state.prod.isOrderEditing,
  }
}

export default connect(stateMapping)(EditNotice)
