import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
  
class ReviewOrder extends React.Component {
  
  render() {
    const { closeHandler, type, outers, unavailable } = this.props;
    
    return (
      <React.Fragment>
        {
          type === 'checkout' && (
            <h3 className="section_title">Your previous order has been added to your cart.</h3>     
          )
        }
        {
          type === 'edit' && (
            <h3 className="section_title">{ outers ? 'You are now editing the pending order.' : 'Your items are currently unavailable.' }</h3>
          )
        }
        
        {
          type === 'checkout' && (
            <p>{ outers } outers were added to your cart successfully. Please note the prices may have changed since your previous orders. Please check before you order.</p>
          )
        }
        
        {/*
          type === 'edit' && outers > 0 && (
            <p></p>
          )
        */}
        
        {
          unavailable.length > 0 && ( 
            <React.Fragment>
              <br/>
              <p className="alert">Sorry, but the following products were unavailable this time:</p>
              {
                unavailable.map((prod) =>
                  <p key={ prod.SKU } className="alert">{ prod.Product }</p>
                )
              }
            </React.Fragment>
          )
        }
        
        <div className="buttons">
          {
            (type === 'edit' || (type === 'checkout' && outers > 0)) && (
              <Link className="button_style-2 fontsize19" to="/cart">View cart</Link>
            )
          }
          {
            type === 'checkout' && (
              <Link className="button_style-2 fontsize19" to="/place-order">View products</Link>
            )
          }
          {
            (type === 'checkout' || outers <= 0) && (
              <button className="button_style-1 fontsize19" onClick={(e)=>closeHandler(e)}>Close</button>
            )
          }
        </div>
      </React.Fragment>
      
    )
  }
}

ReviewOrder.propTypes = {
  type: PropTypes.string.isRequired,
  outers: PropTypes.number.isRequired,
  unavailable: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default connect()(ReviewOrder)