import React from 'react'
import PropTypes from 'prop-types'

const Steps = (props) => {
  const { steps, currentStep, className } = props
  return (
    <div className={`bw-steps ${className}`}>
      <ul className="bw-steps-list">
        {steps.map((step, index) => (
          <li
            key={index}
            className={`bw-step ${index <= currentStep ? 'active' : ''}`}
          >
            {index > 0 && index < steps.length && (
              <span className="bw-step-separator">/</span>
            )}
            {step}
          </li>
        ))}
      </ul>
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number,
  className: PropTypes.string,
}

Steps.defaultProps = {
  steps: [],
  currentStep: 0,
  className: '',
}

export default Steps
