export default function PrizesToBeWon({ className = '' }) {
  return (
    <div className={`prizes-to-be-won-label ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="31.696" height="32" viewBox="0 0 31.696 32">
        <g id="Group_5878" data-name="Group 5878" transform="translate(-450.415 -1005)">
          <rect id="Rectangle_4432" data-name="Rectangle 4432" width="31.696" height="32" rx="15.848" transform="translate(450.415 1005)" fill="#e8a927"/>
          <g id="Group_5899" data-name="Group 5899" transform="translate(453.915 1009.165)">
            <path id="Path_2078" data-name="Path 2078" d="M16.828,8.432a2.156,2.156,0,0,0,.259-.156,1.405,1.405,0,0,0,.459-1.926,1.4,1.4,0,0,0-1.968-.213A4.726,4.726,0,0,0,14.19,8.43ZM8.7,11.482H19.389v5.435a.917.917,0,0,1-.917.917H9.617a.917.917,0,0,1-.917-.917Z" transform="translate(-1.695 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <rect id="Rectangle_4433" data-name="Rectangle 4433" width="2.683" height="6.353" transform="translate(11.006 11.482)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_2079" data-name="Path 2079" d="M16.727,8.432a1.965,1.965,0,0,1-.257-.156,1.4,1.4,0,0,1-.459-1.926,1.405,1.405,0,0,1,1.97-.213A4.726,4.726,0,0,1,19.369,8.43Z" transform="translate(-7.163 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <rect id="Rectangle_4434" data-name="Rectangle 4434" width="11.696" height="3.05" rx="1.525" transform="translate(6.5 8.432)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <rect id="Rectangle_4435" data-name="Rectangle 4435" width="3.172" height="3.05" transform="translate(10.763 8.432)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>
      <span>Prizes to be won</span>
    </div>
  );
}