import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Radio = ({ error, children, className, ...props }) => {
  return (
    <div
      className={`bw-radio ${className || ''} ${
        error ? 'bw-radio--error' : ''
      }`}
    >
      <label className="bw-flex" htmlFor={props.id}>
        <input className="input" type="radio" {...props} />
        <span className="icon"></span>
        <span className="label">{children}</span>
      </label>
    </div>
  )
}

// Set default props
Radio.defaultProps = {
  label: 'Label',
  error: false,
}

//PropTypes
Radio.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
}

export default Radio
