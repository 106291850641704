import React, {useEffect, useState} from "react";
import {
  markSurveyAsCompleted,
  onEmailSubmit,
} from "../../../axios/private/survey";
import Checkbox from "../../../components/form/Checkbox";

export default function SurveyEnd({
                                    handleClose,
                                    surveyId,
                                    setIsLoading,
                                    handleOpenTC,
                                    hasProvidedEmail,
                                    setHasProvidedEmail,
                                    emailText = '',
                                  }) {
  const [hasRequestBeenMade, setHasRequestBeenMade] = useState(false);
  const [email, setEmail] = useState("");
  const [subscribeCheckboxState, setSubscribeCheckboxState] = useState(true)

  useEffect(() => {
    if (!hasRequestBeenMade) {
      setIsLoading(true);
      markSurveyAsCompleted(surveyId);
      setHasRequestBeenMade(true);
      setIsLoading(false);
    }
  }, [hasRequestBeenMade, setIsLoading, surveyId]);

  const handleEmailSubmit = async () => {
    try {
      setIsLoading(true);
      await onEmailSubmit(surveyId, email, subscribeCheckboxState);
      setHasProvidedEmail(true);
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setSubscribeCheckboxState(!subscribeCheckboxState)
}

  return (
    <div className={"survey-end"}>
      <p className={"survey-end__message"}>
        Thank you, for your response this will help us build the JTI Engage of
        the future.
      </p>
      {!hasProvidedEmail && (
        <>
          <p className={"survey-end__message"}>
            {emailText}
          </p>
          <input
            className={"survey-end__email"}
            type={"email"}
            placeholder={"Enter your email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Checkbox
              name="confirm"
              onChange={handleCheckboxChange}
              className="bw-mt-2"
            >
            <span className='bw-text-12 bw-text-gray-800'>
              If you do not wish to receive promotional emails, newsletters, or updates from JTI, please tick the box to opt out of all email marketing communications.</span>
            </Checkbox>
        </>
      )}
      <button
        onClick={hasProvidedEmail ? handleClose : handleEmailSubmit}
        className={`survey-end__close-btn ${
          !hasProvidedEmail && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
            ? "survey-end__close-btn--disabled"
            : ""
        }`}
        disabled={
          !hasProvidedEmail && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        }
      >
        {hasProvidedEmail ? "Close" : "Submit"}
      </button>
      <button
        className={"survey__continue__body__tc-btn"}
        onClick={handleOpenTC}
      >
        Terms and Conditions
      </button>
    </div>
  );
}