import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "../form/Button";
import ProductDefaultImg from "../../images/Loading.svg";
import { Link } from "react-router-dom";
import { toCurrencyString } from "../../utils/functions/currency";
import { getAlternativeProduct, getPeopleAlsoBought } from "../../axios/private/products";
import PrizesToBeWon from "./SocialProofing/PrizesToBeWon";
import FastestGrowing from "./SocialProofing/FastestGrowing";
import HasBeenBoughtBy from "./SocialProofing/HasBeenBoughtBy";
import DiscountLabel from "./SocialProofing/DiscountLabel";
import ColorLabel from "./SocialProofing/ColorLabel";
import LowStockLabel from "./SocialProofing/LowStockLabel";
import OutOfStockLabel from "./SocialProofing/OutOfStockLabel";
import Checkbox from "../form/Checkbox";
import { ReactComponent as CloseIcon } from "../../icons/Cross.svg";
import axios from "axios";
import {
  GAEcommerce,
  getGASocialProofingLabels,
  getIsSocialProofed,
} from "../../script/functions/ga";

import { useSelector } from "react-redux";
import Preloader from "../../script/components/Preloader"

const ProductCard = forwardRef(function ProductCard(props, ref) {
  const {
    children,
    type,
    className,
    productData,
    quantity,
    loading,
    error,
    onAddToCart,
    onRemoveProduct,
    onIncrement,
    onDecrement,
    onAlternativeChange,
    onPeopleAlsoBoughtChange,
    isPeopleAlsoBought,
  } = props;
  const {
    OuterImage,
    PriceOuter,
    MinimumOrder,
    MinimumOrderIncremental,
    Product,
    SKU,
    OutOfStock,
    HasPrizes,
    IsFastestGrowingProduct,
    Discount,
    HasBeenBoughtByText,
    LowStock,
    IsBestSeller,
    NewArrival,
    Category
  } = productData;

  const [state, setState] = useState({
    selectedQuantity: quantity,
    showRemove: false
  });
  const [showModal, setShowModal] = useState(false);
  const [hasPeopleAlsoBought, setHasPeopleAlsoBought] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const accountId = useSelector(
    (state) => state.app.currentAccount.JtiAccountId,
  );

  useEffect(() => {
    // set new quantity when prop changed
    setState((old) => ({ ...old, selectedQuantity: quantity }));
  }, [quantity, SKU]);

  useEffect(() => {
    if (!error) return;
    // set old quantity when the update failed
    setState((old) => ({ ...old, selectedQuantity: quantity }));
  }, [error]);

  useEffect(() => {
    getAlternativeProd();
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  }, [showModal]);

  const getAlternativeProd = () => {
    if (!(OutOfStock && type === "place-order")) return false;

    getAlternativeProduct(SKU)
      .then((product) => {
        if (product) onAlternativeChange(SKU, product);
      })
      .catch((err) => err);
  };

  const getPeopleAlsoBoughtProd = () => {
    getPeopleAlsoBought(SKU)
      .then((product) => {
        if (product) {
          setHasPeopleAlsoBought(true);
          onPeopleAlsoBoughtChange(SKU, product);
        }
      })
      .catch((err) => err);
  };

  const onQuantityChange = (value, operator, event) => {
    if (
      !/^[0-9]*$/.test(value) ||
      (state.showRemove && type === "before-go") ||
      OutOfStock
    )
      return false;
    const minInc = Number(MinimumOrderIncremental) || 1;

    let gaEventName;
    switch (operator) {
      case "-":
        value > 0 ? (value -= minInc) : null;
        type !== "place-order" ? onDecrement(productData, minInc) : null;
        gaEventName = "remove_from_cart";
        break;
      case "+":
        value < 999 ? (value += minInc) : null;
        type !== "place-order" ? onIncrement(productData, minInc) : null;
        gaEventName = "add_to_cart";
        break;
      default:
        value = Number(value);
        break;
    }
    var suggestedProduct = "";
    if (event && event.closest(".place-an-order")) {
      suggestedProduct = "suggested";
    } else {
      suggestedProduct = "not_suggested";
    }

    if (event && !event.closest(".bw-before-go-products")) {
      GAEcommerce(
        gaEventName,
        accountId,
        productData.PriceOuter,
        productData.PackBarCode,
        productData.Product,
        productData.Brand,
        productData.Category,
        suggestedProduct,
        getIsSocialProofed(productData),
        "place_order",
        value,
        "place_order",
        getGASocialProofingLabels(productData),
      );
    }
    setState((old) => ({ ...old, selectedQuantity: value }));
  };

  const onRemove = (e) => {
    e.preventDefault();

    var addedQuantity = e.target
      .closest(".bw-product-actions")
      .querySelector(".bw-product-input").textContent;
    var suggestedProduct = "";

    if (e.target.closest(".place-an-order")) {
      suggestedProduct = "suggested";
    } else {
      suggestedProduct = "not_suggested";
    }

    GAEcommerce(
      "remove_from_cart",
      accountId,
      productData.PriceOuter,
      productData.PackBarCode,
      productData.Product,
      productData.Brand,
      productData.Category,
      suggestedProduct,
      getIsSocialProofed(productData),
      "before_you_go",
      addedQuantity,
      "before_you_go",
      getGASocialProofingLabels(productData),
    );
    onRemoveProduct(productData, state.selectedQuantity);
    setState((old) => ({ ...old, showRemove: false }));
  };

  const addToCartClick = (e) => {
    // when click add all, add only products not already added
    var addedQuantity = e.target
      .closest(".bw-product-actions")
      .querySelector(".bw-product-input").value;

    var placeFromAdd = "";
    var suggestedProduct = "";

    if (e.target.closest(".bw-before-go-products")) {
      placeFromAdd = "before_you_go";
    } else {
      placeFromAdd = "place_order";
    }

    if (e.target.closest(".place-an-order")) {
      suggestedProduct = "suggested";
    } else {
      suggestedProduct = "not_suggested";
    }

    GAEcommerce(
      "add_to_cart",
      accountId,
      productData.PriceOuter,
      productData.PackBarCode,
      productData.Product,
      productData.Brand,
      productData.Category,
      suggestedProduct,
      getIsSocialProofed(productData),
      placeFromAdd,
      addedQuantity,
      "before_you_go",
      getGASocialProofingLabels(productData)
    );

    if (state.showRemove) return;
    if (type !== "before-go") {
      setState((old) => ({ ...old, showRemove: true }));
    }
    return onAddToCart(productData, state.selectedQuantity);
  };

  const placeOrderChange = async () => {
    // getPeopleAlsoBoughtProd();
    if (quantity && state.selectedQuantity === quantity) return;
    const difference = state.selectedQuantity - quantity;
    if (difference > 0) {
      onIncrement(productData, difference);
      GAEcommerce(
        "add_to_cart",
        accountId,
        productData.PriceOuter,
        productData.PackBarCode,
        productData.Product,
        productData.Brand,
        productData.Category,
        "suggested",
        getIsSocialProofed(productData),
        "place_order",
        state.selectedQuantity,
        "place_order",
        getGASocialProofingLabels(productData),
      );
    } else {
      onDecrement(productData, Math.abs(difference));
    }
  };

  return (
    <>
      {isVisible && (
        <div
          className={`bw-product-container ${
            OutOfStock ? "bw-product-out-stock" : ""
          } ${isPeopleAlsoBought ? "bw-people-also-bought" : ""} ${className}`}
          ref={typeof ref === "function" ? ref(addToCartClick) : ref}
        >
          {loading && <div className="bw-absolute bw-grid bw-place-items-center bw-h-full bw-w-full">
            <Preloader />
          </div>}
          {isPeopleAlsoBought && (
            <div className={"bw-people-also-bought__triangle"} />
          )}
          {isPeopleAlsoBought && (
            <button
              className={"bw-people-also-bought__close"}
              onClick={() => {
                setIsVisible(false);
              }}
            >
              <CloseIcon />
            </button>
          )}
          {isPeopleAlsoBought && (
            <h5 className={"bw-people-also-bought__subtitle"}>
              People also bought this product:
            </h5>
          )}

          <div className={`bw-product ${loading ? "bw-opacity-60" : ""}`}>
            <div className="bw-product-image-container">
              <img
                src={OuterImage || ProductDefaultImg}
                alt={Product}
                className="bw-product-image"
              />
              {OutOfStock ? (
                <div className="bw-out-stock-overlay">
                  <p>THIS PRODUCT IS OUT OF STOCK</p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="bw-product-data bw-flex bw-flex-col">
              <div className="bw-flex bw-justify-between bw-flex-col sm:bw-flex-row">
                <div className="bw-flex bw-items-center bw-mb-2 bw-product-title-container">
                  <p className="bw-product-name">{Product}</p>
                </div>
                {type === "cart" ? (
                  <div className="bw-product-single--price">
                    <p className="bw-font-medium bw-text-18">
                      {toCurrencyString(PriceOuter * quantity)}
                    </p>
                    <p className="bw-text-12 bw-ml-4 sm:bw-ml-0">
                      Outer Price {toCurrencyString(PriceOuter)}
                    </p>
                  </div>
                ) : null}
              </div>

              <div
                className={`bw-product-labels ${
                  type === "cart" ? "bw-product-labels--cart" : ""
                }`}
              >
                {type === "cart" ? (
                  ""
                ) : (
                  <span className="bw-product-price">
                    {toCurrencyString(PriceOuter)}
                  </span>
                )}
                {HasPrizes && (
                  <PrizesToBeWon className="bw-product-label bw-mr-2" />
                )}
                {LowStock && (
                  <LowStockLabel className="bw-product-label bw-mr-2" />
                )}
                {IsFastestGrowingProduct && (
                  <FastestGrowing className="bw-product-label" />
                )}
                {Discount && (
                  <DiscountLabel
                    amount={Discount}
                    className="bw-product-label"
                  />
                )}
                {HasBeenBoughtByText && (
                  <HasBeenBoughtBy
                    text={HasBeenBoughtByText}
                    className="bw-product-label"
                  />
                )}
                {IsBestSeller && (
                  <ColorLabel className=" bw-product-label" color={"#E782A9"}>
                    Best seller near you
                  </ColorLabel>
                )}
                {NewArrival && (
                  <ColorLabel className=" bw-product-label" color={"#06B085"}>
                    New Arrival
                  </ColorLabel>
                )}
                {OutOfStock && (
                  <OutOfStockLabel className=" bw-product-label" />
                )}
              </div>
              <ProductInfo
                {...productData}
                isCategoryVisible={!isPeopleAlsoBought}
                categoryName={Category}
                type="desktop"
              />
              <ProductInfo
                {...productData}
                isCategoryVisible={!isPeopleAlsoBought}
                categoryName={Category}
                type="mobile"
              />
              <div className="bw-product-actions">
                <div className="bw-product-quantity-input">
                  <button
                    onClick={() =>
                      onQuantityChange(state.selectedQuantity, "-")
                    }
                    className="bw-product-minus"
                  >
                    -
                  </button>
                  {type === "cart" ||
                  (state.showRemove && type === "before-go") ? (
                    <span className="bw-product-input">
                      {state.selectedQuantity}
                    </span>
                  ) : (
                    <input
                      type="text"
                      maxLength="3"
                      value={state.selectedQuantity}
                      className="bw-product-input"
                      onChange={(e) => onQuantityChange(e.target.value)}
                    />
                  )}

                  <button
                    onClick={() =>
                      onQuantityChange(state.selectedQuantity, "+")
                    }
                    className="bw-product-plus"
                  >
                    +
                  </button>
                </div>
                {type === "place-order" ? (
                  <>
                    <Button
                      label={
                        quantity && state.selectedQuantity === quantity
                          ? "Added"
                          : quantity
                            ? "Update"
                            : "Add to cart"
                      }
                      className={
                        quantity && state.selectedQuantity !== quantity
                          ? "bw-button--black bw-mr-4"
                          : "bw-mr-4"
                      }
                      onClick={placeOrderChange}
                      disabled={!quantity && !state.selectedQuantity}
                    />
                    {OutOfStock && (
                      <Button
                        label={
                          <div className="bw-h-4 bw-flex bw-items-center bw-justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="_36051d809d5a656ac7daeaecd916a786"
                                data-name="36051d809d5a656ac7daeaecd916a786"
                                transform="translate(-70.064 -66.064)"
                              >
                                <rect
                                  id="Rectangle_1563"
                                  data-name="Rectangle 1563"
                                  width="16"
                                  height="16"
                                  transform="translate(70.064 66.064)"
                                  fill="none"
                                />
                                <path
                                  id="Path_1947"
                                  data-name="Path 1947"
                                  d="M29.137,22.951a4.173,4.173,0,1,1,8.339,0h0a8.416,8.416,0,0,0,.818,4.111.5.5,0,0,1,0,.485.462.462,0,0,1-.4.244H28.718a.462.462,0,0,1-.4-.244.5.5,0,0,1,0-.485,8.412,8.412,0,0,0,.819-4.111Zm2.311,4.839v.484a1.86,1.86,0,1,0,3.717,0v-.484m1.608-10.572a6.717,6.717,0,0,1,2.371,2.744m-11.676,0a6.717,6.717,0,0,1,2.371-2.744"
                                  transform="translate(44.69 50.547)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                            <span className="bw-ml-2">
                              Email me when back in stock
                            </span>
                          </div>
                        }
                        className={""}
                        onClick={() => setShowModal(true)}
                      />
                    )}
                  </>
                ) : type === "cart" || state.showRemove ? (
                  <Link to="" className="bw-text-14 bw-ml-2" onClick={onRemove}>
                    Remove
                  </Link>
                ) : (
                  <Button
                    label="Add to cart"
                    onClick={addToCartClick}
                    disabled={!state.selectedQuantity}
                  />
                )}
              </div>
              {error ? (
                <p className="bw-text-12 bw-mt-2 bw-text-red">{error}</p>
              ) : (
                ""
              )}
              {(MinimumOrder || MinimumOrderIncremental) &&
              !isPeopleAlsoBought ? (
                <p className="bw-text-12 bw-mt-2 bw-product--minimum">
                  {MinimumOrder ? (
                    <span>*Minimum order is {MinimumOrder} outers. </span>
                  ) : (
                    ""
                  )}
                  {MinimumOrderIncremental ? (
                    <span>
                      Incremental order is {MinimumOrderIncremental} outers
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {children && OutOfStock ? (
            <div className="bw-product-suggested">
              <p className="bw-out-stock-message">
                This product is out of stock. Why not try:
              </p>
              {children}
            </div>
          ) : (
            ""
          )}
          {children && !OutOfStock && hasPeopleAlsoBought ? children : ""}
        </div>
      )}
      {showModal && (
        <OutOfStockModal closeModal={() => setShowModal(false)} sku={SKU} />
      )}
    </>
  );
});

const numberOrString = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

ProductCard.propTypes = {
  type: PropTypes.oneOf(["cart", "before-go", "place-order"]),
  className: PropTypes.string,
  productData: PropTypes.shape({
    OuterImage: PropTypes.string,
    Product: PropTypes.string,
    PriceOuter: numberOrString,
    PackSize: numberOrString,
    PricePackRrp: numberOrString,
    PackBarCode: numberOrString,
    SocialProofing: PropTypes.object,
    MinimumOrder: PropTypes.number,
    MinimumOrderIncremental: PropTypes.number,
    SKU: PropTypes.string,
    OutOfStock: PropTypes.bool
  }),
  quantity: numberOrString,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddToCart: PropTypes.func,
  onRemoveProduct: PropTypes.func,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func
};

ProductCard.defaultProps = {
  type: "cart",
  className: "",
  productData: {
    OuterImage: ProductDefaultImg,
    Product: "Benson & Hedges Gold 20",
    PriceOuter: 106.57,
    PackSize: 20,
    PricePackRrp: 14.2,
    PackBarCode: "53993120",
    SocialProofing: {},
    MinimumOrder: 5,
    MinimumOrderIncremental: 5,
    SKU: "",
    OutOfStock: false
  },
  quantity: 5,
  loading: false,
  error: "",
  onAddToCart: () => {
  },
  onRemoveProduct: () => {
  },
  onIncrement: () => {
  },
  onDecrement: () => {
  }
};

const ProductInfo = (props) => {
  const {
    PackSize,
    PricePackRrp,
    PackBarCode,
    type,
    isCategoryVisible = true,
    categoryName
  } = props;
  const categories = {
    RMC: "Ready Made Cigarettes",
    RYO: "Roll Your Own",
    EVAP: "e-Vapour",
    NICP: "Nicotine Pouch",
    CGR: "Cigars",
    PIP: "Pipes"
  };

  return (
    <div className={`bw-product-info bw-product-info--${type} bw-items-center`}>
      {isCategoryVisible && (
        <div className="category-label bw-mr-2">
          <p>{categories[categoryName]}</p>
        </div>
      )}
      <p className="bw-product-pack-quantity">Pack Quantity {PackSize}</p>
      <p className="bw-product-rrp">RRP {toCurrencyString(PricePackRrp)}</p>
      <p className="bw-product-barcode">Barcode {PackBarCode}</p>
    </div>
  );
};

const OutOfStockModal = ({ closeModal, className, sku }) => {
  const [email, setEmail] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [subscribeCheckBoxState, setSubscribeCheckboxState] = useState(true);

  const onSubmit = () => {
    if (isEmailValid) {
      axios
        .post("api/Product/ProductAvailabilityEmail", {
          email: email,
          sku: sku,
          OptIn: subscribeCheckBoxState
        })
        .then(closeModal);
    }
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsEmailValid(validateEmail(inputValue));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckboxChange = () => {
    setSubscribeCheckboxState(!subscribeCheckBoxState);
  };

  return (
    <div onClick={closeModal} className="out-of-stock-modal">
      <div
        onClick={(e) => e.stopPropagation()}
        className={`modal-content ${className ? className : ""}`}
      >
        <button className={"out-of-stock-modal__close"} onClick={closeModal}>
          <CloseIcon />
        </button>
        <div className="bw-flex bw-flex-col">
          <h2 className="out-of-stock-modal--header">
            Notification of product availability
          </h2>
          <p>
            You will be notified when the selected product becomes available to
            be ordered. Sorry for any inconvenience during this time.
          </p>
          <p>Please enter an email below to receive a notification.</p>
          <label>
            <p>
              Email<sup>*</sup>
            </p>
            <input type="email" value={email} onChange={handleEmailChange} />
            <Checkbox
              name="confirm"
              onChange={handleCheckboxChange}
              className="bw--mt-4"
            >
              <span className="bw-text-12">
                If you do not wish to receive promotional emails, newsletters,
                or updates from JTI, please tick the box to opt out of all email
                marketing communications.
              </span>
            </Checkbox>
          </label>
          <Button
            label="Submit"
            onClick={onSubmit}
            disabled={!isEmailValid || !email}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
