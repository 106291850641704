import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import { addDays } from 'date-fns'
import { vat, orderSteps, productError } from '../../utils/constants'
import { toCurrencyString } from '../../utils/functions/currency'
import { getBeforeYouGo } from '../../axios/private/products'
import { openPopup, setOpenDrawer } from '../../script/redux/actions.app'
import {
  addCartProduct,
  removeCartProduct,
  validateCart,
  getCart,
} from '../../axios/private/cart'
import { getOrderDetails, getRecentOrder } from '../../axios/private/orders'
import {
  GA,
  GAEcommerceMultiple,
  getGASocialProofingLabels,
  getIsSocialProofed
} from "../../script/functions/ga";
import BeforeYouGo from './BeforeYouGo'
import Steps from '../../components/Steps'
import Button from '../../components/form/Button'
import TextInput from '../../components/form/TextInput'
import ProductCard from '../../components/Product/Card'
import ProductInfo from '../../components/Product/Info'
import Tooltip from '../../components/Tooltip'
import CalendarIcon from '../../images/icons/calendar-black.svg'
import formatDate from '../../utils/functions/formatDate'

const gac = 'Cart :: Product List'

const Cart = (props) => {
  const { cart, suggested, currentStore, userAccounts, history, dispatch, accountId } =
    props
  const [state, setState] = useState({
    po: '',
    date: '',
    recentOrder: null,
    checkoutLoading: false,
    errorMessages: [],
    showBeforeYouGo: true,
    beforeGoProducts: [],
    productLoading: null,
    showWhatThis: false,
    suggestedSubTotal: 0,
    productStatus: null,
  })

  useEffect(() => {
    setState((old) => ({ ...old, suggestedSubTotal: getSuggestedSubTotal() }))
  }, [suggested])

  const getSuggestedSubTotal = () =>
    suggested?.SuggestedProducts?.reduce(
      (subTotal, { ProductDetails, StockReplenishedQuantity }) =>
        subTotal + ProductDetails?.PriceOuter * StockReplenishedQuantity,
      0
    ) || 0

  const getBeforeGoData = async () => {
    await getBeforeYouGo().then((products) => {
      setState((oldVal) => ({
        ...oldVal,
        beforeGoProducts: products,
        showBeforeYouGo: !!products.length,
      }))
    })
  }

  const getRecentOrderData = async () => {
    await getRecentOrder().then((data) => {
      setState((oldVal) => ({
        ...oldVal,
        recentOrder: data,
      }))
    })
  }

  useEffect(() => {
    getBeforeGoData()
    getRecentOrderData()
  }, [])

  useEffect(() => {
    // if we have a pending order number, get the PO number and date
    if (cart?.PendingOrderNumber) {
      getOrderDetails(cart?.PendingOrderNumber)
        .then((data) => {
          if (data)
            setState((old) => ({
              ...old,
              date: data?.SubmitDate ? new Date(data?.SubmitDate) : '',
              po: data?.PoNumber || '',
            }))
        })
        .catch((err) => err)
    }
  }, [cart?.PendingOrderNumber])

  const onChangeStore = (e) => {
    e.preventDefault()
    dispatch(setOpenDrawer('STORE', true))
  }

  const setProductState = (index, loading = false, error = '') => {
    setState((old) => ({ ...old, productStatus: { index, loading, error } }))
  }

  const onProductIncrement = (productData, value, index, location) => {
    setProductState(index, true) // set loading
    return new Promise((resolve, reject) => {
      addCartProduct({
        beCartId: cart?.BackEndCartId,
        SKU: productData?.SKU,
        quantity: value,
        cart_add: location || undefined
      })
        .then((res) => {
          resolve(res)
          setProductState(index)
        })
        .catch((err) => {
          const errMessage = err?.data?.Message || productError
          reject(errMessage)
          setProductState(index, false, errMessage)
        })
    })
  }
  const onProductDecrement = (productData, value, index) => {
    setProductState(index, true) // set loading
    return new Promise((resolve, reject) => {
      removeCartProduct({
        beCartId: cart?.BackEndCartId,
        SKU: productData?.SKU,
        quantity: value,
      })
        .then((res) => {
          resolve(res)
          setProductState(index)
        })
        .catch((err) => {
          const errMessage = err?.data?.Message || productError
          reject(errMessage)
          setProductState(index, false, errMessage)
        })
    })
  }

  const onBeforeGoProceed = () => {
    setState((oldVal) => ({ ...oldVal, showBeforeYouGo: false }))
  }

  const onClearAll = () => {
    dispatch(
      openPopup(
        'clear-cart',
        cart?.PendingOrderNumber
          ? 'Are you sure you want to cancel editing order?'
          : 'Are you sure you want to remove all items from your cart?',
        ''
      )
    )
    GA(gac, 'Text Link :: Clear All','click', accountId)
  }
  const onContinue = () => {
    history.push('/place-order')
    GA(gac, 'Button :: Back to products','click', accountId)
  }
  const onWhatClick = (e) => {
    e.preventDefault()
    setState((old) => ({ ...old, showWhatThis: !old.showWhatThis }))
  }

  const onInputChange = (e, key) => {
    setState((old) => ({ ...old, [key]: e.target.value }))
  }

  const onCheckoutNow = async () => {
    setState((old) => ({
      ...old,
      checkoutLoading: true,
    }))
    const validationMessage = await validateCart(cart?.BackEndCartId).then(
      (data) =>
        data.reduce((errors, val, index) => {
          if (!data[0]?.MemberNames?.length && index > 0) return errors
          errors.push(val.ErrorMessage)
          return errors
        }, [])
    )
    setState((old) => ({
      ...old,
      checkoutLoading: false,
      errorMessages: validationMessage,
    }))
    if (validationMessage.length) return false
    const submitData = {
      PurchaseOrderNumber: state.po || '',
      SubmitDate: state.date ? new Date(state.date).toISOString() : '',
      // ForceDuplicate: true,
      BackEndCartId: cart?.BackEndCartId,
    }

    // get difference between today and last order date
    const recentOrderDate = new Date(
      state.recentOrder?.OrderDate?.replace(/(.*?)\s(.*)/g, '$1T$2')
    )
    const today = new Date()
    const countFromRecentOrder =
      (today.getTime() - recentOrderDate.getTime()) / (1000 * 3600)

    const msg = `<b class=" promptmessage48 ${
      countFromRecentOrder <= 48 ? ' red' : ''
    }"> Last order: ${String(
      formatDate(state?.recentOrder?.OrderDate)
    ).toUpperCase()}  <a href="/my-orders">(view last order) </a> </b>`

    if (cart?.PendingOrderNumber) {
      submitData.OrderNumber = cart.PendingOrderNumber
    }

    dispatch(
      openPopup(
        cart?.PendingOrderNumber ? 'edit-order' : 'checkout',
        cart?.PendingOrderNumber
          ? 'Are you sure you want to edit this pending order?'
          : 'Are you sure you want to submit this order?',
        cart?.PendingOrderNumber ? '' : msg,
        [submitData]
      )
    )
    GA(gac, `Button :: ${cart?.PendingOrderNumber ? 'Save Order' : 'Checkout'}`,'click', accountId)

    var suggestedProduct = 'not_suggested'
    var productsForAnalitics = []

    cart.BackEndCartDetails.forEach((item) => {
      var tempProduct = {}
      tempProduct = item.Product
      tempProduct.suggested = suggestedProduct
      tempProduct.socialProofed = getIsSocialProofed(item)
      tempProduct.productList = 'cart'
      tempProduct.quantity = item.Quantity
      tempProduct.social_proof = getGASocialProofingLabels(item)
      productsForAnalitics.push(tempProduct)
    })
    GAEcommerceMultiple('begin_checkout',accountId, productsForAnalitics)
  }

  return (
    <div className="bw-container-page bw-cart">
      <div className="bw-container">
        {state.showBeforeYouGo && (
          <BeforeYouGo
            products={state.beforeGoProducts}
            productStatus={state.productStatus}
            onAddToCart={onProductIncrement}
            onRemoveProduct={onProductDecrement}
            onProceed={onBeforeGoProceed}
            onContinue={onContinue}
          />
        )}
        {!state.showBeforeYouGo && (
          <>
            <Steps
              steps={orderSteps}
              currentStep={1}
              className="bw-mb-10 md:bw-mb-12"
            />
            <div className="bw-title-wrapper">
              <h1 className="bw-h1">Cart</h1>
              <div className="btns-wrapper">
                {cart?.PendingOrderNumber ? (
                  ''
                ) : (
                  <Button
                    label="Clear All"
                    className="bw-button--secondary bw-mr-2"
                    onClick={onClearAll}
                  />
                )}
                <Button
                  label="Continue Shopping"
                  className="bw-button--secondary"
                  onClick={onContinue}
                />
              </div>
            </div>
            <div className="bw-gray-boxes bw-flex bw-justify-between bw-mt-5">
              <div className="bw-flex-1 md:bw-pr-8">
                <div className="bw-gray-box bw-store-details bw-mb-6 md:bw-mb-5">
                  <div className="title-wrapper sm:bw-flex bw-justify-between md:bw-mb-3 bw-mb-5">
                    <h3 className="bw-cart-subtitle md:bw-mb-0 bw-mb-4 md:bw-w-2/3">
                      STORE ADDRESS ORDER DETAILS
                    </h3>
                    {userAccounts?.length > 1 && (
                      <Link
                        to=""
                        onClick={onChangeStore}
                        className="bw-text-right md:bw-w-1/3"
                      >
                        Change Store
                      </Link>
                    )}
                  </div>
                  <div className="bw-pb-6 bw-border-lightGray bw-border-b-1 bw-border-solid">
                    <p className="bw-font-medium bw-mb-2 bw-text-18">
                      {currentStore?.AccountName}
                    </p>
                    <p className="bw-text-15 bw-leading-tight md:bw-w-1/4 bw-w-1/2">
                      {currentStore?.AddressLine1}
                    </p>
                  </div>
                  {suggested?.SuggestedProducts?.length ? (
                    <div className="bw-flex bw-justify-between bw-pb-0 md:bw-pb-3 bw-mt-6">
                      <div>
                        <p className="bw-font-medium bw-text-18">
                          INSTORE AGREED PRODUCT(S)
                        </p>
                        <p className="bw-text-13">
                          {formatDate(suggested?.AgreedDate, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}{' '}
                          - This section cannot be amended.
                        </p>
                      </div>
                      <div className="bw-text-right bw-ml-3">
                        <p className="bw-font-medium bw-text-16">
                          {toCurrencyString(state.suggestedSubTotal)}
                        </p>
                        <p className="bw-font-medium bw-text-12">Sub Cost</p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {suggested?.SuggestedProducts?.map(
                    ({ ProductDetails, StockReplenishedQuantity }, index) => (
                      <ProductInfo
                        productData={ProductDetails}
                        quantity={StockReplenishedQuantity}
                        key={index}
                      />
                    )
                  )}
                </div>
                <div className="bw-products">
                  {cart?.BackEndCartDetails?.map(
                    ({ Product, Quantity }, index, products) => (
                      <ProductCard
                        productData={Product || {}}
                        quantity={Quantity}
                        onIncrement={(productData, quantity) =>
                          onProductIncrement(productData, quantity, index)
                        }
                        onDecrement={(productData, quantity) =>
                          onProductDecrement(productData, quantity, index)
                        }
                        onRemoveProduct={(productData, quantity) =>
                          onProductDecrement(productData, quantity, index)
                        }
                        loading={
                          state.productStatus?.index === index &&
                          state.productStatus?.loading
                        }
                        error={
                          state.productStatus?.index === index &&
                          state.productStatus?.error
                            ? state.productStatus?.error
                            : ''
                        }
                        className={
                          products.length - 1 === index ? '' : 'bw-mb-3'
                        }
                        key={index}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="bw-gray-box bw-order-summary bw-h-full bw-mt-3 sm:bw-mt-0">
                <h3 className="bw-cart-subtitle bw-mb-4">ORDER SUMMARY</h3>
                <p className="bw-text-15 bw-mb-8">
                  You need 25 items in your cart to check out
                </p>
                <TextInput
                  name="po"
                  value={state.po}
                  label="PO Number"
                  onChange={(e) => onInputChange(e, 'po')}
                />
                <div className="bw-text-input bw-mb-3">
                  <label>
                    <span>Post Order Date</span>
                    <span className="bw-inline-block bw-text-right">
                      <div className="bw-order-summary--tooltip-container">
                        <a href="" onClick={onWhatClick}>
                          What's this?
                        </a>
                        {state.showWhatThis && (
                          <Tooltip onClose={onWhatClick} />
                        )}
                      </div>
                    </span>
                  </label>
                  <div className="bw-relative">
                    <ReactDatePicker
                      selected={state.date}
                      onChange={(newDate) =>
                        setState((old) => ({ ...old, date: newDate }))
                      }
                      onChangeRaw={(e) => e.preventDefault()}
                      dateFormat="dd/MM/yyyy"
                      minDate={addDays(new Date(), 1)}
                      maxDate={addDays(new Date(), 28)}
                      popperPlacement="top-start"
                    />
                    <img
                      src={CalendarIcon}
                      alt="Calendar"
                      className="toggle bw-icon-calendar"
                    />
                  </div>
                </div>
                <div className="bw-order-summary--details">
                  <p>Sub-total</p>
                  <p>{toCurrencyString(cart?.SubTotal)}</p>
                </div>
                <div className="bw-order-summary--details">
                  <p>VAT @ {vat * 100}%</p>
                  <p>{toCurrencyString(cart?.VatTotal)}</p>
                </div>
                <div className="bw-order-summary--details">
                  <p>Order total</p>
                  <p>{toCurrencyString(cart?.OrderTotal)}</p>
                </div>
                {state.errorMessages?.length ? (
                  <div className="bw-text-red bw-text-15">
                    {state.errorMessages.map((err, index) => (
                      <p key={index}>{err}</p>
                    ))}
                  </div>
                ) : (
                  ''
                )}
                {cart?.PendingOrderNumber ? (
                  <div
                    className={`bw-flex bw-flex-wrap bw-mt-${
                      state.errorMessages?.length ? 3 : 5
                    }`}
                  >
                    <Button
                      label="Cancel editing order"
                      className="bw-button--secondary bw-mr-2"
                      onClick={onClearAll}
                    />
                    <Button
                      label="Save order"
                      loading={state.checkoutLoading}
                      onClick={onCheckoutNow}
                    />
                  </div>
                ) : (
                  <Button
                    label="Checkout Now"
                    className={`bw-mt-${
                      state.errorMessages?.length ? 3 : 5
                    } bw-checkout-now`}
                    loading={state.checkoutLoading}
                    onClick={onCheckoutNow}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function stateMapping(state) {
  return {
    cart: state.app.cart,
    currentStore: state.app.currentAccount,
    suggested: state.app.suggestedProducts,
    userAccounts: state.app.userAccounts,
    accountId: state.app.currentAccount.JtiAccountId
  }
}

export default connect(stateMapping)(Cart)
