import { checkAuth } from "../../axios/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function InvoiceLayout({children}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const checkLoggedIn = async () => {
      await checkAuth().then(() =>  setIsAuthenticated(true))
      .catch(() => {
        setIsAuthenticated(false);
        return history.push('/login');
      });
    };

    checkLoggedIn();
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated && children}
    </>
  );
}

export default InvoiceLayout;