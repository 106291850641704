import React from 'react'

const FirstTimeLogin = (props) => {
  const { onClose } = props
  return (
    <div className="bw-first-time-login">
      <div className="bw-flex bw-justify-between">
        <h4>First time login</h4>
        <div
          className="bw-cursor-pointer bw-pl-1 bw-pb-1"
          onClick={() => onClose()}
        >
          <svg
            id="CTA_DELETE"
            data-name="CTA DELETE"
            xmlns="http://www.w3.org/2000/svg"
            width="12.633"
            height="12.633"
            viewBox="0 0 12.633 12.633"
          >
            <rect
              id="Rectangle_3980"
              data-name="Rectangle 3980"
              width="16.252"
              height="1.613"
              rx="0.807"
              transform="translate(1.141) rotate(45)"
              fill="#fff"
            />
            <rect
              id="Rectangle_3981"
              data-name="Rectangle 3981"
              width="16.252"
              height="1.613"
              rx="0.807"
              transform="translate(0 11.492) rotate(-45)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>

      <p>Please follow these easy steps:</p>
      <p>Reset your password</p>
      <p>
        Enter your registered email address - please note that you must also
        have access to the inbox to verify the password reset
      </p>
      <p>
        Enter your NEW password which must be 8 characters long and contain one
        number and use both upper and lowercase letters - please ensure that the
        password criteria is met, it should come up as strong. Your old password
        will not work
      </p>
      <p>Click the submit button</p>
      <p>
        Go to your email account inbox, open the email from JTI Engage and click
        on the link. You password is now reset and you are re-registered.
      </p>
      <p>You can now log in!</p>
      <p>
        Whilst the web address is the same www.jtiengage.com your favourites
        link may not work - so please re-enter the web address and re-save to
        your favourites.
      </p>
    </div>
  )
}

export default FirstTimeLogin
