import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import qs from 'query-string'

import { generalError } from '../../utils/constants'
import { resetPassword } from '../../axios/public'
import { resetPasswordForm } from '../../utils/forms/public'

// Form components
import TextInput from '../../components/form/TextInput'
import Button from '../../components/form/Button'
import PasswordHelper from '../../components/form/PasswordHelper'

const ResetPassword = (props) => {
  const { history } = props
  const [state, setState] = useState({
    passwordStatus: {},
    submitted: false,
    error: false,
  })
  const [invalidAttempts, setInvalidAttempts] = useState(0)

  const queryParams = qs.parse(location.search)

  useEffect(() => {
    if (!queryParams.resetToken) {
      history.push('/login')
      return false
    }
  }, [])

  useEffect(() => {
    if (invalidAttempts === 3) {
      sessionStorage.clear()
      history.replace("/login")
    }
  }, [invalidAttempts])

  const onReset = async (values, { setSubmitting }) => {
    await resetPassword({ ...values, EmailTokenId: queryParams.resetToken })
      .then((res) => {
        setState((oldVal) => ({ ...oldVal, submitted: true }))
      })
      .catch((err) => {
        const message = err?.data?.Message
          ? err.data.Message
          : err.status === 400
          ? 'Password can be changed only once a day.'
          : generalError
        setState((oldVal) => ({ ...oldVal, error: message }))
        setInvalidAttempts((oldVal) => oldVal + 1)
      })
    setSubmitting(false)
  }

  return (
    <>
      <h3 className={`bw-h3 bw-mb-${state.submitted ? 5 : 8}`}>
        Reset password
      </h3>
      {!state.submitted && (
        <p className="bw-font-normal bw-text-15 bw-mb-5 bw-pr-1 bw-leading-snug">
          Please use a password you have not used before. You can reset your
          password only once a day.
        </p>
      )}
      {state.submitted && (
        <>
          <p className="bw-font-bold bw-text-23 bw-mb-4">Thank you!</p>
          <p className="bw-font-normal bw-text-14 bw-mb-3 bw-pr-8 bw-leading-snug">
            Your password has been reset successfully
          </p>
        </>
      )}

      {!state.submitted && state.error && (
        <p className="bw-text-red bw-text-15 bw-mb-2">{state.error}</p>
      )}
      {state.submitted ? (
        <a href="/login" className="bw-btn-link">
          Go to Log In
        </a>
      ) : (
        <>
          <Formik
            {...resetPasswordForm}
            validate={(val) => resetPasswordForm.validate(val, setState)}
            onSubmit={onReset}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <TextInput
                  name="Email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                  label="Account Email Address"
                  errortext={errors.Email && touched.Email && errors.Email}
                />
                <TextInput
                  type="password"
                  name="Password"
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Password}
                  label="New Password"
                  errortext={
                    errors.Password && touched.Password && errors.Password
                  }
                />
                <TextInput
                  type="password"
                  name="ConfirmPassword"
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ConfirmPassword}
                  label="Confirm Password"
                  errortext={
                    errors.ConfirmPassword &&
                    touched.ConfirmPassword &&
                    errors.ConfirmPassword
                  }
                />
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  label="Submit"
                  className="bw-mb-3"
                />
              </form>
            )}
          </Formik>
          {!state.submitted && <PasswordHelper {...state.passwordStatus} />}
        </>
      )}
    </>
  )
}

export default connect()(ResetPassword)
