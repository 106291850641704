class PrivacyPolicyContent extends React.Component {
  
  render() {
    return (
      <React.Fragment>
        <ol>
            <li>
              <h5><b>What does this Privacy Statement cover?</b></h5>
              
              <p>This Privacy Statement explains how JTI Ireland Limited (“we” or “JTI”) use Personal Data we collect through JTI Engage Trade Website (“Website”). “Personal Data” is information about living individuals from which you or another person is identifiable.</p>
              
              <p>Personal Data may be provided to us by you directly or by a third party. </p>
              
              <p>Before providing us with Personal Data about another individual you must: (a) inform the individual about the content of this Privacy Policy and any other applicable privacy notices provided to you; (b) obtain their permission to share their Personal Data with us in accordance with this Privacy Policy and other applicable privacy notices; and (c) obtain any legally required consent, where applicable.</p>
            </li>
            
            <li>
              <h5><b>Personal Data we hold</b></h5>
              
              <p>Personal Data we may hold and process includes:</p>

              <div className="table">
                <div className="head_row">
                  <div>
                    <div>Type of Personal Data</div>
                  </div>
                  <div>
                    <div>Examples</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of Personal Data</span>
                    <div>Contact Information</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>Name, address, business address, email and telephone number, confirmation that trade contacts are over 18 years of age.</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of Personal Data</span>
                    <div>Preferences, marketing activities and customer feedback</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>Preferences or responses to voluntary customer satisfaction surveys</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of Personal Data</span>
                    <div>Online activity information</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>
                      <p>We may receive Personal Data about you when you use the Website; this may include your IP address and other online identifiers (to the extent that they are Personal Data), and other Personal Data that you provide to us online.</p>
                      <p>Information we collect about you and your device. Each time you visit/use the Website we may collect the following information:</p>
                      
                      <ul className="disc">
                        <li>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system; platform; the type of mobile device you use, a unique device identifier (for example, your device's IMEI number, the MAC address of the device's wireless network interface, or the mobile phone number used by the device), mobile network information, the type of mobile browser you use, time zone setting, connection to relevant network;</li>
                        <li>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from the Website (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</li>
                        <li>technical information, including details of your use of any of the Website, including, but not limited to, traffic data, weblogs and other communication data and the resources that you access.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of Personal Data</span>
                    <div>Supplemental information from other sources</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>We and our service providers may supplement the Personal Data we collect with information obtained from other sources (for example, third party commercial information sources, and information from our business partners)</div>
                  </div>
                </div>
              </div>
            </li>
            
            <li>
              <h5><b>How we use Personal Data</b></h5>
              
              <p>We use Personal Data to carry out our business activities.  The main purposes include using Personal Data to:</p>
              
              <ul className="custom">
                <li><span className="custom_list">3.1</span>communicate with you and other individuals (including in respect of Statement issues and trade events with our trade customers);</li>
                <li><span className="custom_list">3.2</span>provide our products and ordering options;</li>
                <li><span className="custom_list">3.3</span>improve the quality of our products and services;</li>
                <li><span className="custom_list">3.4</span>carry out research and analysis, including analysis of our customer base and other individuals whose Personal Data we collect, complete market research, including customer satisfaction surveys, and assess the risks faced by our business;</li>
                <li><span className="custom_list">3.5</span>conduct competitions for entry and communicate with winners;</li>
                <li><span className="custom_list">3.6</span>provide marketing information in accordance with preferences on the products you order and you have told us about;</li>
                <li><span className="custom_list">3.7</span>personalise your experience when you use the Website including by identifying you to anyone to whom you send messages through the Website;</li>
                <li><span className="custom_list">3.8</span>manage our business operations and IT infrastructure, in line with our internal policies and procedures, including those relating to finance and accounting; billing and collections; IT systems operation; data and website hosting; data analytics; business continuity; records management; document management; and auditing;</li>
                <li><span className="custom_list">3.9</span>manage complaints, feedback and queries;</li>
                <li><span className="custom_list">3.10</span>comply with applicable laws and regulatory obligations (including laws and regulations outside your country of residence), for example, laws and regulations relating to public health and tobacco, comply with legal process and court orders; and respond to requests from public and government authorities (including those outside your country of residence); and</li>
                <li><span className="custom_list">3.11</span>establish and defend legal rights to protect our business operations, and those of our group companies or business partners, and secure our rights, and that of our group companies or business partners, you, or other individuals or third parties; to enforce our terms and conditions; and pursue available remedies or limit our damages.</li>
              </ul>
              <br/>
            </li>
            
            <li>
              <h5><b>Sharing of Personal Data</b></h5>
              
              <p>In connection with the purposes described above, we may need to share your Personal Data with third parties (this may involve third parties disclosing Personal Data to us and us disclosing Personal Data to them).  These third parties may include:</p>
              
              <div className="table">
                <div className="head_row">
                  <div>
                    <div>Type of third party</div>
                  </div>
                  <div>
                    <div>Examples</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of third party</span>
                    <div>Our service providers</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>External third party service providers, such as security professionals, auditors and other professional advisors; IT systems, marketing agents, support and hosting service providers; trade advertising, marketing and market research, and data analysis service providers; and other third party vendors and outsourced service providers and group companies that assist us in carrying out business activities.</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of third party</span>
                    <div>Government authorities and third parties involved in court action</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>We may also share Personal Data with: (a) government or other public authorities (including, but not limited to, courts, regulatory bodies, law enforcement agencies, tax authorities and criminal investigations agencies); and (b) third party participants in legal proceedings and their accountants, auditors, lawyers, and other advisors and representatives, as we believe to be necessary or appropriate.</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of third party</span>
                    <div>Other third parties</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>Group companies of JTI</div>
                  </div>
                </div>
                
                <div>
                  <div>
                    <span className="head_column">Type of third party</span>
                    <div>Sale of Business or Assets</div>
                  </div>
                  <div>
                    <span className="head_column">Examples</span>
                    <div>
                      <p>We may disclose your personal information to third parties:</p>
                      <ul className="disc">
                        <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</li>
                        <li>If JTI or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets and data will be disclosed to the purchaser of JTI or its assets.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
            <li>
              <h5><b>Processing of Personal Data as per JTI External Privacy Statement</b></h5>
              
              <p>Due to the global nature of our business, for the purposes set out above we may, from time to time, transfer Personal Data to parties (including group companies) located in other countries (including the USA and other countries that have data protection regimes which are different to those in the EU, including countries which have not been found by the European Commission to provide adequate protection for Personal Data).</p>
              
              <p>We may transfer information internationally to our service providers, business partners, and government or public authorities.</p>
              
              <p>When making these transfers, we will take steps to ensure that your Personal Data is adequately protected and transferred in accordance with the requirements of data protection law.</p>
              
              <p>This may involve the use of data transfer agreements in the form approved by the European Commission or another mechanism recognised by data protection law as ensuring an adequate level of protection for Personal Data transferred outside the EEA (for example, the standard contractual clauses).</p>
            </li>
          </ol>
      </React.Fragment>
    )
  }
}

export default PrivacyPolicyContent