import React from 'react'
import PropTypes from 'prop-types'

const FieldGroup = (props) => {
  const { label, value, type, className } = props
  return (
    <div className={`bw-info-group--${type} ${className}`}>
      <label className="bw-info-group--label">{label}</label>
      <p className="bw-info-group--value">{value}</p>
    </div>
  )
}

FieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  className: PropTypes.string,
}

FieldGroup.defaultProps = {
  label: '',
  value: '',
  type: 'vertical',
  className: '',
}

export default FieldGroup
