import Request from 'superagent'

import {
  getAuthorization,
  getAccountNumberQuery as accountNumber,
  getAccountNumber,
} from '../../utils/storage'
import { domain, sessionError, generalError } from 'Functions/data'

const reviewOrderDetailURL = domain + '/api/OrderHistory/OrderHistoryDetails'
const orderHistoryURL =
  domain + '/api/OrderHistory/FilteredByTimeSpanOrderHistory'
const orderSubmitURL = domain + '/api/Order/Submit'
const updateOrderDetailURL = domain + '/api/Order/UpdatePendingOrderWithDetails'
const cancelOrderURL = domain + '/api/Order/DeletePendingOrder'
const orderInvoiceURL = domain + '/api/OrderHistory/Invoice'
/***
ORDER HISTORY REQUEST (COMPONENT: MyOrders)
***/

export function orderHistoryREQUEST(timeSpan, callback) {
  Request.get(orderHistoryURL + accountNumber())
    .query({ timeSpan: timeSpan })
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) {
          callback(false, [], generalError)
        }
        return false
      }

      const response = res.body.OrderHistory.map((order) => {
        return {
          orderId: order.OrderNumber,
          date: order.SubmitDate || order.OrderDate,
          //postDate: order.SubmissionDate,
          status: order.Status,
          totalItems: order.NumberOfItems,
        }
      })

      callback(true, response)
    })
}

/***
REVIEW ORDER DETAIL REQUEST (COMPONENT: ReviewOrder)
***/

export function orderDetailREQUEST(orderId, callback) {
  Request.get(reviewOrderDetailURL + accountNumber())
    .query({ orderNumber: orderId })
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) {
          callback(
            false,
            {},
            'Order Number is invalid. Please go back to order history.'
          )
        }
        return false
      }

      const body = JSON.parse(JSON.stringify(res.body))

      const response = {
        order: {
          ...res.body,
          OrderDetails: res.body?.OrderHistoryDetails?.map((el) => ({
            Quantity: el.Quantity,
            Product: el.ProductDetails,
          })),
        },
        details: body.OrderHistoryDetails.map((prod) => {
          if (prod.ProductDetails) {
            return Object.assign(prod, prod.ProductDetails)
          }
          return prod
        }),
        date: body.SubmitDate || body.OrderDate,
        //postDate: body.SubmitDate,
        po: body.PoNumber,
        status: body.Status ? body.Status : 0,
        VAT: body.VAT,
      }

      callback(true, response)
    })
}

/***
SUBMIT ORDER REQUEST (COMPONENT: CheckOrder)
***/

export function orderSubmitREQUEST(_data, callback) {
  const data = {
    PurchaseOrderNumber: _data.po,
    OrderedProducts: _data.orders.map((prod) => {
      return {
        SKU: prod.sku,
        Quantity: prod.quantity,
      }
    }),
  }

  if (_data.postDate) {
    data.SubmitDate = _data.postDate
  }

  Request.post(orderSubmitURL + accountNumber())
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status === 401) {
          callback(false, null, sessionError)
        } else {
          let errorMessages = Object.values(err.response.body.ModelState).flat()

          if (
            err.response.body.ModelState &&
            err.response.body.ModelState.DuplicateOrder
          ) {
            callback(
              false,
              null,
              `We suspect this is a duplicate order. Please view your most recent order in <a href="/my-orders">My orders</a>. If you don't believe this is a duplicate order, please wait 5 minutes and submit again.`
            )
          } else if (errorMessages.length > 0) {
            callback(
              false,
              null,
              `Error(s):<br/> ${errorMessages.join('<br/>')}`
            )
          } else {
            callback(false, null, 'Please try again later.')
          }
        }
        return false
      }

      const response = {
        orderId: res.body.OrderNumber,
        date: res.body.SubmitDate || res.body.OrderDate,
        itemTotal: res.body.NumberOfItems,
        subTotal: res.body.SubTotal,
        vatTotal: res.body.VatTotal,
        total: res.body.OrderTotal,
        po: res.body.PoNumber,
        orders: res.body.OrderDetails,
        status: res.body.Status,
      }

      callback(true, response)
    })
}

/***
UPDATE ORDER DETAIL REQUEST (COMPONENT: CART)
***/

export function updateOrderDetailREQUEST(_data, callback) {
  // console.log('updateOrderDetail_data', _data)

  const data = {
    OrderNumber: _data.id,
    PurchaseOrderNumber: _data.po,
    OrderedProducts: _data.orders.map((prod) => {
      return {
        SKU: prod.sku,
        Quantity: prod.quantity,
      }
    }),
  }

  if (_data.postDate) {
    data.SubmitDate = _data.postDate
  }

  Request.put(updateOrderDetailURL + accountNumber())
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .send(JSON.stringify(data))
    .end((err, res) => {
      if (err) {
        if (err.status === 401) {
          callback(false, null, sessionError)
        } else {
          callback(false, null, generalError)
        }
        return false
      }

      const response = {
        orderId: res.body.OrderNumber,
        date: res.body.OrderDate,
        itemTotal: res.body.NumberOfItems,
        subTotal: res.body.SubTotal,
        vatTotal: res.body.VatTotal,
        total: res.body.OrderTotal,
        po: res.body.PoNumber,
        orders: res.body.OrderDetails,
        status: res.body.Status,
      }

      callback(true, response)
    })
}

/***
CANCEL ORDER REQUEST (COMPONENT: ReviewOrder)
***/

export function cancelOrderREQUEST(orderId, callback) {
  const accNumber = getAccountNumber()
  Request.delete(
    `${cancelOrderURL}?orderNumber=${orderId}${
      accNumber ? '&accountNumber=' + accNumber : ''
    }`
  )
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .send(orderId)
    .end((err, res) => {
      if (err) {
        if (err.status === 401) {
          callback(false, sessionError)
        } else {
          callback(false, generalError)
        }
        return false
      }

      callback(true)
    })
}

/***
CANCEL ORDER REQUEST (COMPONENT: ReviewOrder)
***/
export function orderInvoiceREQUEST(orderId, callback) {
  Request.get(orderInvoiceURL + accountNumber())
    .query({ orderNumber: orderId })
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('blob')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) {
          callback(
            false,
            {},
            'Order Number is invalid. Please go back to order history.'
          )
        }
        return false
      }
      const url =  URL.createObjectURL(new Blob([res.body], { type: 'application/pdf' }));
      callback(true, url)
    })
}
