import { Link } from 'react-router-dom'

class HeaderForm extends React.Component {
  
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <header className="header-form">
        
        <div className="logo-engage"><Link to="/login"/></div>
        
        <div className="logo-jti"><div></div></div>
        
      </header>
    )
  } 
}

export default HeaderForm