export default function TourPopOver({ title, description, onSkipTour, onPrevious, onNext, isLastStep, className = '' }) {
  return (
    <div className={`tooltip ${className}`}>
      <div className={"tooltip__content"}>
        <h5 className={"bw-text-2xl bw-font-bold"}>{title}</h5>
        <p className={"bw-text-base"}>{description}</p>
      </div>
      <div className={"tooltip__buttons"}>
        <button onClick={onSkipTour} className={"tooltip__buttons__skip"}>
          Skip the tour
        </button>
        <button onClick={onPrevious} disabled={!onPrevious} className={"tooltip__buttons__previous"}>
          Previous
        </button>
        <button onClick={onNext} className={"tooltip__buttons__next"}>
          {isLastStep ? 'Finish' : 'Next'}
        </button>
      </div>
      <div className="tooltip__arrow"></div>
    </div>
  )
}