class InputText extends React.Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const {
      id, input, label, placeholder, name, type,
      meta: { touched, error }
    } = this.props;

    const fieldName = label.replace(/\s+/g, '-').toLowerCase();
    
    return (
      <div className={`form-field form-field-text ${fieldName}`}>
        <label>{ label }</label>
        <input { ...input } id={ id ? id : '' } placeholder={ placeholder } type={ type ? type : 'text' }/>
        { 
          touched && error && <span className="error_message">{ error }</span> 
        }
      </div>  
    )
  }
}

export default InputText