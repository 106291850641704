import PropTypes from 'prop-types'

import timer from 'Functions/timer'

class TriangledBackBetaTriangle extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      frame: 0
    }
    
    this.time = 0;
    this.interval = 4;
  }
  
  render() {
    const { index } = this.props;
    const { frame } = this.state;
    
    return (
      <div className={ 'animated-triangle animated-triangle-' + index + ' frame-' + frame }/>
    )
  }
  
  update() {
    const { frames, end, index } = this.props;
    
    if(++this.time % this.interval === 0) {
      const frame = this.state.frame + 1;
      
      if(frame >= this.props.frames) {
        this.setState({ frame: 0 });
        this.time = 0;
        end(index);
        return false;
      } 
      this.setState({ frame: frame });
    }
  }
  
  componentDidMount() {
    timer.register('triangle', ()=>this.update());
  }
  
  componentWillUnmount() {
    timer.remove('triangle');
  }
}

TriangledBackBetaTriangle.propTypes = {
  end: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  frames: PropTypes.number.isRequired
}

export default TriangledBackBetaTriangle