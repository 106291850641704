import React from 'react'

const Prev = ({onClick}) => {
    return (
        <button type="button" className="slick-arrow slick-prev" onClick={onClick}>
            <svg id="ARROW_L" data-name="ARROW L" xmlns="http://www.w3.org/2000/svg" width="9.725" height="15.557" viewBox="0 0 9.725 15.557">
                <path id="Union_3" data-name="Union 3" d="M23294.447,20463.4l-1.947-1.947,7.777-7.777,1.947,1.947-5.834,5.834,5.834,5.832-1.947,1.941Z" transform="translate(-23292.5 -20453.674)" fill="#fff"/>
            </svg>
        </button>
    )
}


export default Prev
