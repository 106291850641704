import React, { useState } from "react";
import {
  GA,
  GAEcommerce,
  getProdCat,
  getIsSocialProofed,
  getGASocialProofingLabels,
} from "../../../script/functions/ga";
import { addCartProduct, getCart } from "../../../axios/private/cart";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "../../../script/redux/actions.app";
import Preloader from "../../../script/components/Preloader";

function Range({ bannerData, isCart = false, handleSpecificProducts }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const savedCart = useSelector((state) => state.prod.savedCart);
  const isOrderEditing = useSelector((state) => state.prod.isOrderEditing);
  const accountId = useSelector(
    (state) => state.app.currentAccount.JtiAccountId,
  );

  const saveProductsToCart = (products) => {
    const orderItems = products.map((prod) => {
      return {
        sku: prod.SKU,
        quantity: prod.Order,
      };
    });

    if (!isOrderEditing) {
      setLoading(true);

      const promises = orderItems.map((orderItem) => {
        return addCartProduct({
          beCartId: savedCart?.id,
          SKU: orderItem?.sku,
          quantity: orderItem?.quantity,
          cart_add: "have_you_considered",
        });
      });

      Promise.all(promises)
        .then(() => {
          setLoading(false);
          getCart();
        })
        .catch((error) => {
          dispatch(
            openPopup(
              "alert",
              error.data.Message,
              "Please refresh your browser.",
            ),
          );
        });
    }
  };

  const handleAddRangeToCart = (e, products) => {
    const updatedProducts = products.map((product) => {
      GAEcommerce(
        "add_to_cart",
        accountId,
        product.PriceOuter,
        product.PackBarCode,
        product.Product,
        product.Brand,
        product.Category,
        "suggested",
        getIsSocialProofed(product),
        "range_banner",
        product.MinimumOrder,
        "have_you_considered",
        getGASocialProofingLabels(product),
      );
      return {
        ...product,
        Order: product.MinimumOrderIncremental,
      };
    });

    GA(getProdCat("profitbot"), "Button :: Add :: 1 :: " + products[0].Product);
    saveProductsToCart(updatedProducts);
  };

  const handleViewRange = (e, prods) => {
    let brands = [];

    prods.map((prod) => {
      GA(getProdCat("profitbot"), "Button :: View Range :: " + prod.Product);
      if (brands.indexOf(prod.Brand) === -1) {
        brands.push(prod.Brand);
      }
    });

    if (isCart) {
      return history.push(`/place-order?brand=${brands[0]}`);
    } else {
      const scrollLocation = document.getElementsByClassName(
        "bw-products-filters",
      )[0];
      if (scrollLocation) {
        scrollLocation.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }

      handleSpecificProducts(prods);
    }
  };

  return (
    <div className="hyc-range">
      <div className="hyc-range__wrapper">
        <img
          className="hyc-range__img--desktop"
          src={bannerData.ImagePath}
          alt="desktop banner"
        />
        <img
          className="hyc-range__img--mobile"
          src={bannerData.MobileImagePath}
          alt="mobile banner"
        />
        <div
          className={`hyc-range__product ${
            bannerData?.TextColor === "white" ? "white" : "black"
          }`}
        >
          <h4 className="hyc-range__product__title">{bannerData.Title}</h4>
          <p className="hyc-range__product__price">
            €{bannerData.ConsiderProducts[0].PriceOuter.toFixed(2)}
          </p>
          <p className="hyc-range__product__description">{bannerData.Desc}</p>
          <div className="hyc-range__product__btns">
            <button
              onClick={(e) =>
                handleAddRangeToCart(e, bannerData.ConsiderProducts)
              }
            >
              Add full range to cart
            </button>
            <button
              onClick={(e) => handleViewRange(e, bannerData.ConsiderProducts)}
            >
              View full range
            </button>
          </div>
        </div>
      </div>
      {bannerData.LegalBanner && (
        <div className="hyc-legal-disclaimer">
          <p>{bannerData.LegalBanner}</p>
        </div>
      )}
      <div className={`hyc-range__product--mobile`}>
        <h4 className="hyc-range__product--mobile__title">
          {bannerData.Title}
        </h4>
        <p className="hyc-range__product--mobile__price">
          €{bannerData.ConsiderProducts[0].PriceOuter.toFixed(2)}
        </p>
        <p className="hyc-range__product--mobile__description">
          {bannerData.Desc}
        </p>
        <div className="hyc-range__product--mobile__btns">
          <button
            onClick={(e) =>
              handleAddRangeToCart(e, bannerData.ConsiderProducts)
            }
          >
            Add full range to cart
          </button>
          <button
            onClick={(e) => handleViewRange(e, bannerData.ConsiderProducts)}
          >
            View full range
          </button>
        </div>
      </div>
      {loading && <Preloader />}
    </div>
  );
}

export default Range;
