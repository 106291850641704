import React, { useState } from "react";
import logo from "../../../images/jti-logo.svg";
import Modal from "../../../components/Modal";
import SurveyTC from "./SurveyTC";

export default function SurveyPopupModal({
  introMessage,
  termsAndConditions,
  closeModal,
  startSurvey,
}) {
  const [showTC, setShowTC] = useState(false);

  return (
    <Modal closeModal={closeModal} className={"survey-popup-modal"}>
      {showTC ? (
        <SurveyTC
          termsAndConditions={termsAndConditions}
          startSurvey={startSurvey}
          goBack={() => setShowTC(false)}
        />
      ) : (
        <div className="survey-popup-content">
          <img
            className="survey-popup-logo"
            src={logo}
            alt={"JTI Engage Logo"}
          />
          <p className="intro-message">{introMessage}</p>
          <div className="survey-popup-btns">
            <button className="btn-continue" onClick={startSurvey}>
              Continue
            </button>
            <button className="btn-no-thanks" onClick={closeModal}>
              No, thanks
            </button>
          </div>
          <button
            className="survey-popup-tc-btn"
            onClick={() => setShowTC(true)}
          >
            Terms and Conditions
          </button>
        </div>
      )}
    </Modal>
  );
}