import axios from '../index'
import { compareDesc } from 'date-fns'
import {getAccountNumber, getAuthorization} from "../../utils/storage";
import {domain} from "../../utils/constants";

export const checkTrackAndTrace = () => {
  const requestURL = DEV ? '/bad-request' : '/api/Account/IsTrackAndTrace'
  return new Promise((resolve, reject) => {
    axios
      .get(requestURL)
      .then((res) => {
        resolve(res?.data || {})
      })
      .catch((err) => reject(err))
  })
}

export const getAccountInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Account/UserInfo')
      .then((res) => {
        resolve(res?.data || {})
      })
      .catch((err) => reject(err))
  })
}

export const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/Account/ChangePassword', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const setEoidFid = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put('/api/Account/UpdateAccount', data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response))
  })
}

export const getMyOrders = (timeSpan, accountNumber = getAccountNumber()) => {
  const requestURL = domain
  return new Promise((resolve, reject) => {
    fetch(`${requestURL}/api/OrderHistory/FilteredByTimeSpanOrderHistory?timeSpan=${timeSpan}&accountNumber=${accountNumber}`, {
      headers: {
        'Authorization': getAuthorization(),
      }
    })
      .then((res) => res.json())
      .then((res) => {
        const sortedOrders =
          res?.OrderHistory?.sort((order1, order2) =>
            compareDesc(
              new Date(order1?.OrderDate),
              new Date(order2?.OrderDate)
            )
          ) || []
        resolve(sortedOrders)
      })
      .catch((err) => reject(err.response))
  })
}
