import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Formik} from 'formik'
import {toggleLoader} from 'Redux/actions.app'
import {GA} from '../../../script/functions/ga'
import {generalError} from "../../../utils/constants";
import {InitialValues, onLogin, RecaptchaSiteKey, validationSchema} from './utils'
import TextInput from '../../../components/form/TextInput'
import Checkbox from '../../../components/form/Checkbox'
import Button from '../../../components/form/Button'
import ReCAPTCHA from "react-google-recaptcha"
 
const Login = (props) => {
  const {dispatch} = props
  const [error, setError] = useState(false)
  const [invalidAttempts, setInvalidAttempts] = useState(0)
  const recaptchaRef = useRef(null)
 
  useEffect(() => {
    dispatch(toggleLoader(false))
  }, [])
 
  const handleOnchangeRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync();
  }
 
  return (<>
    <p className="bw-font-normal bw-text-15 bw-mb-8">
      JTI account holders that have not already placed an order online before,
      please{' '}
      <Link
        to="/register"
        onClick={() => GA('Login', 'Text Link :: Register now')}
      >
        Register Now
      </Link>
    </p>
    {invalidAttempts >= 3 && (<p className="bw-text-red bw-text-15 bw-mb-2">
      If you are having issues logging onto the new Engage site please contact customer service on{' '}
      <a href="mailto:websupportirl@jti.com">websupportirl@jti.com</a>
    </p>)}
    {error && invalidAttempts < 3 && (<p className="bw-text-red bw-text-15 bw-mb-2">{error}</p>)}
    <Formik
      initialValues={InitialValues}
      validationSchema={validationSchema}
      onSubmit={(values, env) => {
        handleOnchangeRecaptcha()
          .then((token) => {
            values.recaptchaResponse = token
            onLogin(values, env, {...props, setError, setInvalidAttempts, recaptchaRef})
 
          }).catch((err) => {
          const message = err?.data?.Message || generalError
          setError(message)
          recaptchaRef.current.reset()
        })
 
      }}
    >
      {({
          values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
        }) => (<form onSubmit={handleSubmit} className="bw-mb-10">
        <TextInput
          name="username"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.username}
          label="Account Email Address"
          errortext={errors.username && touched.username && errors.username}
        />
        <TextInput
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          label="Password"
          type="password"
          className="bw-mb-2"
          errortext={errors.password && touched.password && errors.password}
        >
        </TextInput>
        <Checkbox
          name="confirm"
          error={errors.confirm && touched.confirm && errors.confirm}
          onChange={handleChange}
          value={values.confirm}
          className="bw-mb-6"
        >
          I confirm I am 18 years of age.
        </Checkbox>
        <ReCAPTCHA
          sitekey={RecaptchaSiteKey}
          ref={recaptchaRef}
          size='invisible'
        />
        <Button
          className="bw-mb-8"
          loading={isSubmitting}
          disabled={isSubmitting}
          label="Log In"
        />
        <Link
          to="/forgotten-password"
          className="bw-text-14"
          onClick={() => GA('Login', 'Text Link :: Forgotten your password?')}
        >
          Forgotten your password?
        </Link>
        <p className="bw-absolute bw-text-15" style={{bottom: '3rem'}}>
          Having problems logging in?
          <Link
            to="/contact-us"
            className="bw-text-14 bw-ml-3"
            onClick={() => GA('Login', 'Text Link :: Contact us')}
          >
            Contact us
          </Link>
        </p>
      </form>)}
    </Formik>
  </>)
}
 
export default connect()(Login)