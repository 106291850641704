import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Textarea = ({ label, errortext, value, type, ...props }) => {
  return (
    <div className={`bw-textarea ${errortext ? 'bw-textarea--error' : ''}`}>
      <label>{label}</label>
      <div className="relative">
        <textarea {...props} value={value}></textarea>
      </div>
      {errortext ? <div className="bw-text-12 bw-pt-2">{errortext}</div> : null}
    </div>
  )
}

// Set default props
Textarea.defaultProps = {
  placeholder: 'Text area placeholder',
  value: '',
  label: 'Label',
  errortext: null,
}

//PropTypes
Textarea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  errortext: PropTypes.string,
}

export default Textarea
