import React from "react";
import logo from "../../../images/jti-logo.svg";

export default function ComeBackLater({ isComeBackOpen, toggle, text }) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`survey__cbl ${
        isComeBackOpen ? "survey__cbl--open" : "survey__cbl--closed"
      }`}
    >
      {/* Side Label */}
      <div onClick={toggle} className={`survey__cbl__label`}>
        <p>Feedback survey</p>
      </div>

      {/* Continue Survey*/}
      <div className={"survey__cbl__body"}>
        <img
          className={"survey__cbl__body__logo"}
          src={logo}
          alt="JTI Engage logo"
        />
        <p>
          {text || (
            <>
              Don't worry, you can come <br /> back to me later
            </>
          )}
        </p>
      </div>
    </div>
  );
}
