export const domain =
  typeof DOMAIN == "undefined"
    ? "https://jtiwebapidev.azurewebsites.net"
    : DOMAIN;
export const generalError =
  "Sorry, our server has encountered an internal error and was unable to complete your request. Please try again later.";

export const productError = "Error updating product";

export const orderError =
  'Order Number is invalid. Please go back to order history.'

export const formErrors = {
  general: "This field is required.",
  email: "Invalid email format. Please try again.",
  emailNotMatch: `Email addresses don't match. Please try again.`,
  passNotMatch: `Passwords don't match. Please try again.`,
  invalidEoid: "Invalid EOID format. Please try again.",
  invalidFid: "Invalid FID format. Please try again.",
};

export const sortTypes = [
  {
    value: 30,
    label: "Past 1 month",
  },
  {
    value: 120,
    label: "Past 3 months",
  },
  {
    value: 240,
    label: "Past 6 months",
  },
  {
    value: 365,
    label: "Past 1 year",
  },
];

export const defaultSortInterval = 30;

export const orderFilters = [
  {
    label: "View Past week",
    type: "time",
    value: 7,
  },
  {
    label: "View Past month",
    type: "time",
    value: 30,
  },
  {
    label: "View Past year",
    type: "time",
    value: 365,
  },
  {
    label: "Status - Pending",
    type: "status",
    value: 1,
  },
  {
    label: "Status - Submitted",
    type: "status",
    value: 0,
  },
  {
    label: "Status - Cancelled",
    type: "status",
    value: 3,
  },
];

export const vat = 0.23;

export const orderSteps = ["Before you checkout", "Cart", "Order Confirmation"];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const productsSortTypes = [
  {
    label: "Stock Card",
    type: "stock",
    value: "stock-asc",
  },
  {
    label: "A-Z",
    type: "brand",
    value: "brand-asc",
  },
  {
    label: "Z-A",
    type: "brand",
    value: "brand-desc",
  },

  {
    label: "Price List",
    type: "price",
    value: "price-asc",
  },
];

export const productsCategories = [
  { label: "Ready Made Cigarettes", value: "RMC" },
  { label: "Roll Your Own", value: "RYO" },
  { label: "e-Vapour", value: "EVAP" },
  { label: "Nicotine Pouch", value: "NICP" },
  { label: "Cigars ", value: "CGR" },
  { label: "Pipes", value: "PIP" },
];
