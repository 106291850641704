import Request from 'superagent'

import {
  getAuthorization,
  getAccountNumberQuery as accountNumber,
} from '../../utils/storage'
import { domain, sessionError, generalError } from 'Functions/data'

const accountInfoURL = domain + '/api/Account/UserInfo'
const setEoidFidURL = domain + '/api/Account/UpdateAccount'
const changePasswordURL = domain + '/api/Account/ChangePassword'
const trackAndTraceURL = DEV
  ? '/bad-request'
  : domain + '/api/Account/IsTrackAndTrace'
const weatherURL = domain + '/api/Account/GetWeather'

/***
ACCOUNT INFORMATION (COMPONENT: MyAccount)
***/

export function accountInfoREQUEST(callback) {
  Request.get(accountInfoURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) {
          callback(false, {}, generalError)
        }
        return false
      }

      const _data = res.body

      let data = {
        staff: false,
        name: `${_data.FirstName} ${_data.LastName}`,
        email: _data.Email,
      }

      if (_data.AssignedRoles.includes('Retailer')) {
        data = Object.assign(data, {
          account: _data.TmeAccount.JtiAccountId,
          store: _data.TmeAccount.AccountName,
          tm_name: `${_data.TmeAccount.EmployeeFirstName} ${_data.TmeAccount.EmployeeLastName}`,
          tm_email: _data.TmeAccount.EmployeeEmailAddress,
          tm_mobile: _data.TmeAccount.EmployeeMobilePhone,
          eoid: _data.TmeAccount.EOID,
          fid: _data.TmeAccount.FID,
        })
      }

      callback(true, data)
    })
}

/***
SET EOID AND FID (COMPONENT: SetEoidFid)
***/

export function setEoidFidREQUEST(_data, callback) {
  const data = {
    EOID: _data.eoid,
    FID: _data.fid,
  }

  Request.put(setEoidFidURL + accountNumber())
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status === 400) {
          callback(
            false,
            'Invalid EOID and FID. Please check EOID and FID and try again.'
          )
        } else {
          callback(false, generalError)
        }
        return false
      }

      callback(true)
    })
}

/***
CHANGE PASSWORD REQUEST (COMPONENT: ChangePassword)
***/

export function changePasswordREQUEST(_data, callback) {
  const data = {
    OldPassword: _data.OldPassword,
    NewPassword: _data.NewPassword,
    ConfirmPassword: _data.ConfirmPassword,
  }

  Request.post(changePasswordURL + accountNumber())
    .send(JSON.stringify(data))
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status === 400) {
          callback(
            false,
            'Invalid password. Please check your current password and if your new password meets the requirements and try again.'
          )
        } else {
          callback(false, generalError)
        }
        return false
      }

      callback(true)
    })
}

/***
GET TRACK AND TRACE STATUS (Component: Protected)
***/

export function trackAndTraceREQUEST(callback) {
  Request.get(trackAndTraceURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status === 400) {
          callback(false)
        }
        return false
      }

      callback(true)
    })
}

/***
GET WEATHER (FILE: functions/contents.es6)
***/

export function weatherREQUEST(callback) {
  Request.get(weatherURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        return false
      }

      callback(true, res.body.current)
    })
}
