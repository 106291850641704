import React from 'react'
import PropTypes from 'prop-types'

const ProductInfo = (props) => {
  const { productData, quantity } = props
  const { PriceOuter, PackSize, PricePackRrp, PackBarCode, Product } =
    productData
  return (
    <div className="bw-product-info bw-flex bw-justify-between bw-mt-6">
      <div className="bw-pr-3 md:bw-w-3/4 bw-w-1/2">
        <p className="bw-text-16">{Product}</p>
        <p className="bw-product--details">
          <span>Pack Quantity {PackSize}</span>
          <span>RRP €{Number(PricePackRrp).toFixed(2)}</span>
          <span>Barcode {PackBarCode}</span>
        </p>
      </div>
      <div className="bw-text-right md:bw-w-1/4  bw-w-1/2">
        <p className="bw-font-medium bw-text-16 bw-mb-1 md:bw-mb-0">€{PriceOuter}</p>
        <p className="bw-font-medium bw-text-12">Quantity {quantity}</p>
      </div>
    </div>
  )
}

const numberOrString = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

ProductInfo.propTypes = {
  productData: PropTypes.shape({
    Product: PropTypes.string,
    PriceOuter: numberOrString,
    PackSize: numberOrString,
    PricePackRrp: numberOrString,
    PackBarCode: numberOrString,
  }),
  quantity: numberOrString,
}

ProductInfo.defaultProps = {
  productData: {
    Product: 'Benson & Hedges Gold 20',
    PriceOuter: 106.57,
    PackSize: 20,
    PricePackRrp: 14.2,
    PackBarCode: '53993120',
  },
  quantity: 5,
}

export default ProductInfo
