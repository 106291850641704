import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProductCard from "../../components/Product/Card";
import Filters from "../../components/Product/Filters";
import Filter from "../../components/Product/Filters/Filter";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";

import {
  productError,
  productsCategories,
  productsSortTypes,
} from "../../utils/constants";
import {
  setOpenDrawer,
  setProductsBrands,
} from "../../script/redux/actions.app";
import { getProductQuantityFromCart } from "../../utils/functions/products";
import { addCartProduct, removeCartProduct } from "../../axios/private/cart";
import { toCurrencyString } from "../../utils/functions/currency";
import HaveYouConsidered from "../../components/banners/HaveYouConsidered/HaveYouConsidered";
import { getHaveYouConsidered } from "../../axios/private/products";
import useScrollBackToTop from "../../utils/hooks/useScrollBackToTop";

function PlaceOrder() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.app.cart);
  const allProducts = useSelector((state) => state.app.allProducts) || [];
  const productsBrands = useSelector((state) => state.app.productsBrands) || [];

  const [state, setState] = useState({
    suggested: {},
    peopleAlsoBought: {},
    sort: { label: "Sort" },
    products: allProducts,
    categories: productsCategories,
    disabledCategories: [],
    brands: [],
    bestSellers: [],
    productStatus: null,
    showBestsellers: false,
    haveYouConsidered: null,
  });

  useEffect(() => {
    getFilters();
  }, [allProducts]);

  useEffect(() => {
    setState((old) => ({ ...old, brands: productsBrands }));
  }, [productsBrands]);

  useEffect(() => {
    filterProducts();
  }, [state.categories, state.brands, state.showBestsellers]);

  useEffect(() => {
    if (state.sort.value) sortProducts(state.products);
  }, [state.sort]);

  useEffect(() => {
    getHaveYouConsidered()
      .then((data) => {
        setState((old) => ({ ...old, haveYouConsidered: data }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useScrollBackToTop();

  const getFilters = () => {
    const filters = allProducts.reduce(
      (filters, product, index) => {
        // category count
        const categoryIndex = filters?.categories?.findIndex(
          (cat) => cat.value === product?.Category
        );
        filters.categories[categoryIndex].hasOwnProperty("count")
          ? filters.categories[categoryIndex].count++
          : (filters.categories[categoryIndex].count = 1);

        // get all brands and count them
        const brandIndex = filters?.brands?.findIndex(
          (brand) => brand.label === product?.Brand
        );
        brandIndex > -1
          ? filters.brands[brandIndex].count++
          : filters?.brands.push({
              label: product?.Brand,
              count: 1,
              active: false,
            });

        // track best sellers
        if (product?.IsBestSeller) {
          filters.bestSellers.push(product);
        }
        return filters;
      },
      {
        categories: productsCategories.map((el) => ({ ...el, count: 0 })),
        brands: [],
        bestSellers: [],
      }
    );

    dispatch(
      setProductsBrands(
        filters.brands.sort((b1, b2) => (b1.label > b2.label ? 1 : -1))
      )
    );
    setState((old) => ({
      ...old,
      ...filters,
      products: allProducts,
      bestSellers: filters.bestSellers,
    }));
  };

  const alternativeChanged = (sku, product) => {
    setState((old) => ({
      ...old,
      suggested: {
        ...old.suggested,
        [sku]: product,
      },
    }));
  };

  const peopleAlsoBoughtChanged = (sku, product) => {
    setState((old) => ({
      ...old,
      peopleAlsoBought: {
        ...old.peopleAlsoBought,
        [sku]: product[0],
      },
    }));
  };

  const onFilterBrands = () => {
    dispatch(setOpenDrawer("FILTER_PRODUCTS", true));
  };

  const onFilterChange = (type, filter, index) => {
    setState((oldVal) => {
      const newFilter = JSON.parse(JSON.stringify(oldVal[type]));
      newFilter[index].active = !newFilter[index].active;
      if (type === "brands") dispatch(setProductsBrands(newFilter));
      return { ...oldVal, [type]: newFilter };
    });
  };

  const handleBestSellerToggle = () => {
    setState((oldVal) => {
      return { ...oldVal, showBestsellers: !oldVal.showBestsellers };
    });
  };

  const filterProducts = () => {
    const activeCategories = state.categories.reduce((acc, category) => {
      if (category.active) acc.push(category.value);
      return acc;
    }, []);
    const activeBrands = state.brands.reduce((acc, brand) => {
      if (brand.active) acc.push(brand.label);
      return acc;
    }, []);
    const activeBestSellers = state.showBestsellers
      ? state.bestSellers.map((product) => product.SKU)
      : [];

    if (
      !(
        activeCategories.length ||
        activeBrands.length ||
        activeBestSellers.length
      )
    ) {
      setState((old) => ({
        ...old,
        products: allProducts,
        disabledCategories: [],
      }));
      return;
    }

    const availableCategories = {};
    const filtered = allProducts.filter((prod, index) => {
      const hasCategory = activeCategories.length
        ? activeCategories.includes(prod.Category)
        : true;
      const hasBrand = activeBrands.length
        ? activeBrands.includes(prod.Brand)
        : true;
      const isBestSeller = activeBestSellers.length
        ? activeBestSellers.includes(prod.SKU)
        : true;
      if (hasBrand) availableCategories[prod.Category] = true;
      return hasCategory && hasBrand && isBestSeller;
    });

    const disabledCategories = state.categories.map(
      (cat) => !availableCategories[cat.value]
    );

    setState((old) => ({
      ...old,
      disabledCategories,
    }));

    sortProducts(filtered);
  };

  const sortProducts = (products) => {
    const sortedProducts = products.sort((prod1, prod2) => {
      switch (state.sort.value) {
        case "brand-asc":
          // for the same brand sort by product name
          return prod1.Brand === prod2.Brand
            ? prod1.Product > prod2.Product
              ? 1
              : -1
            : prod1.Brand > prod2.Brand
            ? 1
            : -1;
        case "brand-desc":
          return prod1.Brand === prod2.Brand
            ? prod1.Product > prod2.Product
              ? -1
              : 1
            : prod1.Brand > prod2.Brand
            ? -1
            : 1;
        case "price-asc":
          return prod1.PriceOuter > prod2.PriceOuter ? 1 : -1;
        case "stock-asc":
          return prod1.StockCardOrder > prod2.StockCardOrder ? 1 : -1;
        default:
          return 1;
      }
    });
    setState((old) => ({ ...old, products: sortedProducts }));
  };

  const onClearAll = () => {
    const resetBrands = state.brands.map((brand) => ({
      ...brand,
      active: false,
    }));
    dispatch(setProductsBrands(resetBrands));
    setState((old) => ({
      ...old,
      categories: old.categories.map((cat) => ({ ...cat, active: false })),
      brands: resetBrands,
      showBestsellers: false,
    }));
  };

  const setProductState = (sku, loading = false, error = "") => {
    setState((old) => ({ ...old, productStatus: { sku, loading, error } }));
  };

  const onProductUpdate = (productData, quantity, type = "increment") => {
    const updateMethod =
      type === "increment" ? addCartProduct : removeCartProduct;
    setProductState(productData?.SKU, true); // set loading
    updateMethod({
      beCartId: cart?.BackEndCartId,
      SKU: productData?.SKU,
      quantity,
    })
      .then((res) => {
        setProductState(productData?.SKU);
      })
      .catch((err) => {
        const errMessage = err?.data?.Message || productError;
        setProductState(productData?.SKU, false, errMessage);
      });
  };

  const onHYCViewFullRange = () => {
    onClearAll();
    setTimeout(() => {
      setState((old) => ({
        ...old,
        products: state.haveYouConsidered.ConsiderProducts,
      }));
    }, 0);
  };

  return (
    <>
      {state.haveYouConsidered && (
        <HaveYouConsidered
          product={state.haveYouConsidered}
          isCart={false}
          specificProductsHandler={onHYCViewFullRange}
        />
      )}
      <div className="bw-container-page bw-place-order bw-bg-white">
        <div className="bw-container">
          <div className="bw-products-filters">
            <Filter
              type="brands-filter"
              active={true}
              count={state.brands?.filter((brand) => brand?.active)?.length}
              label="Filter Brands"
              onSelect={onFilterBrands}
            />
            <Filters
              filters={state.categories}
              disabledFilters={state.disabledCategories}
              type="categories"
              onSelect={(filter, index) =>
                onFilterChange("categories", filter, index)
              }
            >
              <Filter
                type="categories"
                active={state.showBestsellers}
                count={state.bestSellers.length}
                label="Regional Best Sellers"
                onSelect={handleBestSellerToggle}
              />
            </Filters>
          </div>
          <div className="bw-products-filters-sort">
            <div className="bw-flex bw-flex-grow bw-flex-wrap bw-gap-3 bw-items-center">
              <p className="bw-product-count">
                {state.products?.length +
                  ` product${state.products?.length === 1 ? "" : "s"}`}
              </p>
              <Filters
                filters={state.brands}
                type="brands"
                onSelect={(filter, index) =>
                  onFilterChange("brands", filter, index)
                }
              />
              <button
                className="bw-products-filters-clear"
                onClick={onClearAll}
              >
                Clear All
              </button>
            </div>
            <Select
              dataSelected={state.sort}
              selectedItem={productsSortTypes}
              setData={(newVal) =>
                setState((old) => ({ ...old, sort: newVal }))
              }
              className="bw-select-transparent"
            />
          </div>
          <div className="flex">
            <div className="fixed-width">
              {!state.products.length && (
                <p className="bw-text-15 bw-my-3 bw-text-center">No results</p>
              )}
              {state.products.map((product, index, products) => (
                <div key={index}>
                  <ProductCard
                    productData={product || {}}
                    quantity={getProductQuantityFromCart(
                      product.SKU,
                      cart?.BackEndCartDetails
                    )}
                    type="place-order"
                    onAlternativeChange={alternativeChanged}
                    onPeopleAlsoBoughtChange={peopleAlsoBoughtChanged}
                    onIncrement={(productData, quantity) =>
                      onProductUpdate(productData, quantity, "increment")
                    }
                    onDecrement={(productData, quantity) =>
                      onProductUpdate(productData, quantity, "decrement")
                    }
                    loading={
                      state.productStatus?.sku === product?.SKU &&
                      state.productStatus?.loading
                    }
                    error={
                      state.productStatus?.sku === product?.SKU &&
                      state.productStatus?.error
                        ? state.productStatus?.error
                        : ""
                    }
                    className={products.length - 1 === index ? "" : "bw-mb-3"}
                  >
                    {state.suggested[product?.SKU] ? (
                      <ProductCard
                        productData={state.suggested[product?.SKU] || {}}
                        quantity={getProductQuantityFromCart(
                          state.suggested[product?.SKU]?.SKU,
                          cart?.BackEndCartDetails
                        )}
                        type="place-order"
                        onIncrement={(productData, quantity) =>
                          onProductUpdate(productData, quantity, "increment")
                        }
                        onDecrement={(productData, quantity) =>
                          onProductUpdate(productData, quantity, "decrement")
                        }
                        loading={
                          state.productStatus?.sku ===
                            state.suggested[product?.SKU]?.SKU &&
                          state.productStatus?.loading
                        }
                        error={
                          state.productStatus?.sku ===
                            state.suggested[product?.SKU]?.SKU &&
                          state.productStatus?.error
                            ? state.productStatus?.error
                            : ""
                        }
                        className="bw-product-replacement"
                        key={index}
                      />
                    ) : (
                      ""
                    )}
                    {/*{state.peopleAlsoBought[product?.SKU] ? (*/}
                    {/*  <ProductCard*/}
                    {/*    productData={state.peopleAlsoBought[product?.SKU] || {}}*/}
                    {/*    quantity={getProductQuantityFromCart(*/}
                    {/*      state.peopleAlsoBought[product?.SKU]?.SKU,*/}
                    {/*      cart?.BackEndCartDetails*/}
                    {/*    )}*/}
                    {/*    type="place-order"*/}
                    {/*    isPeopleAlsoBought={true}*/}
                    {/*    onIncrement={(productData, quantity) =>*/}
                    {/*      onProductUpdate(productData, quantity, "increment")*/}
                    {/*    }*/}
                    {/*    onDecrement={(productData, quantity) =>*/}
                    {/*      onProductUpdate(productData, quantity, "decrement")*/}
                    {/*    }*/}
                    {/*    loading={*/}
                    {/*      state.productStatus?.sku ===*/}
                    {/*        state.peopleAlsoBought[product?.SKU]?.SKU &&*/}
                    {/*      state.productStatus?.loading*/}
                    {/*    }*/}
                    {/*    error={*/}
                    {/*      state.productStatus?.sku ===*/}
                    {/*        state.peopleAlsoBought[product?.SKU]?.SKU &&*/}
                    {/*      state.productStatus?.error*/}
                    {/*        ? state.productStatus?.error*/}
                    {/*        : ""*/}
                    {/*    }*/}
                    {/*    className="bw-product-replacement"*/}
                    {/*    key={index}*/}
                    {/*  />*/}
                    {/*) : (*/}
                    {/*  ""*/}
                    {/*)}*/}
                  </ProductCard>
                </div>
              ))}
            </div>
            <div className="bw-before-go-cart-items bw-gray-box bw-sticky-cart">
                <p className={`bw-text-16 bw-font-medium bw-before-go-item ${cart?.BackEndCartDetails?.length > 12 && 'bw-mr-4 md:bw-mr-2'}`}>
                  <span>ITEMS ADDED TO CART</span>
                  <span>{cart?.TotalItems}</span>
                </p>
                {cart?.BackEndCartDetails?.length ? (
                  <div className="bw-added-to-cart-height">
                    {cart?.BackEndCartDetails?.map(
                      ({ Product, Quantity }, index) => (
                        <p className="bw-before-go-item bw-text-15" key={index}>
                          <span className="bw-pr-2">
                            {Product.Product} ({Quantity})
                          </span>
                          <span className="bw-font-bold">
                            {toCurrencyString(Product.PriceOuter * Quantity)}
                          </span>
                        </p>
                      )
                    )}
                  </div>
                ) : (
                  ""
                )}
                <Link to="/cart" className="bw-proceed-to-button">
                  <Button label="Proceed to Checkout" />
                </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlaceOrder;
