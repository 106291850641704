import React from "react";
import Individual from "./Individual";
import Multiple from "./Multiple";
import Range from "./Range";

function HaveYouConsidered({
                             product,
                             isCart,
                             specificProductsHandler,
                           }) {
  if (product === null) {
    return false;
  }

  return (
    <div>
      {product.Type === "Individual" && <Individual bannerData={product} />}
      {product.Type === "Multiple" && <Multiple bannerData={product} />}
      {product.Type === "Range" && (
        <Range
          bannerData={product}
          handleSpecificProducts={specificProductsHandler}
          isCart={isCart}
        />
      )}
    </div>
  );
}

export default HaveYouConsidered;
