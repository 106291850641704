export const path = "/assets/p4p/";

export const p4pItems = [
  {
    "title": "2019 Guide to Tobacco & E-Vapour",
    "description": "Download the entire P4P brochure here.",
    "pdf": "JTIIRE-PartnersForProgressBrochure.pdf",
    "thumb": "2019-guide-to-tobacco-evapour.jpg",
    "link": "/pdf/p4p/2019-guide-to-tobacco-evapour",
    "video": ""
  },
  {
    "title": "Economic landscape",
    "description": "An overview of the Irish Economic Landscape in 2019, including population and employment rates, Brexit sentiment, and internet usage.",
    "pdf": "1-JTIIRE-EconomicLandscape.pdf",
    "thumb": "economic-landscape.jpg",
    "link": "/pdf/p4p/economic-landscape"
  },
  {
    "title": "Grocery Market",
    "description": "A review of the Irish Grocery Market, including overall performance in the market, and a demographic profile of shoppers.",
    "pdf": "2-JTIIRE-GroceryMarket.pdf",
    "thumb": "grocery-market.jpg",
    "link": "/pdf/p4p/grocery-market"
  },
  {
    "title": "Irish Tobacco Category",
    "description": "A focused review of the tobacco category in Ireland, including breakdowns between RMC and RYO and by channel.",
    "pdf": "3-JTIIRE-IrishTobaccoCategory.pdf",
    "thumb": "irish-tobacco-category.jpg",
    "link": "/pdf/p4p/irish-tobacco-category"
  },
  {
    "title": "What is Vaping",
    "description": "An overview of vaping, including device types and an introduction to the Logic range.",
    "pdf": "4-JTIIRE-WhatIsVaping.pdf",
    "thumb": "what-is-vaping.jpg",
    "link": "/pdf/p4p/what-is-vaping"
  },
  {
    "title": "E-Vapour Performance",
    "description": "In 2018, the vaping category was estimated at €61 million, find out more about the split by market share, user demographics, and overall use.",
    "pdf": "5-JTIIRE-eVapingPerformance.pdf",
    "thumb": "evapour-performance.jpg",
    "link": "/pdf/p4p/evapour-performance"
  },
  {
    "title": "Merchandising",
    "description": "There are clear touch points on the path to purchase. Find out how you can optimise visibility and deliver clear communications on this route, engaging and encouraging adult smokers and vapers to explore your store.",
    "pdf": "6-JTIIRE-Merchandising.pdf",
    "thumb": "merchandising.jpg",
    "link": "/pdf/p4p/merchandising"
  },
  {
    "title": "Track & Trace",
    "description": "Track & Trace regulation is now in place – here’s what it means for you.",
    "pdf": "7-JTIIRE-TrackAndTrace.pdf",
    "thumb": "track-trace.jpg",
    "link": "/pdf/p4p/track-and-trace"
  },
  {
    "title": "Illegal Tobacco",
    "description": "The Illegal Tobacco Trade is a serious global problem: taken as a whole it would rank as the 3rd biggest tobacco ‘company’ in the world.",
    "pdf": "8-JTIIRE-IllegalTobacco.pdf",
    "thumb": "illegal-tobacco.jpg",
    "link": "/pdf/p4p/illegal-tobacco"
  },
  {
    "title": "How to Connect with JTI",
    "description": "Through Telesales, Business Advisors and JTI Engage there are multiple ways to connect with JTI. Find out more here.",
    "pdf": "9-JTIIRE-HowToConnectWithJTI.pdf",
    "thumb": "how-to-connect-with-jti.jpg",
    "link": "/pdf/p4p/how-to-connect-with-jti"
  }
];