import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { toCurrencyString } from 'Functions/functions'
// import productHelper from 'Functions/product.helper'

class ProductBar extends React.Component {
  render() {
    const { total, subTotal } = this.props

    return (
      <div className="products-info_bar">
        <div className="fontsize17 hidden-s">Did you forget anything?</div>
        <div className="products-info_bar-total">
          <div className="fontsize17">
            Total: <b>{toCurrencyString(subTotal)}</b>
          </div>
          <div>
            <Link to="/cart" className="button_style-1 fontsize17">
              View cart
              <span className="products-info_bar-cart">{total}</span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

function stateMapping(state) {
  let suggestCount = 0,
    suggestCost = 0

  if (state.prod.suggestsCheckout.length) {
    state.prod.suggestsCheckout.map((item) => {
      suggestCount += item.quantity
      suggestCost += item.price * item.quantity
    })
  }

  return {
    total: state.app.cart?.TotalItems || 0,
    subTotal: state.app.cart?.SubTotal || 0,
  }
}

export default connect(stateMapping)(ProductBar)
