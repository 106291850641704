import {
  getScreenWidth,
  getScreenHeight
} from 'Functions/helper'

import TriangledBackBlock from 'Components/TriangledBackBlock'
import TriangledBackBeta from 'Components/TriangledBackBeta'

class TriangledBack extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.totalBlocks = 4;
    
    this.state = {
      current: 1,
      startTriangle: false,
    }
  }
  
  render() {
    const { triangles } = this.state;
    
    var blocks = [];
    for(let i = 0; i < this.state.current; i++) {
      blocks.push(<TriangledBackBlock key={ i } index={ i } endHandler={ ()=>this.endHandler() }/>)
    }
    
    return (
      <React.Fragment>
        <div className="triangled triangled-whole_animation no-print">
        {
          blocks
        }
        </div>
        
        {
          this.state.startTriangle && (
            <TriangledBackBeta/>
          )
        }
      </React.Fragment>
    )
  }
  
  endHandler() {
    const next = this.state.current + 1;
    if(next <= this.totalBlocks) {
      
      this.setState({ current: next });
    
    } else {
      
      this.setState({ startTriangle: true });
      
    }
  }
  
  componentWillUnmount() {}
}

export default TriangledBack