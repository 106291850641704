import TandCContent from 'Components/blocks/TandCContent'
import { connect } from 'react-redux'

class TermsConditions extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { history } = this.props

    return (
      <>
        <div className="page page-pad-L page-default">
          <h3 className="page_title">Terms and Conditions</h3>

          <TandCContent />
        </div>
      </>
    )
  }

  componentDidMount() {}
}

export default connect()(TermsConditions)
