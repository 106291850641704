class Declaration extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { history } = this.props

    return (
      <>
        <div className="page page-pad-L page-default page-declaration">
          <h3 className="page_title">
            Declaration of REACH Substances of Very High Concern
          </h3>

          <p>
            Regulation No 1907/2006 on the Registration, Evaluation,
            Authorisation and Restriction of Chemicals (REACH) entered into
            force on June 1, 2007. Under REACH, companies operating in the EU
            face certain obligations as manufacturers, importers and/or
            suppliers. One of the key requirements is the duty to communicate
            information on substances in articles (Article 33).
          </p>

          <h4 className="section_title">
            Article 33: Information for Recipients & Customers
          </h4>

          <p>
            Article 33 of REACH requires suppliers to inform recipients and
            respond to consumer enquiries if an article contains more than 0.1%
            (by weight) of a substance of very high concern (SVHC) which is on
            the SVHC candidate list published by the European Chemicals Agency
            (ECHA).
          </p>

          <p>
            In accordance with the requirements of REACH and to allow safe use
            of the product, JTI hereby informs you of the presence of the
            following SVHC contained in Logic products in concentrations above
            0.1% weight by weight (w/w):
          </p>

          <div className="table">
            <div className="head_row">
              <div>
                <div>Substance</div>
              </div>
              <div>
                <div>CAS no.</div>
              </div>
              <div>
                <div>EC no.</div>
              </div>
              <div>
                <div>Individual article affected and their application</div>
              </div>
              <div>
                <div>Safe use information</div>
              </div>
            </div>

            <div>
              <div>
                <span className="head_column">Substance</span>
                <div>Lead</div>
              </div>
              <div>
                <span className="head_column">CAS no.</span>
                <div>7439-92-1</div>
              </div>
              <div>
                <span className="head_column">EC no.</span>
                <div>231-100-4</div>
              </div>
              <div>
                <span className="head_column">
                  Individual article affected and their application
                </span>
                <div>
                  <ul className="disc" style={{ marginBottom: '1em' }}>
                    <li>
                      Brass fittings, connectors and circuit board resistors of
                      electronic cigarettes '<b>Logic Compact</b>' and '
                      <b>Logic LQD</b>'
                    </li>
                    <li>
                      Brass mouthpiece fittings of electronic cigarette '
                      <b>Logic LQD</b>'
                    </li>
                  </ul>
                  <p>
                    The substance is used to improve the electrical
                    conductivity, the corrosion resistance and the
                    manufacturability of the material
                  </p>
                </div>
              </div>
              <div>
                <span className="head_column">Safe use information</span>
                <div>
                  <ol>
                    <li>
                      No exposure to the substance is expected due to its
                      inclusion within an alloy brass, and the presence of a
                      plating layer covering the parts concerned
                    </li>
                    <li>
                      In accordance with the European WEEE Directive Logic
                      Devices should not be disposed of with other household
                      waste at the end of their working life. Please see WEEE
                      instructions supplied with the product on correct disposal
                      channels
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div>
              <div>
                <span className="head_column">Substance</span>
                <div>1,3-propanesultone</div>
              </div>
              <div>
                <span className="head_column">CAS no.</span>
                <div>1120-71-4</div>
              </div>
              <div>
                <span className="head_column">EC no.</span>
                <div>214-317-9</div>
              </div>
              <div>
                <span className="head_column">
                  Individual article affected and their application
                </span>
                <div>
                  <p>Electrolyte in battery of 'Logic Compact'.</p>
                  <p>
                    This substance enables the battery to work effectively at
                    high and low temperatures
                  </p>
                </div>
              </div>
              <div>
                <span className="head_column">Safe use information</span>
                <div>
                  <p>
                    In accordance with the European WEEE Directive Logic Compact
                    battery should not be disposed of with other household waste
                    at the end of their working life. Please see WEEE
                    instructions supplied with the product on correct disposal
                    and recycling channels.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p>
            JTI reserves the right to update and modify this communication, as
            it believes necessary or appropriate. For more information about
            REACH, please access the ECHA website:{' '}
            <a href="http://echa.europa.eu" target="_blank">
              http://echa.europa.eu
            </a>
            .<br />
            The SVHC candidate list can be found at: ECHA Website -{' '}
            <a
              href="https://echa.europa.eu/candidate-list-table"
              target="_blank"
            >
              https://echa.europa.eu/candidate-list-table
            </a>
            .
          </p>
        </div>
      </>
    )
  }
}

export default Declaration
