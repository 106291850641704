import React from 'react'
import PropTypes from 'prop-types'

const OrderEditing = (props) => {
  const { orderId } = props

  return (
    <div className="bw-infobar bw-editing-order">
      <div className="bw-container bw-flex bw-justify-center">
        <p className="bw-text-14">Currently editing order ({orderId}).</p>
      </div>
    </div>
  )
}

OrderEditing.propTypes = {
  orderId: PropTypes.string,
}

OrderEditing.defaultProps = {
  orderId: '',
}

export default OrderEditing
