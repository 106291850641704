import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { toggleLoader, showError, openPopup, closePopup } from 'Redux/actions.app'

import Video from 'Components/blocks/Video'

class JustAfterLogin extends React.Component {
  
  /*constructor(props) {
    super(props)
    
    this.state = {
      banner: '',
    }
  }*/
  
  render() {
    const { banner: { title, image, video, autoPlay, loop , AssetPath, CTA } } = this.props;

    const copy = CTA ? CTA : 'More information available here.';
    
    return (
      <div className="popup-just_after_login">
  
        {
          video !== void 0 ? (
            <Video image={ image } video={ video } autoplay={ autoPlay } loop={ loop }/>
          ) : (
            <div>
              <img src={ image }/>
            </div>
          )
        }

        {AssetPath  &&
          <p style={{marginTop: '15px'}}><a href={AssetPath} target="_blank" rel="noopener noreferrer">{copy}</a></p>
        }
        
      </div>
    )
  }
  
  toHome(e) {
    e.preventDefault();
    
    const { redirect } = this.props;
    redirect(e, '/');
  }
  
  /*componentWillMount() {
    const { banner } = this.props;
    
    const banner = 'https://jtistoragedev.blob.core.windows.net/content/video/Compact Product Demo_360_SD.mp4';
    
    this.setState({ banner: banner });
  }*/
}

JustAfterLogin.propTypes = {
  banner: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default connect()(JustAfterLogin)