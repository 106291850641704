import axios from "../index";

export async function getActiveSurvey(section, setSurvey) {
  await axios
    .get(`/api/Survey/active?section=${section}`)
    .then((response) => {
      if (response && response.data) {
        setSurvey(response.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function submitQuestion(surveyId, questionId, answer) {
  await axios
    .post(`/api/Survey/submit`, {
      SurveyId: surveyId,
      QuestionId: questionId,
      Answers: answer
    })
}

export async function markSurveyAsCompleted(surveyId) {
  await axios
    .get(`/api/Survey/${surveyId}/complete`)
}

export async function onSurveyStart(surveyId) {
  await axios
    .get(`/api/Survey/${surveyId}/entered`)
}

export async function onSurveyClose(surveyId, isClosedFromSticky = false) {
  await axios
    .get(`/api/Survey/${surveyId}/closed${isClosedFromSticky ? '?fromSticky=true' : ''}`)
}

export async function onEmailSubmit(surveyId, email, subscribeCheckboxState) {
  await axios
    .get(`/api/Survey/${surveyId}/submitemail?email=${email}&OptIn=${subscribeCheckboxState}`)
}
