import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getMyOrders } from "../../axios/private/account";
import { getOrderStatus } from "../../utils/functions/order";
import formatDate from "../../utils/functions/formatDate";
import {
  setCurrentOrders,
  setOpenDrawer,
} from "../../script/redux/actions.app";

import { defaultSortInterval } from "../../utils/constants";
import { GA } from "../../script/functions/ga";
import OrderMethod from "../../components/Orders/OrderMethod";
import useScrollBackToTop from "../../utils/hooks/useScrollBackToTop";

const MyOrders = ({ orders, dispatch, currentAccount }) => {
  useEffect(() => {
    getOrders();
  }, []);

  useScrollBackToTop()

  const getOrders = async (filter = defaultSortInterval) => {
    await getMyOrders(filter).then((orders) => {
      dispatch(setCurrentOrders(orders, currentAccount))
    });
  };

  const switchDrawer = (e) => {
    e.preventDefault();
    dispatch(setOpenDrawer("FILTER", true));
  };

  return (
    <div className="bw-my-orders bw-bg-white">
      <div className="bw-container-page">
        <div className="bw-container">
          <h1 className="bw-h1 bw-mt-0 bw-mb-5">My Orders</h1>
          <p className="bw-text-15 bw-mb-6">
            All historic product quantities are correct at time of purchase,
            product price is based on current pricing.
          </p>
          <p className="bw-text-14 bw-mb-20">
            Please note that the order will not be processed until the date
            selected, delivery will be made within our standard timeframe (for
            more details please see our FAQs). How long will delivery of my
            order take?). Orders can be edited, cancelled or the post-order date
            changed if the status is Pending. If the status is Submitted the
            order is in process and no amends can be made online.
          </p>
        </div>
      </div>
      <table className="bw-my-orders-table">
        <thead>
          <tr>
            <th className="bw-my-orders__hidden-cell"></th>
            <th scope="col">Order No.</th>
            <th scope="col">Order Date</th>
            <th scope="col">Order Method</th>
            <th scope="col">Status</th>
            <th scope="col">Invoice No.</th>
            <th scope="col" className="bw-my-orders__select">
              <div className="bw-select-dropdown" onClick={switchDrawer}>
                <div className="bw-dropdown-selected relative">
                  Sort
                  <svg
                    viewBox="0 0 11 6"
                    className="bw-dropdown-arrow bw-pointer-events-none  "
                  >
                    <g stroke="none" strokeWidth="1" fillRule="evenodd">
                      <g
                        id="chcDropdownArrow"
                        transform="translate(-719.000000, -953.000000)"
                        fillRule="nonzero"
                      >
                        <g transform="translate(520.000000, 795.000000)">
                          <g transform="translate(0.000000, 142.000000)">
                            <g transform="translate(204.909091, 18.681818) rotate(90.000000) translate(-204.909091, -18.681818) translate(201.409091, 12.681818)">
                              <path
                                d="M1.18145032,2.29532917 C0.898481345,1.98666289 0.870184448,1.49279683 1.15315342,1.18413054 C1.4078255,0.875464249 1.88887276,0.84459762 2.17184173,1.15326391 L6.33148566,5.44372531 C6.64275153,5.7523916 6.64275153,6.24625766 6.33148566,6.58579058 L2.17184173,10.876252 C1.88887276,11.1540516 1.4078255,11.1540516 1.15315342,10.8145187 C0.870184448,10.5058524 0.898481345,10.0119864 1.18145032,9.73418672 L4.8034532,5.99932463 L1.18145032,2.29532917 Z"
                                id="Path"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </th>
            <th className="bw-my-orders__hidden-cell"></th>
          </tr>
        </thead>
        <tbody>
          {!orders?.length && (
            <tr>
              <td colSpan="12">
                <p className="bw-text-15 bw-my-3 bw-text-center">
                  No orders to show for selected interval.
                </p>
              </td>
            </tr>
          )}
          {orders?.map((order, index) => (
            <tr key={index}>
              <td className="bw-my-orders__hidden-cell"></td>

              <td data-label="Order No.">
                {order?.OrderNumber}
                <Link
                  to={`/review-order?ordernumber=${order?.OrderNumber}`}
                  className="bw-btn-link bw-btn-link--show-tablet"
                  onClick={() => GA("Review Order", "My Orders", "click", currentAccount.JtiAccountId)}
                >
                  Review
                </Link>
              </td>

              <td data-label="Order Date">
                {formatDate(order?.OrderDate, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </td>

              <td data-label="Order Method">
                <div className="bw-my-orders__order-method">
                  <OrderMethod
                    type="Web Sales Order"
                    active={order?.OrderType === "Web Sales Order"}
                  />
                  <OrderMethod
                    type="Telesales Order"
                    active={order?.OrderType === "Telesales Order"}
                  />
                  <OrderMethod
                    type="Sales Order"
                    active={order?.OrderType === "Sales Order"}
                  />
                  <OrderMethod
                    type="Ex-Car Order"
                    active={order?.OrderType === "Ex-Car Order"}
                  />
                </div>
              </td>

              <td data-label="Status">{getOrderStatus(order?.Status)}</td>

              <td data-label="Invoice No.">
                <Link
                    to={`/invoice?ordernumber=${order?.OrderNumber}`}
                >
                  {order?.InvoiceNumber}
                </Link>
              </td>

              <td className="bw-btn-link--hide-tablet">
                <Link
                  to={`/review-order?ordernumber=${order?.OrderNumber}`}
                  className="bw-btn-link"
                  onClick={() => GA("Review Order", "My Orders", "click", currentAccount.JtiAccountId)}
                >
                  Review
                </Link>
              </td>

              <td className="bw-my-orders__hidden-cell"></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function stateMapping(state) {
  return {
    currentAccount: state.app.currentAccount,
    orders: state.app.currentOrders
  }
}

export default connect(stateMapping)(MyOrders);