export default function ColorLabel({
  className = "",
  fill = true,
  color = "#407EC9",
  children,
}) {
  return (
    <div
      className={`color-label ${className}`}
      style={{
        backgroundColor: fill ? color : "#FFF",
        border: fill ? "none" : `1px solid ${color}`,
        color: fill ? "#FFF" : color,
      }}
    >
      <p>{children}</p>
    </div>
  );
}
