import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import qs from 'query-string'
import OrderMethod from "../../components/Orders/OrderMethod";

import {
  getOrderDetails,
  loadPendingOrderIntoCart,
} from '../../axios/private/orders'
import { addCartProduct, getCart } from '../../axios/private/cart'
import { openPopup } from '../../script/redux/actions.app'
import { generalError, orderError } from '../../utils/constants'
import { toCurrencyString } from '../../utils/functions/currency'
import { getOrderStatus } from '../../utils/functions/order'
import { dateFormat } from '../../utils/functions/formatDate'
import { GA } from '../../script/functions/ga'

import ProductCard from '../../components/Product/Card'
import Button from '../../components/form/Button'
import Preloader from '../../script/components/Preloader'

function ReviewOrder() {
  const dispatch = useDispatch()
  const location = useLocation()
  const cart = useSelector(state => state.app.cart)
  const [state, setState] = useState({
    order: null,
    loading: false,
    availableProducts: [],
    outStockProducts: [],
    orderAvailable: false,
  })

  useEffect(() => {
    getOrderData()
  }, [])

  const getOrderData = async () => {
    const params = qs.parse(location.search)
    if (!params.ordernumber) {
      dispatch(openPopup('alert', orderError, ''))
      return false
    }
    setState((old) => ({ ...old, loading: true }))
    await getOrderDetails(params.ordernumber)
      .then((order) => {
        const { availableProducts, outStockProducts } = getProducts(
          order?.OrderHistoryDetails
        )
        setState((old) => ({
          ...old,
          order,
          availableProducts,
          outStockProducts,
          orderAvailable: availableProducts.length && !params.confirmation,
        }))
      })
      .catch((err) => dispatch(openPopup('alert', orderError, '')))
    setState((old) => ({ ...old, loading: false }))
  }

  const getProducts = (products = []) => {
    return products.reduce(
      (acc, prod) => {
        if (!prod.OutOfStock && !prod.IsArchived) {
          acc.availableProducts.push(prod)
        } else {
          acc.outStockProducts.push(prod)
        }
        return acc
      },
      {
        availableProducts: [],
        outStockProducts: [],
      }
    )
  }

  const onPrint = () => {
    GA('Review Order', 'Button :: Print order')
    window.print()
  }

  const onAddToCart = () => {
    setState((old) => ({ ...old, loading: true }))
    // add all available products and get the cart updated
    Promise.all(
      state.availableProducts.map((product) =>
        addCartProduct({
          beCartId: cart?.BackEndCartId,
          SKU: product.SKU,
          quantity: product?.Quantity,
        })
      )
    )
      .then((res) => {
        setState((old) => ({ ...old, loading: false }))
        dispatch(
          openPopup('review-order', '', '', [
            'checkout',
            state.availableProducts.length,
            state.outStockProducts,
          ])
        )
        getCart()
      })
      .catch((err) => {
        setState((old) => ({ ...old, loading: false }))
        const errMessage = err?.data?.Message || generalError
        // dispatch(openPopup('alert', errMessage, ''))
        getCart()
      })
  }

  const onCancel = () => {
    dispatch(
      openPopup(
        'cancel-order',
        'Are you sure you want to cancel this order?',
        '',
        [state.order?.OrderNumber]
      )
    )
  }

  const onEdit = () => {
    dispatch(
      openPopup('review-order', '', '', [
        'edit',
        state.availableProducts.length,
        state.outStockProducts,
      ])
    )
    loadPendingOrderIntoCart(state.order?.OrderNumber)
  }

  return (
    <div className="bw-container-page bw-review-order">
      <div className="bw-container bw-relative">
        <h1 className="bw-h1 bw-mt-0 bw-mb-4">Review your order</h1>
        <h4 className="bw-h4 bw-mb-8">{state.order?.OrderNumber}</h4>
        <div className="bw-top-actions">
          <Link to="/my-orders" className="bw-back-to">
            <svg
              id="ARROW_L"
              data-name="ARROW L"
              xmlns="http://www.w3.org/2000/svg"
              width="9.725"
              height="15.557"
              viewBox="0 0 9.725 15.557"
            >
              <path
                id="Union_3"
                data-name="Union 3"
                d="M23294.447,20463.4l-1.947-1.947,7.777-7.777,1.947,1.947-5.834,5.834,5.834,5.832-1.947,1.941Z"
                transform="translate(-23292.5 -20453.674)"
                fill="#00b2a9"
              />
            </svg>
            <span className="bw-ml-2">Back to Order list</span>
          </Link>
          {/* {!cart?.PendingOrderNumber &&
            state.orderAvailable &&
            !/^(1|2)$/.test(state.order?.Status) && (
              <Button
                label="Add All to Cart"
                className="bw-mb-3"
                onClick={onAddToCart}
              ></Button>
            )} */}
          {!cart?.PendingOrderNumber && state.order?.Status === 1 && (
            <div className="bw-flex">
              <Button
                label="Cancel Order"
                className="bw-button--secondary bw-mr-1 bw-mb-3"
                onClick={onCancel}
              ></Button>
              <Button
                label="Edit Order"
                className="bw-mb-3"
                onClick={onEdit}
              ></Button>
            </div>
          )}
        </div>
        <div className="bw-flex bw-flex-col md:bw-flex-row">
          <div className="bw-products">
            {state.order?.OrderHistoryDetails?.map(
              ({ ProductDetails, Quantity }, index, products) => (
                <ProductCard
                  productData={ProductDetails || {}}
                  quantity={Quantity}
                  className={products.length - 1 === index ? '' : 'bw-mb-3'}
                  type="cart"
                  key={index}
                />
              )
            )}
          </div>
          <div className="bw-gray-box bw-sticky-cart">
            <p className="bw-text-16 bw-font-medium bw-mb-5">YOUR ORDER</p>
            <div className="bw-mb-10">             
              <div className="bw-order-details">
                <label>Order No.</label>
                <p>{state.order?.OrderNumber}</p>
              </div>
              <div className="bw-order-details">
                <label>{`Order Date ${
                  state.order?.Status === 1 ? '(Post order date)' : ''
                }`}</label>
                <p>
                  {state.order?.OrderDate
                    ? dateFormat(state.order.OrderDate)
                    : ''}
                </p>
              </div>
              <div className="bw-order-details">
                <label>Order Method</label>
                {state.order?.OrderType === "Web Sales Order"}
                <div className="ss-my-orders__order-method">
                  <OrderMethod
                    type="Web Sales Order"
                    active={state.order?.OrderType === "Web Sales Order"}
                  />
                  <OrderMethod
                    type="Telesales Order"
                    active={state.order?.OrderType === "Telesales Order"}
                  />
                  <OrderMethod
                    type="Sales Order"
                    active={state.order?.OrderType === "Sales Order"}
                  />
                  <OrderMethod
                    type="Ex-Car Order"
                    active={state.order?.OrderType === "Ex-Car Order"}
                  />
                </div>
              </div>
              <div className="bw-order-details">
                <label>Status</label>
                <p>{getOrderStatus(state.order?.Status)}</p>
              </div>
              <div className="bw-order-details">
                <label>Invoice No.</label>
                <p><a href={`/invoice?ordernumber=${state.order?.OrderNumber}`} target='blank'> {state.order?.InvoiceNumber}</a></p>
              </div>
              <div className="bw-order-details">
                <label>Number of items</label>
                <p>{state.order?.NumberOfItems}</p>
              </div>
            </div>
            <div className="bw-order-cost">
              <p>Sub-total</p>
              <p>{state.order && toCurrencyString(state.order?.SubTotal)}</p>
            </div>
            <div className="bw-order-cost">
              <p>VAT @ {state.order?.VAT * 100}%</p>
              <p>{state.order && toCurrencyString(state.order?.VatTotal)}</p>
            </div>
            <div className="bw-order-cost">
              <p>Order total</p>
              <p>{state.order && toCurrencyString(state.order?.OrderTotal)}</p>
            </div>
            {/* <Button
              label="Print Order"
              className="bw-button--black bw-mt-5"
              onClick={onPrint}
            ></Button> */}
          {!cart?.PendingOrderNumber &&
            state.orderAvailable &&
            !/^(1|2)$/.test(state.order?.Status) && (
              <Button
                label="Add All to Cart"
                className="bw-mb-3"
                onClick={onAddToCart}
              ></Button>
            )}
          </div>
        </div>
        {state.loading && <Preloader />}
      </div>
    </div>
  )
}

export default ReviewOrder
