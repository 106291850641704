import productHelper from '../../script/functions/product.helper'
import { setSuggestedProducts } from '../../script/redux/actions.app'
import store from '../../script/redux/store'
import axios from '../index'
import { setAllProducts } from '../../script/redux/actions.app'

export const getBeforeYouGo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Product/GetBeforeYouGo')
      .then((res) => resolve(res?.data?.Products || []))
      .catch((err) => reject(err.response))
  })
}

export const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Product/AllProducts')
      .then((res) => {
        const products = res?.data?.Products || []
        productHelper.init(products)
        store.dispatch(setAllProducts(products))
        resolve(products)
      })
      .catch((err) => reject(err.response))
  })
}

export const getSuggestedProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Product/GetSuggestedProducts')
      .then((res) => {
        const products = res?.data || {}
        store.dispatch(setSuggestedProducts(products))
        resolve(products)
      })
      .catch((err) => reject(err.response))
  })
}

export const getAlternativeProduct = (sku) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/Product/AlternativeProduct?SKU=${sku}`)
      .then((res) => {
        const product = res?.data || null
        resolve(product)
      })
      .catch((err) => reject(err.response))
  })
}

export const getPeopleAlsoBought = (sku) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/Product/GetPeopleAlsoBought?SKU=${sku}`)
      .then((res) => {
        const products = res?.data || null
        resolve(products)
      })
      .catch((err) => reject(err.response))
  })
}

export const getHaveYouConsidered = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Product/GetHaveYouConsideredProducts')
      .then((res) => {
        const products = res?.data || null
        resolve(products)
      })
      .catch((err) => reject(err.response))
  })
}
