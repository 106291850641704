import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'

import {gaPageView, trackingDomReady} from '../../script/functions/ga'
import {checkAuth, getAccounts} from '../../axios/auth'
import {checkTrackAndTrace} from '../../axios/private/account'
import {getHomeAssets} from '../../axios/private/content'
import {addCartSuggestedProducts, getCart} from '../../axios/private/cart'
import {getAllProducts, getSuggestedProducts,} from '../../axios/private/products'
import {
  hidePopup,
  setOpenDrawer,
  setTrackAndTrace,
  showError,
  toggleLoader,
  toggleNavMenu,
} from '../../script/redux/actions.app'
import {productLoaded, showSuggested, toggleInformation,} from '../../script/redux/actions.prod'
import {orderFilters} from '../../utils/constants'

// Components
import Header from '../Header'
import Footer from '../Footer'
import Widgets from '../Widgets'
// Old components
import Popup from '../../script/components/Popup'
import ScrollBackToTop from '../../script/components/ScrollBackToTop'
import TrackTraceWarn from '../../script/components/blocks/TrackTraceWarn'
import Drawer from '../Drawer'
import StoreSelector from '../Drawer/StoreSelector'
import OrdersFilter from '../Drawer/Filter/Orders'
import ProductsFilter from '../Drawer/Filter/Products'
import {getTier} from '../../utils/functions/tier'
import {getActiveSurvey, onSurveyClose, onSurveyStart} from "../../axios/private/survey";
import SurveyPopupModal from "../../script/components/survey/SurveyPopupModal";
import Survey from "../../script/components/survey/Survey";

const PrivateTemplate = withRouter((props) => {
  const {
    children,
    history,
    location,
    dispatch,
    hasBlur,
    navMenu,
    backToTop,
    popup,
    trackAndTrace,
    drawer,
    userAccounts,
    currentAccount
  } = props
  let getCartInterval = null

  const [state, setState] = useState({
    widgets: [],
    authentication: false,
    redirectTo: false,
  });
  const [survey, setSurvey] = useState(null);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(true);
  const [wasSurveyDismissed, setWasSurveyDismissed] = useState(false);
  const [wasSurveyContinued, setWasSurveyContinued] = useState(false);

  const surveyURLs = {
    ["/"]: "Home",
    ["/place-order"]: "Place%20Order",
    ["/my-orders"]: "My%20Orders",
    ["/videos"]: "Videos",
    ["/p4p"]: "Partner%20Hub",
    ["/cart"]: "Cart",
    ["/order-confirmation"]: "Order%20Confirmation",
    ["/review-order"]: "Review%20Order",
    ["/stock-card"]: "Stockcard",
    ["/my-account"]: "My%20Account",
    ["/change-password"]: "Change%20Password",
    ["/faqs"]: "FAQs",
    ["/vlogs"]: "Vlogs",
  }

  const screenClick = () => {
    dispatch(toggleInformation(''))
  }

  const checkLoggedIn = async () => {
    const isLoggedIn = await checkAuth()
      .then((res) => true)
      .catch((err) => false)
    if (!isLoggedIn) {
      const redirectTo =
        location.pathname === '/cookies-policy'
          ? '/cookies-policy-public'
          : '/login'
      setState((oldVal) => ({...oldVal, redirectTo}))
      return false
    }
    return true
  }

  const checkTier = () => {
    const tierLevel = getTier(currentAccount?.TierAccessLevel)
    if (tierLevel === 3) {
      switch (location?.pathname) {
        case '/my-orders':
        case '/place-order':
        case '/cart':
          setState((oldVal) => ({...oldVal, redirectTo: '/'}))
          break
        default:
          break
      }
    }
  }

  const getLayoutData = async () => {
    const isLoggedIn = await checkLoggedIn()
    if (!isLoggedIn) return false
    dispatch(toggleLoader(true))
    await getAccounts()
    setState((oldVal) => ({...oldVal, authentication: true}))

    const cartData = await getCart()
      .then((res) => res)
      .catch((err) => null)
    await getAllProducts()
      .then((res) => {
        dispatch(productLoaded())
      })
      .catch((err) => {
      })

    // get suggested products and add them to cart
    const suggestedProducts = await getSuggestedProducts()
      .then((res) => res)
      .catch((err) => null)
    if (suggestedProducts) {
      await addCartSuggestedProducts(cartData?.BackEndCartId)
        .then((res) => dispatch(showSuggested(true)))
        .catch((err) => err)
    }

    dispatch(toggleLoader(false))

    getHomeAssets().then((assets) => {
      setState((oldVal) => ({...oldVal, widgets: assets}))
    })
    checkTrackAndTrace().then((data) => {
      if (!data) {
        dispatch(setTrackAndTrace(2))
      }
    })
  }

  const closeDrawer = (type) => {
    dispatch(setOpenDrawer(type, false))
  }

  const getContainerClasses = () => {
    let classes = "container animated fadeIn";
    switch (location?.pathname) {
      case "/":
        classes += " home";
        break;
      case "/cookies-policy":
      case "/declaration":
      case "/videos":
      case "/privacy-policy":
      case "/terms-conditions":
      case "/faqs":
        classes += " bw-bg-white";
        break;
      default:
        break
    }
    return classes
  }

  useEffect(() => {
    if (getCartInterval) clearInterval(getCartInterval)
    // get cart on every 30s
    getCartInterval = setInterval(() => {
      getCart()
        .then((res) => res)
        .catch((err) => {
        })
    }, 30000)
    getLayoutData()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(showError(''))
    dispatch(hidePopup())
    dispatch(toggleNavMenu(false))
    checkLoggedIn()
    trackingDomReady();
    if (currentAccount && currentAccount.JtiAccountId) {
      gaPageView(location?.pathname, currentAccount.JtiAccountId);
    }
    setSurvey(false);
    getActiveSurvey(
      surveyURLs[location.pathname],
      setSurvey
    );
  }, [location]);

  useEffect(() => {
    if (currentAccount && currentAccount.JtiAccountId) {
      gaPageView(location?.pathname, currentAccount.JtiAccountId);
    }
  }, [currentAccount]);

  useEffect(() => {
    checkTier();
  }, [currentAccount, location]);

  useEffect(() => {
    if (isSurveyModalOpen && survey && survey.ShowModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  }, [isSurveyModalOpen]);

  return (
    <>
      {state.redirectTo && <Redirect to={state.redirectTo}/>}
      {!state.authentication ? (
        <div className="wrapper-main"/>
      ) : (
        <>
          {survey && (survey.ShowModal || location.search.includes('?survey=true')) && isSurveyModalOpen && (
            <SurveyPopupModal
              introMessage={survey.Survey.StartHtml}
              termsAndConditions={survey.Survey.TermsHtml}
              startSurvey={() => {
                setIsSurveyModalOpen(false);
                setWasSurveyContinued(true);
                onSurveyStart(survey.Survey.Id);
              }}
              closeModal={() => {
                setIsSurveyModalOpen(false);
                setWasSurveyDismissed(true);
                onSurveyClose(survey.Survey.Id);
              }}
            />
          )}
          <div
            id="Container"
            className={`wrapper-main ${hasBlur ? 'has_blur' : ''} ${
              navMenu ? 'nav_opened' : ''
            }`}
            onClick={screenClick}
          >
            <div className="bw-private-template">
              <Header {...props} />
              <div className={getContainerClasses()}>{children}</div>
              <Widgets widgets={state.widgets}/>
              <Footer loggedIn={true}/>
              {survey && (
                <Survey
                  showComeBackMessage={wasSurveyDismissed}
                  resetWasSurveyDismissed={() => setWasSurveyDismissed(false)}
                  showFirstQuestion={wasSurveyContinued}
                  resetWasSurveyContinued={() => setWasSurveyContinued(false)}
                  survey={survey}
                />
              )}
            </div>
          </div>
          {backToTop && <ScrollBackToTop />}

          {popup && <Popup history={history}/>}

          {trackAndTrace === 2 && <TrackTraceWarn history={history}/>}
        </>
      )}
      <Drawer open={drawer?.isOpen}>
        {drawer.type === 'STORE' && (
          <StoreSelector
            State={{userAccounts, currentAccount}}
            closeDrawer={closeDrawer}
          />
        )}
        {drawer.type === 'FILTER' && (
          <OrdersFilter
            stores={userAccounts}
            filters={orderFilters}
            closeDrawer={closeDrawer}
            dispatch={dispatch}
          />
        )}
        {drawer.type === 'FILTER_PRODUCTS' && drawer?.isOpen && (
          <ProductsFilter
            brands={userAccounts}
            filters={orderFilters}
            closeDrawer={closeDrawer}
            dispatch={dispatch}
          />
        )}
      </Drawer>
    </>
  )
})

function stateMapping(state) {
  return {
    hasBlur: state.app.popup.open,
    popup: state.app.popup.show,
    backToTop: state.app.backTopButton,
    navMenu: state.app.navMenu,
    trackAndTrace: state.app.trackAndTrace,
    drawer: state.app.drawer,
    userAccounts: state.app.userAccounts,
    currentAccount: state.app.currentAccount,
    cart: state.app.cart,
  }
}

export default connect(stateMapping)(PrivateTemplate)