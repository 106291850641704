export function init() {}

export function add(acts) {}

export function remove(acts) {}

export function gaPageView(page, userID = 'none') {
  if (DEV) console.log('>>>>>page', page)
  window.dataLayer.push({
    page,
    event: 'pageView',
    uniqueUserId: userID,
  })
}

export function GA(category, label, action = 'click', userID = 'none') {
  if (DEV) console.log('event>>>', category, '/', label, '/', action)
  window.dataLayer.push({
    category,
    label,
    action,
    event: 'eventTracking',
    uniqueUserId: userID,
  })
}

export function GAEcommerce(
  eventName,
  userID,
  productPrice,
  productBarCode,
  productName,
  productBrand,
  productCategory,
  suggestedProduct,
  socialProofed,
  productList,
  productQuantity,
  cartAdd,
  socialProofingLabels) {
  
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: eventName,
    uniqueUserId: userID,
    ecommerce:{
      currency:'EUR',
      value:productPrice,
      items:[
        {
          item_id: productBarCode,
          item_name: productName,
          item_brand: productBrand,
          item_category: productCategory,
          item_category2: suggestedProduct,
          item_category3: socialProofed,
          item_list_name: productList,
          price: productPrice,
          quantity: productQuantity,
          cart_add: cartAdd,
          social_proof:socialProofingLabels
        }
      ]      
    },
  })
}

export function GAEcommerceMultiple(eventName,userID, data) {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: eventName,
    uniqueUserId: userID,
    ecommerce:{
      currency:'EUR',
      items: data?.map((prod) => {
        return {
          value:prod?.PriceOuter,
          item_id:prod?.PackBarCode,
          item_name:prod?.Product,
          item_brand:prod?.Brand,
          item_category:prod?.Category,
          item_category2:prod?.suggested,
          item_category3:prod?.socialProofed,
          item_list_name:prod?.productList,
          price:prod?.PriceOuter,
          quantity:prod?.quantity,
          social_proof: getGASocialProofingLabels(prod)
        }
      })     
    },
  })
}

export function getProdCat(type) {
  switch (type) {
    case 'recommended':
      return 'Place An Order :: Profit Bot Component'
    case 'product':
      return 'Place An Order :: Product List'
    case 'suggested':
      return 'Cart :: Suggested Products'
    case 'cart':
      return 'Cart :: Product List'
    case 'profitbot':
      return 'Place An Order :: Profit Bot Component'
    case 'beforeyougo':
      return 'Place An Order :: Before you go'
    default:
      return ''
  }
}

export function GAEC(userID, data) {
  var obj = {
    uniqueUserId: userID,
    currencyCode: 'EUR',
    purchase: {
      actionField: {
        id: data?.OrderNumber,
        affiliation: 'JTI Engage',
        revenue: data?.OrderTotal,
        tax: data?.VatTotal,
        shipping: 0,
      },
      items: data?.OrderDetails?.map((prod) => {
        return {
          id: prod?.SKU,
          name: prod?.Product?.Product,
          brand: prod?.Product?.Brand,
          category: prod?.Product?.Category,
          price: prod?.Product?.PriceOuter,
          quantity: prod?.Quantity,
          cart_add: prod?.CartAdd,
          social_proof: getGASocialProofingLabels(prod?.Product),
        }
      }),
    },
  }

  if (DEV) console.log('purchase', obj)
  window.dataLayer.push({
    event: 'ecc.purchase',
    ecommerce: obj,
  })
}

export function gaUserStatus(role) {
  if (DEV) console.log('loggedin')
  window.dataLayer.push({
    category: 'dimension1',
    label: role,
    action: 'set',
    event: 'eventTracking',
  })
}

export function gaCartStep(userID, index) {
  const page = (function () {
    switch (index) {
      case 1:
        return 'Cart'
      case 2:
        return 'Check Order'
      case 3:
        return 'Order Confirmation'
    }
  })()
  const process = (function () {
    switch (index) {
      case 1:
        return 0
      case 2:
        return 0
      case 3:
        return 1
    }
  })()
  if (DEV) console.log(`Cart Step ${process}`, '/', page)
  window.dataLayer.push({
    category: `Cart Step ${process}`,
    label: page,
    action: 'set',
    event: 'eventTracking',
    uniqueUserId: userID,
  })
}

//here
export function gaStockCardType(type, userID) {
  if (DEV) console.log('StockCard:: ', type)
  window.dataLayer.push({
    category: 'StockCard',
    label: `Button :: ${type}`,
    action: 'click',
    event: 'eventTracking',
    uniqueUserId: userID,
  })
}

export function gaStockCardAddToCard(userID) {
  if (DEV) console.log('StockCard:: Add All to Cart')
  window.dataLayer.push({
    category: 'StockCard',
    label: 'Button :: Add All to Cart',
    action: 'click',
    event: 'eventTracking',
    uniqueUserId: userID,
  })
}

export function trackingDomReady(){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
  'event': 'page_ready'
  });
}

export function getGASocialProofingLabels(product) {
  const labels = [];
  if (product.HasPrizes) {
    labels.push("Prizes to be won");
  }
  if (product.LowStock) {
    labels.push("Low stock");
  }
  if (product.IsFastestGrowingProduct) {
    labels.push("Fast Growing Product");
  }
  if (product.Discount) {
    labels.push("Save " + product.Discount + "%");
  }
  if (product.HasBeenBoughtByText) {
    labels.push(product.HasBeenBoughtByText);
  }
  if (product.IsBestSeller) {
    labels.push("Best seller near you");
  }
  if (product.NewArrival) {
    labels.push("New Arrival");
  }
  if (product.OutOfStock) {
    labels.push("Out of stock");
  }
  return labels.join(", ");
}

export function getIsSocialProofed(product) {
  return getGASocialProofingLabels(product) ? "social_proofed" : "not_social_proofed"
}