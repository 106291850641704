import React from 'react'
import { connect } from 'react-redux'

import JTILogo from '../images/jti-logo.svg'

const Page404 = (props) => {
  const { history } = props

  return (
    <>
      <div className="bw-container-page bw-min-h-screen">
        <div className="bw-container">
          <img
            src={JTILogo}
            alt="JTI Engage"
            className="bw-mb-10 md:bw-mb-12 bw-cursor-pointer"
            onClick={() => history.push('/')}
          />
          <div className="page page-forms page-error source-sans-light">
            <h3>
              <b>404</b>
            </h3>
            <h4>Oops, sorry we can't find that page!</h4>
            <p>
              Either something went wrong or the page doesn't exist anymore.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect()(Page404)
