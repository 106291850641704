import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAccountInfo } from '../../axios/private/account'
import { setOpenDrawer } from '../../script/redux/actions.app'
import FieldGroup from '../../components/FieldGroup'

const MyAccount = (props) => {
  const { userAccounts, currentAccount, dispatch } = props
  const [Account, setAccount] = useState({})

  useEffect(() => {
    getAccountInfo()
      .then((data) => {
        setAccount((old) => data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const openStoreSwitcher = () => {
    dispatch(setOpenDrawer('STORE', true))
  }

  return (
    <div className="bw-container-page">
      <div className="bw-container bw-my-account">
        <h1 className="bw-h1 bw-mt-0 bw-mb-5 md:bw-mb-12">My Account</h1>
        <div className="bw-gray-box--large">
          <h4 className="bw-h4 bw-mb-5">Store name(s)</h4>
          <div className="bw-stores">
            {userAccounts?.map((acc, index) => (
              <a
                className={`bw-store ${
                  acc?.JtiAccountId === currentAccount?.JtiAccountId
                    ? 'active'
                    : ''
                }`}
                key={index}
                onClick={openStoreSwitcher}
              >
                <div className="name">{acc?.AccountName}</div>
                <div className="address">{acc?.AddressLine1}</div>
              </a>
            ))}
          </div>
        </div>
        <div className="bw-boxes">
          <div className="bw-gray-box--small account-info">
            <h4 className="bw-h4 bw-mb-5">My Information</h4>
            <FieldGroup
              label="Name"
              value={Account?.FirstName + ' ' + Account?.LastName}
            />
            <FieldGroup label="Account Email Address" value={Account?.Email} />
            <FieldGroup
              label="JTI Account Number"
              value={Account?.TmeAccount?.JtiAccountId}
            />
            <FieldGroup label="EOID" value={Account?.TmeAccount?.EOID} />
            <FieldGroup label="FID" value={Account?.TmeAccount?.FID} />

            <Link to="/change-password" className="bw-btn-link bw-text-14">
              Change Password
            </Link>
          </div>
          <div className="bw-gray-box--small jti-advisor">
            <h4 className="bw-h4 bw-mb-5">JTI Business advisor details</h4>
            <FieldGroup
              label="Name"
              value={
                Account?.TmeAccount?.EmployeeFirstName +
                ' ' +
                Account?.TmeAccount?.EmployeeLastName
              }
            />
            <FieldGroup
              label="Account Email Address"
              value={Account?.TmeAccount?.EmployeeEmailAddress}
            />
            <FieldGroup
              label="Account Phone Number"
              value={Account?.TmeAccount?.EmployeeMobilePhone}
            />

            <p className="bw-info-hint">
              If any of your information is incorrect please contact your BA to
              update these details.
            </p>
            <a
              href={`mailto:${Account?.TmeAccount?.EmployeeEmailAddress}`}
              target="_blank"
              className="bw-btn-link bw-text-14"
            >
              Email BA
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

function stateMapping(state) {
  return {
    userAccounts: state.app.userAccounts,
    currentAccount: state.app.currentAccount,
  }
}

export default connect(stateMapping)(MyAccount)
