import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getRecentOrder } from '../../axios/private/orders'
import { getAccountInfo } from '../../axios/private/account'
import { getNotifications } from '../../axios/public/content'

import InfoBar from './InfoBar'
import Navigation from './Navigation'

import MessageArea from './MessageArea'
import OrderEditing from './OrderEditing'

const Header = (props) => {
  const { cart } = props
  const [State, setState] = useState({
    recentOrder: null,
    userInfo: null,
    notifications: [],
  })

  // Get last order from server
  const reqInfoBar = async () => {
    const recentOrderData = await getRecentOrder()
      .then((res) => res)
      .catch((err) => null)
    const accountInfoData = await getAccountInfo()
      .then((res) => res)
      .catch((err) => null)
    const notificationsData = await getNotifications()
      .then((res) => res.NotificationMessages)
      .catch((err) => [])

    setState((prevState) => ({
      ...prevState,
      recentOrder: recentOrderData,
      userInfo: accountInfoData,
      notifications: notificationsData,
    }))
  }

  useEffect(() => {
    reqInfoBar()
  }, [])

  return (
    <>
      <InfoBar State={State} />
      <Navigation {...props} />
      <MessageArea State={State} />
      {cart?.PendingOrderNumber && (
        <OrderEditing orderId={cart?.PendingOrderNumber} />
      )}
    </>
  )
}

Header.propTypes = {}

export default Header
