export const PRODUCT_LOADED = 'PRODUCT_LOADED'

export const ADD_ORDER = 'ADD_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const RESET_ORDER = 'RESET_ORDER'

export const SET_CART = 'SET_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const ADD_PRODS_CART = 'ADD_PRODS_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CHANGE_PROD_SORT = 'CHANGE_PROD_SORT'
export const CHANGE_CART_SORT = 'CHANGE_CART_SORT'

export const SAVE_ORDER_RESULT = 'SAVE_ORDER_RESULT'
export const RESET_ORDER_RESULT = 'RESET_ORDER_RESULT'

export const CHANGE_HISTORY_PERIOD = 'CHANGE_HISTORY_PERIOD'

export const TOGGLE_INFORMATION = 'TOGGLE_INFORMATION'

export const CHANGE_RECOMMEND_PREFERENCE = 'RECOMMEND_PREFERENCE'

export function productLoaded(loaded = true) {
  return {
    type: PRODUCT_LOADED,
    loaded,
  }
}

export function addOrder(sku, order, current, inCart = false) {
  return {
    type: ADD_ORDER,
    sku,
    order,
    current,
    inCart,
  }
}

export function removeOrder() {
  return {
    type: REMOVE_ORDER,
  }
}

export function resetOrder() {
  return {
    type: RESET_ORDER,
  }
}

export function setCart(cart) {
  return {
    type: SET_CART,
    cart,
  }
}

export function addToCart(prod, order) {
  return {
    type: ADD_TO_CART,
    prod,
    order,
  }
}

export function updateCart(sku, order) {
  return {
    type: UPDATE_CART,
    sku,
    order,
  }
}

export function addProdsCart(prods) {
  return {
    type: ADD_PRODS_CART,
    prods,
  }
}

export function removeFromCart(sku) {
  return {
    type: REMOVE_FROM_CART,
    sku,
  }
}

export function changeProdSort(sort) {
  return {
    type: CHANGE_PROD_SORT,
    sort,
  }
}

export function changeCartSort(sort) {
  return {
    type: CHANGE_CART_SORT,
    sort,
  }
}

export function saveOrderResult(data) {
  return {
    type: SAVE_ORDER_RESULT,
    data,
  }
}

export function resetOrderResult() {
  return {
    type: RESET_ORDER_RESULT,
  }
}

export function changeHistoryPeriod(index) {
  return {
    type: CHANGE_HISTORY_PERIOD,
    index,
  }
}

export function toggleInformation(sku) {
  return {
    type: TOGGLE_INFORMATION,
    sku,
  }
}

export function changeRecommendPreference(show) {
  return {
    type: CHANGE_RECOMMEND_PREFERENCE,
    show,
  }
}

/***
SUGGESTED PRODUCTS
***/

export const SET_SUGGESTED_PRODUCTS = 'SET_SUGGESTED_PRODUCTS'
export const SET_SUGGESTED_INFO = 'SET_SUGGESTED_INFO'
export const SHOW_SUGGESTED = 'SHOW_SUGGESTED'
export const SET_SUGGESTED_PRODUCTS_CHECKOUT = 'SET_SUGGESTED_PRODUCTS_CHECKOUT'

export function setSuggestedProducts(products = []) {
  return {
    type: SET_SUGGESTED_PRODUCTS,
    products,
  }
}

export function setSuggestedProductsCheckout(suggestedProductsCheckout = []) {
  return {
    type: SET_SUGGESTED_PRODUCTS_CHECKOUT,
    suggestedProductsCheckout,
  }
}

export function setSuggestedInfo(suggestedInfo) {
  return {
    type: SET_SUGGESTED_INFO,
    suggestedInfo,
  }
}

export function showSuggested(show) {
  return {
    type: SHOW_SUGGESTED,
    show,
  }
}

/***
SAVED CART
***/

export const CREATE_SAVED_CART = 'CREATE_SAVED_CART'
export const UPDATE_SAVED_CART = 'UPDATE_SAVED_CART'
export const DELETE_SAVED_CART = 'DELETE_SAVED_CART'
export const RESET_SAVED_CART = 'RESET_SAVED_CART'
export const ADD_PRODUCT_SAVED_CART = 'ADD_PRODUCT_SAVED_CART'
//export const UPDATE_PRODUCT_SAVED_CART = 'UPDATE_PRODUCT_SAVED_CART';
//export const ADD_PRODUCTS_SAVED_CART = 'ADD_PRODUCTS_SAVED_CART';

export function createSavedCart(id, products) {
  return {
    type: CREATE_SAVED_CART,
    id,
    products,
  }
}

export function updateSavedCart(savedCart, sku = 0) {
  savedCart.sku = sku

  return {
    type: UPDATE_SAVED_CART,
    savedCart,
  }
}

export function deleteSavedCart() {
  return {
    type: DELETE_SAVED_CART,
  }
}

export function resetSavedCart() {
  return {
    type: RESET_SAVED_CART,
  }
}

export function addProductSavedCart(sku, quantity) {
  return {
    type: ADD_PRODUCT_SAVED_CART,
    sku,
    quantity,
  }
}

/*export function updateProductSavedCart(sku, quantity) {
  return {
    type: UPDATE_PRODUCT_SAVED_CART,
    sku, 
    quantity,
  }
}

export function addProductsSavedCart(products) {
  return {
    type: ADD_PRODUCTS_SAVED_CART,
    products,
  }
}/*

/***
POST DATE ORDER
***/

export const SET_ORDER_EDITING = 'SET_ORDER_EDITING'

export function setOrderEditing(id, date, po) {
  return {
    type: SET_ORDER_EDITING,
    id,
    date,
    po,
  }
}
