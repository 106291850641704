class Select extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      open: false,
      selection: ''
    }
  }
  
  render() {
    const {
      input, label, placeholder, options, required,
      meta: { touched, error }
    } = this.props;
   
    return (
      <div className="form-field form-field-select">
        <label>{ label }</label>
        <div onClick={()=>this.toggleOption()}>
          <span>{ input.value ? input.value : placeholder }</span>
          <i className="icon-Down-Arrow"></i>
          
          <select ref={(dom)=>this.select=dom} { ...input } onChange={(e)=>{this.selectChange(e)}}/>
           
        </div>
        
        <div>
          <div className="options" style={{ display: this.state.open ? 'block' : 'none' }}>
          {
            !required && (
              <div onClick={()=>this.selectAction('')}><span>&nbsp;</span></div>
            )
          }
          {
            options.map((op, i) =>  
              <div key={ i } onClick={()=>this.selectAction(op)}><span className={ this.state.selection === op ? 'active' : '' }>{ op }</span></div>
            )
          }
          </div>
        </div>
        
        { 
          touched && error && <span className="error_message">{ error }</span> 
        }
      </div>
    )
  }
  
  toggleOption() {
    this.setState({ open: !this.state.open });
  }
  
  selectAction(value) {
    this.props.input.onChange(value);
    this.setState({ open: false, selection: value });
  }
  
  selectChange(e) {
    const value = e.target[e.target.selectedIndex].text;
    
    this.props.input.onChange(value);
  }
  
  componentDidMount(){

    this.props.input.onChange(this.props.selectedSubject)

 
  }
}

export default Select