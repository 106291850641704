import {partnerHubGetNewContentCountREQUEST, partnerHubListREQUEST, homeTitleCopyREQUEST, homePodsREQUEST, usefulDownloadREQUEST, latestVlogsREQUEST, faqsREQUEST, contactSubjectsREQUEST, videoListREQUEST, vlogListREQUEST } from '@Requests/contents'

 
/***
PARTNER HUB CONTENT
***/

let partnerHubGetNewContentCount = []

export function getpartnerHubGetNewContentCount(callback) {
  
  partnerHubGetNewContentCountREQUEST((result, data) => {
      
      if(result) {
        partnerHubGetNewContentCount = data;
        callback(data);
      }
   
    });
  
}



/***
PARTNER HUB CONTENT
***/

let partnerHubList = []

export function getPartnerHubList(callback) {
  
    partnerHubListREQUEST((result, data) => {
      
      if(result) {
        partnerHubList = data;
        callback(data);
      }
   
    });
  
}


/***
TITLE/COPY ON HOME
***/

let homeTitleCopy = []

export function getHomeTitleCopy(callback) {
  if(homeTitleCopy.length) {
    callback(homeTitleCopy);
  } else {
    homeTitleCopyREQUEST((result, data) => {
      if(result) {
        homeTitleCopy = data;
        callback(data);
      }
    });
  }
}

/***
HOME PODS ON HOME
***/

let homePods = []

export function getHomePods(callback) {
  if(homePods.length) {
    callback(homePods);
  } else {
    homePodsREQUEST((result, data) => {
    
      if(result) {
        homePods = data;
        callback(data);
      }
    });
  }
}

/***
USERFUL DOWNLOAD
***/

let usefulDownloads = [];

export function getUsefulDownloads(callback) {
  if(usefulDownloads.length) {
    callback(usefulDownloads);
  } else {
    usefulDownloadREQUEST((result, data) => {
      if(result) {
        usefulDownloads = data;
        callback(data);
      }
    });
  }
}

/***
LATEST VLOGS
***/

let latestVlogs = [];

export function getLatestVlogs(callback) {
  if(latestVlogs.length) {
    callback(latestVlogs);
  } else {
    latestVlogsREQUEST((result, data) => {
      if(result) {
        latestVlogs = data;
        callback(data);
      }
    });
  }
}

/***
FAQs
***/

let faqs = [];

export function getFAQs(callback) {
  if(faqs.length) {
    callback(faqs);  
  } else {
    faqsREQUEST((result, data) => {
      if(result) {
        faqs = data;
        callback(data);
      }
    });
  }
}

/***
CONTACT SUBJECT
***/

let contactSubjects = [];

export function getSubjects(callback) {
  if(contactSubjects.length) {
    return callback(contactSubjects);
  } else {
    contactSubjectsREQUEST((result, data) => {
      if(result) {
        contactSubjects = data;
        callback(data);
      }
    });
  }
}

/***
VIDEO LIST
***/

let videoList = [];

export function getVideoList(callback) {
  if(videoList.length) {
    callback(videoList);
  } else {
    videoListREQUEST((result, data) => {
      if(result) {
        videoList = data;
        callback(data);
      }
    });
  }
}

/***
VLOG LIST
***/

let vlogList = [];

export function getVlogList(callback) {
  if(vlogList.length) {
    callback(vlogList);
  } else {
    vlogListREQUEST((result, data) => {
      if(result) {
        vlogList = data;
        callback(data);
      }
    });
  }
}
