import React from 'react'

const EoidFidInfo = (props) => {
    return (
        <div className="bw-eoid-fid-info">
            <div className="bw-eoid-fid-info__single">
                <p>Economic operator identifier code</p>
                <div className="bw-eoid-fid-info__arrows"></div>
            </div>
            <div className="bw-eoid-fid-info__single">
                <p>Facility identifier code</p>
                <div className="bw-eoid-fid-info__arrows bw-eoid-fid-info__arrows-double"></div>
            </div>
            <div className="bw-eoid-fid-info__single">
                <p>Indication if the registration is made on behalf of a retail outlet operator not involved otherwise in the tobacco trade</p>
                <div className="bw-eoid-fid-info__arrows bw-eoid-fid-info__arrows-double"></div>
            </div>
            <div className="bw-eoid-fid-info__single">
                <p>Identifier of the economic operator that acts on behalf of a retail outlet operator not involved otherwise in the tobacco trade</p>
                <div className="bw-eoid-fid-info__arrows bw-eoid-fid-info__arrows-double"></div>
            </div>
            <div className="bw-eoid-fid-info__single">
                <p>Status</p>
                <div className="bw-eoid-fid-info__arrows bw-eoid-fid-info__arrows-double"></div>
            </div>
            <div className="bw-eoid-fid-info__single">
                <p>Options</p>
                <div className="bw-eoid-fid-info__arrows bw-eoid-fid-info__arrows-double"></div>
            </div>
        </div>
    )
}


export default EoidFidInfo
