import React from "react";
import * as Yup from "yup";
import { GA } from "Functions/ga";
import { popupBannersREQUEST } from "@Requests/contents";
import { suggestedProductsREQUEST } from "@Requests/products";
import { openPopup } from "Redux/actions.app";
import { setSuggestedInfo, setSuggestedProducts } from "Redux/actions.prod";
import { generalError } from "../../../utils/constants";
import { getAccounts, login } from "../../../axios/auth";

export const RecaptchaSiteKey = process.env.REACT_APP_GoogleRecaptchaSiteKey;
export const InitialValues = {
  username: "",
  password: "",
  confirm: false,
  recaptchaResponse: "",
};
export const validationSchema = Yup.object().shape({
  username: Yup.string().email().required("Please enter a valid Email Address"),
  password: Yup.string().required("Please enter a valid password"),
  confirm: Yup.boolean().oneOf([true], true),
});

export const onLogin = async (values, { setSubmitting }, props) => {
  const { dispatch, setError, setInvalidAttempts, recaptchaRef } = props;

  GA("Login", "Button :: Login");

  try {
    const loginResult = await login({ grant_type: "password", ...values });
    const accountsResult = await getAccounts();
    setInvalidAttempts(0);
    setError(false);
    suggestedProductsREQUEST((spResult, suggests, suggestedInfo) => {
      setSubmitting(false);
      if (spResult) {
        dispatch(setSuggestedInfo(suggestedInfo));

        dispatch(
          setSuggestedProducts(
            suggests.map((sgt) => {
              return {
                sku: sgt.SKU,
                quantity: sgt.Order,
                name: sgt.Product,
              };
            }),
          ),
        );
      } else {
        dispatch(setSuggestedProducts());
      }

      popupBannersREQUEST((result, data) => {
        if (result) {
          if (
            data.isDisplayMultiple ||
            new Date(loginResult.LastLoginDate) <= new Date(data.activeFrom)
          ) {
            dispatch(openPopup("just-after-login", "", "", [data]));
          }
        }
        props.history.push("/");
      });
    });
  } catch (err) {
    recaptchaRef.current.reset();
    console.error(
      `Login Error ${err?.status}: ${err?.data?.error_description}`,
    );
    setSubmitting(false);

    let errMessage = err?.data?.error_description || generalError;

    if (err?.status === 400) {
      setInvalidAttempts((oldVal) => oldVal + 1);
      errMessage = (
        <>
          Oops! It seems like the username or password you entered is incorrect.
          Please double-check your credentials and try again.
        </>
      );
    }

    if (
      err?.data?.error_description === "The user is locked out." ||
      err?.data?.error_description === "Account Disabled" ||
      err?.data?.error_description === "Recaptcha is invalid."
    ) {
      errMessage = (
        <>
          Your account has been locked, please contact our support: &nbsp;
          <a href="mailto:websupportirl@jti.com">websupportirl@jti.com</a>
        </>
      );
    }

    setError(errMessage);
  }
};
