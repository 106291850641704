import React, {useRef, useState} from "react";
import Slider from "react-slick";
import RecommendProduct from "../../../script/components/blocks/RecommendProduct";

function Multiple({bannerData}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 819,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          centerPadding: "10px",
        },
      },
    ],
    beforeChange: (next) => setActiveSlide(next),
  };

  return (
    <div className="hyc-multiple bw-container">
      <h3 className="hyc-multiple__hyc">Have you considered...</h3>
      <Slider ref={sliderRef} {...settings}>
        {bannerData.ConsiderProducts.map((prod) => (
          <RecommendProduct
            key={prod.SKU}
            prod={prod}
            hasHead={false}
            type="recommended"
          />
        ))}
      </Slider>
      {bannerData.ConsiderProducts.length > 0 && (
        <div className="slider-navigation">
          <div className={`slider-bar slider-bar-${activeSlide + 1}`}>
            <div
              className="active"
              style={{
                width: 100 / bannerData.ConsiderProducts.length + "%",
                left:
                  (100 / bannerData.ConsiderProducts.length) * activeSlide +
                  "%",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Multiple;