import React from 'react'
import PropTypes from 'prop-types'

import Slider from 'react-slick'
import NextArrow from './arrows/Next'
import PrevArrow from './arrows/Prev'
import Slide from './Slide'

const Notification = (props) => {
  const { slides } = props

  const stickyPosition = slides.findIndex((el) => el.IsSticky)
  const sliderSettings = {
    initialSlide: stickyPosition > -1 ? stickyPosition : 0,
    dots: true,
    autoplay: stickyPosition === -1,
    autoplaySpeed: 3000,
    infinite: true,
    swipeToSlide: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (index) => (
      <div className="dot-container">
        <div className="dot"></div>
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <div className="notification">
      <Slider {...sliderSettings}>
        {slides.map((slide, index) => (
          <React.Fragment key={index}>
            <Slide {...slide} />
          </React.Fragment>
        ))}
      </Slider>
    </div>
  )
}

Notification.propTypes = {
  slides: PropTypes.array.isRequired,
}
Notification.defaultProps = {
  slides: [
    {
      title: '',
      copy: '',
      linkUrl: '',
      linkLabel: '',
      isSticky: '',
      order: '',
    },
  ],
}

export default Notification
