import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toggleLoader, hidePopup } from 'Redux/actions.app'

import { deleteCart } from '../../../axios/private/cart'
import {
  resetOrder,
  setSuggestedProductsCheckout,
} from '../../redux/actions.prod'

class ClearCart extends React.Component {
  render() {
    const { closeHandler } = this.props

    return (
      <div className="buttons">
        <button
          className="button_style-1 fontsize19"
          onClick={(e) => closeHandler(e)}
        >
          No
        </button>
        <button
          className="button_style-2 fontsize19"
          onClick={(e) => this.clearCart(e)}
        >
          Yes
        </button>
      </div>
    )
  }

  async clearCart(e) {
    const { dispatch, cartId } = this.props

    if (!cartId) return false

    dispatch(toggleLoader(true))

    await deleteCart(cartId)
    dispatch(setSuggestedProductsCheckout())
    dispatch(resetOrder())
    dispatch(toggleLoader(false))

    dispatch(hidePopup())
  }
}

ClearCart.propTypes = {
  history: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

function stateMapping(state) {
  return {
    cartId: state.app.cart?.BackEndCartId || null,
  }
}

export default connect(stateMapping)(ClearCart)
