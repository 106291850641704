/***
DOMAIN
***/

export const domain =
  typeof DOMAIN == 'undefined'
    ? 'https://jtiwebapidev.azurewebsites.net'
    : DOMAIN

/***
ERROR MESSAGES
***/

export const sessionError =
  'Session has expired. Please refresh your browser and log back in.'

export const generalError =
  'Sorry, our server has encountered an internal error and was unable to complete your request. Please try again later.'

/***
VAT RATE
***/

export const vat = 0.23

/***
CIGARETTE CATEGORIES
***/

export const cigCategories = {
  RMC: 'Ready Made Cigarettes',
  RYO: 'Roll Your Own',
  EVAP: 'e-Vapour',
  NICP: 'Nicotine Pouch',
  CGR: 'Cigars ',
  PIP: 'Pipes',
}

/***
SORT TYPES FOR PRODUCTIONS
***/

export const sortTypes = [
  {
    label: 'Stock Card',
    method: 'sortStockCard',
    asc: true,
  },
  {
    label: 'A-Z',
    method: 'sortBrand',
    asc: true,
  },
  {
    label: 'Z-A',
    method: 'sortBrand',
    asc: false,
  },

  {
    label: 'Price List',
    method: 'sortPriceList',
    asc: true,
  },
]
export const StockCardsortTypes = [
  {
    label: 'A-Z',
    method: 'sortBrand',
    asc: true,
  },
  {
    label: 'Z-A',
    method: 'sortBrand',
    asc: false,
  },
]

/***
ORDER PERIODS
***/

export const orderPeriods = [
  {
    label: 'Past 1 month',
    value: 30,
  },
  {
    label: 'Past 3 months',
    value: 90,
  },
  {
    label: 'Past 6 months',
    value: 180,
  },
  {
    label: 'Past 1 year',
    value: 365,
  },
]

/***
ORDER STATUS
***/

export function getStatus(i) {
  switch (i) {
    case 0:
      return 'Submitted'
    case 1:
      return 'Pending'
    case 2:
      return 'Processing'
    case 3:
      return 'Cancelled'
    default:
      return 'Submitted'
  }
}
