import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ error, children, className, ...props }) => {
  return (
    <div
      className={`bw-checkbox ${className || ''} ${
        error ? 'bw-checkbox--error' : ''
      }`}
    >
      <label className="bw-flex">
        <input className="input" type="checkbox" {...props} />
        <span className="icon"></span>
        <span className="label">{children}</span>
      </label>
    </div>
  )
}

// Set default props
Checkbox.defaultProps = {
  label: 'Label',
  error: false,
}

//PropTypes
Checkbox.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
}

export default Checkbox
