import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';


class NavigationPrompt extends React.Component
{


  render() {
    return (
        <div className="buttons">
        <button className="button_style-1 fontsize19"  onClick={(e) =>this.props.closeHandler() }>Continue editing</button>
        <button style={{float:"right"}} className="button_style-2 fontsize19" onClick={this.props.redirect}>Forget changes</button>

      </div>
    );
  }
}

NavigationPrompt.propTypes = {
 
};

export default withRouter(NavigationPrompt);