import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loading from '../Loading'

const Button = ({ label, loading, className, ...props }) => {
  return (
    <div
      className={`bw-button ${className || ''} ${
        loading ? 'bw-button-loading' : ''
      }`}
    >
      <button {...props}>
        <span>{label}</span>
        {/* {label} */}
        {loading ? <Loading className="loading-icon" /> : null}
      </button>
    </div>
  )
}

// Set default props
Button.defaultProps = {
  type: 'submit',
  label: 'Button text',
  loading: false,
}

//PropTypes
Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
}

export default Button
