import React from "react";
import RecommendProduct from "../../../script/components/blocks/RecommendProduct";

function Individual({ bannerData }) {
  return (
    <div className="hyc-individual">
      <div className="hyc-individual__wrapper">
        <img
          className="hyc-individual__img--desktop"
          src={bannerData.ImagePath}
          alt="desktop banner"
        />
        <img
          className="hyc-individual__img--mobile"
          src={bannerData.MobileImagePath}
          alt="mobile banner"
        />
        <div
          className={`hyc-individual__product ${
            bannerData?.TextColor === "white" ? "white" : "black"
          }`}
        >
          <h4 className="hyc-individual__product__title">{bannerData.Title}</h4>
          <h3 className="hyc-individual__product__name">
            {bannerData.ConsiderProducts[0].Product}
            {bannerData.ConsiderProducts[0].IsNew && (
              <span>{bannerData.ConsiderProducts[0].NewText}</span>
            )}
          </h3>
          <p className="hyc-individual__product__price">
            €{bannerData.ConsiderProducts[0].PriceOuter.toFixed(2)}
          </p>
          <p className="hyc-individual__product__description">
            {bannerData.Desc}
          </p>
          <RecommendProduct
            prod={bannerData.ConsiderProducts[0]}
            hasHead={false}
            type="profitbot"
          />
        </div>
      </div>
      {bannerData.LegalBanner && (
        <div className="hyc-legal-disclaimer">
          <p>{bannerData.LegalBanner}</p>
        </div>
      )}
      <div
        className={`hyc-individual__product--mobile`}
      >
        <h4 className="hyc-individual__product--mobile__title">{bannerData.Title}</h4>
        <h3 className="hyc-individual__product--mobile__name">
          {bannerData.ConsiderProducts[0].Product}
          {bannerData.ConsiderProducts[0].IsNew && (
            <span>{bannerData.ConsiderProducts[0].NewText}</span>
          )}
        </h3>
        <p className="hyc-individual__product--mobile__price">
          €{bannerData.ConsiderProducts[0].PriceOuter.toFixed(2)}
        </p>
        <p className="hyc-individual__product--mobile__description">
          {bannerData.Desc}
        </p>
        <RecommendProduct
          prod={bannerData.ConsiderProducts[0]}
          hasHead={false}
          type="profitbot"
        />
      </div>
    </div>
  );
}

export default Individual;
