import { combineReducers } from 'redux'

import {
  COOKIES_STATUS,
  ADD_CONSENT_TYPE,
  RESET_CONSENT_TYPE,
  REMOVE_CONSENT_TYPE,
  ADD_ACTIVE_CONSENT_TYPE,
  RESET_ACTIVE_CONSENT_TYPE,
  REMOVE_ACTIVE_CONSENT_TYPE,
  TOGGLE_CONSENT_DIALOG,
} from 'Redux/actions.cookie'

  
function consent(state = '', action) {
  switch(action.type) {
    case COOKIES_STATUS:
      return action.consent;
      
    default:
      return state;
  }
}

function consentTypes(state = [], action) {
  switch(action.type) {
    case ADD_CONSENT_TYPE:
      return [...state].concat(action.cookies);
      
    case RESET_CONSENT_TYPE:
      return [];
      
    case REMOVE_CONSENT_TYPE:
      return state.filter((ck) => {
        return ck !== action.cookie;
      });
      
    default: 
      return state;
  }
}

function activeConsentTypes(state = [], action) {
  switch(action.type) {
    case ADD_ACTIVE_CONSENT_TYPE:
      return [...state].concat(action.cookies);
      
    case RESET_ACTIVE_CONSENT_TYPE:
      return [];
      
    case REMOVE_ACTIVE_CONSENT_TYPE:
      return state.filter((ck) => {
        return ck !== action.cookie;
      });
      
    default: 
      return state;
  }
}

function consentDialog(state = false, action) {
  switch(action.type) {
    case TOGGLE_CONSENT_DIALOG:
      return action.show;
      
    default:
      return state;
  }
}

export default combineReducers({
  consent,
  consentTypes,
  activeConsentTypes,
  consentDialog,
})