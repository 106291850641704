import PrivacyPolicyContent from 'Components/blocks/PrivacyPolicyContent'
import { connect } from 'react-redux'

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { history } = this.props

    return (
      <>
        <div className="page page-pad-L page-default page-privacy_policy">
          <h3 className="page_title">Privacy Policy</h3>

          <PrivacyPolicyContent />
        </div>
      </>
    )
  }
}

export default connect()(PrivacyPolicy)
