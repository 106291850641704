import { GAEcommerce, getGASocialProofingLabels, getIsSocialProofed } from "../../functions/ga";
import TrashIcon from '../../../images/icons/trash.svg'
import React, { useEffect } from 'react'

const formatDate = (date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return [day, month, year].join('/');
};

const textColor = () => {
  var productsArray = document.querySelectorAll('.stockCard-product_col-title')
  productsArray.forEach((item)=> {
    var colors = item.style.backgroundColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if(colors) {
      var r = colors[1];
      var g = colors[2];
      var b = colors[3];
      var o = Math.round(((parseInt(r) * 299) + (parseInt(g) * 587) + (parseInt(b) * 114)) /1000);

      if(o > 125) {
        item.style.color = '#000000';
      } else {
        item.style.color = '#fff';
      }
    } else {
      item.style.color = '#000000';
    }
  })
}

function StockCardProduct({
  product,
  purchaseHistory,
  hasHead,
  remove,
  selectQuantity,
  accountId,
  index,
}) {
  useEffect(()=>{
    textColor()
  },[])

  return (
    <>
      {hasHead &&
        <div className="stockCard-brand-head bw-flex"> {index === 0 ?
          <>
            <div className=" stockCard-product_col--first">
              {product.Brand}
            </div>
            <p>Price</p>
            {/* {purchaseHistory.map((purchase, i) => (
              <div key={i} className="order-history_col order-history_col--first">
                {formatDate(purchase.OrderDate)}
              </div>
            ))} */}
          </>
          :
          product.Brand}
        </div>}
      <div
        className={`stockCard-product ${
          product.OutOfStock ? " outofstock" : ""
        }`}
      >
        <div className="stockCard-product_col stockCard-product_col-title" style={{backgroundColor: product.FlashColour}}>
          {!product.OutOfStock &&
          product.IsSocialProofing &&
          product.SocialProofing.IconType === 1 ? (
            <HotIconType isOpen={false} context={product.SocialProofing}/>
          ) : (
            ""
          )}
          {product.Product}
          {product.OutOfStock && <i> OUT OF STOCK </i>}
        </div>
        {purchaseHistory.map((history, i) => (
          <div key={i} className="stockCard-product_col">
            {product.History.filter(
              (obj) => obj.OrderDate === history.OrderDate
            ).length > 0
              ? product.History.filter(
                (obj) => obj.OrderDate === history.OrderDate
              )[0].QuantityPurchased
              : 0}
          </div>
        ))}
        <div className="stockCard-product_col">
          <div className="sc-product-row-order">
            <a
              href="#"
              className="icon-Minus no-print"
              onClick={(e) => {
                e.preventDefault();
                !product.OutOfStock && selectQuantity(product.SKU, "-");
              }}
            ></a>
            <input
              type="text"
              className="no-print"
              disabled
              value={product.QuantityToPurchase}
            />
            <a
              href="#"
              className="icon-Plus  no-print"
              onClick={(e) => {
                e.preventDefault();
                !product.OutOfStock && selectQuantity(product.SKU, "+");
              }}
            ></a>
          </div>
        </div>
        <div className="stockCard-product_col stockCard-product_col-price">
          €{(product.PriceOuter * product.QuantityToPurchase).toFixed(2)}
        </div>
        <div className="stockCard-product_col" style={{maxWidth: "50px"}}>
          <button
            className="remove no-print"
            onClick={(e) => {
              !product.OutOfStock && remove(product.SKU);

              const addedQuantity = e.target
                .closest(".stockCard-product")
                .querySelector("input.no-print").value;

              GAEcommerce(
                "remove_from_cart",
                accountId,
                product.PriceOuter,
                product.PackBarCode,
                product.Product,
                product.Brand,
                product.Category,
                "suggested",
                getIsSocialProofed(product),
                "stock_card",
                addedQuantity,
                "stock_card",
                getGASocialProofingLabels(product)
              );
            }}
          >
            <img src={TrashIcon} alt="your cart"/>
          </button>
        </div>
      </div>
    </>
  );
}

export default StockCardProduct