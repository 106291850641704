import PasswordValidator from 'password-validator'
import PasswordScore from 'simple-password-tester'

const Email = /^[=!#&'*+}{\/%a-z0-9_.‘’-]{1,63}[=!#&'*+}{\/%a-z0-9_-]{1}@[a-z0-9.-]+\.[a-z]{2,5}$/i;
const Name = /^[a-z\-'= ]+$/i;
const Color = /^#[0-9a-f]{3,6}$/i;
const Mobile = /^(\+353|\+44)?(0?[0-9]{4})([0-9]+)$/;

const EoidFid = /^QCALL[0-9a-zA-Z]{4,5}$/;

const Promo = /^[0-9]{4}$/;

const passwordSchema = new PasswordValidator();
passwordSchema
  .is().min(8)
  .has().uppercase()
  .has().lowercase()
  .has().digits()
  .has().not().spaces()
  //.has().symbols();


export function testEmail(value) {
  return Email.test(value);
}

export function testName(value) {
  return Name.test(value);
}

export function testColor(value) {
  return Color.test(value);
}

export function testPassword(value) {
  return passwordSchema.validate(value);
}

export function testEoidFid(value) {
  return EoidFid.test(value); 
}

export function getMobileString(value) {
  const matches = value.match(Mobile);
  if(matches) {
    return `${matches[1] ? matches[1] + ' ' : ''}${matches[2]} ${matches[3]}`
  }
  return '';
}

export function scorePassword(value) {
  const score = PasswordScore(value);
  return score > 80 ? 80 : score;
}

export function getPasswordScore(value) {
  if(value >= 60) {
    return 'Strong';
  } else if(value >= 30) {
    return 'Medium';
  } else if(value >= 1) {
    return 'Weak';
  } else {
    return '';
  }
}

export function testPromo(value) {
  return Promo.test(value);
}