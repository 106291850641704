import React from 'react'
import { GA } from '../../script/functions/ga'

const Slide = (props) => {
  const { Title, Copy, LinkUrl, LinkLabel, isSticky, order } = props

  const onLinkClick = (title) => {
    GA('Accordion :: Slider', `Click Text :: ${title}`)
  }

  return (
    <div className="notification-slide">
      {Title && (
        <h3 className="bw-font-heading bw-font-bold bw-text-18 bw-mb-3">
          {Title}
        </h3>
      )}
      {Copy && <p className="bw-text-15">{Copy}</p>}
      {LinkLabel && (
        <a
          href={LinkUrl}
          target="_blank"
          className="bw-text-14"
          onClick={() => onLinkClick(Title)}
        >
          {LinkLabel}
        </a>
      )}
    </div>
  )
}

export default Slide
