import { cigCategories, sortTypes, StockCardsortTypes } from 'Functions/data'

class ProductHelper {
  constructor() {
    this.original = ''
    this.products = []
    this.originalList = []
  }

  init(data) {
    this.products = data

    this.products = this.sortStockCard(this.products)

    this.original = JSON.stringify(this.products)

    this.originalList = JSON.parse(this.original)
  }

  sort(type) {
    var sortIndex = 0
    sortTypes.every((st, index) => {
      if (st.label === type) {
        sortIndex = index
        return false
      }
      return true
    })
    return sortIndex
  }

  sortProducts(type) {
    this.products = this[sortTypes[type].method](
      this.products,
      sortTypes[type].asc
    )
  }

  sortProductsFiltered(type, products) {
    return this[sortTypes[type].method](products, sortTypes[type].asc)
  }

  sorSCtProducts(type, products) {
    return this[StockCardsortTypes[type].method](
      products,
      StockCardsortTypes[type].asc
    )
  }

  sortSC(type) {
    var sortIndex = 0
    StockCardsortTypes.every((st, index) => {
      if (st.label === type) {
        sortIndex = index
        return false
      }
      return true
    })
    return sortIndex
  }

  preSortPrice(a, b, asc = true) {
    if (a.PriceOuter < b.PriceOuter) {
      return asc ? -1 : 1
    } else if (a.PriceOuter > b.PriceOuter) {
      return asc ? 1 : -1
    } else {
      return 0
    }
  }

  preSortBrand(a, b, asc = true) {
    if (a.Brand < b.Brand) {
      return asc ? -1 : 1
    } else if (a.Brand > b.Brand) {
      return asc ? 1 : -1
    } else {
      return 0
    }
  }

  preSortCategory(a, b) {
    const cats = Object.keys(cigCategories)

    const aIdx = cats.indexOf(a.Category)
    const bIdx = cats.indexOf(b.Category)

    if (aIdx < bIdx) {
      return -1
    } else if (aIdx > bIdx) {
      return 1
    } else {
      return 0
    }
  }

  sortBrand(array, asc = true) {
    array.sort((a, b) => {
      const resBrand = this.preSortBrand(a, b, asc)
      if (resBrand) return resBrand

      const resCategory = this.preSortCategory(a, b)
      if (resCategory) return resCategory

      return this.preSortPrice(a, b, asc)
    })

    return array
  }

  sortPrice(array, asc = true) {
    array.sort((a, b) => {
      const resPrice = this.preSortPrice(a, b, asc)
      if (resPrice) return resPrice

      return this.preSortBrand(a, b, asc)
    })

    return array
  }

  sortPriceList(array, asc = true) {
    array.sort((a, b) => {
      if (a.PriceListOrder < b.PriceListOrder) {
        return asc ? -1 : 1
      } else if (a.PriceListOrder > b.PriceListOrder) {
        return asc ? 1 : -1
      } else {
        return 0
      }
    })

    return array
  }

  sortStockCard(array, asc = true) {
    array.sort((a, b) => {
      if (a.StockCardOrder < b.StockCardOrder) {
        return asc ? -1 : 1
      } else if (a.StockCardOrder > b.StockCardOrder) {
        return asc ? 1 : -1
      } else {
        return 0
      }
    })

    return array
  }

  filter(cats) {
    if (!cats.length) {
      this.products = JSON.parse(this.original)
    } else {
      this.products = JSON.parse(this.original).filter((prod) => {
        return !cats.every((cat) => {
          if (cat === prod.Category) return false
          return true
        })
      })
    }
    return this.products
  }

  getProducts() {
    return this.products
  }

  getBrands() {
    const brands = this.products.map((item) => item.Brand)
    return brands.filter((value, index, self) => self.indexOf(value) === index)
  }

  getCategories() {
    const Category = this.products.map((item) => item.Category)
    return Category.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }

  getOriginalBrands() {
    const brands = this.originalList.map((item) => item.Brand)
    return brands.filter((value, index, self) => self.indexOf(value) === index)
  }

  getOriginalCategories() {
    const Category = this.originalList.map((item) => item.Category)
    return Category.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }

  getProductsByBrand(brand) {
    return this.products.filter((product) => product.Brand === brand)
  }

  getProductsByCategory(category) {
    return this.products.filter((product) => product.Category === category)
  }

  getOriginalList() {
    return this.originalList
  }

  getProduct(sku) {
    return this.originalList.filter((prod) => {
      return prod.SKU === sku
    })[0]
  }

  getProductsForCart(savedCartProducts) {
    const skus = savedCartProducts.map((prod) => {
      return prod.sku
    })

    const products = this.originalList.filter((prod) => {
      return skus.indexOf(prod.SKU) > -1
    })

    return products.map((prod) => {
      prod.Order = savedCartProducts.filter((p) => {
        return p.sku === prod.SKU
      })[0].quantity
      return prod
    })
  }
}

export default new ProductHelper()
