export default function InfoButton({ onClick }) {
  return (
    <button onClick={onClick} className={"bw-inline bw-bg-transparent bw-border-0 bw-ml-3 bw-cursor-pointer"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g id="Icon_feather-info" data-name="Icon feather-info" transform="translate(-1.5 -1.5)">
          <path id="Path_2080" data-name="Path 2080" d="M23,13A10,10,0,1,1,13,3,10,10,0,0,1,23,13Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
          <path id="Path_2081" data-name="Path 2081" d="M18,21.946V18" transform="translate(-5 -4.966)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
          <path id="Path_2082" data-name="Path 2082" d="M18,12h0" transform="translate(-5 -3)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
        </g>
      </svg>
    </button>
  )
}