import Request from 'superagent'

import {
  getAuthorization,
  getAccountNumberQuery as accountNumber,
} from '../../utils/storage'
import { domain } from 'Functions/data'
import productHelper from 'Functions/product.helper'
import axios from "axios";

const productsURL = domain + '/api/Product/AllProducts'
const recommendedProductsURL = domain + '/api/Product/GetRecommendedProducts'
//const suggestedProductsURL = DEV ? '/data/product-get_suggested_products' : domain + '/api/Product/GetSuggestedProducts';
const suggestedProductsURL = domain + '/api/Product/GetSuggestedProducts'
const alternativeProductURL = domain + '/api/Product/AlternativeProduct'
const haveYouConsideredProductsURL =
  domain + '/api/Product/GetHaveYouConsideredProducts'

const showStockCardURL = domain + '/api/Product/ShowStockCard'
const getStockCardURL = domain + '/api/Product/GetStockCard'
const getBeforeYouGoURL = domain + '/api/Product/GetBeforeYouGo'

/***
 Have You Forgotten
    
***/

export function getBeforeYouGoREQUEST(callback) {
  Request.get(getBeforeYouGoURL + accountNumber())
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }
      callback(true, res.body)
    })
}

/***
Show Stock Card
    200 if user has the role and has products in their cart; we should show the option
    204 if the user has the role; we should show the option
    401 if the user does not have the role; we should not show the option
***/

export function showStockCardREQUEST(callback) {
  Request.get(showStockCardURL + accountNumber())
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (res.statusCode < 300) callback(true)
      else callback(false)
    })
}

/***
Get Stock Card
    200   
    204 if the user has submitted no orders yet
    401 if the user does not have the required role
***/

export function getStockCardREQUEST(callback) {
  Request.get(getStockCardURL + accountNumber())
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (res.statusCode === 200) {
        callback(true, res)
      } else {
        callback(false, res)
      }
    })
}

/***
ALL PRODUCTS REQUEST (Initial Data Loading)
***/

export function productsREQUEST(callback) {
  Request.get(productsURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        if (err.status !== 401) callback(false)
        return false
      }

      productHelper.init(res.body.Products)
      callback(true)
    })
}

/***
RECOMMENDED PRODUCTS REQUEST (COMPONENT: RecommendProducts)
***/

export function recommendedProductsREQUEST(values, callback) {
  Request.get(recommendedProductsURL + accountNumber())
    .query(values)
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }
      callback(true, res.body.RecommendedProducts)
    })
}

/***
HAVE YOU CONSIDERED PRODUCTS REQUEST (COMPONENT: AllProducts)
***/

export function haveYouConsideredProductsREQUEST(values, callback) {
  Request.get(haveYouConsideredProductsURL + accountNumber())
    .query(values)
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      callback(true, res.body)
    })
}

/***
SUGGESTED PRODUCTS REQUEST (COMPONENT: Login)
***/

export function suggestedProductsREQUEST(callback) {
  Request.get(suggestedProductsURL + accountNumber())
    .set({ Authorization: getAuthorization() })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      const suggestedInfo = res.body
      const products = suggestedInfo.SuggestedProducts.map((prod) => {
        return Object.assign({}, prod.ProductDetails, {
          Order: prod.StockReplenishedQuantity,
        })
      })

      callback(true, products, suggestedInfo)
    })
}

/***
ALTERNATIVE PRODUCT REQUEST (COMPONENT: Product)
***/

export function alternativeProductREQUEST(sku, callback) {
  Request.get(alternativeProductURL + accountNumber())
    .query({ SKU: sku })
    .set({
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    })
    .responseType('json')
    .end((err, res) => {
      if (err) {
        callback(false)
        return false
      }

      callback(true, res.body)
    })
}

/***
 TOUR STATUS REQUEST (COMPONENT: StockCard)
 ***/

export async function getTourStatus() {
  try {
    const response = await axios.get('api/Product/GetStockcardTour')
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export async function saveTourProgress(step) {
  try {
    const response = await axios.post('api/Product/SubmitStockcardTour', {
      Completed: false,
      Step: step,
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export async function completeTour(step) {
  try {
    const response = await axios.post('api/Product/SubmitStockcardTour', {
      Completed: true,
      Step: step,
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}