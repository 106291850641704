import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import { competitionSubmit } from 'Functions/requests'
import { toggleLoader, showError, openPopup, closePopup } from 'Redux/actions.app'
import { testPromo } from 'Functions/validations'
import { removeLoggedIn } from 'Functions/storage'

import InputText from 'Components/form/InputText'
import Video from 'Components/blocks/Video'

const Names = {
  pc: 'code',
}

class PromoCodeField extends React.Component {
  
  constructor(props) {
    super(props)
    
    this.state = {
      hasVideo: false,
      invalidError: '',
    }
  }
  
  render() {
    const { handleSubmit, invalid, app_error } = this.props;
    
    return (
      <div className="popup-promocode">
        <h3 className="section_title">Welcome to JTI Engage</h3>
        
        <p>Enter our prize draw for your chance to win an Amazon Echo Spot*. Please enter the four-digit code that appeared at the end of the JTI Engage video.</p>
        <p>If you would like to view the video again you can <a href="#" onClick={ (e)=>this.showVideo(e) }>watch here</a>.</p>
        
        {
          this.state.hasVideo && (
            <Video name="introduction"/>
          )
        }
        
        <form onSubmit={ handleSubmit(submission) } autoComplete="off" autoCorrect="off" style={{ marginTop: `${30 / 16}rem` }}>
         
          <Field name={ Names.pc } component={ InputText } label="Prize draw code" type="text" placeholder="four-digit code"/>
          
          {
            app_error !== '' && (
              <p className="error_message" style={{ paddingBottom: '1em' }}>{ app_error }</p>  
            )
          }

          <p><Link to="/terms-conditions-promo" target="_blank">*Terms & Conditions</Link></p>

          <div className="buttons">
            <button className="button_style-2 fontsize19" type="submit" disabled={ invalid }>Submit</button>
            <button className="button_style-1 fontsize19" onClick={(e)=>this.toHome(e)}>Close</button>
          </div>

        </form>
      </div>
    )
  }
  
  toHome(e) {
    e.preventDefault();
    
    const { redirect } = this.props;
    redirect(e, '/');
  }
  
  showVideo(e) {
    e.preventDefault();
    
    this.setState({ hasVideo: true });
  }
}

const validation = (values) => {
  var error = {};
  
  if(!values[Names.pc]) {
    error[Names.pc] = ' ';
  } else if(!testPromo(values[Names.pc])) {
    error[Names.pc] = 'Please enter four-digit code.';
  }
  
  return error;
}

const submission = (values, dispatch, props) => {
  const { compName } = props;
  
  dispatch(toggleLoader(true));
  
  if(!compName) removeLoggedIn();
  
  competitionSubmit(
    {
      id: compName, 
      data: {
        name: Names.pc,
        value: values[Names.pc],
      }
    }, 
    (result, status, error) => {
      dispatch(toggleLoader(false));
      dispatch(showError(''));
      
      if(status !== 400) {
        dispatch(closePopup());
      }
    
      if(result) {
        
        window.setTimeout(()=>{
          dispatch(openPopup(
            'redirect-home',
            'Congratulations! Your entry has been registered.',
            ''
          ));
        }, 500);
      
      } else {
      
        if(status === 500) {
          removeLoggedIn();
        }
        
        if(status === 400) {
          dispatch(showError(error));
          return false;
        }
      
        window.setTimeout(()=>{
          dispatch(openPopup(
            'redirect-home',
            error,
            '',
          ));
        }, 500);
      }
    }
  );
}

const PromoCodeFieldForm = reduxForm({
  form: 'promo-code-form',
  validate: validation,
})(PromoCodeField)

PromoCodeField.propTypes = {
  redirect: PropTypes.func.isRequired,
  compName: PropTypes.string.isRequired,
}

function stateMapping(state) {
  return {
    app_error: state.app.error
  }
}

export default connect(stateMapping)(PromoCodeFieldForm)