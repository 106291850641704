import React from 'react'
import PropTypes from 'prop-types'

const Tooltip = (props) => {
  const { title, paragraphs, onClose } = props
  return (
    <div className="bw-cart-tooltip">
      <div className="bw-cart-tooltip--close" onClick={onClose}></div>
      <p className="bw-text-18 bw-font-medium bw-pr-5 bw-mb-5">{title}</p>
      {paragraphs.map((paragraph, index) => (
        <p className="bw-text-14" key={index}>
          {paragraph}
        </p>
      ))}
    </div>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
}

Tooltip.defaultProps = {
  title: 'Post Order Date',
  paragraphs: [
    'Orders can be post-dated by up to 4 weeks by selecting the required date at the base of the order screen. Please note that the order will not be processed until the date selected, delivery will be made within our standard timeframe (for more details please see our FAQs How long will delivery of my order take?).',
    'Post-dated order details can be edited up to 9pm on the day before the order is due to be processed. Please go to your My Orders section and click on Review Order.',
  ],
  onClose: () => {},
}

export default Tooltip
