import React, {useEffect, useRef, useState} from 'react'
import {Formik} from 'formik'
import {connect} from 'react-redux'
import qs from 'query-string'
import {resendEmailForm} from '../../utils/forms/public'
import {forgottenPassword} from '../../axios/public'
import {generalError} from '../../utils/constants'
import Button from '../../components/form/Button'
import TextInput from '../../components/form/TextInput'
import FirstTimeLogin from '../../components/FirstTimeLogin'
import {RecaptchaSiteKey} from './Login/utils'
import ReCAPTCHA from "react-google-recaptcha"

const ForgottenPassword = (props) => {
  const [state, setState] = useState({
    submitted: false,
    isNew: false,
    showGuide: false,
    error: false,
    invalidAttempts: 0,
  })

  const recaptchaRef = useRef(null)

  const handleOnchangeRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync();
  }

  useEffect(() => {
    const queryParams = qs.parse(location.search)
    if (queryParams['new-account'])
      setState((oldVal) => ({...oldVal, isNew: true}))
  }, [])

  const onSubmit = async (values, {setSubmitting}) => {
    handleOnchangeRecaptcha()
      .then((cToken) => {
        values.recaptchaResponse = cToken
        forgottenPassword(values)

      }).then(() => {
      setState((oldVal) => ({
        ...oldVal,
        submitted: true,
        error: false,
        invalidAttempts: 0,
      }))
    })
      .catch((err) => {
        recaptchaRef.current.reset()
        const message =
          err?.status === 400
            ? `${values.Email} is not an email address.`
            : err?.data?.Message || generalError
        setState((oldVal) => ({
          ...oldVal,
          error: message,
          invalidAttempts: oldVal.invalidAttempts + 1,
        }))
      })

    setSubmitting(false)
  }

  return (
    <>
      <h3 className="bw-h3 bw-mb-6">
        {state.isNew ? (
          <>
            Setting up a<br/>
            new password
          </>
        ) : (
          'Forgotten password'
        )}
      </h3>

      {!state.submitted ? (
        <>
          {state.isNew && (
            <p className="bw-font-normal bw-text-15 bw-mb-4 bw-pr-1 bw-leading-snug">
              If you are already a JTI Engage online customer, welcome to the
              new look website. To enter the site, please enter the email you
              registered with.
            </p>
          )}
          {state.error && (
            <p className="bw-text-red bw-text-15 bw-mb-2">
              {state.invalidAttempts >= 3 ? (
                <>
                  Please check that you're entering the email address for your
                  JTI account. If you are having issues logging onto the Engage
                  website please contact customer service on{' '}
                  <a href="mailto:websupportirl@jti.com">
                    websupportirl@jti.com
                  </a>{' '}
                  where someone will be able to help you.
                </>
              ) : (
                state.error
              )}
            </p>
          )}
          <Formik {...resendEmailForm} onSubmit={onSubmit}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                  label="Account Email Address"
                  errortext={errors.Email && touched.Email && errors.Email}
                />
                <ReCAPTCHA
                  sitekey={RecaptchaSiteKey}
                  ref={recaptchaRef}
                  size='invisible'
                />
                <Button
                  onClick={handleOnchangeRecaptcha}
                  className="bw-mb-6"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  label="Submit"
                />
              </form>
            )}
          </Formik>
          {state.isNew && (
            <>
              <p className="bw-font-normal bw-text-15 bw-mb-4 bw-pr-1 bw-leading-snug">
                For a step-by-step guide please
                <a
                  href="#"
                  className="bw-text-14 bw-ml-2"
                  onClick={() =>
                    setState((oldVal) => ({
                      ...oldVal,
                      showGuide: !oldVal.showGuide,
                    }))
                  }
                >
                  Click Here
                </a>
              </p>
              {state.showGuide && (
                <FirstTimeLogin
                  onClose={() =>
                    setState((oldVal) => ({
                      ...oldVal,
                      showGuide: false,
                    }))
                  }
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <p className="bw-font-bold bw-text-23 bw-mb-4">Thank you!</p>
          <p className="bw-font-normal bw-text-14 bw-mb-4 bw-pr-8 bw-leading-snug">
            If you have supplied a valid and registered email address you will
            shortly receive a password reset email. If you do not receive an
            email please double check the email address you supplied is correct
            and is your JTI registered email address.
          </p>
          <a href="/login" className="bw-btn-link">
            Go to Log In
          </a>
        </>
      )}
    </>
  )
}

export default connect()(ForgottenPassword)
