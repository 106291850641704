import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import { verifyEmail } from '../../axios/public'

import Loading from '../../components/Loading'

const ExpiredToken = () => (
  <>
    <h3 className="bw-h3 bw-mb-6 bw-pr-8">
      Your verification token has expired
    </h3>
    <p className="bw-mb-2">Please re-send verification email</p>
    <a href="/resend-verification" className="bw-btn-link">
      Resend Email
    </a>
  </>
)

const Error = ({ error }) => (
  <>
    <h3 className="bw-h3 bw-mb-6">Error</h3>
    <p className="bw-text-red bw-text-15 bw-mb-2">{error}</p>
    <a href="/login" className="bw-btn-link">
      Go to Log In
    </a>
  </>
)

const VerifyAccount = (props) => {
  const { history, location } = props
  const [state, setState] = useState({
    isExpired: false,
    error: false,
    loading: false,
  })

  useEffect(() => {
    const params = qs.parse(location.search)

    if (!params.token || !params.userId) {
      history.push('/login')
      return false
    }

    verifyEmail({
      code: params.token,
      userId: params.userId,
    })
      .then(() => {
        history.push('/login')
      })
      .catch((err) => {
        const message = err?.data?.Message || ''
        if (message) setState((oldVal) => ({ ...oldVal, error: message }))
        else setState((oldVal) => ({ ...oldVal, isExpired: true }))
      })
  }, [])

  return (
    <>
      {state.error ? (
        <Error error={state.error} />
      ) : state.isExpired ? (
        <ExpiredToken />
      ) : (
        <div className="bw-text-center">
          <Loading />
        </div>
      )}
    </>
  )
}

export default connect()(VerifyAccount)
