import {
  setToken,
  setTokenType,
  setUsername,
  setIsStaff,
  setIsRetailer,
  setAccountNumber,
} from '../utils/storage'

import { gaUserStatus } from 'Functions/ga'
import axios from './index'
import { setCurrentAccount, setUserAccounts } from '../script/redux/actions.app'
import store from '../script/redux/store'
import { getAccountNumber } from '../utils/storage'

/***
LOGGED IN CHECK (Component: Protected)
***/

export const checkAuth = () => {
  return new Promise((resolve, reject) => {
    axios
      .head('/api/Account/CheckAuth', {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

/***
LOGIN REQUEST (COMPONENT: Login)
***/

export function login(data) {
  const encodedData = normalize(data)
  return new Promise((resolve, reject) => {
    axios
      .post('/Token', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        const response = res.data

        setToken(response.access_token)
        setTokenType(response.token_type)
        setUsername(response.userName)
        setIsStaff(response.IsStaff)
        setIsRetailer(response.AccessToRestrictions)
        gaUserStatus('LoggedIn')
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

export const getAccounts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/Account/GetAccounts')
      .then((res) => {
        const accounts = res?.data?.UserAccounts || []
        const storageAcc = getAccountNumber()
        if (accounts.length) {
          //check if has set an account
          let accIndex = accounts.findIndex(
            (el) => el?.JtiAccountId === storageAcc
          )
          accIndex = accIndex > -1 ? accIndex : 0
          setAccountNumber(accounts[accIndex]?.JtiAccountId)
          store.dispatch(setUserAccounts(accounts || []))
          store.dispatch(setCurrentAccount(accounts[accIndex] || {}))
        }

        resolve(res)
      })
      .catch((err) => reject(err))
  })
}

function normalize(obj) {
  return Object.keys(obj).reduce((a, b) => {
    return a + (a ? '&' : '') + b + '=' + encodeURIComponent(obj[b])
  }, '')
}
