import PropTypes from 'prop-types'
import { GA } from '../../functions/ga'
// import PlayIcon from '@Images/playicon.png'
import PlayTriangle from '@Images/playTriangle.svg'
import { connect } from "react-redux";
import Preloader from "../Preloader"

class VideoTag extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasPlayed: 0,
    }
    this.onPlay = this.onPlay.bind(this)
    this.onPause = this.onPause.bind(this)
  }

  render() {
    const { removeDefault, image, video, loop, autoplay } = this.props
    const { hasPlayed } = this.state

    return (
      <div onClick={() => this.play(!removeDefault && !hasPlayed)}>
        <video
          ref={(dom) => (this.video = dom)}
          onContextMenu={(e) => {
            e.preventDefault()
            return false
          }}
          controlsList="nodownload"
          controls={
            autoplay || removeDefault || hasPlayed ? 'controls' : void 0
          }
          autoPlay={autoplay || void 0}
          muted={autoplay || void 0}
          preload={!autoplay ? 'none' : 'preload'}
          poster={image || void 0}
          loop={loop || void 0}
          onPlay={this.onPlay}
          onPause={this.onPause}
        >
          <source type="video/mp4" src={video} />
        </video>

        {!autoplay && !removeDefault && hasPlayed < 2 && (
          // <img className={ `video-playbutton ${hasPlayed === 1 ? 'animated duration05 fadeOut' : ''}` } src={ PlayIcon } alt="Play"/>
          <div
            className={`video-playbutton ${
              hasPlayed === 1 ? 'animated duration05 fadeOut' : ''
            }`}
          >
            <svg viewBox="0 0 152 176">
              <path
                id="Polygon_1"
                data-name="Polygon 1"
                d="M82.807,8.969a6,6,0,0,1,10.385,0l77.593,134.025A6,6,0,0,1,165.593,152H10.407a6,6,0,0,1-5.193-9.006Z"
                transform="translate(152) rotate(90)"
                fill="#00b2a9"
              />
            </svg>
          </div>
        )}
      </div>
    )
  }

  onPlay(evt) {
    if (this.props.track !== undefined) return false

    GA('Video Engagement', `'Play :: ' ${this.props.title}`,'click', this.props.accountId)
  }
  onPause(evt) {
    if (this.props.track !== undefined) return false

    GA('Video Engagement', `'Pause :: ' ${this.props.title}`,'click', this.props.accountId)
  }

  play(enabled) {
    if (enabled) {
      this.setState({ hasPlayed: 1 })
      this.video.play()

      window.setTimeout(() => {
        this.setState({ hasPlayed: 2 })
      }, 500)
    }

    if (this.props.track !== undefined) {
      this.props.track('View', this.props.id)
    }
  }
}

VideoTag.propTypes = {
  removeDefault: PropTypes.bool,
  image: PropTypes.string,
  video: PropTypes.string,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
}

class Video extends React.Component {
  render() {
    const { removeDefault, image, video, loop, autoplay } = this.props

    return (
      <div className="block-video">
        {!video ? (
          <div>
            <div className="bw-absolute bw-h-full bw-w-full bw-top-0 bw-left-0 bw-grid bw-place-items-center">
              <Preloader />
            </div>
          </div>
        ) : (
          <VideoTag
            video={video}
            image={image || ''}
            autoplay={autoplay || false}
            loop={loop || false}
            removeDefault={removeDefault || false}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

Video.propTypes = {
  removeDefault: PropTypes.bool,
  image: PropTypes.string,
  video: PropTypes.string,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(Video)
