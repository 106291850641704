import PropTypes from 'prop-types'

class ForgotPassAlert extends React.Component {
  
  render() {
    const { closeHandler } = this.props;
    
    return (
      <React.Fragment>
        <p>If you are having issues logging onto the New Engage site please contact customer service on <a href="mailto:websupportirl@jti.com">websupportirl@jti.com</a> where someone will be able to help you.<br/>
        {/*
          <br/>To place a stock order now you can use the old site for a limited time only.
        */}
        </p>
        
        <div className="buttons">
          {/*
            <a className="button_style-2 fontsize19" href="http://old.jtiengage.com/" target="_blank">Go to the site</a>
          */}
          <button className="button_style-1 fontsize19" onClick={(e)=>closeHandler(e)}>Close</button>
        </div>
      </React.Fragment>
    )
  }
}

ForgotPassAlert.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default ForgotPassAlert