import React from 'react'
import { connect } from 'react-redux'

import Footer from '../../components/Footer'
import JTILogo from '../../images/jti-logo.svg'
import PrivacyPolicyContent from '../../components/PrivacyPolicyContent'

const PrivacyPolicyPublic = (props) => {
  const { history } = props

  return (
    <>
      <div className="bw-container-page page-privacy_policy">
        <div className="bw-container">
          <img
            src={JTILogo}
            alt="JTI Engage"
            className="bw-mb-10 md:bw-mb-12 bw-cursor-pointer"
            onClick={() => history.push('/')}
          />
          <div className="bw-page-terms">
            <h1 className="bw-h1">Privacy Policy</h1>
            <PrivacyPolicyContent />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default connect()(PrivacyPolicyPublic)
