import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router";
import JTILogo from "../../images/jti-logo.svg";

import { gaPageView, trackingDomReady } from "../../script/functions/ga";
import { getNotifications, getBackgrounds } from "../../axios/public/content";
import Notification from "../notification";
import Background from "../background";

export const PublicTemplate = withRouter((props) => {
  const { children, history } = props;
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);

  useEffect(() => {
    getNotifications().then((res) => {
      setNotifications(() => res.NotificationMessages || []);
    });
    getBackgrounds().then((res) => {
      setBackgrounds(() => res.BackgroundAssets || []);
    });
  }, []);

  useEffect(() => {
    trackingDomReady();
    gaPageView(history.location.pathname);
  }, [location]);

  return (
    <div
      className={`bw-public-template ${
        !notifications.length ? "bw-no-notification" : ""
      }`}
    >
      <div className="bw-public-form">
        <img
          src={JTILogo}
          alt="JTI Engage"
          className="bw-mb-10 md:bw-mb-12 bw-cursor-pointer"
          onClick={() => history.push("/")}
        />
        {children}
      </div>
      <div className="bw-public-bg">
        <Background backgrounds={backgrounds} />
        {notifications.length ? (
          <div className="bw-public-notification">
            <Notification slides={notifications} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
});

export default PublicTemplate;
