import React, {useState} from "react";
import Progress from "./Progress";
import {submitQuestion} from "../../../axios/private/survey";

export default function RateQuestion({
                                       question,
                                       currentQuestion,
                                       totalQuestions,
                                       nextQuestion,
                                       questionId,
                                       surveyId,
                                       setIsLoading,
                                     }) {
  const [answer, setAnswer] = useState(null);

  function handleSubmit() {
    if (answer) {
      setIsLoading(true);
      submitQuestion(surveyId, questionId, [{Custom: answer}]).then(() => {
        setIsLoading(false);
        nextQuestion();
      });
    }
  }

  return (
    <div className={"rate-question"}>
      <p className={"rate-question__subtitle"}>
        To help us build the JTI ENGAGE of the future it would be great if you
        could answer the below question:
      </p>
      <p className={"rate-question__question"}>{question}</p>
      <div className={"rate-question__scale"}>
        <span>Poor</span>
        <span>Excellent</span>
      </div>
      <div className={"rate-question__ratings"}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <button
            className={`rate-question__ratings__btn ${
              answer === rating ? "rate-question__ratings__btn--active" : ""
            }`}
            onClick={() => setAnswer(rating)}
          >
            {rating}
          </button>
        ))}
      </div>

      <button onClick={handleSubmit} className={"rate-question__next-btn"}>
        Next
      </button>
      <div className={"rate-question__progress"}>
        <Progress currentStep={currentQuestion} totalSteps={totalQuestions}/>
      </div>
    </div>
  );
}