export const getTier = (tierLevel) => {
  switch (tierLevel) {
    case 'Tier 1':
      return 1
    case 'Tier 2':
      return 2
    case 'Tier 3':
      return 3
    default:
      return 2
  }
}
