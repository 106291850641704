import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { useHistory } from "react-router-dom"

import { generalError } from '../../utils/constants'
import { changePassword } from '../../axios/private/account'

import { changePasswordForm } from '../../utils/forms/private'
import Button from '../../components/form/Button'
import TextInput from '../../components/form/TextInput'
import PasswordHelper from '../../components/form/PasswordHelper'

const ChangePassword = (props) => {
  const [state, setState] = useState({
    showPassHelper: false,
    passwordStatus: {},
    submitted: false,
    error: false,
  })
  const [invalidAttempts, setInvalidAttempts] = useState(0)
  const history = useHistory()

  useEffect(() => {
    if (invalidAttempts === 3) {
      sessionStorage.clear()
      history.replace("/login")
    }
  }, [invalidAttempts])

  const onChangePassword = async (values, { setSubmitting }) => {
    // console.log(values)
    await changePassword(values)
      .then((res) => {
        setState((oldVal) => ({ ...oldVal, submitted: true }))
      })
      .catch((err) => {
        const message =
          err.status === 400
            ? 'Password can be changed only once a day.'
            : generalError
        setState((oldVal) => ({ ...oldVal, error: message }))
        setInvalidAttempts((oldVal) => oldVal + 1)
      })
    setSubmitting(false)
  }

  const togglePassHelper = () => {
    setState((oldVal) => ({
      ...oldVal,
      showPassHelper: !oldVal.showPassHelper,
    }))
  }

  const onGoBack = () => {
    setState((oldVal) => ({ ...oldVal, error: false, submitted: false }))
  }

  return (
    <>
      <div className="bw-container-page bw-change-pass">
        <div className="bw-container">
          <h1 className="bw-h1 bw-mt-0 bw-mb-5">Change Password</h1>
          {state.submitted ? (
            <>
              <p className="bw-text-15 bw-mb-16">
                Your password has been successfully changed.
              </p>
              <Button label="Back" onClick={onGoBack} />
            </>
          ) : (
            <>
              <p className="bw-text-15 bw-mb-10 bw-mr-8 md:bw-mr-0">
                Please add a password you have not used yet. You can change your
                password once a day.
              </p>
              {state.error && (
                <p className="bw-text-red bw-text-15 bw-mb-2 bw-max-w-lg">
                  {state.error}
                </p>
              )}
              <Formik
                {...changePasswordForm}
                validate={(val) => changePasswordForm.validate(val, setState)}
                onSubmit={onChangePassword}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <TextInput
                      type="password"
                      name="OldPassword"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.OldPassword}
                      label="Current Password"
                      errortext={
                        errors.OldPassword &&
                        touched.OldPassword &&
                        errors.OldPassword
                      }
                    />
                    <div className="bw-relative">
                      <TextInput
                        type="password"
                        name="NewPassword"
                        autoComplete="new-password"
                        onChange={handleChange}
                        onBlur={(e) => {
                          togglePassHelper()
                          handleBlur(e)
                        }}
                        onFocus={togglePassHelper}
                        value={values.NewPassword}
                        label="New Password"
                        errortext={
                          errors.NewPassword &&
                          touched.NewPassword &&
                          errors.NewPassword
                        }
                      />
                    </div>

                    <TextInput
                      type="password"
                      name="ConfirmPassword"
                      autoComplete="new-password"
                      className="bw-mb-3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ConfirmPassword}
                      label="Confirm Password"
                      errortext={
                        errors.ConfirmPassword &&
                        touched.ConfirmPassword &&
                        errors.ConfirmPassword
                      }
                    />
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="bw-mb-3"
                      label="Change Password"
                    />
                    {state.showPassHelper && (
                      <PasswordHelper
                        {...state.passwordStatus}
                      />
                    )}
                  </form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default connect()(ChangePassword)
