import axios from 'axios'
import { domain } from '../utils/constants'
import { getAuthorization, getAccountNumber } from '../utils/storage'

axios.defaults.baseURL = domain

axios.interceptors.request.use((request) => {
  request.baseURL = domain
  request.params = {}
  const token = getAuthorization()
  const accountNumber = getAccountNumber()
  if (token) request.headers.common['Authorization'] = token
  if (accountNumber) request.params.accountNumber = accountNumber
  return request
})

export default axios
