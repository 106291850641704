import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import productHelper from 'Functions/product.helper'
import { updateCartREQUEST } from '@Requests/saved-cart'
import { addOrder, updateSavedCart, addProductSavedCart } from 'Redux/actions.prod'
import { setCartStorage } from 'Functions/storage'

class SubmitTAndTWarn extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      error: false,  
    }
  }
  
  render() {
    const { closeHandler, tandtProducts, outStocks } = this.props;
    
    return (
      <React.Fragment>
        {
          tandtProducts.length > 0 && (
            <React.Fragment>
              <p>You have Track & Trace product{ tandtProducts.length > 1 && 's' } in your cart.</p>
              <br/>
              <ul className="color-orange">
                {
                  tandtProducts.map((prod, i) => 
                    <li key={ i }>{ prod.name }</li>
                  )
                }
              </ul>
              <br/>
              <p>Track & Trace product{ tandtProducts.length > 1 ? 's have' : ' has' } been removed from your cart. <strong>To order Track & Trace products please register for your EOID and FID codes.</strong>.</p>
              <br/>
            </React.Fragment>
          )
        }
        
        {
          outStocks.length > 0 && (
            <React.Fragment>
              <p>You have out of stock product{ outStocks.length > 1 && 's' } in your cart.</p>
              <br/>
              <ul className="color-orange">
                {
                  outStocks.map((prod, i) => 
                    <li key={ i }>{ prod.name }</li>
                  )
                }
              </ul>
              <br/>
              <p>Out of stock product{ outStocks.length > 1 ? 's have' : ' has' } been removed from your cart.</p>
            </React.Fragment>
          )
        }
      
        <div className="buttons">
          <button className="button_style-1 fontsize19" onClick={(e)=>closeHandler(e)}>Close</button>
        </div>
        
        {
          this.state.error && (
            <p className="color-error">Sorry, our server has encountered an internal error and was unable to remove unavailable products from your cart.</p>  
          )
        }
        
      </React.Fragment>
    )
  }
  
  componentDidMount() {
    const { dispatch, tandtProducts, outStocks, savedCart, isOrderEditing } = this.props;
    
    const products = [...new Set([...tandtProducts.map(v=>{return JSON.stringify(v)}), ...outStocks.map(v=>{return JSON.stringify(v)})])]
      .map((v) => { return JSON.parse(v) })
      .map((v) => {
        return {
          sku: v.sku,
          quantity: 0,
        }
      });
    
    if(!isOrderEditing) {
      
      updateCartREQUEST(savedCart, products, (result, data, error) => {
        if(result) {
          
          products.forEach((prod) => {
            dispatch(addOrder(prod.sku, 0, 0, false));
          });
          dispatch(updateSavedCart(data));

        } else {

          this.setState({ error: true });

        }
      });
      
    } else {
     
      products.forEach((prod) => {
        
        dispatch(addOrder(prod.sku, 0, 0, false));
        dispatch(addProductSavedCart(prod.sku, 0));
      
      });
      
    }
  }
}

SubmitTAndTWarn.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  tandtProducts: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  outStocks: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
}

function stateMapping(state) {
  return {
    savedCart: state.prod.savedCart,
    isOrderEditing: state.prod.isOrderEditing,
  }
}

export default connect(stateMapping)(SubmitTAndTWarn)