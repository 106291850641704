import axios from '../index'
import store from '../../script/redux/store'
import { setCart } from '../../script/redux/actions.app'
import { stringify as stringifyParams } from 'query-string'
import { addOrder, updateSavedCart } from '../../script/redux/actions.prod'
import { normalizeOldCartData } from '../../utils/functions/old'

export const getCart = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/BackEndCart')
      .then((res) => {
        const data = res?.data || {}
        resolve(data)
        store.dispatch(setCart(data))
        // store data in old way for old pages (place-order and stock-card)
        data?.BackEndCartDetails?.map(({ Product, Quantity }) => {
          store.dispatch(addOrder(Product.SKU, Quantity, Quantity, true))
        })
        store.dispatch(updateSavedCart(normalizeOldCartData(data)))
      })
      .catch((err) => reject(err.response))
  })
}

export const deleteCart = (beCartId) => {
  const params = stringifyParams({ beCartId })
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/BackEndCart?${params}`)
      .then((res) => {
        if (res.status === 204) getCart()
        resolve(res?.data || {})
      })
      .catch((err) => reject(err.response))
  })
}

export const validateCart = (beCartId) => {
  const params = stringifyParams({ beCartId })
  return new Promise((resolve, reject) => {
    axios
      .patch(`/api/BackEndCart?${params}`)
      .then((res) => {
        resolve(res?.data || [])
      })
      .catch((err) => reject(err.response))
  })
}

export const addCartProduct = (data,shouldDispatch=true) => {
  const params = stringifyParams(data)
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/BackEndCart?${params}`)
      .then((res) => {
        const data = res?.data || {}
        if(shouldDispatch)
        store.dispatch(setCart(data))
        resolve(data)
      })
      .catch((err) => reject(err.response))
  })
}

export const removeCartProduct = (data) => {
  const params = stringifyParams(data)
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/BackEndCart?${params}`)
      .then((res) => {
        const data = res?.data || {}
        store.dispatch(setCart(data))
        resolve(data)
      })
      .catch((err) => reject(err.response))
  })
}

export const addCartSuggestedProducts = (beCartId) => {
  const params = stringifyParams({ beCartId })
  return new Promise((resolve, reject) => {
    axios
      .patch(`/api/BackEndCart/AddSuggestedOrderProducts?${params}`)
      .then((res) => {
        const data = res?.data || {}
        store.dispatch(setCart(data))
        resolve(data)
      })
      .catch((err) => reject(err.response))
  })
}
