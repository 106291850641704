import React, { Component } from 'react';
import Request from 'superagent';
import { getAuthorization } from 'Functions/storage';
import { domain } from 'Functions/data';
import { gsap, Quart } from 'gsap/all';
import { GA } from 'Functions/ga'
import Logo from './Logo';
import { getSubjects } from 'Functions/contents'
import { connect } from "react-redux";
 

class ChatBot extends Component {

  constructor(props) {
    super(props);

    this.user = <svg xmlns="http://www.w3.org/2000/svg" width="41" height="33" viewBox="0 0 41 33"><g transform="translate(-1729 -675)"><circle cx="3.481" cy="3.481" r="3.481" transform="translate(1737.931 687.92)"/><g transform="translate(1729 675)" fill="none" stroke="#000" strokeWidth="3"><rect width="41" height="33" rx="16.5" stroke="none"/><rect x="1.5" y="1.5" width="38" height="30" rx="15" fill="none"/></g><circle cx="3.481" cy="3.481" r="3.481" transform="translate(1753.931 687.92)"/></g></svg>;
    
    this.state = {
      open: false,
      hidden: false,
      
      chat: [
      ]
    }

    this.start = this.start.bind(this);
    this.hide = this.hide.bind(this);

    this.timer = setTimeout(this.hide, 5000);
  }

  componentDidMount() {
    //Velocity(document.querySelector('.chatbot__header'), {translateY: 10}, {duration: 0});
    gsap.set('.chatbot__header', {y: 10});
    getSubjects((data) => {
      this.setState({ subjects: data });
    });
  }

  getQuestion(id) {
    let url = domain + '/api/Cms/HelpbotQuestion';
    if (id > 0) url += '?questionId='+id;
    Request
      .get(url)
      .set({'Authorization': getAuthorization()})
      .responseType('json')
      .end((err, res) => {
        if(err) {
          return false;
        }
        let { chat } = this.state;
        chat.push(res.body);
        this.setState({ chat }, function() {
          var objDiv = document.querySelector('.chatbot__chat');
          objDiv.scrollTop = objDiv.scrollHeight;
        });
      });
  }

  hide(e) {
    if (e) e.stopPropagation();
    if (e && document.querySelector('html').classList.contains('no-touchevents') || !e) {
      if (!this.state.open) {
        this.setState({ hidden: true}, function() {
          gsap.to('.chatbot', 0.4, {width: 58, ease: Quart.easeOut, overwrite: true});
          gsap.to('.chatbot__open', 0.2, {alpha: 0, right: 0, ease: Quart.easeOut, overwrite: true});
        });
        return false;
      }
    }
  }

  show(e) {
    if (document.querySelector('html').classList.contains('no-touchevents')) {
      if (e) e.stopPropagation();
      if (!this.state.open) {
        this.setState({ hidden: false}, function() {
          gsap.to('.chatbot', 0.4, {width: 170, ease: Quart.easeOut, overwrite: true});
          gsap.to('.chatbot__open', 0.2, {alpha: 1, right: 16, ease: Quart.easeOut, delay: 0.2, overwrite: true});
          gsap.to('.chatbot__logo', 0.2, { css: {left: 12, translateX: 0}, delay: 0, ease: Quart.easeOut });
        });
        return false;
      }
    }
  }

  open() {
    if (!this.state.open) {
      //category, label, action = 'click'
      setTimeout(() => {
        GA('HelpBot Engagement', 'Open HelpBot','click', this.props.accountId);
        this.setState({ open: true });
        if (document.querySelector('html').classList.contains('touchevents')) {
          document.querySelector('body').classList.add('noscroll');
        }

        gsap.to('.chatbot', 0.2, { width: 602, ease: Quart.easeOut });
        gsap.to('.chatbot', 0.2, { css: {height: '100vh', top: 0, paddingTop: 145}, delay: 0.1, ease: Quart.easeOut });

        gsap.to('.chatbot__open', 0.2, { alpha: 0, right: 0, ease: Quart.easeOut });
        gsap.to('.chatbot__hide', 0.2, { alpha: 0, right: -40, ease: Quart.easeOut });

        gsap.to('.chatbot__logo', 0.2, { css: {left: '50%', translateX: '-50%'}, ease: Quart.easeOut });
        gsap.set('.chatbot__logo', { height: 85, width: 65, delay: 0.1 });
        gsap.to('.chatbot__logo', 0.3, { top: 60, ease: Quart.easeOut });

        gsap.to('.chatbot__close', 0.2, { top: 35, alpha: 1, delay: 0.4, ease: Quart.easeOut });
        gsap.to('.chatbot__header', 0.2, { alpha: 1, y: 0, delay: 0.4, ease: Quart.easeOut });

        setTimeout(this.start, 1000);
      }, 100);
    }
  }

  close() {
    if (this.state.open) {
      GA('HelpBot Engagement', 'Close HelpBot','click', this.props.accountId);
      if (document.querySelector('html').classList.contains('touchevents')) {
        document.querySelector('body').classList.remove('noscroll');
      }
      gsap.to('.chatbot__close', 0.12, { top: 48, alpha: 0, ease: Quart.easeOut });
      gsap.to('.chatbot__header', 0.12, { alpha: 0, y: 10, ease: Quart.easeOut });
      
      gsap.set('.chatbot__logo', { height: 22, width: 35 });
      gsap.to('.chatbot__logo', 0.2, { top: 16, ease: Quart.easeOut });

      gsap.to('.chatbot', 0.2, { width: 58, ease: Quart.easeOut, delay: 0.2 });
      gsap.to('.chatbot', 0.2, { css: {height: '50px', top: window.innerWidth >= 819.5 ? 187 : 142, paddingTop: 0}, delay: 0.12, ease: Quart.easeOut });

      this.setState({ chat: [] });
      setTimeout(function() {
        this.setState({ open: false });
      }.bind(this), 500)
    }
  }

  start() {
    this.setState({ chat: [] }, this.getQuestion(0));
  }

  answer(id, answer) {
   
    // fetch new question
 
    let { chat } = this.state;
    
  
    chat.push({ answer: answer });
    //chat.push(this.state.script[id])

   
    this.getQuestion(id)

    if(chat.length>=2){
    
      GA('HelpBot Engagement',  `${chat[chat.length-2].Text} :: ${answer}` ,'click', this.props.accountId );
    }

    this.setState({ chat }, function() {
      var objDiv = document.querySelector('.chatbot__chat');
      objDiv.scrollTop = objDiv.scrollHeight;
    
    });
  }
  
  linkAction(e,link){
    e.preventDefault()
    if(link==='/#/?section=downloads' && this.props.history.location.pathname === "/" ){
      this.close()
      document.documentElement.scrollTop =  document.getElementById('bw-private-widgets').offsetTop;
    }else if(link==='/#/' && this.props.history.location.pathname === "/" ){
      this.close()
    }else{
      this.props.history.push(link.replace('/#', ''))
    }
  }

  revert(index) {
    let { chat } = this.state;
    chat.length = index+1;
    this.setState({ chat }, function() {
      var objDiv = document.querySelector('.chatbot__chat');
			objDiv.scrollTop = objDiv.scrollHeight;
    });
  }

  render() {
    const { chat } = this.state;

    return (
      <div className={ `chatbot${this.state.open ? ' chatbot--open' : ''}` } onClick={ () => this.open() } onMouseOver={ (e) => this.show(e) }onMouseOut={ (e) => this.hide(e) }>
        
        <svg className="chatbot__close" onClick={ () => this.close() } xmlns="http://www.w3.org/2000/svg" width="22.627" height="22.627" viewBox="0 0 22.627 22.627"><g transform="translate(-791.252 503.46) rotate(-45)"><line x2="30" transform="translate(900.5 219.5)" fill="none" stroke="#000" strokeWidth="2"/><line x1="30" transform="translate(915.5 204.5) rotate(90)" fill="none" stroke="#000" strokeWidth="2"/></g></svg>
        <Logo />

        <div className="chatbot__open">Can I help?</div>
        <div className="chatbot__header">Engage Helpbot </div>

        <div className="chatbot__chat">
          {
            chat.map((e, i) => (
              e.Text ?
                <div className="chatbot__bot" key={ `msg_${i}` }>
                  <Logo classless />
                  
                  { (chat.length > 1 && i < chat.length-1) && <svg onClick={ () => this.revert(i) } xmlns="http://www.w3.org/2000/svg" width="26.861" height="26.861" viewBox="0 0 26.861 26.861"><path d="M14.966,4a10.966,10.966,0,0,0,0,21.932,11.912,11.912,0,0,0,10.966-8.225H23.191a9.522,9.522,0,0,1-8.225,5.483,8.225,8.225,0,0,1,0-16.449,6.4,6.4,0,0,1,5.483,2.742L16.337,13.6h9.6V4L23.191,6.742C20.723,5.371,17.982,4,14.966,4Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 2.848, -4.899)" fillRule="evenodd"/></svg> }

                  <p>{ e.Text }</p>
                  { i === chat.length-1 &&
                    <div className="chatbot__bot-options">
                      { e.Answers.map((a, j) => (
                        a.NextQuestionId ?
                          <button key={ `opt_${i}_${j}` } className="chatbot__button" onClick={ () => this.answer(a.NextQuestionId, a.Text) }>{ a.Text }</button>
                        :
                        <a href="#" onClick={ event=>this.linkAction(event,a.CTALink ) } key={ `opt_${i}_${j}` } className="chatbot__button">{ a.Text }</a>
                      ))}
                    </div>
                  }
                </div>
              :
                <div className="chatbot__answer" key={ `msg_${i}` }>
                  <span>{ e.answer }</span>
                  { this.user }
                </div>
            ))
          }
        </div>
        
      </div>
    );
  }
}

function stateMapping(state) {
  return {
    accountId: state.app.currentAccount.JtiAccountId
  };
}

export default connect(stateMapping)(ChatBot)